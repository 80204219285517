import React from "react";
import { Navigate } from "react-router-dom";

import LoginForm from "../LoginForm";
import Logout from "../Logout";
import NotFound from "../NotFound";
import LpimHome from "./LpimHome";
import ContactUsForm from "../common/ContactUsForm";

// ----- Performance -----
import QuickSightDashboards from "./performance/QuickSightDashboards";
import LookerStudioDashboard from "./performance/LookerStudioDashboard";

// ----- Product Detail -----
import LpimProductSearch from "./product/LpimProductSearch";
import LpimProductDetail from "./product/LpimProductDetail";
// ----- Listing & Dq -----
import ListingDqAnal from "./listing_dq/ListingDqAnal";
// ----- Price Watch -----
import PriceWatchHome from "./price_watch/PriceWatchHome";
import CompeAnal from "./price_watch/CompeAnal";
import StaleCompe from "./price_watch/StaleCompe";
import StaleCompeAudit from "./price_watch/StaleCompeAudit";
import MarginAnal from "./price_watch/MarginAnal";
import PriceVsBigc from "./price_watch/PriceVsBigc";
import SalesAnal from "./price_watch/SalesAnal";
// ----- Inventory Watch -----
import InventoryMismatch from "./inventory_watch/InventoryMismatch";
import InactiveInStock from "./inventory_watch/InactiveInStock";
import ActiveNotInStock from "./inventory_watch/ActiveNotInStock";
import StaleInventory from "./inventory_watch/StaleInventory";
import OverstayedStock from "./inventory_watch/OverstayedStock";
// ----- Marketing -----
import MarketingAnal from "./marketing/MarketingAnal";

// importing BrowserRouter as Router allows us to use the name "Router" for convenience
// in later react-router-dom "Switch" is replaced with "Routes"
import { useRoutes } from "react-router-dom";

const LpimRoutes = ({ user = "", baseConfig }) => {
  let routes = useRoutes([
    //--------- Public paths --------------------------------------------------------
    {
      path: "/",
      // element: user ? <LpimHome /> : <Navigate to="/login" />,
      // @TODO -> create proper home page as needed
      element: user ? <LpimProductDetail /> : <Navigate to="/login" />,
    },
    {
      path: "/login",
      element: <LoginForm termsLink="#" linkTarget="_self" />,
    },
    {
      path: "/logout",
      element: <Logout />,
    },
    {
      path: "/not-found",
      element: <NotFound />,
    },
    {
      path: "/contact",
      element: <ContactUsForm baseConfig={baseConfig} />,
    },
    //--------- performance --------------------------------------------------------
    {
      path: "/dashboard-listings",
      element: user ? <QuickSightDashboards /> : <Navigate to="/login" />,
    },
    {
      path: "/dashboard-marketing",
      element: user ? <LookerStudioDashboard /> : <Navigate to="/login" />,
    },
    //--------- product-detail --------------------------------------------------------
    {
      path: "/product-search",
      element: user ? <LpimProductSearch /> : <Navigate to="/login" />,
    },
    {
      path: "/product-detail",
      element: user ? <LpimProductDetail /> : <Navigate to="/login" />,
    },
    {
      path: "/product-detail/:sku",
      element: user ? <LpimProductDetail /> : <Navigate to="/login" />,
    },
    ,
    //--------- listing dq paths --------------------------------------------------------
    {
      path: "/listing-dq",
      element: user ? <ListingDqAnal /> : <Navigate to="/login" />,
    },
    //--------- price watch paths --------------------------------------------------------
    {
      path: "/price-watch",
      element: user ? <PriceWatchHome /> : <Navigate to="/login" />,
    },
    {
      path: "/compe-anal",
      element: user ? <CompeAnal /> : <Navigate to="/login" />,
    },
    {
      path: "/stale-compe",
      element: user ? <StaleCompe /> : <Navigate to="/login" />,
    },
    {
      path: "/overdue-compe-audit",
      element: user ? <StaleCompeAudit /> : <Navigate to="/login" />,
    },
    {
      path: "/margin-anal",
      element: user ? <MarginAnal /> : <Navigate to="/login" />,
    },
    {
      path: "/price-vs-bigc",
      element: user ? <PriceVsBigc /> : <Navigate to="/login" />,
    },
    {
      path: "/sales-anal",
      element: user ? <SalesAnal /> : <Navigate to="/login" />,
    },
    //--------- inventory watch paths --------------------------------------------------------
    {
      path: "/inventory-mismatch",
      element: user ? <InventoryMismatch /> : <Navigate to="/login" />,
    },
    {
      path: "/inactive-instock",
      element: user ? <InactiveInStock /> : <Navigate to="/login" />,
    },
    {
      path: "/active-not-in-stock",
      element: user ? <ActiveNotInStock /> : <Navigate to="/login" />,
    },
    {
      path: "/stale-inventory",
      element: user ? <StaleInventory /> : <Navigate to="/login" />,
    },
    {
      path: "/overstayed-stock",
      element: user ? <OverstayedStock /> : <Navigate to="/login" />,
    },
    //--------- marketing paths --------------------------------------------------------------
    {
      path: "/google-anal",
      element: user ? (
        <MarketingAnal mktgChannel="google" />
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/bing-anal",
      element: user ? (
        <MarketingAnal mktgChannel="bing" />
      ) : (
        <Navigate to="/login" />
      ),
    },
  ]); // end useRoutes
  return routes;
};

export default LpimRoutes;

import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link as RouterLink } from "react-router-dom";
import {
  TextField,
  Button,
  Box,
  Typography,
  Paper,
  Divider,
  Grid,
  Link as MuiLink,
  Container,
  Alert,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import LinkIcon from "@mui/icons-material/Link";
import SearchIcon from "@mui/icons-material/Search";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import http from "../../services/httpService";
import PatentClaimsList from "./PatentClaimsList";
import { useAuth } from "../../context/AuthContext";
import { useWorksheet } from "../../context/WorksheetContext";
import { normalizePatId, stripClaimNumber } from "./utils/patentUtils";

const apiUrlPatanal = `${window._env_.REACT_APP_PATANALAI_API_URL}`; // Patanal API Endpoint
const SOURCE_IS_PATANAL = false; // Select between Patanal and PQAI DB

const PatentSearch = () => {
  const { user } = useAuth();
  const { worksheet, addToWorksheet } = useWorksheet();
  const patentsInWorksheet = worksheet.patents; // Access the 'patents' array from the worksheet object
  const navigate = useNavigate();
  const { patentId: urlPatentId } = useParams();
  const [patentId, setPatentId] = useState("");
  const [patentResult, setPatentResult] = useState({ data: null, message: "" });
  const [patLoading, setPatLoading] = useState(false);
  const [dwgLoading, setDwgLoading] = useState(false);
  const [showClaims, setShowClaims] = useState(false);
  const [dwgs, setDwgs] = useState([]);
  const [showDwgs, setShowDwgs] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedClaim, setSelectedClaim] = useState(null); // State to track selected claim
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (urlPatentId) {
      setPatentId(urlPatentId);
      fetchPatent(urlPatentId);
    }
  }, [urlPatentId]);

  //-------------------------------------------------------------------------------
  // Fetch Patents
  //-------------------------------------------------------------------------------
  const fetchPatent = async (id) => {
    setPatLoading(true);

    // Clear fetched drawings and close tabs
    setShowClaims(false); // Hide the claims section if it's visible
    setShowDwgs(false);
    setDwgs([]);

    // Normallize the ID
    let normalizedId = normalizePatId(id);

    const tryFetch = async (currentId) => {
      const apiUrl = `${apiUrlPatanal}/patents/${currentId}`;
      const headers = {
        Authorization: `JWT ${http.getCookie("access_token")}`,
      };
      const query = new URLSearchParams({
        source: SOURCE_IS_PATANAL ? "patanal" : "pqai",
      });

      try {
        const response = await axios.get(`${apiUrl}?${query.toString()}`, {
          headers,
        });
        return { found: true, data: response.data };
      } catch (error) {
        if (
          axios.isAxiosError(error) &&
          (error.response?.status === 404 || error.response?.status === 500) &&
          currentId.endsWith("B2")
        ) {
          // If B2 not found, try B1
          normalizedId = currentId.replace("B2", "B1");
          return tryFetch(normalizedId);
        }
        return { found: false };
      }
    };

    const result = await tryFetch(normalizedId);
    if (result.found) {
      const resultData = { data: result.data, message: "" };
      setPatentResult(resultData);
      // Update patentId only if not isExampleInitFetch and the normalizedId is different from the initially provided id
      if (normalizedId !== id) {
        setPatentId(normalizedId);
      }
    } else {
      console.error("Failed to fetch patent: ", id);

      const message = (
        <span>
          Patent matching query not found in our database. Search in Google{" "}
          <MuiLink
            href={`https://patents.google.com/patent/${id}`}
            target="_blank"
          >
            {id}
          </MuiLink>
          .
        </span>
      );

      const errorResult = { data: null, message: message };

      setPatentResult(errorResult);
    }

    setPatLoading(false);
  };

  //-------------------------------------------------------------------------------
  // Fetch Drawings
  //-------------------------------------------------------------------------------
  const fetchDrawings = async (id) => {
    setDwgLoading(true);
    // Example: /patents/US7654321B2/all-dwgs
    // @TODO --> server log at endpoint shows this is being fetched 3 times for the same domains
    // const apiUrl = `${apiUrlPatanal}/patents/${id}/all-dwgs?w=800&h=800&imgfmt=png`;
    const apiUrl = `${apiUrlPatanal}/patents/${id}/all-dwgs`;
    const headers = {
      Authorization: `JWT ${http.getCookie("access_token")}`,
    };
    const query = new URLSearchParams({
      source: SOURCE_IS_PATANAL ? "patanal" : "pqai",
      w: "800",
      // h: "800",
      imgfmt: "png",
    });
    try {
      // const response = await http.get(apiUrl);
      const response = await axios.get(`${apiUrl}?${query.toString()}`, {
        headers,
      });
      const dwgPromises = response.data.dwgs.map(async (dwgUrl) => {
        const imageResponse = await fetch(dwgUrl, {
          headers: {
            Authorization: `JWT ${http.getCookie("access_token")}`,
          },
        });
        const imageBlob = await imageResponse.blob();
        return URL.createObjectURL(imageBlob);
      });

      const dwgsDataUrls = await Promise.all(dwgPromises);
      setDwgs(dwgsDataUrls);
      setShowDwgs(true);
    } catch (error) {
      console.error("Failed to fetch drawings:", error);
      setDwgs([]);
      setShowDwgs(false);
    } finally {
      setDwgLoading(false);
    }
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  const toggleDwgs = () => {
    setShowDwgs(!showDwgs);
    if (!showDwgs && dwgs.length === 0) {
      fetchDrawings(patentId);
    }
  };

  const clearSearch = () => {
    setPatentId(""); // Clear the patent ID input
    // Clear the search results
    setPatentResult({
      data: null,
      message: "",
    });
    setShowClaims(false); // Hide the claims section if it's visible
    setShowDwgs(false);
    setDwgs([]);
  };

  // format date from yyyy-mm-dd
  // const formatDate = (dateString) => {
  //   const options = { year: "numeric", month: "long", day: "numeric" };
  //   return new Date(dateString).toLocaleDateString(undefined, options);
  // };

  const formatDate = (dateString) => {
    // Specify the locale as 'en-US' and change the month format to 'short' to get the abbreviated month
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      timeZone: "UTC",
    };
    // Create a new Date object in UTC to avoid timezone issues
    const date = new Date(dateString + "T00:00:00Z");
    return date.toLocaleDateString("en-US", options);
  };

  // Helper function to get formatted inventor name. Return last name. If more than one add et al.
  // Example: "Alexander F. Zazovsky" or "Colin Longfield"
  const getFormattedInventors = (inventors) => {
    if (inventors && inventors.length === 0) return "";

    const lastName = inventors[0].split(" ").slice(-1)[0]; // Get last element after splitting by space
    return inventors.length > 1 ? `${lastName} et al.` : lastName;
  };

  // Helper function to get formatted assignee or default text
  const getFormattedAssignee = (assignees) => {
    if (assignees && assignees.length === 0) {
      return "No Assignee Found";
    } else {
      return assignees.length > 1 ? `${assignees[0]} et al.` : assignees[0];
    }
  };

  //-------------------------------------------------------------------------------
  // Render Patent Search Buttons
  //-------------------------------------------------------------------------------
  const _handleSearchSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior (page refresh)
    fetchPatent(patentId);
  };

  const _renderPatentSearchFields = () => {
    return (
      <Paper
        elevation={3}
        sx={{ p: 3, margin: "auto", maxWidth: "90%", mt: 1 }}
      >
        <Typography variant="h6" align="center" gutterBottom>
          Patent Search
        </Typography>
        <Typography
          variant="caption" // Smaller text size
          align="center"
          display="block" // Ensures the text is on its own line
          sx={{
            color: "text.secondary", // Muted color
            mb: 3,
          }}
        >
          (Patent analytics powered by{" "}
          <MuiLink
            href="https://github.com/pqaidevteam/pqai/blob/master/docs/README-API.md"
            target="_blank"
            rel="noopener noreferrer"
          >
            Project PQAI
          </MuiLink>
          )
        </Typography>

        <Grid
          container
          spacing={2}
          justifyContent="center"
          sx={{ mt: 2, mb: 2 }}
        >
          <form onSubmit={_handleSearchSubmit}>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <TextField
                label="Enter Patent Number"
                variant="outlined"
                value={patentId}
                onChange={(e) => setPatentId(e.target.value.trim())}
                helperText="Enter a patent Number. Example: US7010330B1"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                type="submit" // Button submits form
                variant="contained"
                onClick={() => fetchPatent(patentId)}
                disabled={patLoading || patentId.trim() === ""}
                sx={{
                  // mt: 2,
                  ml: 2,
                  backgroundColor: "#88BBD6",
                  "&:hover": { backgroundColor: "#71a5c7" },
                }}
              >
                {patLoading ? <CircularProgress size={24} /> : "Search"}
              </Button>

              <Button
                variant="outlined"
                onClick={clearSearch}
                sx={{
                  // mt: 2,
                  ml: 2,
                  backgroundColor: "white",
                  "&:hover": { backgroundColor: "#f0f0f0" },
                }}
              >
                Clear
              </Button>
            </Grid>
          </form>
        </Grid>
      </Paper>
    );
  };

  //-------------------------------------------------------------------------------
  // Render Refinement Buttons
  //-------------------------------------------------------------------------------
  const _handleClaimSelect = (claim, index) => {
    setSearchQuery(stripClaimNumber(claim));
    setSelectedClaim(index); // Store the index of the selected claim
  };

  const _handleNavigateToPriorArt = () => {
    navigate("/patents/patent-prior-art", {
      state: {
        patentId: patentId,
        patentResult: patentResult,
        searchQuery: selectedClaim
          ? searchQuery
          : stripClaimNumber(patentResult.data?.claims[0]), // Search Claim 1 by default
        selectedClaim: selectedClaim ? selectedClaim : 0, // Claim 1 by default
        beforeDate: patentResult.data?.filing_date, // Use filing date as priority date
        patentFilingDateApplied: true,
      },
    });
  };

  const _renderRefinementButts = (patent) => {
    // Check if the patent is already added to worksheet
    const patentInWorksheet = patentsInWorksheet.some(
      (p) => p.pn === patent.pn
    );

    // What is this patent?
    const patentSource = `Pat Search`;

    return (
      <Box display="flex" mt={3}>
        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item xs="auto">
            {patentInWorksheet ? (
              <Button
                style={{ backgroundColor: "green", color: "white" }}
                disabled
              >
                Added to Worksheet
              </Button>
            ) : (
              <Button
                variant="outlined"
                onClick={() => addToWorksheet(patent, "patents", patentSource)}
              >
                Add to Worksheet
              </Button>
            )}
          </Grid>

          <Grid item xs="auto">
            <Button variant="outlined" onClick={toggleDwgs}>
              {showDwgs ? "Hide Drawings" : "Show Drawings"}
            </Button>
          </Grid>

          <Grid item xs="auto">
            <Button
              variant="outlined"
              onClick={() => setShowClaims(!showClaims)}
            >
              {showClaims ? "Hide Claims" : "Show Claims"}
            </Button>
          </Grid>

          <Grid item xs="auto">
            <Button variant="outlined" onClick={_handleNavigateToPriorArt}>
              {selectedClaim + 1
                ? `Search Prior Art Cl.${selectedClaim + 1}`
                : `Search Prior Art`}
            </Button>
          </Grid>

          <Grid item xs="auto">
            <Button variant="outlined">Search Devices</Button>
          </Grid>
        </Grid>
      </Box>
    );
  };

  //-------------------------------------------------------------------------------
  // Render Full Page and Results
  //-------------------------------------------------------------------------------

  return (
    <>
      <Container maxWidth="lg" className="link-no-decoration">
        {_renderPatentSearchFields()}
      </Container>

      <Container maxWidth="x1" className="link-no-decoration">
        {patentResult.message && (
          <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
            {/* <Typography variant="body1">{patentResult.message}</Typography> */}

            <Alert severity="warning">{patentResult.message}</Alert>
          </Paper>
        )}

        {patentResult.data && (
          <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
            <Typography variant="h6">{patentResult.data.title}</Typography>
            <Divider sx={{ my: 1 }} />

            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12} sm={6} md={3}>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ color: "text.secondary", fontSize: "0.8rem" }}
                >
                  <CalendarTodayIcon />
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {formatDate(patentResult.data.publication_date)}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ color: "text.secondary", fontSize: "0.8rem" }}
                >
                  <BusinessIcon />
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {patentResult.data.assignees
                      ? getFormattedAssignee(patentResult.data.assignees)
                      : "*Assignee Not Found"}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ color: "text.secondary", fontSize: "0.8rem" }}
                >
                  <PersonIcon />
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {patentResult.data.inventors
                      ? getFormattedInventors(patentResult.data.inventors)
                      : "*Inventor(s) not found"}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ color: "text.secondary", fontSize: "0.8rem" }}
                >
                  <MuiLink
                    href={`https://patents.google.com/patent/${patentId}`}
                    target="_blank"
                    display="flex"
                    alignItems="center"
                  >
                    <LinkIcon />
                    <Typography variant="body1" sx={{ ml: 1 }}>
                      {patentId}
                    </Typography>
                  </MuiLink>
                </Box>
              </Grid>
            </Grid>

            <Typography variant="body1" paragraph sx={{ mt: 3 }}>
              {patentResult.data.abstract}
            </Typography>

            {_renderRefinementButts(patentResult.data)}

            {showDwgs && (
              <>
                <Divider sx={{ my: 2 }} />
                <Box
                  sx={{
                    display: "flex",
                    overflowX: "auto",
                    maxWidth: "100%",
                  }}
                >
                  {dwgLoading ? (
                    <CircularProgress />
                  ) : (
                    dwgs.map((dwgUrl, index) => (
                      <img
                        key={index}
                        src={dwgUrl}
                        alt={`Drawing ${index + 1}`}
                        style={{
                          height: "150px",
                          marginRight: "5px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleImageClick(dwgUrl)}
                      />
                    ))
                  )}
                </Box>
              </>
            )}

            {/* Dialog for the selected (clicked) image */}
            <Dialog
              open={Boolean(selectedImage)}
              onClose={handleClose}
              maxWidth="lg"
              aria-labelledby="image-dialog-title"
              aria-describedby="image-dialog-description"
            >
              <img
                src={selectedImage}
                alt="Selected Drawing"
                style={{ width: "800px", height: "auto" }} // Larger size when image is clicked
              />
            </Dialog>

            {showClaims && (
              <>
                <Divider sx={{ my: 2 }} />
                <Alert severity="info" sx={{ mt: 2, mb: 1 }}>
                  Click on a claim and then click on "Search Prior Art" above to
                  find prior art for that claim.
                </Alert>

                <PatentClaimsList
                  claimsList={patentResult.data.claims}
                  selectedClaim={selectedClaim}
                  handleClaimSelect={_handleClaimSelect}
                  removeClaimNumber={false}
                />
              </>
            )}
          </Paper>
        )}
      </Container>
    </>
  );
};

export default PatentSearch;

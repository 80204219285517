import React, { useState } from "react";
import Carousel from "react-material-ui-carousel";
import {
  Button,
  Box,
  Typography,
  IconButton,
  Grid,
  Container,
  AppBar,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import HeaderCarouselItem from "../common/HeaderCarouselItem";

const PatentAnalyticsHome = ({ baseConfig }) => {
  const [autoPlay, setAutoPlay] = useState(true);

  const toggleAutoPlay = () => {
    setAutoPlay(!autoPlay);
  };

  // Define the items for the carousel
  const items = [
    {
      name: "Wireless Technology Standards",
      description:
        "Our team can help you find prior art standards and help you identify and evaluate devices potentially compatible with given standards",
      image: "pat-1-standards.png",
    },
    {
      name: "Device Research",
      description:
        "Let our team help you analyze components and devices used in wireless sites including active radios and passive components to identify potential licensing targets",
      image: "pat-2-devices.png",
    },
    {
      name: "Artificial Intelligence",
      description: "AI-assisted patent analysis",
      image: "pat-3-patai.mp4",
    },
    {
      name: "Patent Analytics Research",
      description: "Describe ... - ",
      image: "pat-4-research.png",
    },
  ];

  return (
    <Grid container spacing={2} className="link-no-decoration">
      <Grid item xs={12}>
        <Box position="relative">
          <Carousel autoPlay={autoPlay} interval={4500}>
            {items.map((item, i) => (
              <HeaderCarouselItem key={i} item={item} baseConfig={baseConfig} />
            ))}
          </Carousel>
          <Box
            position="absolute"
            top={0}
            right={0}
            p={4} // Adjust padding as needed
            zIndex="tooltip" // To ensure the button is above the carousel images
          >
            <IconButton
              onClick={toggleAutoPlay}
              size="small"
              sx={{ bgcolor: "rgba(255,255,255,0.7)" }}
            >
              {autoPlay ? (
                <PauseIcon fontSize="inherit" />
              ) : (
                <PlayArrowIcon fontSize="inherit" />
              )}
            </IconButton>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PatentAnalyticsHome;

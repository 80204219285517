import React from "react";
import _ from "lodash"; //lodash is the optimized version of underscore
import PropTypes from "prop-types";

const Pagination = (props) => {
  const { itemsCount, pageSize, currentPage, onPageChange } = props;
  const pagesCount = Math.ceil(itemsCount / pageSize);

  // use lodash to generate an array with [1, 2, ... pagesCount]
  const pages = _.range(1, pagesCount + 1);

  // if only have one page then want to return no pagination
  if (pagesCount <= 1) return null;

  // create a subset of pages to show
  const pageSubset = pages.slice(Math.max(0, currentPage - 5), currentPage + 4);

  // click handlers for previous and next buttons
  const handlePrevious = () => onPageChange(currentPage - 1);
  const handleNext = () => onPageChange(currentPage + 1);
  const handleFirst = () => onPageChange(1);
  const handleLast = () => onPageChange(pagesCount);

  return (
    <nav>
      <ul className="pagination">
        {/* Only render first button if current page isn't the first */}
        {currentPage !== 1 && (
          <li className="page-item">
            <a className="page-link" onClick={handleFirst}>
              First
            </a>
          </li>
        )}

        {/* Only render previous button if current page isn't the first */}
        {currentPage !== 1 && (
          <li className="page-item">
            <a className="page-link" onClick={handlePrevious}>
              Previous
            </a>
          </li>
        )}

        {pageSubset.map((page) => (
          <li key={page} className={page === currentPage ? "page-item active" : "page-item"}>
            <a className="page-link" onClick={() => onPageChange(page)}>
              {page}
            </a>
          </li>
        ))}

        {/* Only render next button if current page isn't the last */}
        {currentPage !== pagesCount && (
          <li className="page-item">
            <a className="page-link" onClick={handleNext}>
              Next
            </a>
          </li>
        )}

        {/* Only render last button if current page isn't the last */}
        {currentPage !== pagesCount && (
          <li className="page-item">
            <a className="page-link" onClick={handleLast}>
              Last
            </a>
          </li>
        )}
      </ul>
    </nav>
  );
};

// Define proptypes for type checking - important if we pass wrong prop because this would otherwise not be in console which would be hard to debug
// reactjs.org -> advanced guides -> Typechecking with proptypes --> https://reactjs.org/docs/typechecking-with-proptypes.html

Pagination.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;

import React from "react";
import { styled } from "@mui/system";
import { Typography, Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignalStream } from "@fortawesome/pro-solid-svg-icons";

// Define styled components
const TitleContainer = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "isMainHeading" && prop !== "siteBranding",
})(({ siteBranding, isMainHeading }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  backgroundColor: isMainHeading ? siteBranding.tertColor : "transparent",
  minHeight: isMainHeading ? 50 : 40,
}));

const ColorStripe = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "isMainHeading" && prop !== "siteBranding",
})(({ siteBranding, isMainHeading }) => ({
  width: isMainHeading ? 20 : 14,
  backgroundColor: siteBranding.secColor,
  alignSelf: "stretch",
}));

const TitleSection = styled(Box)({
  display: "flex",
  alignItems: "center", // Aligns the icon and the text on the same row
  flex: 1, // Using flex 1 instead of flexGrow to maintain proportions
  paddingLeft: 10, // Left padding for some spacing from the color stripe
  paddingTop: 4,
  paddingBottom: 4,
});

const ReusableTitle = ({
  title,
  siteBranding,
  icon,
  contentId,
  isMainHeading = true,
}) => {
  const IconComponent = icon?.muiIcon || FontAwesomeIcon; // Choose between MUI Icon or FontAwesome

  return (
    <TitleContainer siteBranding={siteBranding} isMainHeading={isMainHeading}>
      <ColorStripe siteBranding={siteBranding} />
      <TitleSection>
        {icon && isMainHeading && (
          <IconComponent
            icon={icon?.faIcon || faSignalStream}
            style={{
              marginRight: 12,
              color: "white",
              fontSize: "24px",
            }}
          />
        )}
        <Typography
          variant="h5"
          id={contentId}
          style={{
            paddingBottom: 0,
            color: isMainHeading ? "white" : siteBranding.tertColor,
          }}
        >
          {title}
        </Typography>
      </TitleSection>
    </TitleContainer>
  );
};

export default ReusableTitle;

import React, { useState, useEffect } from "react";
import axios from "axios";
import http from "../../services/httpService";
import { Link } from "react-router-dom";

const baseURL = `${window._env_.REACT_APP_LPIM_API_URL}/patanal_api`;

const RadioModuleUsage = ({ devid, loadUsage }) => {
  const [radioModuleUsage, setRadioModuleUsage] = useState([]);

  useEffect(() => {
    const fetchRadioModuleUsage = async () => {
      if (!loadUsage) return; // Only fetch data when loadUsage is true

      try {
        const headers = {
          Authorization: `JWT ${http.getCookie("access_token")}`,
        };
        const response = await axios.get(
          `${baseURL}/devices/radio_module_usage/?devid=${devid}`,
          { headers }
        );
        setRadioModuleUsage(response.data.radiomodule_usage);
      } catch (error) {
        console.error("Failed to fetch radio module usage:", error);
        setRadioModuleUsage([]);
      }
    };

    fetchRadioModuleUsage();
  }, [devid, loadUsage]); // Dependency array fetch only when devid or loadUsage changes

  if (!devid) return null;

  return (
    <div className="list-group">
      {radioModuleUsage.length > 0 ? (
        radioModuleUsage.map((usageDevice) => (
          <Link
            key={usageDevice.devid}
            to={`/devices/device-detail/${usageDevice.devid}`}
            className="list-group-item list-group-item-action"
          >
            <h5>{usageDevice.title}</h5>
            <p>MPN: {usageDevice.mpn}</p>
          </Link>
        ))
      ) : (
        <p>No radio module usage found.</p>
      )}
    </div>
  );
};

export default RadioModuleUsage;

import React, { useState, useEffect } from "react";
import {
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Typography,
  Grid,
  Box,
  Paper,
  IconButton,
  Tooltip,
  Autocomplete,
  Link as MuiLink,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import http from "../../services/httpService";
import Pagination from "../common/Pagination";
import { paginate } from "./utils/paginate";
import _ from "lodash";
import * as CONFIG from "./utils/patanalaiGlobalLabelsVars";
import PatentReviewTable from "./PatentReviewTable";
import SearchBox from "../common/SearchBox";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import InfoIcon from "@mui/icons-material/Info";
import InputAdornment from "@mui/material/InputAdornment";
import GetAppIcon from "@mui/icons-material/GetApp";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import { useAuth } from "../../context/AuthContext";
import * as XLSX from "xlsx";

const apiUrlPatanal = `${window._env_.REACT_APP_PATANALAI_API_URL}`; // Patanal API Endpoint

//@TODO
//1. Instead of "Add notes" button, have an "editable pencil" on any editable field - Reviewer, Score, Notes
//2. Collapse patent review paper after import to create more vertical space for table
//3. Search across multiple fields - reduce search width
//4. Add filter/sort - expiration, no score, reviewer
//5. Export Excel
//6. Export PDF
//7. Restriction on # of rows of import
//8. Save results to persistent storage (DB) -> Mongo? S3?
//10. Add strength rating  - get button to show strength rating - create strenght rating function placeholder
//     - review competitor strength ratings
//     - invalidity search median score for X prior art (patents | npl)
//     - fwd citations
//     - claim structure (length of independent claims? Types of claims)
//     - litigation?
//     - RTC - what's the technology it pertains to
//     - detectability
//     - SEP - QOR on std, type of std, mandatory/optional, feature used
//     - value of licensing targets (vertical, segment, companies, licenses, sample product values, SSPPU, integration level)

const PatentReview = () => {
  const { user } = useAuth();
  const [file, setFile] = useState(null);
  const [patents, setPatents] = useState([]);
  const [importPerformed, setImportPerformed] = useState(false);

  // Set DEFAULTS
  const DEFAULT_PAGE_SIZE = 3;

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [searchQuery, setSearchQuery] = useState("");

  const [sortCol, setSortCol] = useState({
    path: "patent_id",
    order: "asc",
  });
  const [isLoading, setIsLoading] = useState(false);

  //----------------------------------------------------------------------------
  // Load from Session Storage
  //----------------------------------------------------------------------------
  useEffect(() => {
    // Attempt to load patents from session storage
    const savedPatents = sessionStorage.getItem("patents");
    if (savedPatents) {
      setPatents(JSON.parse(savedPatents));
      setImportPerformed(true);
    }
  }, []);

  //----------------------------------------------------------------------------
  // File Change
  //----------------------------------------------------------------------------
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  //----------------------------------------------------------------------------
  // Import & Fetch Patents
  //----------------------------------------------------------------------------
  const handleImport = async () => {
    setIsLoading(true);

    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const json = XLSX.utils.sheet_to_json(worksheet);
    const patentIds = json.map((row) => row.patent_id);

    const getFields = ["pn", "title", "abstract", "publication_date"];
    const apiUrl = `${apiUrlPatanal}/patents/list/`;
    const accessToken = http.getCookie("access_token");
    const headers = {
      Authorization: `JWT ${accessToken}`,
    };
    const body = {
      patent_ids: patentIds,
      fields: getFields,
      source: "pqai",
    };

    try {
      const response = await axios.post(apiUrl, body, { headers });

      if (
        (response.status === 200 || response.status === 207) &&
        response.data.patents
      ) {
        const mappedPatents = patentIds.map((patentId) => {
          const foundPatent = response.data.patents.find(
            (p) => p.pn === patentId
          );
          if (foundPatent) {
            const jsonEntry = json.find(
              (entry) => entry.patent_id === patentId
            );
            return {
              ...foundPatent,
              patent_id: jsonEntry.patent_id,
              reviewer: jsonEntry.reviewer,
              score: jsonEntry.score,
            };
          } else {
            return {
              patent_id: patentId,
              title: "-Not found-",
              publication_date: "-",
              reviewer:
                json.find((entry) => entry.patent_id === patentId)?.reviewer ||
                "Unknown",
              score:
                json.find((entry) => entry.patent_id === patentId)?.score ||
                "-",
              notes: "Patent data could not be fetched",
            };
          }
        });

        setPatents(mappedPatents);
        setTotalResults(mappedPatents.length);
        setTotalPages(Math.ceil(mappedPatents.length / pageSize));

        // Save the fetched patents to session storage
        sessionStorage.setItem("patents", JSON.stringify(mappedPatents));

        // Handle patents that could not be fetched
        if (response.data.errors && response.data.errors.length > 0) {
          console.log(
            "Some patents could not be fetched:",
            response.data.errors
          );
        }
      } else {
        console.log("Unexpected response format:", response.data);
      }
    } catch (error) {
      console.error("Failed to fetch patent details:", error);
      // Handle errors here, such as showing a message to the user
    } finally {
      setImportPerformed(true);
      setIsLoading(false);
    }
  };

  //----------------------------------------------------------------------------
  // Clear Patents
  //----------------------------------------------------------------------------

  const _clearPatents = () => {
    // Remove patents from state and from session storage
    setPatents([]);
    sessionStorage.removeItem("patents");
    setImportPerformed(false);

    // Note: You might not want to reset pageSize to allow users to keep their preferred setting
  };

  //----------------------------------------------------------------------------
  // Pagination Control
  //----------------------------------------------------------------------------
  const _handlePageChange = (page) => {
    //console.log(`Page Clicked = ${page}`);
    setCurrentPage(page);
    // No need to call fetchDevices here as it will be called by the effect hook when currentPage changes
  };

  // When page size is changed, we should reset back to the first page and fetch devices.
  const _handlePageSizeChange = (event) => {
    const newPageSize = event.target.value;
    setPageSize(newPageSize);
    setCurrentPage(1); // Reset to the first page
    // No need to call fetchDevices here as it will be called by the effect hook when currentPage changes
  };

  //----------------------------------------------------------------------------
  // Search Results/ Sort
  //----------------------------------------------------------------------------
  const _handleSearchChange = (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const _handleSort = (receivedSortCol) => {
    setSortCol((prevState) => ({
      ...prevState,
      path: receivedSortCol.path,
      order: receivedSortCol.order,
    }));
  };

  let patentsFiltered;

  if (searchQuery) {
    // if there is a search query then filter based on search query
    patentsFiltered = patents.filter((pat) =>
      pat.patend_id.toLowerCase().startsWith(searchQuery.toLowerCase())
    );
  } else {
    patentsFiltered = patents;
  }

  // sort patents
  // sortCol.path and sortCol.order are in array because we can sort by multiple columns using lodash's orderBy util
  const patentsSorted = _.orderBy(
    patentsFiltered,
    [sortCol.path],
    [sortCol.order]
  );

  // paginate patents
  const patentsPaginated = paginate(patentsSorted, currentPage, pageSize);

  // get number of devices and desctructure to rename to patentCount
  const { length: patentCount } = patents;

  //-------------------------------------------------------------------------------
  // Conditional Render of Device Results Table
  //-------------------------------------------------------------------------------
  const _renderPatentTable = () => {
    if (isLoading) return <CircularProgress size={24} />;

    if (!importPerformed)
      return (
        <div style={{ marginTop: "10px" }}>
          {CONFIG.RFW_HORIZDOT}
          <Alert severity="info">
            Upload import Excel or CSV and Click on "Import" to fetch patent
            data.
          </Alert>
        </div>
      );

    if (!patentCount || patentCount === 0)
      return (
        <div style={{ marginTop: "10px" }}>
          {CONFIG.RFW_HORIZDOT}
          <Alert severity="warning">
            No patents in the database match the search criteria.
          </Alert>
        </div>
      );
    return (
      <>
        <div>{CONFIG.RFW_HORIZDOT}</div>
        <Grid container alignItems="center" sx={{ mb: 2 }}>
          {/* Pagination Info and Page Size Selection */}
          {_renderPaginationInfo()}

          <Grid item xs={12} align="center">
            <SearchBox
              searchValue={searchQuery}
              searchOnChange={(e) => _handleSearchChange(e.currentTarget.value)}
            />
          </Grid>

          <Grid item xs={12} align="center">
            <PatentReviewTable
              patents={patentsPaginated}
              sortColumn={sortCol}
              onSort={_handleSort}
            />
          </Grid>

          <Grid item xs={12} align="center">
            {totalPages > 1 && (
              <Pagination
                itemsCount={totalResults} // This is total patents and not patents returned for current page
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={_handlePageChange}
              />
            )}
          </Grid>
        </Grid>
      </>
    );
  }; // end const _renderPatentTable = () =>

  //-------------------------------------------------------------------------------
  // Render Pagination Controls
  //-------------------------------------------------------------------------------
  const _renderPaginationInfo = () => (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      sx={{ mb: 2 }}
    >
      <Grid item>
        <Typography variant="subtitle1">
          Total results: {totalResults}
        </Typography>
      </Grid>
      <Grid item>
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle1" sx={{ marginRight: 2 }}>
            Items per page:
          </Typography>
          <FormControl size="small" sx={{ minWidth: 80 }}>
            <Select
              id="page-size-select"
              value={pageSize}
              onChange={_handlePageSizeChange}
              displayEmpty
            >
              {[3, 5, 10, 20].map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );

  //-------------------------------------------------------------------------------
  // Render Full Page w/ Results Table
  //-------------------------------------------------------------------------------
  return (
    <Grid container spacing={1}>
      {/* Search Filters Container */}
      <Container maxWidth="lg" className="link-no-decoration">
        <Paper
          elevation={3}
          sx={{ p: 3, margin: "auto", maxWidth: "90%", mt: 2 }}
        >
          <Typography variant="h6" align="center" gutterBottom>
            Patent Review
          </Typography>
          <Typography
            variant="caption" // Smaller text size
            align="center"
            display="block" // Ensures the text is on its own line
            sx={{
              color: "text.secondary", // Muted color
              mb: 3,
            }}
          >
            (Patent data provided by{" "}
            <MuiLink
              href="https://github.com/pqaidevteam/pqai/blob/master/docs/README-API.md"
              target="_blank"
              rel="noopener noreferrer"
            >
              Project PQAI
            </MuiLink>
            )
          </Typography>

          <Grid container spacing={2} justifyContent="left" sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6} md={3}>
              <input type="file" onChange={handleFileChange} accept=".xlsx" />
            </Grid>

            <Grid item xs={12} align="center">
              {/* Create marginBottom for xtra small devices (mobile) when buttons stack */}
              <Button
                startIcon={<GetAppIcon />}
                variant="contained"
                onClick={handleImport}
                disabled={!file || isLoading}
                sx={{ mr: 2, mb: { xs: 1, sm: 0 } }}
              >
                Import Patents
              </Button>
              <Button
                startIcon={<ClearAllIcon />}
                variant="outlined"
                onClick={_clearPatents}
                disabled={!patents.length || isLoading}
                sx={{ mr: 2, mb: { xs: 1, sm: 0 } }}
              >
                Clear Patents
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>

      {/* Device Results Table & Pagination Rendering */}
      <Grid item xs={12} align="center">
        {_renderPatentTable()}
      </Grid>
    </Grid>
  );
};

export default PatentReview;

import React from "react";
import {
  Typography,
  Paper,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Container,
} from "@mui/material";

const CookiePolicy = () => {
  return (
    <Container maxWidth="md" className="link-no-decoration">
      <Paper
        style={{
          padding: "20px",
          marginTop: "20px",
          backgroundColor: "#f9f9f9",
        }}
      >
        <Typography variant="h4" gutterBottom align="center">
          Cookie Policy
        </Typography>

        <Typography paragraph>
          Our <Link href="/privacy-policy">Privacy Policy</Link> explains how we
          collect and use information from and about you when you use our
          websites or mobile applications, attend our events, or otherwise
          interact with us. This Cookie Policy explains more about how Patent
          Analytics, Inc. ("Patanal", “we” or “us”) uses cookies and your
          choices concerning cookies.
        </Typography>

        <Typography variant="h6" gutterBottom>
          What are Cookies?
        </Typography>
        <Typography paragraph>
          Like most websites, we use cookies and similar technologies to
          remember things about you so that we can provide you with a better
          experience.
        </Typography>
        <Typography paragraph>
          <ul>
            <li>
              Cookies are small data files stored on your browser or device.
              They may be served by the entity that operates the website you are
              visiting (“first-party cookies”) or by other companies
              (“third-party cookies”). For example, we partner with third-party
              analytics providers, like Google, which set cookies when you visit
              our websites. This helps us understand how you are using our
              websites so that we can improve them.
            </li>
            <li>
              Pixels are small images on a web page or in an email. Pixels
              collect information about your browser or device and can set
              cookies.
            </li>
            <li>
              Local storage allows data to be stored locally on your browser or
              device and includes HTML5 local storage and browser cache.
            </li>
            <li>
              SDKs are blocks of code provided by our partners that may be
              installed in our mobile applications. SDKs help us understand how
              you interact with our mobile applications and collect certain
              information about the device and network you use to access the
              application.
            </li>
          </ul>
        </Typography>

        <Typography variant="h6" gutterBottom>
          How We Use Cookies
        </Typography>
        <Typography paragraph>
          We use cookies for a number of reasons, like helping us see which
          features are most popular, counting visitors to a page, improving our
          users’ experience, and keeping our services secure. The cookies we use
          generally fall into one of the following categories.
        </Typography>

        <Typography paragraph>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ verticalAlign: "top", fontWeight: "bold" }}>
                  Category of Cookies
                </TableCell>
                <TableCell style={{ verticalAlign: "top", fontWeight: "bold" }}>
                  Why we use these cookies
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ verticalAlign: "top" }}>
                  Technical
                </TableCell>
                <TableCell>
                  These cookies are essential for our services to function
                  properly. Like the other cookies we use, technical cookies may
                  be either first-party cookies or third-party cookies.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ verticalAlign: "top" }}>Security</TableCell>
                <TableCell>
                  We use these cookies to help identify and prevent security
                  risks.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ verticalAlign: "top" }}>
                  Performance
                </TableCell>
                <TableCell>
                  We use these cookies to collect information about how you
                  interact with our services and to help us improve them. For
                  example, we may use these cookies to determine if you have
                  interacted with a certain page.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ verticalAlign: "top" }}>
                  Analytics
                </TableCell>
                <TableCell>
                  We use these cookies to help us understand and improve our
                  services. For example, we can use these cookies to learn more
                  about which features are the most popular with our users and
                  where we may need to make improvements.
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Typography>

        <Typography variant="h6" gutterBottom>
          Your Choices
        </Typography>
        <Typography paragraph>
          Although most browsers and devices accept cookies by default, their
          settings usually allow you to clear or decline cookies. If you disable
          cookies, however, some of the features of our websites may not
          function properly. To prevent your data from being used by Google
          Analytics, you can install{" "}
          <Link
            href="https://tools.google.com/dlpage/gaoptout"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google’s opt-out browser add-on
          </Link>
          . To prevent data from being collected from our mobile apps, including
          any SDKs that may be installed therein, you may delete the app at any
          time.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Contact Us
        </Typography>
        <Typography paragraph>
          If you have any questions about our use of cookies, please{" "}
          <Link href="/contact">contact us</Link>.
        </Typography>
        <Typography
          sx={{ fontStyle: "italic", color: "gray", fontSize: "0.875rem" }}
        >
          This Policy was last revised on April 8, 2024.
        </Typography>
      </Paper>
    </Container>
  );
};

export default CookiePolicy;

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faForward } from "@fortawesome/pro-solid-svg-icons";
import { asinSchema, patentSchema } from "../../../utils/validationSchemas";
import WizInfoDialog from "./WizInfoDialog";
import parse from "html-react-parser";

const WizActionSelectTextField = ({
  question,
  questionInfo,
  initialValue = [],
  onComplete,
  fieldType = "asin",
}) => {
  const validInitialValue = Array.isArray(initialValue) ? initialValue : [];
  const [fields, setFields] = useState(
    validInitialValue.length > 0 && validInitialValue[0] !== "Skipped"
      ? validInitialValue
      : [""]
  );
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState([]); // Track if fields have been touched
  const [isEditing, setIsEditing] = useState(
    !validInitialValue || validInitialValue.length === 0
  );
  const [showInfo, setShowInfo] = useState(null);
  const [skip, setSkip] = useState(validInitialValue[0] === "Skipped");

  const config = {
    asin: {
      label: "ASIN",
      maxFields: 4,
      schema: asinSchema,
      inputProps: { maxLength: 10 },
      helperText: "ASIN must be exactly 10 characters",
      allowSkip: true,
    },
    patent: {
      label: "Patent Number",
      maxFields: 4,
      schema: patentSchema,
      inputProps: {},
      helperText:
        "Patent number must match the format for US, D, or RE patents",
      allowSkip: true,
    },
  }[fieldType];

  useEffect(() => {
    if (!skip) {
      const validateFields = async () => {
        const newErrors = {};
        await Promise.all(
          fields.map(async (field, index) => {
            if (field) {
              try {
                await config.schema.validate(field);
                newErrors[index] = "";
              } catch (err) {
                newErrors[index] = err.message;
              }
            } else if (touched[index]) {
              newErrors[index] = "This field is required"; // Mark as invalid if empty and touched
            }
          })
        );
        setErrors(newErrors);
      };
      validateFields();
    } else {
      setErrors({}); // Clear errors if skip is enabled
    }
  }, [fields, config.schema, skip, touched]);

  const handleAddField = () => {
    if (fields.length < config.maxFields) {
      setFields([...fields, ""]);
      setTouched([...touched, false]); // Initialize touched state for new field
    }
  };

  const handleRemoveField = (index) => {
    const updatedFields = fields.filter((_, i) => i !== index);
    setFields(updatedFields);
    setTouched(touched.filter((_, i) => i !== index)); // Remove touched state for removed field
  };

  const handleChange = (index, value) => {
    const updatedFields = [...fields];
    updatedFields[index] = value || "";
    setFields(updatedFields);
  };

  const handleBlur = (index) => {
    const updatedTouched = [...touched];
    updatedTouched[index] = true;
    setTouched(updatedTouched);
  };

  const handleNext = () => {
    if (isEditing) {
      const finalFields = skip
        ? ["Skipped"]
        : fields.filter((field) => field.length > 0);
      onComplete(finalFields); // Pass as an array, not HTML
    }
    setIsEditing(!isEditing);
  };

  const handleSkipChange = (event) => {
    const isChecked = event.target.checked;
    setSkip(isChecked);
    if (isChecked) {
      setFields(["Skipped"]);
      setTouched([]); // Clear touched state when skipping
    } else {
      setFields([""]);
      setTouched([false]);
    }
  };

  const allFieldsValid =
    skip ||
    (fields.every((field, index) => field && !errors[index]) &&
      fields.length > 0);

  return (
    <Box>
      <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
        <Typography variant="h6" sx={{ mr: 1 }}>
          {question}
        </Typography>
        {questionInfo && (
          <Button
            startIcon={<InfoIcon />}
            onClick={() => setShowInfo(questionInfo)}
          >
            Info
          </Button>
        )}
      </Box>

      {/* Skip option */}
      {config.allowSkip && (
        <FormControlLabel
          control={<Checkbox checked={skip} onChange={handleSkipChange} />}
          label="Skip for Now"
          disabled={!isEditing}
          sx={{ mb: 2 }}
        />
      )}

      {fields.map((field, index) => (
        <Box key={index} sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <TextField
            value={field || ""}
            onChange={(e) => handleChange(index, e.target.value)}
            onBlur={() => handleBlur(index)} // Mark field as touched on blur
            label={`${config.label} ${index + 1}`}
            fullWidth
            error={Boolean(errors[index]) && touched[index]} // Only show error if field is touched
            helperText={
              touched[index] && errors[index]
                ? errors[index]
                : config.helperText
            }
            disabled={!isEditing || skip}
            {...config.inputProps}
          />
          {index === fields.length - 1 && fields.length < config.maxFields && (
            <IconButton
              onClick={handleAddField}
              sx={{ ml: 1 }}
              disabled={!isEditing || skip}
            >
              <AddIcon />
            </IconButton>
          )}
          {fields.length > 1 && (
            <IconButton
              onClick={() => handleRemoveField(index)}
              sx={{ ml: 1 }}
              disabled={!isEditing || skip}
            >
              <RemoveIcon />
            </IconButton>
          )}
        </Box>
      ))}

      {allFieldsValid && (
        <Button
          onClick={handleNext}
          variant="contained"
          startIcon={
            isEditing ? (
              <FontAwesomeIcon icon={faForward} />
            ) : (
              <FontAwesomeIcon icon={faPenToSquare} />
            )
          }
          color="primary"
          sx={{ mt: 2 }}
        >
          {isEditing ? "Next" : "Edit"}
        </Button>
      )}

      {showInfo && (
        <WizInfoDialog
          onClose={() => setShowInfo(null)}
          content={parse(showInfo)}
        />
      )}
    </Box>
  );
};

export default WizActionSelectTextField;

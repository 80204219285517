import React, { Component } from "react";

const OverstayedStock = () => {
  return (
    <div>
      <ul>
        <li>
          overstayed inventory - threshold, purchase date, units, value (total
          overstayed value to KPIs)
        </li>
      </ul>
    </div>
  );
};

export default OverstayedStock;

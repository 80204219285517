import React, { Component } from "react";

const TableHeader = ({ columns, sortColumn, onSort }) => {
  const _raiseSort = (path) => {
    // clone sortcolumn object
    const sortColCloned = { ...sortColumn };

    if (sortColCloned.path === path) {
      // if the path selected is the same as the path in state, if ascending make descending or vice-versa
      sortColCloned.order = sortColCloned.order === "asc" ? "desc" : "asc";
    } else {
      // if the path selected is not the same as path in state, make path in state to the path selected
      // and default sort is ascending
      sortColCloned.path = path;
      sortColCloned.order = "asc";
    }

    onSort(sortColCloned);
  };

  const _renderSortIcon = (col) => {
    // Do not render icons if not the sort column or if dont have path (for content fields)
    if (!col.path || col.path !== sortColumn.path) return null;
    if (sortColumn.order === "asc") return <i className="fa fa-sort-asc"></i>;
    return <i className="fa fa-sort-desc"></i>;
  };

  return (
    <thead>
      <tr>
        {columns.map((col) => (
          <th
            className="clickable"
            key={col.path || col.key}
            onClick={() => _raiseSort(col.path)}
          >
            {col.label} {_renderSortIcon(col)}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;

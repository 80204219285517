import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Grid,
  IconButton,
  Toolbar,
  useMediaQuery,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import PhoneIcon from "@mui/icons-material/Phone";
import KthGoogleSearchBox from "./KthGoogleSearchBox";
import { useTheme } from "@mui/material/styles";
import { getImageUrl } from "../../utils/helpers";
import { useWorksheet } from "../../context/WorksheetContext";

const KthHeader = ({ user, baseConfig }) => {
  const { welcomeText, siteBranding } = baseConfig;
  const ViewWorksheetButton = baseConfig.WorksheetComponent;
  const { worksheet } = useWorksheet();
  //@TODO - if add patents, devices tab, edit check below to just check if any enabled tab has data instead of wiz.wizid === 0.
  // check if worksheet has wizard data
  // const wizTypes = ["patent-enforcement", "ai-compliance", "ai-eula"];
  const worksheetTypes = baseConfig.worksheetTabs;
  const dataInWorksheet =
    worksheetTypes.some((type) =>
      worksheet?.[type]?.some((wiz) => wiz.wizid === 0)
    ) || false;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  //---------------------------------------------------------------------------
  // Top Row (Welcome Text, Phone, Contact, Login/Logout)
  //---------------------------------------------------------------------------
  const _topRow = () => {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ p: 1 }}
      >
        {/* Welcome Text (Hidden on Small Screens) */}
        {!isSmallScreen && (
          <Grid item>
            <Typography variant="body1" color={siteBranding.priColor}>
              {welcomeText}
            </Typography>
          </Grid>
        )}

        {/* Phone, Contact Us, Sign In/Out */}
        <Grid item>
          <Grid container alignItems="center" spacing={2}>
            {/* Phone Icon and Number */}
            <Grid item>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <PhoneIcon
                    sx={{
                      color: siteBranding.priColor,
                      fontSize: isSmallScreen ? "0.9rem" : "1rem",
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    color={siteBranding.priColor}
                    sx={{ fontSize: isSmallScreen ? "0.9rem" : "1rem" }}
                  >
                    +1-602-837-0631
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* Contact Us */}
            <Grid item>
              <IconButton
                component={RouterLink}
                to="/contact"
                sx={{ color: siteBranding.priColor }}
                aria-label="contact"
                title="Contact Us"
              >
                <MailIcon
                  sx={{
                    color: siteBranding.priColor,
                    fontSize: isSmallScreen ? "0.9rem" : "1rem",
                  }}
                />
              </IconButton>
              <Typography
                component={RouterLink}
                to="/contact"
                sx={{
                  textDecoration: "none",
                  color: siteBranding.priColor,
                  fontSize: isSmallScreen ? "0.9rem" : "1rem",
                }}
              >
                Contact Us
              </Typography>
            </Grid>

            {/* Sign In/Out */}
            <Grid item>
              {user ? (
                <>
                  <IconButton
                    component={RouterLink}
                    to="/logout"
                    sx={{ color: siteBranding.priColor }}
                    aria-label="logout"
                    title="Logout"
                  >
                    <LogoutIcon
                      sx={{
                        color: siteBranding.priColor,
                        fontSize: isSmallScreen ? "0.9rem" : "1rem",
                      }}
                    />
                  </IconButton>
                  <Typography
                    component={RouterLink}
                    to="/logout"
                    sx={{
                      textDecoration: "none",
                      color: siteBranding.priColor,
                      fontSize: isSmallScreen ? "0.9rem" : "1rem",
                    }}
                  >
                    Logout
                  </Typography>
                </>
              ) : (
                <>
                  <IconButton
                    component={RouterLink}
                    to="/login"
                    sx={{ color: siteBranding.priColor }}
                    aria-label="login"
                    title="Client Login"
                  >
                    <LoginIcon
                      sx={{
                        color: siteBranding.priColor,
                        fontSize: isSmallScreen ? "0.9rem" : "1rem",
                      }}
                    />
                  </IconButton>
                  <Typography
                    component={RouterLink}
                    to="/login"
                    sx={{
                      textDecoration: "none",
                      color: siteBranding.priColor,
                      fontSize: isSmallScreen ? "0.9rem" : "1rem",
                    }}
                  >
                    {!isSmallScreen ? "Client Login" : "Login"}{" "}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  //---------------------------------------------------------------------------
  // Logo Header for Small Screens
  //---------------------------------------------------------------------------
  const _smallScreenToolbar = () => {
    return (
      <Grid container spacing={2} alignItems="left">
        <Grid item xs={12}>
          {siteBranding?.logo && (
            <RouterLink to="/" style={{ textDecoration: "none" }}>
              <img
                src={getImageUrl(siteBranding.logo)}
                alt="Logo"
                style={{
                  maxHeight: "96px",
                  width: "auto",
                  marginTop: "5px",
                }}
              />
            </RouterLink>
          )}
        </Grid>
        <Grid item xs={12}>
          {/* Conditionally Render Worksheet Button if it exists for app */}
          {ViewWorksheetButton && dataInWorksheet && (
            <ViewWorksheetButton baseConfig={baseConfig} />
          )}
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ width: "300px" }}>
            <KthGoogleSearchBox />
          </Box>
        </Grid>
      </Grid>
    );
  };

  const _largeScreenToolbar = () => {
    return (
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          {siteBranding?.logo && (
            <RouterLink to="/" style={{ textDecoration: "none" }}>
              <img
                src={getImageUrl(siteBranding.logo)}
                alt="Logo"
                style={{
                  maxHeight: "96px",
                  width: "auto",
                  marginTop: "5px",
                }}
              />
            </RouterLink>
          )}
        </Grid>
        <Grid item xs="auto">
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="flex-end"
          >
            {/* Conditionally Render Worksheet Button if it exists for app */}
            {ViewWorksheetButton && dataInWorksheet && (
              <Grid item>
                <Box>
                  {" "}
                  <ViewWorksheetButton baseConfig={baseConfig} />
                </Box>
              </Grid>
            )}
            <Grid item>
              <Box sx={{ width: "300px" }}>
                <KthGoogleSearchBox />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {_topRow()}
      <Divider />
      <Toolbar>
        {isSmallScreen ? _smallScreenToolbar() : _largeScreenToolbar()}
      </Toolbar>
    </>
  );
};

export default KthHeader;

import React, { Component, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import http from "../../../services/httpService";
import {
  Button,
  Grid,
  Box,
  Typography,
  TextField,
  FormHelperText,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Collapse,
  Alert,
  Select,
  MenuItem,
} from "@mui/material";
import prods from "../utils/prods";
import Pagination from "../../common/Pagination";
import { paginate } from "../utils/paginate";
import ListGroup from "../../common/ListGroup";
import SearchBox from "../../common/SearchBox";
import _ from "lodash";
import * as CONFIG from "../utils/Global-Labels-Links-Vars";
import LoadingSpinner from "../../common/LoadingSpinner";
import { getFilteredStaleCompe } from "../utils/buildChannelProducts";
import StaleCompeTable from "./StaleCompeTable";

const baseURL = `${window._env_.REACT_APP_LPIM_API_URL}/lpim_api/products-stale-competitor`;

const StaleCompe = () => {
  //-------------------------------------------------------------------------------
  // SET DEFAULTS
  //-------------------------------------------------------------------------------
  const DEFAULT_PAGE_SIZE = 10;
  const DEFAULT_SKUS_TO_GET = 500;
  const MAX_SKUS_TO_GET = 1000;

  // Stock Source
  const STOCK_IGNORE = "STOCK_IGNORE";
  const STOCK_INSTOCK = "STOCK_INSTOCK";

  const MARGIN_ENABLE = "MARGIN_ENABLE";
  const MARGIN_DISABLE = "MARGIN_DISABLE";

  // SKUs to Hide
  const HIDE_NONE = "HIDE_NONE";
  const HIDE_NO_COMPE_FOUND = "HIDE_NO_COMPE_FOUND";

  // PriceWatch Enabled Status
  const SHOW_PRICEWATCH_ENABLED = "SHOW_PRICEWATCH_ENABLED";
  const SHOW_PRICEWATCH_DISABLED = "SHOW_PRICEWATCH_DISABLED";

  //-------------------------------------------------------------------------------
  // HOOKS
  //-------------------------------------------------------------------------------

  const navigate = useNavigate();
  const [priceWatchStatus, setPriceWatchStatus] = useState(
    SHOW_PRICEWATCH_ENABLED
  );
  const [skusToHide, setSkusToHide] = useState(HIDE_NO_COMPE_FOUND);
  const [skusToGet, setSkusToGet] = useState(DEFAULT_SKUS_TO_GET);
  const [marginEnable, setMarginEnable] = useState(MARGIN_DISABLE);
  const [marginThreshold, setMarginThreshold] = useState(CONFIG.MARGIN_LOW);
  const [freshnessDays, setFreshnessDays] = useState(
    CONFIG.DEFAULT_COMPE_FRESH_DAYS
  );
  const [stockStatus, setStockStatus] = useState(STOCK_IGNORE);
  const [products, setProducts] = useState([]);
  const [filteredCompeProducts, setFilteredCompeProducts] = useState([]);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [initialSearchPerformed, setInitialSearchPerformed] = useState(false);

  const [categories, setCategories] = useState([{ title: "All Categories" }]);
  const [selectedCategory, setSelectedCategory] = useState({
    title: "All Categories",
  });
  const [sortCol, setSortCol] = useState({
    path: "margin_percent",
    order: "desc",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  //-------------------------------------------------------------
  const numProds = products.length;

  //-------------------------------------------------------------
  const _handleSkusToHideChange = (e) => {
    setSkusToHide(e.target.value);
  };

  //-------------------------------------------------------------
  const _handlePriceWatchStatusChange = (e) => {
    setPriceWatchStatus(e.target.value);
  };

  //-------------------------------------------------------------
  const _handleSkusToGetChange = (e) => {
    let selectedSkusToGet = e.target.value;
    if (selectedSkusToGet > MAX_SKUS_TO_GET) {
      selectedSkusToGet = MAX_SKUS_TO_GET;
    }

    setSkusToGet(selectedSkusToGet);
  };

  //-------------------------------------------------------------
  const _handleMarginEnableChange = (e) => {
    setMarginEnable(e.target.value);
  };

  //-------------------------------------------------------------
  const _handleMarginThresholdChange = (e) => {
    setMarginThreshold(e.target.value);
  };

  //-------------------------------------------------------------
  const _handleFreshnessDaysChange = (e) => {
    let selectedFreshnessDays = e.target.value;
    if (selectedFreshnessDays > CONFIG.MAX_COMPE_FRESH_DAYS) {
      selectedFreshnessDays = CONFIG.MAX_COMPE_FRESH_DAYS;
    }

    setFreshnessDays(selectedFreshnessDays);
  };

  //-------------------------------------------------------------
  const _handleStockStatusChange = (e) => {
    setStockStatus(e.target.value);
  };

  // Determine if getting price-watch enabled items
  const priceWatchStatusQuery =
    priceWatchStatus === SHOW_PRICEWATCH_ENABLED ? "true" : "false";
  const stockStatusQuery = stockStatus === STOCK_INSTOCK ? "true" : "false";

  //-------------------------------------------------------------
  const _handleGetStaleCompeButtonClicked = (pageNumber = 1) => {
    // Fetch Prods only if Prods is not included in state
    if (numProds === 0) {
      fetchProducts((pageNumber = 1));
    }
  };

  //----------------------------------------------------------------------------
  // Device Fetch
  //----------------------------------------------------------------------------
  const fetchProducts = async (pageNumber = 1) => {
    setIsLoading(true);
    setInitialSearchPerformed(true);

    const headers = {
      Authorization: `JWT ${http.getCookie("access_token")}`,
    };
    const query = new URLSearchParams({
      page_size: pageSize,
      page: pageNumber,
    });

    query.set("price_watch", priceWatchStatusQuery);
    query.set("instock", stockStatusQuery);

    try {
      const response = await axios.get(`${baseURL}?${query.toString()}`, {
        headers,
      });
      setProducts(response.data.results);
      setTotalResults(response.data.count);
      setTotalPages(Math.ceil(response.data.count / pageSize));
      setCurrentPage(pageNumber);
    } catch (error) {
      console.error("Failed to fetch products", error);
      setProducts([]);
    } finally {
      setIsLoading(false);
    }
  };

  //-------------------------------------------------------------
  const _handleRefreshProducts = async () => {
    fetchProducts();
  };
  //-------------------------------------------------------------------------------
  // EFFECTS
  //-------------------------------------------------------------------------------
  useEffect(
    // Update filtered channel products whenever filters change
    () => {
      // filter compeProds to include only the items that meet search criteria
      const filteredCompeProds = getFilteredStaleCompe({
        products,
        marginEnable,
        marginThreshold,
        freshnessDays,
        skusToHide,
        skusToGet,
      });

      setFilteredCompeProducts(filteredCompeProds);

      //Set categories based on filtered products
      const filterCategories = filteredCompeProds.map((cat) => {
        let catProperties = {
          title: cat.category,
        };
        return catProperties;
      });

      // Now set categories using setCategories hook using title.
      setCategories([{ title: "All Categories" }, ...filterCategories]);

      // Also select to select all categories when filter changes
      setSelectedCategory({
        title: "All Categories",
      });
    },
    [
      products,
      marginEnable,
      marginThreshold,
      freshnessDays,
      skusToHide,
      skusToGet,
    ]
  );

  useEffect(() => {
    // Only refresh if not currently loading some other fetch
    if (!isLoading && numProds !== 0) {
      _handleRefreshProducts();
    }
  }, [priceWatchStatus, stockStatus]); // This will run when one of the dependencies changes

  //-------------------------------------------------------------
  const _handleCategorySelect = (category) => {
    //console.log(category);
    setSelectedCategory(category);
    // Note cannot set search query to null because this is a controlled component. If set to null/undefined react will think this is an uncontrolled component
    // and will give warning when user types in typed that trying to covert an uncontrolled component to a controlled component.
    setSearchQuery("");
    setCurrentPage(1);

    // Reset current page to 1 otherwise if select a category with multiple pages then go back to a category with fewer pages, will display blank
    setCurrentPage(1);
  };
  //-------------------------------------------------------------
  const _handleSort = (receivedSortCol) => {
    setSortCol((prevState) => ({
      ...prevState,
      path: receivedSortCol.path,
      order: receivedSortCol.order,
    }));
  };
  //-------------------------------------------------------------
  const _handleSearchChange = (query) => {
    setSearchQuery(query);
    setSelectedCategory("");
    setCurrentPage(1);
  };
  //-------------------------------------------------------------------------------
  // COMPONENT OBJECTS
  //-------------------------------------------------------------------------------
  // use destructuring to get length of products (number of items and rename to prodCount)
  // the below equivalent to const prodCount = products.length.
  const { length: prodCount } = filteredCompeProducts;

  // filter products based on selected category (or search query) before pagination
  // && used to cover all categories
  // start with filtered products as all products
  let productsFiltered = filteredCompeProducts;

  if (searchQuery) {
    // if there is a search query then fitler based on search query
    productsFiltered = filteredCompeProducts.filter((p) =>
      p.sku.toLowerCase().startsWith(searchQuery.toLowerCase())
    );
  } else {
    // if no search query but there's category selection then filter based on category
    productsFiltered =
      selectedCategory && selectedCategory.title !== "All Categories"
        ? filteredCompeProducts.filter(
            (p) => p.category === selectedCategory.title
          )
        : filteredCompeProducts;
  }

  // sort products
  // sortCol.path and sortCol.order are in array because we can sort by multiple columns using lodash's orderBy util
  const productsSorted = _.orderBy(
    productsFiltered,
    [sortCol.path],
    [sortCol.order]
  );

  //----------------------------------------------------------------------------
  // Pagination Control
  //----------------------------------------------------------------------------
  // // paginate products
  // const productsPaginated = paginate(productsSorted, currentPage, pageSize);

  const _handlePageChange = (page) => {
    //console.log(`Page Clicked = ${page}`);
    setCurrentPage(page);
    // No need to call fetchProducts here as it will be called by the effect hook when currentPage changes
  };

  // When page size is changed, we should reset back to the first page and fetch devices.
  const _handlePageSizeChange = (event) => {
    const newPageSize = event.target.value;
    setPageSize(newPageSize);
    setCurrentPage(1); // Reset to the first page
    // No need to call fetchProducts here as it will be called by the effect hook when currentPage changes
  };

  // This effect is used to fetch devices on page change or when page size changes,
  // but only after an initial search has been performed.
  useEffect(() => {
    if (initialSearchPerformed) {
      fetchProducts(currentPage);
    }
  }, [currentPage, pageSize]);

  //-------------------------------------------------------------------------------
  // Render Pagination Controls
  //-------------------------------------------------------------------------------
  const _renderPaginationInfo = () => (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      sx={{ mb: 2 }}
    >
      <Grid item>
        <Typography variant="subtitle1">
          Total results: {totalResults}
        </Typography>
      </Grid>
      <Grid item>
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle1" sx={{ marginRight: 2 }}>
            Items per page:
          </Typography>
          <FormControl size="small" sx={{ minWidth: 80 }}>
            <Select
              id="page-size-select"
              value={pageSize}
              onChange={_handlePageSizeChange}
              displayEmpty
            >
              {[5, 10, 20, 30, 50].map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );

  //-------------------------------------------------------------------------------
  // Conditional Render of Margin Anal Table
  //-------------------------------------------------------------------------------
  const _renderStaleCompeTable = () => {
    if (isLoading) return <LoadingSpinner />;
    if (!prodCount || prodCount === 0)
      return (
        <div style={{ marginTop: "10px" }}>
          {CONFIG.RFW_HORIZDOT}
          <p> ..... No listings in the database match the search criteria.</p>
        </div>
      );
    return (
      <Grid item xs={12} align="center">
        <div>{CONFIG.RFW_HORIZDOT}</div>
        {/* Pagination Info and Page Size Selection */}
        {_renderPaginationInfo()}
        <div className="row">
          <div className="col-2">
            <ListGroup
              items={categories}
              selectedItem={selectedCategory}
              onItemSelect={_handleCategorySelect}
              textProperty="title"
              idProperty="title"
            />
          </div>
          <div className="col">
            <p>
              Showing <strong> {productsSorted.length} </strong> products from
              category <strong>{selectedCategory.title} </strong> in the current
              page matching selected criteria.
            </p>
            <SearchBox
              searchValue={searchQuery}
              searchOnChange={(e) => _handleSearchChange(e.currentTarget.value)}
            />
            <StaleCompeTable
              prods={productsSorted}
              sortColumn={sortCol}
              onSort={_handleSort}
            />

            {totalPages > 1 && (
              <Pagination
                itemsCount={totalResults} // This is total products and not products returned for current page
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={_handlePageChange}
              />
            )}
          </div>
        </div>
      </Grid>
    );
  };
  //-------------------------------------------------------------------------------
  //Render of Margin Anal Page
  //-------------------------------------------------------------------------------
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} align="center">
        <Typography component="h4" variant="h4">
          {"Missing/Stale Competitor Analysis"}
        </Typography>
      </Grid>

      <Grid item xs={12} align="center">
        <Box
          sx={{
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{ background: "#FFEADE", borderRadius: "16px", paddingX: 2 }}
          >
            <Typography component="h6" variant="h6">
              {"Select Rfwel Status of Stock"}
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                row
                defaultValue={STOCK_IGNORE}
                onChange={_handleStockStatusChange}
              >
                <FormControlLabel
                  value={STOCK_IGNORE}
                  control={<Radio color="primary" />}
                  label="Ignore Rfwel Stock Status"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value={STOCK_INSTOCK}
                  control={<Radio color="secondary" />}
                  label="Has QBO or Supplier Stock"
                  labelPlacement="bottom"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} align="center">
        <Box
          sx={{
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{ background: "#F4DDF4", borderRadius: "16px", paddingX: 2 }}
          >
            <Typography component="h6" variant="h6">
              {"Enable Margin Check"}
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                row
                defaultValue={MARGIN_DISABLE}
                onChange={_handleMarginEnableChange}
              >
                <FormControlLabel
                  value={MARGIN_ENABLE}
                  control={<Radio color="primary" />}
                  label="Consider Margin"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value={MARGIN_DISABLE}
                  control={<Radio color="secondary" />}
                  label="Ignore Margin"
                  labelPlacement="bottom"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box
            sx={{
              background: "#F4DDF4",
              borderRadius: "16px",
              paddingX: 2,
              marginLeft: 2,
            }}
          >
            <Typography component="h6" variant="h6">
              {"Select % Margin Threshold"}
            </Typography>
            <FormControl>
              <TextField
                required={true}
                type="number"
                onChange={_handleMarginThresholdChange}
                defaultValue={CONFIG.MARGIN_LOW}
                inputProps={{
                  min: 1,
                  max: 100,
                  style: { textAlign: "center" },
                }}
              />
              <FormHelperText>
                <span align="center">% Margin Threshold</span>
              </FormHelperText>
            </FormControl>
          </Box>

          <Box
            sx={{
              background: "#F1DAEE",
              borderRadius: "16px",
              paddingX: 2,
              marginLeft: 2,
            }}
          >
            <Typography component="h6" variant="h6">
              {"Select # Days of Freshness"}
            </Typography>
            <FormControl>
              <TextField
                required={true}
                type="number"
                value={freshnessDays}
                onChange={_handleFreshnessDaysChange}
                inputProps={{
                  min: 1,
                  max: CONFIG.MAX_COMPE_FRESH_DAYS,
                  style: { textAlign: "center" },
                }}
              />
              <FormHelperText>
                <span align="center">
                  Stale if not refreshed since X days ago
                </span>
              </FormHelperText>
            </FormControl>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} align="center">
        <Box
          sx={{
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Box sx={{ background: "#D5F7F1", borderRadius: "16px" }}>
            <Typography component="h6" variant="h6">
              {"Select Items to Show"}
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                row
                defaultValue={HIDE_NO_COMPE_FOUND}
                onChange={_handleSkusToHideChange}
              >
                <FormControlLabel
                  value={HIDE_NONE}
                  control={<Radio color="primary" />}
                  label="Show All"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value={HIDE_NO_COMPE_FOUND}
                  control={<Radio color="primary" />}
                  label="Hide No Compe Found Selected"
                  labelPlacement="bottom"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box
            sx={{
              background: "#D5F7F1",
              borderRadius: "16px",
              paddingX: 2,
              marginLeft: 2,
            }}
          >
            <Typography component="h6" variant="h6">
              {"Select Price-Watch Status"}
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                row
                defaultValue={SHOW_PRICEWATCH_ENABLED}
                onChange={_handlePriceWatchStatusChange}
              >
                <FormControlLabel
                  value={SHOW_PRICEWATCH_ENABLED}
                  control={<Radio color="primary" />}
                  label="Price-Watch Enabled"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value={SHOW_PRICEWATCH_DISABLED}
                  control={<Radio color="secondary" />}
                  label="Price-Watch Disabled"
                  labelPlacement="bottom"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box
            sx={{
              background: "#EFF2CB",
              borderRadius: "16px",
              paddingX: 2,
              marginLeft: 2,
            }}
          >
            <Typography component="h6" variant="h6">
              {"Select Max Number of SKUs to Get"}
            </Typography>
            <FormControl>
              <TextField
                required={true}
                type="number"
                onChange={_handleSkusToGetChange}
                defaultValue={DEFAULT_SKUS_TO_GET}
                inputProps={{
                  min: 1,
                  max: MAX_SKUS_TO_GET,
                  style: { textAlign: "center" },
                }}
              />
              <FormHelperText>
                <span align="center">Number of Listings to Get</span>
              </FormHelperText>
            </FormControl>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} align="center">
        <Button
          color="primary"
          variant="contained"
          onClick={_handleGetStaleCompeButtonClicked}
          disabled={isLoading || numProds !== 0}
        >
          {" "}
          Get Missing/Stale Compe
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={_handleRefreshProducts}
          disabled={isLoading}
          sx={{ marginLeft: 2 }}
        >
          {"Refresh from DB"}
        </Button>
      </Grid>
      <Grid item xs={12} align="center">
        <Button color="secondary" variant="contained" to="/" component={Link}>
          {" "}
          Back
        </Button>
      </Grid>

      {_renderStaleCompeTable()}
    </Grid>
  );
};

export default StaleCompe;

import React, { useState } from "react";
import { Box, Button, Typography, Grid } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import WizInfoDialog from "./WizInfoDialog";
import parse from "html-react-parser";

const WizDecisionPoint = ({
  baseConfig,
  question,
  questionInfo,
  options,
  onAnswer,
  selectedOption,
}) => {
  const {
    priColor,
    priHoverColor,
    tertColor,
    tertHoverColor,
    quadColor,
    quadHoverColor,
  } = baseConfig.siteBranding;
  const [showInfo, setShowInfo] = useState(null);

  const handleOptionSelect = (nextStepId, optionText, optionInfo) => {
    // Call onAnswer with option text and option info
    onAnswer(nextStepId, optionText, optionInfo);
  };

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Typography variant="h6" sx={{ mr: 1 }}>
          {question}
        </Typography>
        {questionInfo && (
          <Button
            startIcon={<InfoIcon />}
            onClick={() => setShowInfo(questionInfo)}
          >
            Info
          </Button>
        )}
      </Box>
      <Typography variant="body1">Please select an option:</Typography>
      <Grid container spacing={2}>
        {options && options.length > 0 ? (
          options.map((option) => (
            <Grid item xs={12} key={option.id}>
              <Button
                fullWidth
                variant={
                  selectedOption === option.option ? "contained" : "outlined"
                }
                // color="primary"
                sx={{
                  color: selectedOption === option.option ? "#fff" : priColor,
                  borderColor:
                    selectedOption === option.option ? "transparent" : priColor,
                  backgroundColor:
                    selectedOption === option.option ? tertColor : "#fff",
                  "&:hover": {
                    color: "#fff",
                    borderColor: "transparent",
                    backgroundColor:
                      selectedOption === option.option
                        ? tertHoverColor
                        : tertColor,
                  },
                }}
                onClick={() =>
                  handleOptionSelect(
                    option.next_step,
                    option.option,
                    option.info
                  )
                }
              >
                {option.option}
              </Button>
              {option.info && (
                <Button
                  startIcon={<InfoIcon />}
                  onClick={() => setShowInfo(option.info)}
                  sx={{ ml: 1 }}
                >
                  Info
                </Button>
              )}
            </Grid>
          ))
        ) : (
          <Typography variant="body2" color="textSecondary">
            No options available.
          </Typography>
        )}
      </Grid>
      {showInfo && (
        <WizInfoDialog
          onClose={() => setShowInfo(null)}
          content={parse(showInfo)}
        />
      )}
    </Box>
  );
};

export default WizDecisionPoint;

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link as RouterLink } from "react-router-dom";
import {
  Grid,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  InputLabel,
  Paper,
  Container,
  Typography,
  Box,
  Link as MuiLink,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Alert,
  Divider,
  ButtonBase,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import axios from "axios";
import http from "../../services/httpService";
import InfoIcon from "@mui/icons-material/Info";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import HelpIcon from "@mui/icons-material/Help";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import LinkIcon from "@mui/icons-material/Link";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Chip from "@mui/material/Chip";
import StartIcon from "@mui/icons-material/Start";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

import RestrictedAccessPrompt from "../common/RestrictedAccessPrompt";
import PatentClaimsList from "./PatentClaimsList";
import { useAuth } from "../../context/AuthContext";
import { useWorksheet } from "../../context/WorksheetContext";
import { normalizePatId } from "./utils/patentUtils";

const apiUrlPatanal = `${window._env_.REACT_APP_PATANALAI_API_URL}`; // Patanal API Endpoint
const SOURCE_IS_PATANAL = false; // Select between Patanal and PQAI DB
const EXAMPLE_PATENT = "US7010330B1";

//@TODO ----------------------------
//1. Same patent used as prior art for multiple claims -> Click on "Added to Worksheet" for options {Update, Remove} - Update will update source "Prior art to pn1 cl.1, 2; pn2 cl.9"

const PatentPriorArt = () => {
  const { user } = useAuth();
  const { worksheet, addToWorksheet } = useWorksheet();
  const patentsInWorksheet = worksheet.patents; // Access the 'patents' array from the worksheet object
  const location = useLocation();
  const navigate = useNavigate();

  const [searchLoading, setSearchLoading] = useState(false);
  const [patLoading, setPatLoading] = useState(false);

  // Patent Search
  const [patentId, setPatentId] = useState("");
  const [patentResult, setPatentResult] = useState({ data: null, message: "" });
  const [patentExampleResult, setPatentExampleResult] = useState({
    data: null,
    message: "",
  });
  const [showClaims, setShowClaims] = useState(false);
  const [patentFilingDateApplied, setPatentFilingDateApplied] = useState(false); // State to track if the filing date has been applied to priority date
  const [selectedClaim, setSelectedClaim] = useState(null); // State to track selected claim
  const [dialogPriorityDateOpen, setDialogPriorityDateOpen] = useState(false);

  // Prior Art Query params
  const [searchType, setSearchType] = useState("102");
  const [searchQuery, setSearchQuery] = useState("");
  const [afterDate, setAfterDate] = useState(null);
  const [beforeDate, setBeforeDate] = useState(null);
  const [index, setIndex] = useState("auto"); // Default to auto-select
  const [docType, setDocType] = useState("patent"); // Default to patent

  // Results
  const [searchResult, setSearchResult] = useState({ data: null, message: "" });
  const [claimChartOpenDialog, setClaimChartOpenDialog] = useState(false);

  //-------------------------------------------------------------------------------
  // Get Example Patent & Read State when Component Mounts - For Redirects from Patent Search
  //-------------------------------------------------------------------------------
  useEffect(() => {
    const initializeData = async () => {
      // fetch EXAMPLE_PATENT data on component load
      const isExampleInitFetch = true;
      await fetchPatent(EXAMPLE_PATENT, isExampleInitFetch);

      // Check if there is a location.state which means that this is redirect from patent search
      // Wait until fetchPatent above done
      if (location.state) {
        applyStateFromLocation(location.state);
        // @TODO - when i enable the below to automatically trigger prior art search with defaults from redirect, i get anomalous results (search of example patent claim #1)
        // _handlePriorArtSearch(); // Trigger the search
        navigate(location.pathname, { replace: true }); // Clear the state
      }
    };

    initializeData();
  }, []);

  const applyStateFromLocation = (state) => {
    setPatentId(state.patentId);
    setPatentResult(state.patentResult);
    setSearchQuery(state.searchQuery);
    setSelectedClaim(state.selectedClaim);
    setBeforeDate(moment(state.beforeDate));
    setPatentFilingDateApplied(true);
  };

  //-------------------------------------------------------------------------------
  // Run Example
  //-------------------------------------------------------------------------------
  // If Run example clicked, load Example and do search
  const _handleSearchExample = async () => {
    if (!patentExampleResult.data) {
      const isExampleInitFetch = true;
      await fetchPatent(EXAMPLE_PATENT, isExampleInitFetch);
    }

    if (patentExampleResult.data) {
      setPatentId(EXAMPLE_PATENT);
      setPatentResult(patentExampleResult);
      setSearchQuery(
        patentExampleResult.data?.claims[0].replace(/^\d+\.\s*/, "")
      ); // Search Claim 1 (strip claim #)
      setSelectedClaim(0); // Claim 1
      setBeforeDate(moment(patentExampleResult.data?.filing_date)); // Use example patent filing date as priority date
      setPatentFilingDateApplied(true);

      // Trigger search based on example patent data (+defaults)
      _handlePriorArtSearch();
    }
  };

  //-------------------------------------------------------------------------------
  // Fetch Patent
  //-------------------------------------------------------------------------------
  const fetchPatent = async (id, isExampleInitFetch) => {
    setPatLoading(true);
    setShowClaims(false); // Hide the claims section if it's visible
    _handleClearSearch(); // Clear previous search when getting patent

    // Normallize the ID
    let normalizedId = normalizePatId(id);

    const tryFetch = async (currentId) => {
      const apiUrl = `${apiUrlPatanal}/patents/${currentId}`;
      const headers = {
        Authorization: `JWT ${http.getCookie("access_token")}`,
      };
      const query = new URLSearchParams({
        source: SOURCE_IS_PATANAL ? "patanal" : "pqai",
      });

      try {
        const response = await axios.get(`${apiUrl}?${query.toString()}`, {
          headers,
        });
        return { found: true, data: response.data };
      } catch (error) {
        if (
          axios.isAxiosError(error) &&
          (error.response?.status === 404 || error.response?.status === 500) &&
          currentId.endsWith("B2")
        ) {
          // If B2 not found, try B1
          normalizedId = currentId.replace("B2", "B1");
          return tryFetch(normalizedId);
        }
        return { found: false };
      }
    };

    const result = await tryFetch(normalizedId);
    if (result.found) {
      const resultData = { data: result.data, message: "" };
      if (isExampleInitFetch) {
        setPatentExampleResult(resultData);
      } else {
        setPatentResult(resultData);
        // Update patentId only if not isExampleInitFetch and the normalizedId is different from the initially provided id
        if (normalizedId !== id) {
          setPatentId(normalizedId);
        }
      }
    } else {
      console.error("Failed to fetch patent: ", id);
      const message = (
        <span>Patent not found. Enter search query manually below.</span>
      );
      const errorResult = { data: null, message: message };
      if (isExampleInitFetch) {
        setPatentExampleResult(errorResult);
      } else {
        setPatentResult(errorResult);
      }
    }

    setPatLoading(false);
  };

  //-------------------------------------------------------------------------------
  // Fetch Prior Art
  //-------------------------------------------------------------------------------

  const _handlePriorArtSearch = async () => {
    setSearchLoading(true);

    // Example: /patents/prior-art-search
    const apiUrl = `${apiUrlPatanal}/patents/prior-art-search`;
    const headers = {
      Authorization: `JWT ${http.getCookie("access_token")}`,
    };
    const urlQuery = new URLSearchParams({
      source: SOURCE_IS_PATANAL ? "patanal" : "pqai",
      q: searchQuery,
      index: index,
      type: docType,
      "search-type": searchType,
      ...(afterDate && { after: afterDate.format("YYYY-MM-DD") }),
      ...(beforeDate && { before: beforeDate.format("YYYY-MM-DD") }),
    });
    try {
      const response = await axios.get(`${apiUrl}?${urlQuery.toString()}`, {
        headers,
      });

      setSearchResult({ data: response.data.results, message: "" });
    } catch (error) {
      console.error("Failed to fetch prior art:", error);
      const message = <span>No prior art found for query parameters</span>;
      setSearchResult({
        data: null,
        message: message,
      });
    } finally {
      setSearchLoading(false);
    }
  };

  //-------------------------------------------------------------------------------
  // Claim Chart & Priority Date
  //-------------------------------------------------------------------------------
  const _handleClaimChartOpenDialog = () => {
    setClaimChartOpenDialog(true);
  };

  const _handleClaimChartCloseDialog = () => {
    setClaimChartOpenDialog(false);
  };

  const _handleDialogPriorityDateToggle = () => {
    setDialogPriorityDateOpen(!dialogPriorityDateOpen);
  };

  //-------------------------------------------------------------------------------
  // Clear Search
  //-------------------------------------------------------------------------------
  const _handleClearSearch = () => {
    setSearchQuery("");
    setAfterDate(null);
    setBeforeDate(null);
    setIndex("auto");
    setDocType("patent");
    setSearchResult({
      data: null,
      message: "",
    });
    setPatentFilingDateApplied(false); // Remove applied in filing date in claims dialog
    setSelectedClaim(null); // Remove applied in claim in claims dialog
  };

  //-------------------------------------------------------------------------------
  // Render Patent Search Buttons
  //-------------------------------------------------------------------------------
  const _handleSearchSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior (page refresh)
    fetchPatent(patentId);
  };

  const _handleClearPatent = () => {
    setPatentId(""); // Clear the patent ID input
    // Clear the search results
    setPatentResult({
      data: null,
      message: "",
    });
    setShowClaims(false);
  };

  const DEPRECATED______renderPatentUnderTestMiniDetail = (result) => {
    return (
      <>
        <Typography variant="h6">{result.title}</Typography>

        <Divider sx={{ my: 1 }} />
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          <Box
            display="flex"
            alignItems="center"
            sx={{ color: "text.secondary", fontSize: "0.8rem" }}
          >
            <CalendarTodayIcon sx={{ fontSize: "1rem" }} />
            <Typography sx={{ ml: 1 }}>
              {formatDate(result.filing_date)}
            </Typography>
          </Box>

          {result.assignees && (
            <Box
              display="flex"
              alignItems="center"
              sx={{ color: "text.secondary", fontSize: "0.8rem", ml: 1 }}
            >
              <BusinessIcon sx={{ fontSize: "1rem" }} />
              <Typography sx={{ ml: 1 }}>
                {getFormattedAssignee(result.assignees)}
              </Typography>
            </Box>
          )}

          <Box
            display="flex"
            alignItems="center"
            sx={{ fontSize: "0.8rem", ml: 1 }}
          >
            <MuiLink
              href={`https://patents.google.com/patent/${patentId}`}
              target="_blank"
              display="flex"
              alignItems="center"
            >
              <LinkIcon />
              <Typography variant="body1" sx={{ ml: 1 }}>
                {patentId}
              </Typography>
            </MuiLink>
          </Box>
        </Box>
      </>
    );
  };

  const _renderPatentUnderTestMiniDetail = (result) => {
    return (
      <Paper elevation={3} sx={{ p: 2 }}>
        <Typography variant="h6">{result.title}</Typography>
        <Divider sx={{ my: 1 }} />
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              display="flex"
              alignItems="center"
              sx={{ color: "text.secondary", fontSize: "0.8rem" }}
            >
              <CalendarTodayIcon sx={{ fontSize: "1rem" }} />
              <Typography sx={{ ml: 1 }}>
                {formatDate(result.filing_date)}
              </Typography>
            </Box>
          </Grid>

          {result.assignees && (
            <Grid item xs={12} sm={6} md={4}>
              <Box
                display="flex"
                alignItems="center"
                sx={{ color: "text.secondary", fontSize: "0.8rem" }}
              >
                <BusinessIcon sx={{ fontSize: "1rem" }} />
                <Typography sx={{ ml: 1 }}>
                  {getFormattedAssignee(result.assignees)}
                </Typography>
              </Box>
            </Grid>
          )}

          <Grid item xs={12} sm={6} md={4}>
            <Box display="flex" alignItems="center" sx={{ fontSize: "0.8rem" }}>
              <MuiLink
                href={`https://patents.google.com/patent/${patentId}`}
                target="_blank"
                display="flex"
                alignItems="center"
              >
                <LinkIcon />
                <Typography variant="body1" sx={{ ml: 1 }}>
                  {patentId}
                </Typography>
              </MuiLink>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  const _renderPatentClaimSearchFields = () => {
    return (
      <>
        <Grid item xs={6}>
          {/* Put everything in a form so can use enter to submit */}
          <form onSubmit={_handleSearchSubmit}>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <TextField
                label="Patent Number"
                variant="outlined"
                value={patentId}
                onChange={(e) => setPatentId(e.target.value.trim())}
                // fullWidth
                helperText="Enter a patent Number to fetch claim details directly"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sx={{ mb: 1 }}>
              <Button
                type="submit" // Button submits form
                variant="contained"
                onClick={() => fetchPatent(patentId)}
                disabled={patLoading || patentId.trim() === ""}
                sx={{
                  mt: 1,
                  backgroundColor: "#88BBD6",
                  "&:hover": { backgroundColor: "#71a5c7" },
                }}
              >
                {patLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  "Get Patent Claims"
                )}
              </Button>

              <Button
                variant="outlined"
                onClick={_handleClearPatent}
                sx={{
                  mt: 1,
                  ml: 2,
                  backgroundColor: "white",
                  "&:hover": { backgroundColor: "#f0f0f0" },
                }}
              >
                Clear Patent
              </Button>
            </Grid>
          </form>
        </Grid>

        {patentResult.message && (
          <Grid item xs={6} sx={{ mb: 2 }}>
            <Alert severity="warning">{patentResult.message}</Alert>
          </Grid>
        )}

        {patentResult.data && (
          <>
            <Grid item xs={6} sx={{ mb: 2 }}>
              {_renderPatentUnderTestMiniDetail(patentResult.data)}
              <Button
                onClick={() => setShowClaims(true)}
                variant="outlined"
                sx={{ mt: 4, mb: 2 }}
              >
                Show/Apply Claims to Prior Art Query
              </Button>
              <Tooltip title="Click to show claims and then click on claim to apply it to search box.">
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            {_renderClaimSelectDialog(patentResult)}
          </>
        )}
      </>
    );
  };

  //-------------------------------------------------------------------------------
  // Render Patent Search and Claim Selection Fields
  //-------------------------------------------------------------------------------
  const _applyPatentFilingDate = (dateString) => {
    const newDate = moment(dateString);
    if (newDate.isValid()) {
      setBeforeDate(newDate);
      setPatentFilingDateApplied(true);
    } else {
      console.error("Invalid date format:", dateString);
      setPatentFilingDateApplied(false); // Ensure it's set to false if the date is invalid
    }
  };

  const _handleClaimSelect = (claim, index) => {
    setSearchQuery(claim);
    setSearchResult({
      data: null,
      message: "",
    }); // Clear previous search results
    setSelectedClaim(index); // Store the index of the selected claim
    setShowClaims(false); // Close the claims dialog after selection
  };

  const _renderClaimSelectDialog = (patentResult) => {
    return (
      <Dialog
        open={showClaims}
        onClose={() => setShowClaims(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {patentResult.data.pn} - {patentResult.data.title}
        </DialogTitle>
        <DialogContent dividers>
          <Alert severity="info" sx={{ mt: 2, mb: 1 }}>
            Click filing date to set priority date as filing date but note that
            the filing date is NOT always the priority date.{" "}
            <a
              href="https://news-insights.kthlaw.com/en-us/patents/patent-analysis/patent-dates"
              target="_blank"
              rel="noopener"
              style={{ color: "#0077cc", textDecoration: "none" }}
            >
              Learn more
            </a>
            .
          </Alert>

          <Box display="flex" alignItems="center">
            <Typography
              gutterBottom
              component="span"
              sx={{ fontWeight: "bold" }}
            >
              Publication Date:
            </Typography>
            <Typography gutterBottom component="span" sx={{ ml: 1 }}>
              {patentResult.data.publication_date}
            </Typography>
          </Box>

          {/* Click Filing Date to Set Priority Date */}
          <ButtonBase
            onClick={() =>
              _applyPatentFilingDate(patentResult.data.filing_date)
            }
            sx={{
              display: "block",
              cursor: "pointer",
              "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
            }}
          >
            <Typography
              gutterBottom
              component="span"
              sx={{ fontWeight: "bold" }}
            >
              Filing Date:
            </Typography>
            <Typography gutterBottom component="span" sx={{ ml: 1 }}>
              {patentResult.data.filing_date}
            </Typography>
            {patentFilingDateApplied && (
              <Typography
                sx={{
                  color: "white",
                  backgroundColor: "green",
                  borderRadius: "4px",
                  p: 0.5, // Padding inside the box
                  display: "inline-block", // Make it an inline element with background
                  ml: 2, // Margin left for spacing after the date
                  fontSize: "0.8rem", // Smaller font size
                }}
              >
                Applied
              </Typography>
            )}
          </ButtonBase>

          {/* Click Claims to Set Prior Art Search Query String */}
          <Alert severity="info" sx={{ mt: 2, mb: 1 }}>
            Click on a claim to paste it to prior-art search field.
          </Alert>

          <PatentClaimsList
            claimsList={patentResult.data.claims}
            selectedClaim={selectedClaim}
            handleClaimSelect={_handleClaimSelect}
            removeClaimNumber={true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowClaims(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  //-------------------------------------------------------------------------------
  // Render Query Selection and Prior Art Type Fields
  //-------------------------------------------------------------------------------

  const _renderPriorArtQuerySelectFields = () => {
    return (
      <>
        <Grid item xs={12}>
          <TextField
            label="Search Query String"
            multiline
            rows={4}
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              // Clear previous search results when the query string changes
              setSearchResult({
                data: null,
                message: "",
              });
            }}
            placeholder="Paste or manually enter claims to search prior art ..."
            helperText="Manually enter/edit query or click on claims to paste complete claim."
            fullWidth
            variant="outlined"
          />
        </Grid>

        <Grid item xs={4}>
          <DatePicker
            label="Prior art after"
            value={afterDate}
            onChange={setAfterDate}
          />
          <Tooltip title="Returns patents or npl published after this date. Use to limit age of prior-art references.">
            <IconButton>
              <InfoIcon color="info" />
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item xs={4}>
          <DatePicker
            label="Priority Date (art before)"
            value={beforeDate}
            onChange={setBeforeDate}
          />

          <IconButton onClick={_handleDialogPriorityDateToggle}>
            <ReportProblemIcon color="warning" />
          </IconButton>

          <Dialog
            open={dialogPriorityDateOpen}
            onClose={_handleDialogPriorityDateToggle}
          >
            <DialogTitle sx={{ textAlign: "center" }}>
              Priority Date Information
            </DialogTitle>
            <DialogContent>
              <p>
                Set this date to get patents or NPL published before this date.
                Ordinarily would want to set this to the priority date of the
                patent.
              </p>
              <Alert severity="info">
                <p>
                  Note that the filing date is NOT necessarily the priority
                  date. The priority date could be the filing date of an earlier
                  application that this patent claims priority to. For more on
                  priority dates{" "}
                  <a
                    href="https://news-insights.kthlaw.com/en-us/patents/patent-analysis/patent-dates"
                    target="_blank"
                    rel="noopener"
                    style={{ color: "#0077cc", textDecoration: "none" }}
                  >
                    read more
                  </a>
                  .
                </p>
              </Alert>
            </DialogContent>
            <DialogActions>
              <Button onClick={_handleDialogPriorityDateToggle}>Close</Button>
            </DialogActions>
          </Dialog>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id="index-label">CPC Class</InputLabel>
            <Select
              labelId="index-label"
              value={index}
              label="CPC CLass"
              onChange={(e) => {
                setIndex(e.target.value);
                // Clear previous search results when CPC changes
                setSearchResult({
                  data: null,
                  message: "",
                });
              }}
            >
              <MenuItem value="auto">Auto</MenuItem>
              <MenuItem value="H04W">H04W</MenuItem>
              <MenuItem value="E21B">E21B</MenuItem>
              <MenuItem value="H01Q">H01Q</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <Box
            border={1}
            borderColor="grey.500"
            borderRadius={3}
            sx={{
              p: 1, // Adds padding inside the Box
              display: "flex", // Makes Box a flex container
              flexDirection: "column", // Stacks children vertically
              alignItems: "center", // Centers children along the cross axis (horizontally)
              justifyContent: "center", // Centers children along the main axis (vertically)
            }}
          >
            <FormControl
              component="fieldset"
              sx={{ width: "100%", textAlign: "center" }}
            >
              <FormLabel component="legend">Search Type</FormLabel>
              <RadioGroup
                row
                value={searchType}
                onChange={(e) => {
                  setSearchType(e.target.value);
                  // Clear previous search results when the search type changes
                  setSearchResult({
                    data: null,
                    message: "",
                  });
                }}
                name="search-type"
                sx={{
                  justifyContent: "center", // Center the RadioGroup content
                }}
              >
                <FormControlLabel
                  value="102"
                  control={<Radio color="primary" />}
                  label="102"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="103"
                  control={<Radio color="secondary" />}
                  label="103"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box
            border={1}
            borderColor="grey.500"
            borderRadius={3}
            sx={{
              p: 1, // Adds padding inside the Box
              display: "flex", // Makes Box a flex container
              flexDirection: "column", // Stacks children vertically
              alignItems: "center", // Centers children along the cross axis (horizontally)
              justifyContent: "center", // Centers children along the main axis (vertically)
            }}
          >
            <FormControl
              component="fieldset"
              sx={{ width: "100%", textAlign: "center" }}
            >
              <FormLabel component="legend" sx={{ mb: 1 }}>
                Search In
              </FormLabel>
              <RadioGroup
                row
                value={docType}
                onChange={(e) => {
                  setDocType(e.target.value);
                  // Clear previous search results when the doc type changes
                  setSearchResult({
                    data: null,
                    message: "",
                  });
                }}
                name="document-type"
                sx={{
                  justifyContent: "center", // Center the RadioGroup content
                }}
              >
                <FormControlLabel
                  value="patent"
                  control={<Radio color="primary" />}
                  label="Patents"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="npl"
                  control={<Radio color="secondary" />}
                  label="NPL (Non-Patent Literature)"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>
      </>
    );
  };

  //-------------------------------------------------------------------------------
  // Render Prior Art Search Fields (Patent Search & Prior Art Type Selection)
  //-------------------------------------------------------------------------------
  const _renderSearchFields = () => {
    return (
      <Paper
        elevation={3}
        sx={{ p: 3, margin: "auto", maxWidth: "90%", mt: 1 }}
      >
        <Typography variant="h6" align="center" gutterBottom>
          Patent Prior Art Search
        </Typography>
        <Typography
          variant="caption" // Smaller text size
          align="center"
          display="block" // Ensures the text is on its own line
          sx={{
            color: "text.secondary", // Muted color
            mb: 3,
          }}
        >
          (Patent analytics powered by{" "}
          <MuiLink
            href="https://github.com/pqaidevteam/pqai/blob/master/docs/README-API.md"
            target="_blank"
            rel="noopener noreferrer"
          >
            Project PQAI
          </MuiLink>
          )
        </Typography>

        <Alert
          severity="info"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            mb: 3,
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm>
              {
                "Fetch patent and select claims for prior-art search, or manually enter query string directly below."
              }
            </Grid>
            <Grid item xs={12} sm="auto">
              <Button
                startIcon={<StartIcon />}
                variant="outlined"
                size="small"
                onClick={_handleSearchExample}
              >
                Run Example Search
              </Button>
            </Grid>
          </Grid>
        </Alert>

        <Grid container spacing={2} justifyContent="left" sx={{ mb: 2 }}>
          {/* Patent Claim Search Fields */}
          {_renderPatentClaimSearchFields()}

          <Grid item xs={12}>
            <Divider sx={{ mb: 2 }}>
              <Chip label="Refine Prior Art Query" size="small" />
            </Divider>
          </Grid>

          {/* Prior Art Query and Selection Fields */}
          {_renderPriorArtQuerySelectFields()}

          {/* Prior Art Search and Clear Buttons */}
          <Grid item xs={12} align="center">
            {/* Create marginBottom for xtra small devices (mobile) when buttons stack */}
            <Button
              startIcon={<SearchIcon />}
              variant="contained"
              onClick={_handlePriorArtSearch}
              disabled={searchLoading || searchQuery?.trim() === ""}
              sx={{ mr: 2, mt: 1, mb: { xs: 1, sm: 0 } }}
            >
              {searchLoading ? (
                <CircularProgress size={24} />
              ) : (
                "Search Prior Art"
              )}
            </Button>
            <Button
              startIcon={<SearchOffIcon />}
              variant="outlined"
              onClick={_handleClearSearch}
              sx={{ mr: 2, mt: 1, mb: { xs: 1, sm: 0 } }}
            >
              Clear Search
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  //-------------------------------------------------------------------------------
  // Render Patent Results paper content - one patent
  //-------------------------------------------------------------------------------
  // format date from yyyy-mm-dd to MM DD YYYY
  // const formatDate = (dateString) => {
  //   const options = { year: "numeric", month: "long", day: "numeric" };
  //   return new Date(dateString).toLocaleDateString(undefined, options);
  // };
  const formatDate = (dateString) => {
    // Specify the locale as 'en-US' and change the month format to 'short' to get the abbreviated month
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      timeZone: "UTC",
    };
    // Create a new Date object in UTC to avoid timezone issues
    const date = new Date(dateString + "T00:00:00Z");
    return date.toLocaleDateString("en-US", options);
  };

  // Helper function to get formatted inventor name. Return last name. If more than one add et al.
  // Example: "Alexander F. Zazovsky" or "Colin Longfield"
  const getFormattedInventors = (inventors) => {
    if (inventors && inventors.length === 0) return "";

    const lastName = inventors[0].split(" ").slice(-1)[0]; // Get last element after splitting by space
    return inventors.length > 1 ? `${lastName} et al.` : lastName;
  };

  // Helper function to get formatted assignee or default text
  const getFormattedAssignee = (assignees) => {
    if (assignees && assignees.length === 0) {
      return "No Assignee Found";
    } else {
      return assignees.length > 1 ? `${assignees[0]} et al.` : assignees[0];
    }
  };

  const _renderRefinementButts = (patent) => {
    // Check if the patent is already added to worksheet
    const patentInWorksheet = patentsInWorksheet.some(
      (p) => p.pn === patent.publication_id
    );

    // What is this patent?
    const patentSource = `Prior art to ${patentId} cl.${selectedClaim + 1}`;

    return (
      <Box display="flex" mt={3}>
        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item xs="auto">
            {patentInWorksheet ? (
              <Button
                style={{ backgroundColor: "green", color: "white" }}
                disabled
              >
                Added to Worksheet
              </Button>
            ) : (
              <Button
                variant="outlined"
                onClick={() => addToWorksheet(patent, "patents", patentSource)}
              >
                Add to Worksheet
              </Button>
            )}
          </Grid>

          <Grid item xs="auto">
            {/* @TODO - details should show full text and drawings in dialog and not navitage to patent-search (no need to refine prior art!) */}
            <Button
              variant="outlined"
              // component={RouterLink}
              // to={`/patent-search/${patentId}`}
            >
              Show Details
            </Button>
          </Grid>

          <Grid item xs="auto">
            <Button variant="outlined" onClick={_handleClaimChartOpenDialog}>
              Show Invalidity Chart
            </Button>
          </Grid>

          {/* Dialog to show RestrictedAccessPrompt */}
          <Dialog
            open={claimChartOpenDialog}
            onClose={_handleClaimChartCloseDialog}
          >
            <DialogTitle>Access Restricted</DialogTitle>
            <DialogContent>
              <RestrictedAccessPrompt />
            </DialogContent>
            <DialogActions>
              <Button onClick={_handleClaimChartCloseDialog} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Box>
    );
  };

  const _renderSearchResultPaperContent = (result, searchType, docType) => {
    return (
      <>
        <Typography variant="h6">
          {result.publication_id} - {result.title}
        </Typography>
        <Typography
          variant="caption"
          display="block" // Ensures the text is on its own line
          sx={{
            color: "text.secondary", // Muted color
            mb: 1,
          }}
        >
          Score: {result.score}
        </Typography>
        <Divider sx={{ my: 1 }} />
        {/* <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          sx={{ mt: 2 }}
        > */}
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} sm={6} md={3}>
            <Box
              display="flex"
              alignItems="center"
              sx={{ color: "text.secondary", fontSize: "0.8rem" }}
            >
              <CalendarTodayIcon />
              <Typography variant="body1" sx={{ ml: 1 }}>
                {formatDate(result.publication_date)}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Box
              display="flex"
              alignItems="center"
              sx={{ ml: 2, color: "text.secondary", fontSize: "0.8rem" }}
            >
              <PersonIcon />
              <Typography variant="body1" sx={{ ml: 1 }}>
                {result.inventors && getFormattedInventors(result.inventors)}
              </Typography>
            </Box>
          </Grid>

          {docType === "patent" && (
            <Grid item xs={12} sm={6} md={3}>
              <Box
                display="flex"
                alignItems="center"
                sx={{ ml: 2, color: "text.secondary", fontSize: "0.8rem" }}
              >
                <BusinessIcon />
                <Typography variant="body1" sx={{ ml: 1 }}>
                  {/* {result.assignees &&
              getFormattedAssignee(result.assignees)} */}
                  {result.owner}
                </Typography>
              </Box>
            </Grid>
          )}
          {docType === "patent" && (
            <Grid item xs={12} sm={6} md={3}>
              <Box
                display="flex"
                alignItems="center"
                sx={{ ml: 2, color: "text.secondary", fontSize: "0.8rem" }}
              >
                <MuiLink
                  href={`https://patents.google.com/patent/${result.publication_id}`}
                  target="_blank"
                  display="flex"
                  alignItems="center"
                >
                  <LinkIcon />
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {result.publication_id}
                  </Typography>
                </MuiLink>
              </Box>
            </Grid>
          )}
          {/* </Box> */}
        </Grid>
        <Typography variant="body1" paragraph sx={{ mt: 3 }}>
          <span style={{ fontWeight: "bold" }}>Abstract: </span>
          {result.abstract}
        </Typography>

        {searchType === "102" && _renderRefinementButts(result)}
      </>
    );
  };

  //-------------------------------------------------------------------------------
  // Render Final UI
  //-------------------------------------------------------------------------------

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-us">
      {/* Search Fields */}
      <Container maxWidth="lg" className="link-no-decoration">
        {_renderSearchFields()}
      </Container>

      {/* Search Results */}
      <Container maxWidth="x1" className="link-no-decoration">
        {searchResult.data &&
          (searchType === "102"
            ? // Render single results for SearchType 102
              searchResult.data.map((result, index) => (
                <Grid
                  container
                  spacing={2}
                  sx={{ mt: 2 }}
                  key={`${searchType}-${index}`}
                >
                  <Grid item xs={12}>
                    <Paper elevation={2} sx={{ p: 2, mb: 1 }}>
                      {_renderSearchResultPaperContent(
                        result,
                        searchType,
                        docType
                      )}
                    </Paper>
                  </Grid>
                </Grid>
              ))
            : // Render grouped results for SearchType 103
              searchResult.data.map((group, groupIndex) => (
                <Grid
                  container
                  spacing={2}
                  sx={{ mt: 2 }}
                  key={`103-combination-${groupIndex}`}
                >
                  <Grid item xs={12}>
                    <Paper elevation={2} sx={{ p: 2, mb: 1 }}>
                      <h3>103 Combination {groupIndex + 1}</h3>
                      {group.map((result, index) => (
                        <div key={`result-${groupIndex}-${index}`}>
                          <h5>
                            {result.publication_id} - {result.title}
                          </h5>
                          <Typography
                            variant="caption"
                            display="block" // Ensures the text is on its own line
                            sx={{
                              color: "text.secondary", // Muted color
                              mb: 1,
                            }}
                          >
                            Score: {result.score}
                          </Typography>

                          <p>
                            <span style={{ fontWeight: "bold" }}>
                              Abstract:{" "}
                            </span>
                            {result.abstract}
                          </p>
                        </div>
                      ))}
                    </Paper>
                  </Grid>
                </Grid>
              )))}
      </Container>
    </LocalizationProvider>
  );
};

export default PatentPriorArt;

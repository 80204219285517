import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";

const WorksheetDeviceTable = ({ devices, removeFromWorksheet }) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Manuf P/N</TableCell>
        <TableCell>Brand</TableCell>
        <TableCell>Title</TableCell>
        <TableCell>Category</TableCell>
        <TableCell>Standards</TableCell>
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {devices.map((device) => (
        <TableRow key={device.devid}>
          <TableCell>{device.mpn}</TableCell>
          <TableCell>{device.brand_name}</TableCell>
          <TableCell>{device.title}</TableCell>
          <TableCell>{device.category_name}</TableCell>
          <TableCell>{device.standards.join(", ")}</TableCell>
          <TableCell>
            <Button
              onClick={() => removeFromWorksheet(device.devid, "devices")}
            >
              Remove
            </Button>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

export default WorksheetDeviceTable;

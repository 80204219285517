import React, { useState, useEffect } from "react";
import Table from "../../common/Table";
import StatusIcon from "../../common/StatusIcon";
import { is_listed } from "../utils/buildChannelProducts";
import BuyButton from "../utils/BuyButton";
import _ from "lodash";
import * as CONFIG from "../utils/Global-Labels-Links-Vars";

const PricingDetailTable = ({ product }) => {
  const columnsStatic = [
    { path: "channel", label: "Channel" },
    {
      path: "price",
      label: "Price (USD)",
      content: (prod) => CONFIG.formatCurrency(prod.price),
    },
    { path: "qty", label: "Qty" },
    { path: "handling", label: "Handling" },
    {
      path: "margin",
      label: "Margin",
      content: (prod) => CONFIG.formatCurrency(prod.margin),
    },
    {
      path: "margin_percent",
      label: "Margin %",
      content: (prod) => CONFIG.formatPercentage(prod.margin_percent),
    },
    {
      path: "price_action",
      label: "Margin Status",
      content: (prod) => (
        <StatusIcon
          status={prod.price_action}
          scope={"detail"}
          listed={prod.listat == "Listed"}
        />
      ),
    },
    {
      key: "price_link",
      label: "Price Link",
      content: (prod) => {
        const returnOfIsListed = is_listed("", prod);
        const channel = returnOfIsListed.channel;
        const listed = returnOfIsListed.listed;
        const pid = returnOfIsListed.pid;
        return listed && pid ? (
          <BuyButton channel={channel} pid={pid} size="sm" />
        ) : (
          ""
        );
      },
    },
  ];

  //console.log(`product in ChannelPricingDetail`);
  //console.log(product);
  //----------------------------------------------------------------------------------------------
  // SORTING
  //----------------------------------------------------------------------------------------------
  // Sort State Hook
  const [sortCol, setSortCol] = useState({
    path: "margin_percent",
    order: "asc",
  });

  // Sort Handler
  const _handleSort = (receivedSortCol) => {
    setSortCol((prevState) => ({
      ...prevState,
      path: receivedSortCol.path,
      order: receivedSortCol.order,
    }));
  };

  const pricingSorted = _.orderBy(product, [sortCol.path], [sortCol.order]);

  //----------------------------------------------------------------------------------------------

  return (
    <Table
      columns={columnsStatic}
      sortColumn={sortCol}
      onSort={_handleSort}
      data={pricingSorted}
      idProperty={"channel"}
    />
  );
};

export default PricingDetailTable;

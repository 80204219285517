import React from "react";
import { Card, CardMedia } from "@mui/material";

const ImageCarouselItem = ({ item, onClick }) => {
  return (
    <Card
      sx={{
        width: "300px",
        height: "300px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "none", // Removes the border/shadow
        border: "none", // Ensures no border is present
      }}
    >
      <CardMedia
        component="img"
        image={item.image}
        alt={item.image_alt}
        onClick={() => onClick(item.image)}
        sx={{
          maxWidth: "100%",
          maxHeight: "100%",
          width: "auto",
          height: "auto",
        }}
      />
    </Card>
  );
};

export default ImageCarouselItem;

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// Title
// -------
// 50-60 characters
// Relevant: accurately reflect page content, include relevant keywords at beginning for better visibility in search results, avoid misleading/clickbaity content
// Concise: convey main message clearly and succintly, avoid jargon
// Clear and readable - clear value prop
// Unique - distinct from other page titles and differentiates from competitors
// Engaging - sparks interest and encourages click through. Consider language and and tone of ideal client

// Description
// -----------
// Ideal Length: 150-160 characters
// Maximum Length: Up to 320 characters (though anything beyond 160 is likely to be truncated in search results)

// Keywords:
// ----------
// Focus on a smaller set of highly relevant and targeted keywords rather than a large list of loosely related terms. Choose keywords that are:
// Relevant: Closely related to your page's content and the services you offer
// Specific: Target specific niches or long-tail keywords to attract more qualified traffic
// High-Intent: Reflect the search terms potential clients are likely to use when looking for your services

const SetDocumentMeta = ({ title, description, keywords }) => {
  const location = useLocation();

  useEffect(() => {
    // Set the document title if provided, otherwise leave it as is
    if (title) {
      document.title = title;
    }

    // Set the meta description if provided, otherwise remove it
    const metaDescriptionTag = document.querySelector(
      'meta[name="description"]'
    );
    if (description) {
      if (metaDescriptionTag) {
        metaDescriptionTag.setAttribute("content", description);
      } else {
        const meta = document.createElement("meta");
        meta.name = "description";
        meta.content = description;
        document.head.appendChild(meta);
      }
    } else if (metaDescriptionTag) {
      metaDescriptionTag.remove();
    }

    // Set the meta keywords if provided, otherwise remove it
    const metaKeywordsTag = document.querySelector('meta[name="keywords"]');
    if (keywords) {
      if (metaKeywordsTag) {
        metaKeywordsTag.setAttribute("content", keywords);
      } else {
        const meta = document.createElement("meta");
        meta.name = "keywords";
        meta.content = keywords;
        document.head.appendChild(meta);
      }
    } else if (metaKeywordsTag) {
      metaKeywordsTag.remove();
    }
  }, [title, description, keywords, location]);
};

export default SetDocumentMeta;

import React from "react";
import { Container, Typography, Paper, Link as MuiLink } from "@mui/material";

const KthTermsOfService = ({ baseConfig }) => {
  return (
    <Container maxWidth="lg" className="link-no-decoration">
      <Paper
        style={{
          padding: "20px",
          marginTop: "20px",
          backgroundColor: "#f9f9f9",
        }}
      >
        <Typography variant="h4" gutterBottom align="center">
          Terms of Service
        </Typography>
        <Typography variant="h6">Introduction</Typography>
        <Typography paragraph>
          By accessing or using Kama Thuo, PLLC websites and webtools
          (collectively "websites"), including, without limitation
          www.kthlaw.com and news-insights.kthlaw.com, you agree to be bound by
          these Terms of Service and all terms incorporated by reference. If you
          do not agree to all of these terms, do not use these websites.
        </Typography>
        <Typography variant="h6">Use of Services</Typography>
        <Typography paragraph>
          The services are available for your use subject to the terms outlined
          herein.
        </Typography>
        <Typography variant="h6">Disclaimer of Warranties</Typography>
        <Typography paragraph>
          THE INFORMATION CONTAINED IN THESE WEBSITES IS PROVIDED TO YOU "AS
          IS," FOR YOUR INTERNAL INFORMATIONAL PURPOSES ONLY, WITHOUT ANY
          REPRESENTATION OR WARRANTY OF ACCURACY OR COMPLETENESS OF INFORMATION
          OR OTHER WARRANTY OF ANY KIND, INCLUDING ANY IMPLIED WARRANTY OF
          QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
          NON-INFRINGEMENT. IN NO EVENT WILL KAMA THUO, PLLC BE LIABLE TO ANY
          PARTY FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL
          DAMAGES FOR USE OF THESE WEBSITES OR RELIANCE UPON ANY INFORMATION OR
          MATERIAL ACCESSED VIA IT OR ANY OTHER HYPERLINKED WEBSITE INCLUDING,
          BUT NOT LIMITED TO, DAMAGES ARISING FROM LOSS OF PROFITS, BUSINESS
          INTERRUPTION, OR LOSS OF DATA, EVEN IF KAMA THUO, PLLC IS EXPRESSLY
          ADVISED ABOUT THE POSSIBILITY OF SUCH DAMAGES, TO THE FULLEST EXTENT
          ALLOWABLE BY LAW.
        </Typography>
        <Typography variant="h6">Disclaimer of Legal Opinion</Typography>
        <Typography paragraph>
          Any information or results provided on the websites are NOT A LEGAL
          OPINION and SHOULD NOT BE CONSTRUED AS LEGAL ADVICE. If you require
          legal assistance, please{" "}
          <MuiLink href="https://www.kthlaw.com/contact">Contact Us</MuiLink>
          {", "}
          and we will evaluate whether we are able to assist you. This
          evaluation may include, but is not limited to, determining whether any
          conflicts exist that would prevent us from representing you, assessing
          whether your matter falls within our practice areas and expertise,
          confirming whether we are licensed to provide the required legal
          services in your location or jurisdiction, and ensuring we have the
          capacity and resources to dedicate to your matter in a timely and
          effective manner. Please note that contacting us to seek legal
          assistance does not establish an attorney-client relationship. An
          attorney-client relationship is only established once an engagement
          letter has been executed, explicitly agreeing to the terms of
          representation.
        </Typography>
        <Typography variant="h6">External Links Disclaimer</Typography>
        <Typography paragraph>
          Our websites may contain links to external websites that are not
          provided or maintained by or in any way affiliated with Kama Thuo,
          PLLC. These links are provided solely for your convenience to provide
          further information. They do not signify that we endorse the
          website(s) or the content within them. Kama Thuo, PLLC does not
          guarantee the accuracy, relevance, timeliness, or completeness of any
          information on these external websites. Please be cautious and check
          the terms and privacy policies of any external sites you interact
          with.
        </Typography>
        <Typography variant="h6">
          Submitting Information to Kama Thuo, PLLC.
        </Typography>
        <Typography paragraph>
          For details on what types of information are collected and how they
          are handled, please refer to our{" "}
          <MuiLink href="/privacy-policy">Privacy Policy</MuiLink>.
        </Typography>
        <Typography paragraph>
          WE DO NOT STORE OR LOG YOUR PROMPT INPUTS, OPTION SELECTIONS, SEARCH
          QUERIES, SEARCH HISTORY, OR WEB TOOLS USE HISTORY ON OUR SERVERS.
        </Typography>
        <Typography paragraph>
          Your input actions and data are sent to the backend server over a
          secure link and are used solely for locating and retrieving results.
          No records or logs of the queries, prompts, or actions are kept once
          the results are computed and returned.
        </Typography>
        <Typography paragraph>
          When you submit other information to Kama Thuo, PLLC through our
          websites, unless covered by a separate written agreement of
          confidentiality signed by both parties, any such information provided
          will be deemed NOT TO BE CONFIDENTIAL. Furthermore, by submitting any
          unsolicited ideas or materials, including but not limited to ideas on
          improving or enhancing our checkers, wizards, analytics tools, and
          AI/ML models, you agree that your submissions will automatically
          become the property of Kama Thuo, PLLC, without any compensation to
          you. Kama Thuo, PLLC may use or redistribute the submissions and their
          contents for any purpose and in any way.
        </Typography>

        <Typography variant="h6">Limitations of Data</Typography>
        <Typography paragraph>
          The information provided in our checkers and other tools is limited to
          information available in our database and data providers' databases at
          the time of accessing the information. We cannot guarantee the
          comprehensiveness of these databases, and you may not always find the
          most relevant results due to the limitation of the databases,
          articulation of the queries, and the search or retrieval algorithm.
        </Typography>
        <Typography variant="h6">Accuracy of Data</Typography>
        <Typography paragraph>
          Users are advised to cross-check the text and metadata of the search,
          filter, and checker results we provide with other data sources. The
          accuracy of the text and metadata cannot be guaranteed because we rely
          on 3rd party data sources to get these details. Some metadata can
          update at any time and the latest information may not reflect in the
          results.
        </Typography>
        <Typography variant="h6">Intellectual Property</Typography>
        <Typography paragraph>
          All content on our websites, including but not limited to text,
          graphics, logos, button icons, images, audio clips, digital downloads,
          and software, is the property of Kama Thuo, PLLC or its content
          suppliers, and protected by United States and international copyright
          laws.
        </Typography>
        <Typography variant="h6">Governing Law</Typography>
        <Typography paragraph>
          These terms and any disputes arising out of or related to the services
          shall be governed by, construed, and enforced in accordance with, the
          laws of the State of Arizona.
        </Typography>
        <Typography
          sx={{ fontStyle: "italic", color: "gray", fontSize: "0.875rem" }}
        >
          This Terms of Service was last revised on April 13, 2024.
        </Typography>
      </Paper>
    </Container>
  );
};

export default KthTermsOfService;

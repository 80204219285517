import _ from "lodash";

//**********************************************************************************************************************************/
// General Cost
//**********************************************************************************************************************************/
export function getCost(product) {
  let cost = null;
  let costSource = "";

  // Get QBO cost
  const costQBO = _.get(product, "inventory_for_product.cost_qbo");

  // Get suppliers - could be an empty array if suppliers not entered
  const suppliers = product.suppliers_for_product;

  // Get Primary Supplier Object if any supplier tagged as primary supplier (picks up first one if there are inadvertently multiple primary suppliers)
  const priSuppObj = _.find(suppliers, { is_primary_supplier: true });

  // Get Primary Supplier name from priSuppObj
  const priSuppName = _.get(priSuppObj, "supplier");

  // Get primary supplier cost
  const costPriSupp = _.get(priSuppObj, "item_cost");

  //If there is a primary supplier cost use that
  if (costPriSupp) {
    cost = costPriSupp;
    costSource = `${priSuppName} (Primary Supplier)`;
  } else {
    // get cost from any other suppliers
    // Use lodash to get an array of all the costs
    const suppCosts = _.map(suppliers, "item_cost"); // [item_cost, item_cost, item_cost]

    // Use the lowest supplier cost
    // @TODO -- alert that secondary supplier cost is lower than primary supplier cost
    let minCostSupp = null;
    if (suppCosts.length !== 0) {
      const minCostSupp = Math.min(...suppCosts);
      if (minCostSupp > 0) {
        cost = minCostSupp;
        costSource = "Min of Other Suppliers";
      } else {
        // We have suppliers but no cost is included in supplier fields
        // use QBO cost
        cost = costQBO;
        costSource = "QBO Cost";
      }
    } else {
      // We have no suppliers -> use QBO cost
      cost = costQBO;
      costSource = "QBO Cost";
    }
  }

  //If we have determined cost return it in an object indicating the source of the cost
  if (cost) {
    return { cost: cost, cost_source: costSource };
  }

  return null;
}

//**********************************************************************************************************************************/
// BIGC Cost w/ MOQ
//**********************************************************************************************************************************/
export function getBigcCost(bigcCost, product) {
  // Get BigC MOQ
  const bigcMoq = _.get(product, "inventory_for_product.moq_bigc");

  if (bigcCost && bigcMoq > 1) return bigcCost * bigcMoq;
  return bigcCost;
}

//**********************************************************************************************************************************/
export default {
  get: getCost,
};

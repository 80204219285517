import React, { Component } from "react";

const SearchBox = ({
  searchValue = "",
  searchOnChange = () => {
    return null;
  },
}) => {
  return (
    <input
      className="form-control me-2"
      type="search"
      placeholder="Search"
      aria-label="Search"
      value={searchValue}
      onChange={searchOnChange}
      style={{ marginBottom: 20 }}
    />
  );
};

export default SearchBox;

import React, { Component } from "react";
import TableHeaderTiered from "./TableHeaderTiered";
import TableBodyTiered from "./TableBodyTiered";

const TableTiered = (props) => {
  const { columns, sortColumn, onSort, data, idProperty, numGroup1 } = props;

  /*
  return (
    <table className="table">
      <TableHeaderTiered
        columns={columns}
        sortColumn={sortColumn}
        onSort={onSort}
      />
      <TableBodyTiered data={data} columns={columns} idProperty={idProperty} />
    </table>
  );
  */
  // Get the number of columns to set span
  const numCols = columns.length;
  const numGroup2 = numCols - numGroup1;

  // HTML color code names --> https://htmlcolorcodes.com/color-names/
  return (
    <table className="table">
      {/** 
      <colgroup span={numGroup1}></colgroup>
      <colgroup span={numGroup2}></colgroup>
  */}
      <colgroup>
        <col />
        <col span={numGroup1} style={{ backgroundColor: "LightCyan" }} />
        <col span={numGroup2} style={{ backgroundColor: "LightYellow" }} />
      </colgroup>

      <TableHeaderTiered
        columns={columns}
        numGroup1={numGroup1}
        numGroup2={numGroup2}
        sortColumn={sortColumn}
        onSort={onSort}
      />
      <TableBodyTiered data={data} columns={columns} idProperty={idProperty} />
    </table>
  );
};

export default TableTiered;

// See --> https://www.w3.org/WAI/tutorials/tables/irregular/ (but examples wrong)
// Format of Table with Two Headers
/*
return (
  <table className="table">
    <colgroup span="2"></colgroup>
    <colgroup span="2"></colgroup>

    <thead>
      <tr>
        <td rowSpan="2"></td>
        <th colSpan="2" scope="colgroup">
          Mars
        </th>
        <th colSpan="2" scope="colgroup">
          Venus
        </th>
      </tr>
      <tr>
        <th scope="col">Produced</th>
        <th scope="col">Sold</th>
        <th scope="col">Produced</th>
        <th scope="col">Sold</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row">Teddy Bears</th>
        <td>50,000</td>
        <td>30,000</td>
        <td>100,000</td>
        <td>80,000</td>
      </tr>
      <tr>
        <th scope="row">Board Games</th>
        <td>10,000</td>
        <td>5,000</td>
        <td>12,000</td>
        <td>9,000</td>
      </tr>
    </tbody>
  </table>
);
*/

import * as XLSX from "xlsx";
import { exportFieldsMapping } from "./worksheetFieldsForExport";

//---------------------------------------------------------------------------------------
// Export One Worksheet to Excel
//---------------------------------------------------------------------------------------
export const exportToExcel = (data, sheetName) => {
  const fields = exportFieldsMapping[sheetName] || Object.keys(data[0] || {});
  //   const fields = exportFieldsMapping[sheetName];
  const filteredData = data.map((item) => {
    const filteredItem = {};
    fields.forEach((field) => {
      if (item.hasOwnProperty(field)) {
        filteredItem[field] = item[field];
      }
    });
    return filteredItem;
  });

  const worksheet = XLSX.utils.json_to_sheet(filteredData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  XLSX.writeFile(workbook, `${sheetName}.xlsx`);
};

//---------------------------------------------------------------------------------------
// Export All WorkSheets to Excel
//---------------------------------------------------------------------------------------
export const exportAllToExcel = (worksheet) => {
  const workbook = XLSX.utils.book_new();
  Object.entries(worksheet).forEach(([key, value]) => {
    const fields = exportFieldsMapping[key] || Object.keys(value[0] || {});
    const filteredData = value.map((item) => {
      const filteredItem = {};
      fields.forEach((field) => {
        if (item.hasOwnProperty(field)) {
          filteredItem[field] = item[field];
        }
      });
      return filteredItem;
    });

    const sheet = XLSX.utils.json_to_sheet(filteredData);
    XLSX.utils.book_append_sheet(workbook, sheet, key);
  });
  XLSX.writeFile(workbook, "AllWorksheets.xlsx");
};

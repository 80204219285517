import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";

const WorksheetLicensingTable = ({ licensing, removeFromWorksheet }) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Segment</TableCell>
        <TableCell>Vertical</TableCell>
        <TableCell>Applications</TableCell>
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {licensing.map((segment) => (
        <TableRow key={segment.segment}>
          <TableCell>{segment.segment}</TableCell>
          <TableCell>{segment.vertical}</TableCell>
          <TableCell>{segment.Applications.join(", ")}</TableCell>
          <TableCell>
            <Button
              onClick={() => removeFromWorksheet(segment.segment, "licensing")}
            >
              Remove
            </Button>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

export default WorksheetLicensingTable;

import React from "react";
import { Container, Grid, Alert } from "@mui/material";

const NotFound = () => {
  return (
    <Container>
      <Grid item xs={12}>
        <Alert severity="error" sx={{ mt: 4 }}>
          404 - Page Not Found
        </Alert>
      </Grid>
    </Container>
  );
};

export default NotFound;

import _ from "lodash";

export function getInventoryLocks(product) {
  const inventoryLockedArr = product.inventory_locked;

  // Get objects for each potential channel that could be locked. If channel not locked object will be null
  const lockedObjBigC = _.find(inventoryLockedArr, { channel: "bigc" });
  const lockedObjAmzSellerUs = _.find(inventoryLockedArr, {
    channel: "amz_seller_us",
  });
  const lockedObjAmzFbaUs = _.find(inventoryLockedArr, {
    channel: "amz_fba_us",
  });
  const lockedObjAmzSellerCa = _.find(inventoryLockedArr, {
    channel: "amz_seller_ca",
  });
  const lockedObjEbay = _.find(inventoryLockedArr, { channel: "ebay" });
  const lockedObjWmt = _.find(inventoryLockedArr, { channel: "wmt" });
  const lockedObjNe = _.find(inventoryLockedArr, { channel: "newegg" });
  const lockedObjNeBiz = _.find(inventoryLockedArr, { channel: "newegg_biz" });

  // Create booleans if object for channel exists
  const bigcInventoryLocked = lockedObjBigC !== undefined;
  const amzSellerUsInventoryLocked = lockedObjAmzSellerUs !== undefined;
  const amzFbaUsInventoryLocked = lockedObjAmzFbaUs !== undefined;
  const amzSellerCaInventoryLocked = lockedObjAmzSellerCa !== undefined;
  const ebayInventoryLocked = lockedObjEbay !== undefined;
  const wmtInventoryLocked = lockedObjWmt !== undefined;
  const neInventoryLocked = lockedObjNe !== undefined;
  const neBizInventoryLocked = lockedObjNeBiz !== undefined;

  return {
    inventory_lock_bigc: bigcInventoryLocked,
    inventory_lock_release_bigc: bigcInventoryLocked
      ? lockedObjBigC.inventorylock_release_date
      : null,
    inventory_lock_amz_seller_us: amzSellerUsInventoryLocked,
    inventory_lock_release_amz_seller_us: amzSellerUsInventoryLocked
      ? lockedObjAmzSellerUs.inventorylock_release_date
      : null,
    inventory_lock_amz_fba_us: amzFbaUsInventoryLocked,
    inventory_lock_release_amz_fba_us: amzFbaUsInventoryLocked
      ? lockedObjAmzFbaUs.inventorylock_release_date
      : null,
    inventory_lock_amz_seller_ca: amzSellerCaInventoryLocked,
    inventory_lock_release_amz_seller_ca: amzSellerCaInventoryLocked
      ? lockedObjAmzSellerCa.inventorylock_release_date
      : null,
    inventory_lock_ebay: ebayInventoryLocked,
    inventory_lock_release_ebay: ebayInventoryLocked
      ? lockedObjEbay.inventorylock_release_date
      : null,
    inventory_lock_wmt: wmtInventoryLocked,
    inventory_lock_release_wmt: wmtInventoryLocked
      ? lockedObjWmt.inventorylock_release_date
      : null,
    inventory_lock_newegg: neInventoryLocked,
    inventory_lock_release_newegg: neInventoryLocked
      ? lockedObjNe.inventorylock_release_date
      : null,
    inventory_lock_newegg_biz: neBizInventoryLocked,
    inventory_lock_release_newegg_biz: neBizInventoryLocked
      ? lockedObjNeBiz.inventorylock_release_date
      : null,
  };

  // TODO - get notes from objects
}

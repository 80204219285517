import React, { useState, useCallback } from "react";
import {
  Box,
  Modal,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearchMinus,
  faSearchPlus,
  faRotateLeft,
} from "@fortawesome/pro-solid-svg-icons";
import CloseIcon from "@mui/icons-material/Close";
import parse from "html-react-parser";

const INITIAL_ZOOM = 1; // Initial zoom level
const ZOOM_INCREMENT = 0.1; // Zoom increment value
const INITIAL_OFFSET = { x: 0, y: 0 }; // Initial drag offset

const ZoomableContent = ({ content }) => {
  const [zoomedImage, setZoomedImage] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(INITIAL_ZOOM);
  const [dragOffset, setDragOffset] = useState(INITIAL_OFFSET);
  const [dragging, setDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

  const isLargeScreen = useMediaQuery("(min-width: 768px)");

  const handleImageClick = useCallback(
    (src) => {
      if (!isLargeScreen) return; // Restrict functionality to large screens
      setZoomedImage(src);
      setModalOpen(true);
      setZoomLevel(INITIAL_ZOOM); // Reset zoom level
      setDragOffset(INITIAL_OFFSET); // Reset drag offset
    },
    [isLargeScreen]
  );

  const handleCloseModal = useCallback(() => {
    setZoomedImage(null);
    setModalOpen(false);
  }, []);

  const zoomIn = () => {
    setZoomLevel((prevZoom) => prevZoom + ZOOM_INCREMENT);
  };

  const zoomOut = () => {
    setZoomLevel((prevZoom) =>
      Math.max(INITIAL_ZOOM, prevZoom - ZOOM_INCREMENT)
    );
  };

  const resetZoom = () => {
    setZoomLevel(INITIAL_ZOOM);
    setDragOffset(INITIAL_OFFSET);
  };

  const handleWheelZoom = (event) => {
    event.preventDefault();
    if (event.deltaY < 0) {
      zoomIn();
    } else {
      zoomOut();
    }
  };

  const handleDragStart = (e) => {
    setDragging(true);
    setDragStart({ x: e.clientX, y: e.clientY });
  };

  const handleDrag = (e) => {
    if (dragging) {
      setDragOffset((prevOffset) => ({
        x: prevOffset.x + e.clientX - dragStart.x,
        y: prevOffset.y + e.clientY - dragStart.y,
      }));
      setDragStart({ x: e.clientX, y: e.clientY });
    }
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  const parsedContent = parse(content, {
    replace: (domNode) => {
      if (
        domNode.name === "img" &&
        domNode.attribs &&
        domNode.attribs.class &&
        domNode.attribs.class.includes("rfw-kth-zoomable-image")
      ) {
        return (
          <img
            {...domNode.attribs}
            onClick={() => handleImageClick(domNode.attribs.src)}
            style={{
              cursor: isLargeScreen ? "pointer" : "default",
              transition: "transform 0.2s ease-in-out",
            }}
          />
        );
      }
    },
  });

  return (
    <>
      {/* Render parsed content */}
      <Box>{parsedContent}</Box>

      {/* Modal for Zoomed Image */}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="zoomed-image-modal"
        aria-describedby="A larger version of the selected image"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: "rgba(0, 0, 0, 0.8)",
        }}
        onMouseMove={handleDrag}
        onMouseUp={handleDragEnd}
        onWheel={handleWheelZoom} // scroll wheel zoom functionality
      >
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onMouseDown={handleDragStart}
        >
          {/* Close Button */}
          <IconButton
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              top: 16,
              right: 16,
              color: "white",
              zIndex: 1000,
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* Zoom Controls */}
          <Box
            sx={{
              position: "absolute",
              top: 16,
              left: "50%",
              transform: "translateX(-50%)",
              display: "flex",
              alignItems: "center",
              gap: 2,
              color: "white",
              zIndex: 1000,
            }}
          >
            <IconButton onClick={zoomOut} aria-label="zoom out" color="inherit">
              <FontAwesomeIcon icon={faSearchMinus} />
            </IconButton>
            <Typography variant="body1" color="white">
              {Math.round(zoomLevel * 100)}%
            </Typography>
            <IconButton
              onClick={resetZoom}
              aria-label="reset zoom"
              color="inherit"
            >
              <FontAwesomeIcon icon={faRotateLeft} />
            </IconButton>
            <IconButton onClick={zoomIn} aria-label="zoom in" color="inherit">
              <FontAwesomeIcon icon={faSearchPlus} />
            </IconButton>
          </Box>

          {zoomedImage && (
            <img
              src={zoomedImage}
              alt="Zoomed content"
              style={{
                transform: `translate(${dragOffset.x}px, ${dragOffset.y}px) scale(${zoomLevel})`,
                transformOrigin: "center",
                cursor: dragging ? "grabbing" : "grab",
                transition: dragging ? "none" : "transform 0.2s ease",
              }}
              onDragStart={(e) => e.preventDefault()} // Disable default drag behavior
            />
          )}
        </Box>
      </Modal>
    </>
  );
};

export default ZoomableContent;

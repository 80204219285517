import {
  faHome,
  faToolbox,
  faBroadcastTower,
  faCartFlatbedBoxes,
  faMagnifyingGlass,
} from "@fortawesome/pro-solid-svg-icons";
import HomeIcon from "@mui/icons-material/Home";
import CellTowerIcon from "@mui/icons-material/CellTower";

export const partsFinderNavbarConfig = [
  {
    title: "Home",
    path: "/product-detail",
    iconFa: faHome,
    iconMui: HomeIcon,
    authRequired: false,
    children: [],
  },
  {
    title: "Products",
    path: "/product-search",
    iconFa: faMagnifyingGlass,
    authRequired: false,
    children: [
      {
        title: "Product Search",
        path: "/product-search",
        pageFooterText:
          "You may also search directly in <a href='https://shop.rfwel.com/' target='_blank'>E-Store</a> for most-accurate pricing",
      },
      {
        title: "Product Detail",
        path: "/product-detail",
        pageFooterText:
          "You may also search directly in <a href='https://shop.rfwel.com/' target='_blank'>E-Store</a> for most-accurate pricing",
      },
    ],
  },
  {
    title: "Parts",
    path: "/find-parts",
    iconFa: faToolbox,
    authRequired: false,
    children: [],
  },
  {
    title: "Parts Store",
    iconFa: faCartFlatbedBoxes,
    path: "external", // Unique identifier for external link
    externalUrl: "https://shop.rfwel.com", //@TODO -> switch this to parts-finder.rfwel.com
    authRequired: false,
    children: [], // No children for external links
  },
];

import React from "react";
import { Container, Typography, Paper, Box, Grid } from "@mui/material";

const LookerStudioDashboard = () => {
  const IFRAME_HEIGHT = "6000px"; // estimate of max content @TODO - do we get the height dynamically?
  return (
    <Container maxWidth="lg">
      <header>
        <Typography variant="h4" component="h1" gutterBottom>
          Big Commerce Marketing Report
        </Typography>
        <Typography variant="body1" paragraph>
          Below is the Big Commerce Marketing Report. Here, you will find
          integrated insights from Google Ads, Analytics, Search Console, and
          Google Analytics 4 (GA4).
        </Typography>
        <Typography variant="body1" paragraph>
          Our report analyzes key performance metrics such as Impressions,
          Clicks, Click-Through Rate (CTR), Cost Per Click (CPC), and Conversion
          Rates to give you a comprehensive understanding of your campaign's
          effectiveness. It helps you identify trends, assess the efficiency of
          your spend, and understand the behavior and conversion patterns of
          your users from various devices and campaigns.
        </Typography>
      </header>

      <Box mt={4}>
        <Typography variant="body1" paragraph>
          Here's a quick rundown of key digital marketing terms. Use the date
          filter below to make period adjustment for comparative analysis:
        </Typography>
        <Paper style={{ padding: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="subtitle2">Impressions</Typography>
              <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
                The number of times your ads were displayed to potential
                customers.
              </Typography>
              <Typography variant="subtitle2">Clicks</Typography>
              <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
                The total number of times users clicked on your ads.
              </Typography>
              <Typography variant="subtitle2">
                Click-Through Rate (CTR)
              </Typography>
              <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
                The percentage of impressions that led to a click.
              </Typography>
              <Typography variant="subtitle2">
                Average Cost Per Click (Avg. CPC)
              </Typography>
              <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
                This metric shows the average amount paid for each ad click.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="subtitle2">Conversion Rate</Typography>
              <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
                A measure of clicks resulting in conversions such as sales or
                lead submissions.
              </Typography>
              <Typography variant="subtitle2">Cost Per Conversion</Typography>
              <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
                The average cost associated with a single conversion.
              </Typography>
              <Typography variant="subtitle2">CPM (Cost Per Mille)</Typography>
              <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
                The price of 1,000 ad impressions on a webpage.
              </Typography>
              <Typography variant="subtitle2">Organic Search</Typography>
              <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
                Visitors who come to your site from a search engine results page
                without paid advertising.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="subtitle2">Cross-network</Typography>
              <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
                Traffic that comes across various advertising networks.
              </Typography>
              <Typography variant="subtitle2">Direct</Typography>
              <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
                Visitors who arrive at your site by typing your URL directly
                into their browser's address bar.
              </Typography>
              <Typography variant="subtitle2">Referral</Typography>
              <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
                Visitors referred by another website via a link.
              </Typography>
              <Typography variant="subtitle2">Unassigned</Typography>
              <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
                Traffic where the source has not been specified.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4}></Grid>
          </Grid>
        </Paper>
      </Box>

      <Box mt={2}>
        <Paper>
          <Typography variant="body1" style={{ padding: 20 }}>
            The report period is by default fixed to year to date. It delves
            into the device access patterns, product performance, and traffic
            source breakdown. The report offers high-level insights on traffic
            and revenue standing. You could use the date filter below to adjust
            the period to observe the fluctuations of user engagements and
            conversions.
          </Typography>
        </Paper>
      </Box>

      <Box
        mt={2}
        style={{
          //   position: "relative",
          //   paddingBottom: "56.25%",
          //   height: 0,
          overflow: "hidden",
        }}
      >
        <iframe
          src="https://lookerstudio.google.com/embed/reporting/65929a9b-efd3-422d-8a6d-2c458164e58b/page/rAho"
          style={{
            border: 0,
            // position: "absolute",
            // top: 0,
            // left: 0,
            width: "100%",
            height: IFRAME_HEIGHT, // Use the static height @TODO - does height get passed in iframe?
          }}
          allowFullScreen
          sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
        ></iframe>
      </Box>
    </Container>
  );
};

export default LookerStudioDashboard;

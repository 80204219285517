import React, { Component, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  Box,
  Typography,
  TextField,
  FormHelperText,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Collapse,
  Alert,
} from "@mui/material";
import prods from "../utils/prods";
import Pagination from "../../common/Pagination";
import { paginate } from "../utils/paginate";
import ListGroup from "../../common/ListGroup";
import SearchBox from "../../common/SearchBox";
import _ from "lodash";
import * as CONFIG from "../utils/Global-Labels-Links-Vars";
import LoadingSpinner from "../../common/LoadingSpinner";
import {
  buildChannelProducts,
  getFilteredMargins,
} from "../utils/buildChannelProducts";
import MarginAnalTable from "./MarginAnalTable";

const MarginAnal = () => {
  //-------------------------------------------------------------------------------
  // SET DEFAULTS
  //-------------------------------------------------------------------------------
  const DEFAULT_PAGE_SIZE = 10;
  const DEFAULT_SKUS_TO_GET = 200;
  const MAX_SKUS_TO_GET = 500;

  // Hide SKU values
  const HIDE_NONE = "HIDE_NONE";
  const HIDE_CLEARANCE = "HIDE_CLEARANCE";
  const HIDE_PRICELOCKED = "HIDE_PRICELOCKED";
  //-------------------------------------------------------------------------------
  // HOOKS
  //-------------------------------------------------------------------------------

  const navigate = useNavigate();
  const [clearanceHide, setClearanceHide] = useState(HIDE_CLEARANCE);
  const [priceLockedHide, setPriceLockedHide] = useState(HIDE_PRICELOCKED);
  const [skusToGet, setSkusToGet] = useState(DEFAULT_SKUS_TO_GET);
  const [marginThreshold, setMarginThreshold] = useState(CONFIG.MARGIN_LOW);
  const [marginThresholdDisplay, setMarginThresholdDisplay] = useState(
    CONFIG.MARGIN_LOW
  );
  const [marginLimit, setMarginLimit] = useState("<THRESH");
  const [compeThreshold, setCompeThreshold] = useState(CONFIG.COMPE_THRESHOLD);
  const [products, setProducts] = useState([]);
  const [channelProducts, setChannelProducts] = useState([]);
  const [filteredChannelProducts, setFilteredChannelProducts] = useState([]);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(1);
  const [categories, setCategories] = useState([{ title: "All Categories" }]);
  const [selectedCategory, setSelectedCategory] = useState({
    title: "All Categories",
  });
  const [sortCol, setSortCol] = useState({
    path: "margin_percent",
    order: "asc",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  //-------------------------------------------------------------
  const numProds = products.length;

  //-------------------------------------------------------------
  const _handleClearanceHideChange = (e) => {
    setClearanceHide(e.target.value);
  };

  //-------------------------------------------------------------
  const _handlePriceLockedHideChange = (e) => {
    setPriceLockedHide(e.target.value);
  };

  //-------------------------------------------------------------
  const _handleSkusToGetChange = (e) => {
    let selectedSkusToGet = e.target.value;
    if (selectedSkusToGet > MAX_SKUS_TO_GET) {
      selectedSkusToGet = MAX_SKUS_TO_GET;
    }

    setSkusToGet(selectedSkusToGet);
  };
  //-------------------------------------------------------------
  const _handleMarginThresholdChange = (e) => {
    setMarginThreshold(e.target.value);
    setMarginThresholdDisplay(e.target.value); // change the display value
  };
  //-------------------------------------------------------------
  const _handleCompeThresholdChange = (e) => {
    setCompeThreshold(e.target.value);
  };
  //-------------------------------------------------------------
  const _handleMarginLimitChange = (e) => {
    const marginLimitValue = e.target.value;
    setMarginLimit(marginLimitValue);

    // Change order asc to desc based on analysis type. If below threshold show worse margin first
    if (marginLimitValue === "<THRESH") {
      setSortCol({ path: "margin_percent", order: "asc" });
      setMarginThreshold(CONFIG.MARGIN_LOW);
      setMarginThresholdDisplay(CONFIG.MARGIN_LOW); // Set the displayed value
    } else {
      setSortCol({ path: "margin_percent", order: "desc" });
      setMarginThreshold(CONFIG.MARGIN_HIGH);
      setMarginThresholdDisplay(CONFIG.MARGIN_HIGH); // Set the displayed value
    }
  };
  //-------------------------------------------------------------
  const _handleGetMarginDataButtonClicked = () => {
    // Set IsLoading to true and disable Get Margin Data button to prevent multiple API calls
    // Will only fetch if there are no products loaded so setIsLoading(true) only then
    if (numProds === 0) {
      setIsLoading(true);
    }
    const fetchProds = async () => {
      const productsFetched = await prods.get_all(
        `/patanal_api/products-margin/`
      );
      setProducts(productsFetched);

      // create channel products
      const channProds = buildChannelProducts(productsFetched);
      setChannelProducts(channProds);

      // Hide Loading screen when fetch complete
      setIsLoading(false);
    };
    // Fetch Prods only if Prods is not included in state
    if (numProds === 0) {
      fetchProds();
    }
  };

  //-------------------------------------------------------------
  const _handleRefreshProducts = async () => {
    setIsLoading(true);
    const productsFetched = await prods.get_all(
      "/patanal_api/products-margin/"
    );
    setProducts(productsFetched);
    setIsLoading(false);
  };

  //-------------------------------------------------------------------------------
  // EFFECTS
  //-------------------------------------------------------------------------------

  useEffect(
    // Update filtered channel products whenever filters change
    () => {
      // filter channProds to include only the items that meet threshold set
      const filteredChannProds = getFilteredMargins({
        products: channelProducts,
        marginThreshold,
        marginLimit,
        compeThreshold,
        skusToGet,
        clearanceHide,
        priceLockedHide,
      });

      setFilteredChannelProducts(filteredChannProds);

      //Set categories based on filtered products
      const filterCategories = filteredChannProds.map((cat) => {
        let catProperties = {
          title: cat.category,
        };
        return catProperties;
      });

      // Now set categories using setCategories hook using title.
      setCategories([{ title: "All Categories" }, ...filterCategories]);

      // Also select to select all categories when filter changes
      setSelectedCategory({
        title: "All Categories",
      });
    },
    [
      products,
      marginThreshold,
      marginLimit,
      compeThreshold,
      skusToGet,
      clearanceHide,
      priceLockedHide,
    ]
  );

  //-------------------------------------------------------------
  const _handlePageChange = (page) => {
    //console.log(`Page Clicked = ${page}`);
    setCurrentPage(page);
  };
  //-------------------------------------------------------------
  const _handleCategorySelect = (category) => {
    //console.log(category);
    setSelectedCategory(category);
    // Note cannot set search query to null because this is a controlled component. If set to null/undefined react will think this is an uncontrolled component
    // and will give warning when user types in typed that trying to covert an uncontrolled component to a controlled component.
    setSearchQuery("");
    setCurrentPage(1);

    // Reset current page to 1 otherwise if select a category with multiple pages then go back to a category with fewer pages, will display blank
    setCurrentPage(1);
  };
  //-------------------------------------------------------------
  const _handleSort = (receivedSortCol) => {
    setSortCol((prevState) => ({
      ...prevState,
      path: receivedSortCol.path,
      order: receivedSortCol.order,
    }));
  };
  //-------------------------------------------------------------
  const _handleSearchChange = (query) => {
    setSearchQuery(query);
    setSelectedCategory("");
    setCurrentPage(1);
  };
  //-------------------------------------------------------------------------------
  // COMPONENT OBJECTS
  //-------------------------------------------------------------------------------
  // use destructuring to get length of products (number of items and rename to prodCount)
  // the below equivalent to const prodCount = products.length.
  const { length: prodCount } = filteredChannelProducts;

  // filter products based on selected category (or search query) before pagination
  // && used to cover all categories
  // start with filtered products as all products
  let productsFiltered = filteredChannelProducts;

  if (searchQuery) {
    // if there is a search query then fitler based on search query
    productsFiltered = filteredChannelProducts.filter((p) =>
      p.sku.toLowerCase().startsWith(searchQuery.toLowerCase())
    );
  } else {
    // if no search query but there's category selection then filter based on category
    productsFiltered =
      selectedCategory && selectedCategory.title !== "All Categories"
        ? filteredChannelProducts.filter(
            (p) => p.category === selectedCategory.title
          )
        : filteredChannelProducts;
  }

  // sort products
  // sortCol.path and sortCol.order are in array because we can sort by multiple columns using lodash's orderBy util
  const productsSorted = _.orderBy(
    productsFiltered,
    [sortCol.path],
    [sortCol.order]
  );

  // paginate products
  const productsPaginated = paginate(productsSorted, currentPage, pageSize);
  //-------------------------------------------------------------------------------
  // Conditional Render of Margin Anal Table
  //-------------------------------------------------------------------------------
  const _renderMarginTable = () => {
    if (isLoading) return <LoadingSpinner />;
    if (!prodCount || prodCount === 0)
      return (
        <div style={{ marginTop: "10px" }}>
          {CONFIG.RFW_HORIZDOT}
          <p> ..... No listings in the database match the search criteria.</p>
        </div>
      );
    return (
      <Grid item xs={12} align="center">
        <div>{CONFIG.RFW_HORIZDOT}</div>
        <div className="row">
          <div className="col-2">
            <ListGroup
              items={categories}
              selectedItem={selectedCategory}
              onItemSelect={_handleCategorySelect}
              textProperty="title"
              idProperty="title"
            />
          </div>
          <div className="col">
            <p>
              Showing{" "}
              <strong>
                {" "}
                {productsFiltered.length} {selectedCategory.title}{" "}
              </strong>{" "}
              products in the database matching selected criteria.
            </p>
            <SearchBox
              searchValue={searchQuery}
              searchOnChange={(e) => _handleSearchChange(e.currentTarget.value)}
            />
            <MarginAnalTable
              prods={productsPaginated}
              sortColumn={sortCol}
              onSort={_handleSort}
            />

            <Pagination
              itemsCount={productsFiltered.length}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={_handlePageChange}
            />
          </div>
        </div>
      </Grid>
    );
  };
  //-------------------------------------------------------------------------------
  //Render of Margin Anal Page
  //-------------------------------------------------------------------------------
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} align="center">
        <Typography component="h4" variant="h4">
          {"Margin Analysis"}
        </Typography>
      </Grid>

      <Grid item xs={12} align="center">
        <Box
          sx={{
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{ background: "#FFEADE", borderRadius: "16px", paddingX: 2 }}
          >
            <Typography component="h6" variant="h6">
              {"Select Margin Analysis Type"}
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                row
                defaultValue={"<THRESH"}
                onChange={_handleMarginLimitChange}
              >
                <FormControlLabel
                  value="<THRESH"
                  control={<Radio color="primary" />}
                  label="Below Threshold"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value=">THRESH"
                  control={<Radio color="secondary" />}
                  label="Above Threshold"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value="<COMPE"
                  control={<Radio color="secondary" />}
                  label="Above Threshold & Below Competitors"
                  labelPlacement="bottom"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} align="center">
        <Box
          sx={{
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{ background: "#F4DDF4", borderRadius: "16px", paddingX: 2 }}
          >
            <Typography component="h6" variant="h6">
              {"Select % Margin Threshold"}
            </Typography>
            <FormControl>
              <TextField
                required={true}
                type="number"
                onChange={_handleMarginThresholdChange}
                value={marginThresholdDisplay}
                inputProps={{
                  min: 1,
                  max: 100,
                  style: { textAlign: "center" },
                }}
              />
              <FormHelperText>
                <span align="center">% Margin Threshold</span>
              </FormHelperText>
            </FormControl>
          </Box>
          <Box
            sx={{
              background: "#DDE2F4",
              borderRadius: "16px",
              paddingX: 2,
              marginLeft: 2,
            }}
          >
            <Typography component="h6" variant="h6">
              {"Select % Price Below Competitors"}
            </Typography>
            <FormControl>
              <TextField
                required={true}
                type="number"
                onChange={_handleCompeThresholdChange}
                defaultValue={CONFIG.COMPE_THRESHOLD}
                inputProps={{
                  min: 1,
                  max: 100,
                  style: { textAlign: "center" },
                }}
              />
              <FormHelperText>
                <span align="center">% Below Competitors</span>
              </FormHelperText>
            </FormControl>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} align="center">
        <Box
          sx={{
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Box sx={{ background: "#D5F7F1", borderRadius: "16px" }}>
            <Typography component="h6" variant="h6">
              {"Show/Hide Clearance"}
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                row
                defaultValue={HIDE_CLEARANCE}
                onChange={_handleClearanceHideChange}
              >
                <FormControlLabel
                  value={HIDE_NONE}
                  control={<Radio color="primary" />}
                  label="Show All"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value={HIDE_CLEARANCE}
                  control={<Radio color="primary" />}
                  label="Hide Clearance"
                  labelPlacement="bottom"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box
            sx={{
              background: "#D5F7F1",
              borderRadius: "16px",
              paddingX: 2,
              marginLeft: 2,
            }}
          >
            <Typography component="h6" variant="h6">
              {"Show/Hide Price-Locked"}
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                row
                defaultValue={HIDE_PRICELOCKED}
                onChange={_handlePriceLockedHideChange}
              >
                <FormControlLabel
                  value={HIDE_NONE}
                  control={<Radio color="primary" />}
                  label="Show All"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value={HIDE_PRICELOCKED}
                  control={<Radio color="primary" />}
                  label="Hide PriceLocked"
                  labelPlacement="bottom"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box
            sx={{
              background: "#EFF2CB",
              borderRadius: "16px",
              paddingX: 2,
              marginLeft: 2,
            }}
          >
            <Typography component="h6" variant="h6">
              {"Select Max Number of Listings to Get"}
            </Typography>
            <FormControl>
              <TextField
                required={true}
                type="number"
                onChange={_handleSkusToGetChange}
                defaultValue={DEFAULT_SKUS_TO_GET}
                inputProps={{
                  min: 1,
                  max: MAX_SKUS_TO_GET,
                  style: { textAlign: "center" },
                }}
              />
              <FormHelperText>
                <span align="center">Number of Listings to Get</span>
              </FormHelperText>
            </FormControl>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} align="center">
        <Button
          color="primary"
          variant="contained"
          onClick={_handleGetMarginDataButtonClicked}
          disabled={isLoading || numProds !== 0}
        >
          {" "}
          Get Margin Data
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={_handleRefreshProducts}
          disabled={isLoading}
          sx={{ marginLeft: 2 }}
        >
          {"Refresh from DB"}
        </Button>
      </Grid>
      <Grid item xs={12} align="center">
        <Button color="secondary" variant="contained" to="/" component={Link}>
          {" "}
          Back
        </Button>
      </Grid>

      {_renderMarginTable()}
    </Grid>
  );
};

export default MarginAnal;

// @TODO
/*
--> cross-reference with competitor pricing to determine price reduction opportunity
--> Create assignments for data refresh (competitor pricing list, cost/price investigation, etc.)
*/

import React, { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Box,
  Grid,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ContactUsForm from "../common/ContactUsForm";

const features = [
  {
    name: "Patent Search",
    groups: [
      "Manager",
      "Pat-L-Chi",
      "Pat-L-Delta",
      "Pat-L-Gamma",
      "Pat-L-Alpha",
    ],
  },
  {
    name: "Device Search",
    groups: [
      "Manager",
      "Pat-L-Chi",
      "Pat-L-Delta",
      "Pat-L-Gamma",
      "Pat-L-Alpha",
    ],
  },
  {
    name: "Standards Search",
    groups: ["Manager", "Pat-L-Chi", "Pat-L-Delta", "Pat-L-Gamma"],
  },
  {
    name: "Patent Classification",
    groups: ["Manager", "Pat-L-Chi", "Pat-L-Delta"],
  },
  {
    name: "Device Classification",
    groups: ["Manager", "Pat-L-Chi", "Pat-L-Delta"],
  },
  { name: "Patent Review", groups: ["Manager", "Pat-L-Chi"] },
  { name: "Patent Licensing", groups: ["Manager", "Pat-L-Chi"] },
  { name: "Experiments", groups: ["Pat-L-Chi"] },
];

const groupNames = {
  Manager: "Pat-Epsilon",
  "Pat-L-Chi": "Pat-Chi",
  "Pat-L-Delta": "Pat-Delta",
  "Pat-L-Gamma": "Pat-Gamma",
  "Pat-L-Alpha": "Pat-Alpha",
};

const qualifications = {
  "Pat-L-Chi": "Lawyer/Law-Firm Experiments",
  Manager: "Free to Lawyers and Law Firms Only",
  "Pat-L-Delta": "Free by Invitation",
  "Pat-L-Gamma": "Free to All (Login Required)",
  "Pat-L-Alpha": "Free to All",
};

const groupOrder = [
  "Pat-L-Alpha",
  "Pat-L-Gamma",
  "Pat-L-Delta",
  "Manager",
  "Pat-L-Chi",
];

const PatanalaiAccount = ({ baseConfig }) => {
  const { user } = useAuth();
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const [contactFormDefaults, setContactFormDefaults] = useState({});

  const userGroup = user ? user.group : "Pat-L-Alpha";
  const highlightedColumn = groupNames[userGroup];

  const isFeatureAvailable = (feature, group) => feature.groups.includes(group);

  //--------------------------------------------------------------------------------------
  // Updgrade Request
  //--------------------------------------------------------------------------------------

  const shouldRenderUpgradeButton = (currentGroup) => {
    const userGroupIndex = groupOrder.indexOf(userGroup);
    const currentGroupIndex = groupOrder.indexOf(currentGroup);
    return currentGroupIndex > userGroupIndex;
  };

  const handleUpgradeRequest = (group) => {
    const defaultValues = {
      subject: `Requesting access to ${groupNames[group]}`,
    };

    if (user) {
      setContactFormDefaults({
        ...defaultValues,
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
      });
    } else {
      setContactFormDefaults(defaultValues);
    }

    setContactDialogOpen(true);
  };

  const closeDialog = () => {
    setContactDialogOpen(false);
  };

  //--------------------------------------------------------------------------------------
  // Access Levels Table
  //--------------------------------------------------------------------------------------
  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h5" gutterBottom>
        Account Details:{" "}
        {user ? `${user.first_name} ${user.last_name}` : "Guest"}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card
            variant="outlined"
            sx={{ backgroundColor: "#f0f4f8", borderLeft: "4px solid #88BBD6" }}
          >
            <CardContent>
              <Typography variant="h6">{groupNames[userGroup]}</Typography>
              <Typography variant="body2" color="textSecondary">
                {user
                  ? "You are subscribed to this access level!"
                  : "You have access at this level!"}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      backgroundColor: "#e0e0e0",
                      fontSize: "1.1rem",
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      backgroundColor: "#e0e0e0",
                      fontSize: "1.1rem",
                      textAlign: "center",
                    }}
                  >
                    Pat-Alpha
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      backgroundColor: "#e0e0e0",
                      fontSize: "1.1rem",
                      textAlign: "center",
                    }}
                  >
                    Pat-Gamma
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      backgroundColor: "#e0e0e0",
                      fontSize: "1.1rem",
                      textAlign: "center",
                    }}
                  >
                    Pat-Delta
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      backgroundColor: "#e0e0e0",
                      fontSize: "1.1rem",
                      textAlign: "center",
                    }}
                  >
                    Pat-Epsilon
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      backgroundColor: "#e0e0e0",
                      fontSize: "1.1rem",
                      textAlign: "center",
                    }}
                  >
                    Pat-Chi
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {features.map((feature) => (
                  <TableRow key={feature.name}>
                    <TableCell>{feature.name}</TableCell>
                    <TableCell
                      sx={
                        highlightedColumn === "Pat-Alpha"
                          ? { backgroundColor: "#f0f4f8", textAlign: "center" }
                          : { textAlign: "center" }
                      }
                    >
                      {isFeatureAvailable(feature, "Pat-L-Alpha") ? (
                        <CheckIcon style={{ color: "green" }} />
                      ) : (
                        <CloseIcon style={{ color: "red" }} />
                      )}
                    </TableCell>
                    <TableCell
                      sx={
                        highlightedColumn === "Pat-Gamma"
                          ? { backgroundColor: "#f0f4f8", textAlign: "center" }
                          : { textAlign: "center" }
                      }
                    >
                      {isFeatureAvailable(feature, "Pat-L-Gamma") ? (
                        <CheckIcon style={{ color: "green" }} />
                      ) : (
                        <CloseIcon style={{ color: "red" }} />
                      )}
                    </TableCell>
                    <TableCell
                      sx={
                        highlightedColumn === "Pat-Delta"
                          ? { backgroundColor: "#f0f4f8", textAlign: "center" }
                          : { textAlign: "center" }
                      }
                    >
                      {isFeatureAvailable(feature, "Pat-L-Delta") ? (
                        <CheckIcon style={{ color: "green" }} />
                      ) : (
                        <CloseIcon style={{ color: "red" }} />
                      )}
                    </TableCell>
                    <TableCell
                      sx={
                        highlightedColumn === "Pat-Epsilon"
                          ? { backgroundColor: "#f0f4f8", textAlign: "center" }
                          : { textAlign: "center" }
                      }
                    >
                      {isFeatureAvailable(feature, "Manager") ? (
                        <CheckIcon style={{ color: "green" }} />
                      ) : (
                        <CloseIcon style={{ color: "red" }} />
                      )}
                    </TableCell>
                    <TableCell
                      sx={
                        highlightedColumn === "Pat-Chi"
                          ? { backgroundColor: "#f0f4f8", textAlign: "center" }
                          : { textAlign: "center" }
                      }
                    >
                      {isFeatureAvailable(feature, "Pat-L-Chi") ? (
                        <CheckIcon style={{ color: "green" }} />
                      ) : (
                        <CloseIcon style={{ color: "red" }} />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow
                  sx={{ fontWeight: "bold", backgroundColor: "#e0e0e0" }}
                >
                  <TableCell>Who Qualifies?</TableCell>
                  <TableCell
                    sx={
                      highlightedColumn === "Pat-Alpha"
                        ? { backgroundColor: "#f0f4f8", textAlign: "center" }
                        : { textAlign: "center" }
                    }
                  >
                    {qualifications["Pat-L-Alpha"]}
                  </TableCell>
                  <TableCell
                    sx={
                      highlightedColumn === "Pat-Gamma"
                        ? { backgroundColor: "#f0f4f8", textAlign: "center" }
                        : { textAlign: "center" }
                    }
                  >
                    {qualifications["Pat-L-Gamma"]}
                  </TableCell>
                  <TableCell
                    sx={
                      highlightedColumn === "Pat-Delta"
                        ? { backgroundColor: "#f0f4f8", textAlign: "center" }
                        : { textAlign: "center" }
                    }
                  >
                    {qualifications["Pat-L-Delta"]}
                  </TableCell>
                  <TableCell
                    sx={
                      highlightedColumn === "Pat-Epsilon"
                        ? { backgroundColor: "#f0f4f8", textAlign: "center" }
                        : { textAlign: "center" }
                    }
                  >
                    {qualifications["Manager"]}
                  </TableCell>
                  <TableCell
                    sx={
                      highlightedColumn === "Pat-Chi"
                        ? { backgroundColor: "#f0f4f8", textAlign: "center" }
                        : { textAlign: "center" }
                    }
                  >
                    {qualifications["Pat-L-Chi"]}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  {[
                    "Pat-L-Alpha",
                    "Pat-L-Gamma",
                    "Pat-L-Delta",
                    "Manager",
                    "Pat-L-Chi",
                  ].map((group) =>
                    shouldRenderUpgradeButton(group) ? (
                      <TableCell
                        key={group}
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          onClick={() => handleUpgradeRequest(group)}
                          sx={{
                            //   mt: 1,
                            backgroundColor: "#88BBD6",
                            "&:hover": { backgroundColor: "#71a5c7" },
                          }}
                        >
                          Request Free Upgrade
                        </Button>
                      </TableCell>
                    ) : (
                      <TableCell key={group}></TableCell>
                    )
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {/* Upgrade Contact us form Dialog */}
      <Dialog
        open={contactDialogOpen}
        onClose={closeDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <ContactUsForm
            baseConfig={baseConfig}
            initialValues={contactFormDefaults}
            formTitle={user ? "Upgrade Access" : "Request Access"}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PatanalaiAccount;

import React from "react";
//--------------------------------------------------------------------------------------
// Channel & Buy Button Labels
//--------------------------------------------------------------------------------------

export const BIGC = "bigc";
export const AMZ = "amz";
export const AMZ_SELLER_US = "amz_seller_us";
export const AMZ_SELLER_US_B2B = "amz_seller_us_b2b";
export const AMZ_FBA_US = "amz_fba_us";
export const AMZ_FBA_US_B2B = "amz_fba_us_b2b";
export const AMZ_SELLER_CA = "amz_seller_ca";
export const AMZ_US = "amz_us";
export const AMZ_CA = "amz_ca";
export const AMZ_AFF = "AMZ_AFF";
export const EBAY = "ebay";
export const WMT = "wmt";
export const NE = "newegg";
export const NE_BIZ = "newegg_biz";
export const ENGR = "ENGR";
export const HCOMP = "HCOMP";
export const PCOMP = "PCOMP";
export const FORUMS = "RfWeL-Forums";
export const WDI = "WDI";
export const GOOGLE = "GOOGLE";
export const ALL = "ALL";
export const JOOM = "JOOM";
export const PATANAL = "PATANAL";

// URL source tag when landing on BigC (to know that came from patanal JS)
export const SOURCE_TAG = "ptjs";

// TEMP styling -> Move to CSS
export const RFW_HORIZDOT = (
  //<hr style={{ borderBottom: "dotted 1px #000" }}></hr>
  <hr
    style={{
      borderTop: "2px dotted #999",
      marginTop: "0.9091em",
      paddingTop: "0.9091em",
    }}
  ></hr>
);

//--------------------------------------------------------------------------------------
// Channel Listing Status Labels
//--------------------------------------------------------------------------------------
export const lbl_LISTING_ACTIVE = "Listed";
export const lbl_LISTING_ACTIVE_PENDING_UPDATE = "Listed-Pending-Update";
export const lbl_LISTING_APPROVED = "Approved-for-Listing";
export const lbl_LISTING_INACTIVE_PENDING_UPDATE = "Disabled-Pending-Update";
export const lbl_LISTING_INACTIVE = "Disabled";
export const lbl_LISTING_DISABLED = "Disabled";
export const lbl_LISTING_SCHEDULED = "Scheduled";
export const lbl_LISTING_FFS = "FFS";
export const lbl_LISTING_ZBB = "No-Plan-to-List"; //zero-based-budget (an intel moniker for shelf it indefinitely)

//--------------------------------------------------------------------------------------
// General Purpose Functions/Utilities
//--------------------------------------------------------------------------------------
//@TODO - find better home for these
export function buildLink(url, label) {
  if (url)
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {label}
      </a>
    );
  if (label === "Link") return `--`;
  return label;
}

export function capitalizeFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatCurrency(value) {
  let amount = parseFloat(value);
  if (!isNaN(amount)) {
    return `$${amount.toFixed(2)}`;
  }
  return null;
}

export function formatPercentage(value) {
  let percent = parseFloat(value);
  if (!isNaN(percent)) {
    return `${percent.toFixed(1)}%`;
  }
  return null;
}

//--------------------------------------------------------------------------------------
// Brand Labels
//--------------------------------------------------------------------------------------
export const MAN_MISSING = "--UNSPECIFIED--";
export const MAN_AMANA = "Amana";
export const MAN_AMERSTD = "American Standard";
export const MAN_CARRIER = "Carrier";
export const MAN_COMFORTMAKER = "Comfortmaker";
export const MAN_DAIKIN = "Daikin";
export const MAN_FRIEDRICH = "Friedrich";
export const MAN_FRIGIDAIRE = "Frigidaire";
export const MAN_FUJITSU = "Fujitsu";
export const MAN_GOODMAN = "Goodman";
export const MAN_GREE = "Gree";
export const MAN_HITACHI = "Hitachi";
export const MAN_HONEYWELL = "Honeywell";
export const MAN_LENNOX = "Lennox";
export const MAN_LG = "LG";
export const MAN_LUXAIRE = "Luxaire";
export const MAN_MIDEA = "Midea";
export const MAN_MITSUBISHI = "Mitsubishi Electric";
export const MAN_MRCOOL = "MRCOOL";
export const MAN_PANASONIC = "Panasonic";
export const MAN_RHEEM = "Rheem";
export const MAN_RUUD = "Ruud";
export const MAN_SAMSUNG = "Samsung";
export const MAN_TRANE = "Trane";
export const MAN_VENSTAR = "Venstar";
export const MAN_YORK = "York";

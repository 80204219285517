import {
  faHome,
  faToolbox,
  faFileChartColumn,
  faCartFlatbedBoxes,
  faMagnifyingGlass,
  faMoneyBillTrendUp,
  faHandHoldingDollar,
  faHandHoldingBox,
  faListCheck,
  faChartMixed,
} from "@fortawesome/pro-solid-svg-icons";
export const lpimNavbarConfig = [
  {
    title: "Home",
    path: "/product-detail",
    iconFa: faHome,
    authRequired: false,
    children: [],
  },
  {
    title: "Performance",
    path: "/dashboard",
    iconFa: faChartMixed,
    authRequired: true,
    children: [
      {
        title: "Listings",
        path: "/dashboard-listings",
      },
      {
        title: "Marketing",
        path: "/dashboard-marketing",
      },
    ],
  },
  {
    title: "LPIM Search",
    path: "/product-search",
    iconFa: faMagnifyingGlass,
    authRequired: true,
    children: [
      {
        title: "Product Search",
        path: "/product-search",
        pageFooterText:
          "You may also search directly in <a href='https://shop.rfwel.com/' target='_blank'>E-Store</a> for most-accurate pricing",
      },
      {
        title: "Product Detail",
        path: "/product-detail",
        pageFooterText:
          "You may also search directly in <a href='https://shop.rfwel.com/' target='_blank'>E-Store</a> for most-accurate pricing",
      },
    ],
  },
  {
    title: "Listings & DQ",
    path: "/listing-dq",
    iconFa: faListCheck,
    authRequired: true,
    children: [],
  },
  {
    title: "Price Watch",
    path: "/price-watch",
    iconFa: faHandHoldingDollar,
    authRequired: true,
    children: [
      {
        title: "Competitor Analysis",
        path: "/compe-anal",
      },
      {
        title: "Stale Competitors",
        path: "/stale-compe",
      },
      {
        title: "Overdue Compe Audits",
        path: "/overdue-compe-audit",
      },
      {
        title: "Margin Analysis",
        path: "/margin-anal",
      },
      {
        title: "Channel Price vs Bigc",
        path: "/price-vs-bigc",
      },
      {
        title: "Sales Analysis",
        path: "/sales-anal",
      },
    ],
  },
  {
    title: "Inventory Watch",
    path: "/inactive-instock",
    iconFa: faHandHoldingBox,
    authRequired: true,
    children: [
      {
        title: "Inventory Mismatch",
        path: "/inventory-mismatch",
      },
      {
        title: "Inactive In-Stock",
        path: "/inactive-instock",
      },
      {
        title: "Active Not-In-Stock",
        path: "/active-not-in-stock",
      },
      {
        title: "Stale Inventory Data",
        path: "/stale-inventory",
      },
      {
        title: "Overstayed Stock",
        path: "/overstayed-stock",
      },
    ],
  },
  {
    title: "Marketing",
    path: "/google-anal",
    iconFa: faMoneyBillTrendUp,
    authRequired: true,
    children: [
      {
        title: "Google Analysis",
        path: "/google-anal",
      },
      {
        title: "Bing Analysis",
        path: "/bing-anal",
      },
    ],
  },
  {
    title: "Parts Store",
    iconFa: faCartFlatbedBoxes,
    path: "external", // Unique identifier for external link
    externalUrl: "https://shop.rfwel.com", //@TODO -> switch this to parts-finder.rfwel.com
    authRequired: false,
    children: [], // No children for external links
  },
  {
    title: "Parts Finder",
    iconFa: faToolbox,
    path: "external",
    externalUrl: "https://parts-finder.rfwel.com",
    authRequired: false,
    children: [], // No children for external links
  },
  {
    title: "Reports",
    iconFa: faFileChartColumn,
    path: "external",
    externalUrl:
      "https://lpim-backend.rfwel.com/big-commerce-marketing-report/",
    authRequired: true,
    children: [],
  },
];

import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import formatDate from "../utils/formatDateTime";
import profit from "../utils/priceMargin";
import Table from "../../common/Table";
import TableTiered from "../../common/TableTiered";
import Watch from "../../common/Watch";
import StatusIcon from "../../common/StatusIcon";
import * as CONFIG from "../utils/Global-Labels-Links-Vars";

const PriceWatchTable = ({ prods, onRemove, onWatch, onSort, sortColumn }) => {
  // prods here is an array of objects [[{},{},{}...], [], [], ... []]
  // each inner array contains object for product in different channels

  // Create static header
  const columnsStatic = [
    {
      path: "sku",
      label: "SKU",
      content: (prod) => (
        <Link to={`/product-detail/${prod.sku}`}> {prod.sku} </Link>
      ),
    },
    { path: "compe_stat", label: "Status" },
    {
      key: "margin_stat_bigc",
      label: "Bigc",
      content: (prod) => (
        <StatusIcon
          status={prod.margin_stat_bigc}
          listed={prod.list_stat_bigc === CONFIG.lbl_LISTING_ACTIVE}
        />
      ),
    },
    {
      key: "margin_stat_amz_seller_us",
      label: "AMZ Seller",
      content: (prod) => (
        <StatusIcon
          status={prod.margin_stat_amz_seller_us}
          listed={prod.list_stat_amz_seller_us === CONFIG.lbl_LISTING_ACTIVE}
        />
      ),
    },
    {
      key: "margin_stat_amz_fba_us",
      label: "AMZ FBA",
      content: (prod) => (
        <StatusIcon
          status={prod.margin_stat_amz_fba_us}
          listed={prod.list_stat_amz_fba_us === CONFIG.lbl_LISTING_ACTIVE}
        />
      ),
    },
    {
      key: "margin_stat_amz_seller_ca",
      label: "AMZ CA",
      content: (prod) => (
        <StatusIcon
          status={prod.margin_stat_amz_seller_ca}
          listed={prod.list_stat_amz_seller_ca === CONFIG.lbl_LISTING_ACTIVE}
        />
      ),
    },
    {
      key: "margin_stat_ebay",
      label: "eBay",
      content: (prod) => (
        <StatusIcon
          status={prod.margin_stat_ebay}
          listed={prod.list_stat_ebay === CONFIG.lbl_LISTING_ACTIVE}
        />
      ),
    },
    {
      key: "margin_stat_wmt",
      label: "Walmart",
      content: (prod) => (
        <StatusIcon
          status={prod.margin_stat_wmt}
          listed={prod.list_stat_wmt === CONFIG.lbl_LISTING_ACTIVE}
        />
      ),
    },
    {
      key: "margin_stat_ne",
      label: "Newegg",
      content: (prod) => (
        <StatusIcon
          status={prod.margin_stat_ne}
          listed={prod.list_stat_ne === CONFIG.lbl_LISTING_ACTIVE}
        />
      ),
    },
    {
      key: "margin_stat_nebiz",
      label: "Newegg-Biz",
      content: (prod) => (
        <StatusIcon
          status={prod.margin_stat_nebiz}
          listed={prod.list_stat_nebiz === CONFIG.lbl_LISTING_ACTIVE}
        />
      ),
    },
    {
      key: "remove-item",
      content: (prod) => (
        <button
          onClick={() => onRemove(prod)}
          className="btn btn-danger btn-sm"
        >
          Hide
        </button>
      ),
    },
  ];

  return (
    <TableTiered
      columns={columnsStatic}
      numGroup1={1}
      sortColumn={sortColumn}
      onSort={onSort}
      data={prods}
      idProperty={"sku"}
    />
  );
};

export default PriceWatchTable;

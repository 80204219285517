import React from "react";
import * as CONFIG from "../utils/Global-Labels-Links-Vars";

export function buildChannelLinks(channel, pid) {
  // if no channel provided, return null
  if (!channel) {
    return null;
  }

  let url, label;
  switch (channel) {
    case CONFIG.BIGC:
      url = `https://shop.rfwel.com/products.php?productId=${pid}&source=${CONFIG.SOURCE_TAG}`;
      label = "BIGC";
      break;
    case CONFIG.AMZ_SELLER_US:
      url = `https://www.amazon.com/gp/product/${pid}?m=A3HRH3RGP717Q`;
      label = "AMZ Seller US";
      break;
    case CONFIG.AMZ_SELLER_US_B2B:
      url = `https://www.amazon.com/gp/product/${pid}?m=A3HRH3RGP717Q`;
      label = "AMZ Seller US B2B";
      break;
    case CONFIG.AMZ_FBA_US:
      url = `https://www.amazon.com/gp/product/${pid}?m=A3HRH3RGP717Q`;
      label = "AMZ FBA";
      break;
    case CONFIG.AMZ_FBA_US_B2B:
      url = `https://www.amazon.com/gp/product/${pid}?m=A3HRH3RGP717Q`;
      label = "AMZ FBA B2B";
      break;
    case CONFIG.AMZ_SELLER_CA:
      url = `https://www.amazon.com/gp/product/${pid}?m=A3HRH3RGP717Q`;
      label = "AMZ Seller CA";
      break;
    case CONFIG.EBAY:
      url = `https://www.ebay.com/itm/${pid}`;
      label = "eBay";
      break;
    case CONFIG.WMT:
      url = `https://www.walmart.com/ip/${pid}?selectedSellerId=101005160`;
      label = "Walmart";
      break;
    case CONFIG.NE:
      url = `https://www.newegg.com/Product/Product.aspx?Item=${pid}`;
      label = "Newegg";
      break;
    case CONFIG.NE_BIZ:
      url = `https://www.neweggbusiness.com/Product/Product.aspx?Item=${pid}`;
      label = "Newegg-Biz";
      break;
  }

  // if channel provided but no pid, return label without link
  if (!pid && label) {
    return label;
  }

  // otherwise if have url and label return link
  if (url && label)
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {label}
      </a>
    );

  // Should never reach this
  return null;
}

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { WorksheetProvider } from "./context/WorksheetContext";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";

const domain = window.location.hostname;

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <AuthProvider>
    <WorksheetProvider>
      <Router>
        <App domain={domain} />
      </Router>
    </WorksheetProvider>
  </AuthProvider>
);

import React, { useState, useEffect, useRef } from "react";
import { Grid, Box, Paper } from "@mui/material";
import { useAuth } from "../../context/AuthContext";
import GenaiPrompt from "../genai/GenaiPrompt";
import GenaiResults from "../genai/GenaiResults";

const PatanalAiHome = () => {
  const { user } = useAuth();
  const [genaiResults, setGenaiResults] = useState([]);
  const resultsEndRef = useRef(null);

  useEffect(() => {
    if (resultsEndRef.current) {
      resultsEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [genaiResults]);

  const handleClearResults = () => {
    setGenaiResults([]);
  };

  return (
    <Grid
      container
      spacing={2}
      className="link-no-decoration genai-container"
      justifyContent="center"
    >
      <Grid item xs={12} sx={{ mt: 4, pb: 15 }}>
        <div id="results-container" className="genai-results-container">
          {genaiResults.map((result, index) => (
            <GenaiResults key={index} result={result} />
          ))}
          <div ref={resultsEndRef} />
        </div>
        <Box
          position="fixed"
          bottom={80}
          left={0}
          width="100%"
          bgcolor="white"
          p={2}
          borderTop="1px solid #dee2e6"
        >
          <Paper elevation={3} sx={{ p: 2 }}>
            <GenaiPrompt
              onResults={(newResults) =>
                setGenaiResults((prevResults) => [...prevResults, newResults])
              }
              onClear={handleClearResults}
            />
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PatanalAiHome;

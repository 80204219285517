import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import prods from "../utils/prods";
import BootstrapAccordion from "../utils/BootstrapAccordion";
import BootstrapContainer from "../utils/BootstrapContainer";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
// import _ from "lodash";
import _, { debounce } from "lodash";
import cost from "../utils/cost";
import formatDate from "../utils/formatDateTime";
import PricingDetailTable from "./PricingDetailTable";
import CompeDetailTable from "./CompeDetailTable";
import InventoryDetailTable from "./InventoryDetailTable";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";

import {
  buildChannelProducts,
  buildCompeDetailProduct,
  buildInventoryDetailProduct,
} from "../utils/buildChannelProducts";
import * as CONFIG from "../utils/Global-Labels-Links-Vars";

const LpimProductDetail = () => {
  const { sku: skuParam } = useParams(); // unpacking and retrieve SKU from URL
  const navigate = useNavigate();

  const [inputValue, setInputValue] = useState(skuParam || "");
  const [sku, setSku] = useState(skuParam || "");
  const debouncedSetSku = useRef(
    debounce((nextSku) => setSku(nextSku), 500)
  ).current; //debounce for 500ms after user stops typing

  const [product, setProduct] = useState([]);
  const [compeProducts, setCompeProducts] = useState();

  useEffect(() => {
    if (skuParam) {
      setSku(skuParam);
      fetchProd(skuParam);
    }
  }, [skuParam]);

  //-------------------------------------------------------------
  const _handleUpdateSku = (e) => {
    // const newSku = e.target.value.trim();
    const newSku = e.trim();
    // setInputValue(newSku); // update the input immediately
    // debouncedSetSku(newSku); // update the SKU after user has stopped typing
    navigate(`/product-detail/${newSku}`);
  };

  const _handleSearchSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior (page refresh)
    _handleUpdateSku(sku);
  };
  //-------------------------------------------------------------
  const _handleRefreshProducts = async () => {
    //setIsLoading(true);
    const trimmedSku = sku.trim();
    const productFetched = await prods.get_prod(
      `/patanal_api/product-detail/${trimmedSku}`
    );
    setProduct(productFetched);
    //setIsLoading(false);
  };

  const fetchProd = async (sku) => {
    try {
      const trimmedSku = sku.trim();
      const productFetched = await prods.get_prod(
        `/patanal_api/product-detail/${trimmedSku}`
      );
      setProduct(productFetched);
    } catch (error) {
      console.error("Error fetching product", error);
    }
  };

  // -------------------------------------------------------------------------------
  // Get the Cost
  // -------------------------------------------------------------------------------
  const prodCost = _.get(product, "product_cost");
  const prodCostSource = _.get(product, "cost_source");
  const pricingLastUpdate = _.get(product, "prices_for_product.last_update");
  const qboLastUpdate = _.get(product, "inventory_for_product.last_update");

  // Write strings for display
  const costString = prodCost
    ? `Cost = ${CONFIG.formatCurrency(prodCost)} (from ${prodCostSource})`
    : `**COST NOT FOUND** (update Django Admin)`;
  const pricingLastUpdateString = pricingLastUpdate
    ? `Pricing Data Last Updated = ${formatDate(pricingLastUpdate)}`
    : "**NO PRICING INFO**";
  const qboLastUpdateString = qboLastUpdate
    ? `QBO Data Last Updated = ${formatDate(qboLastUpdate)}`
    : "**NO QBO INFO**";
  const marginHighUsedString = `MARGIN_HIGH > ${CONFIG.MARGIN_HIGH}%`;
  const marginLowUsedString = `MARGIN_LOW < ${CONFIG.MARGIN_LOW}%`;

  // Determine if a combo of substitution
  const isCombo = _.get(product, "is_a_combo");
  const comboItemsList = isCombo ? _.get(product, "combo_items") : null;
  const comboItemsStr =
    isCombo && comboItemsList && comboItemsList.length !== 0
      ? `(combo items: ${comboItemsList.join(", ")})`
      : "";
  const isSub = _.get(product, "is_a_substitution");
  const subItem = isSub ? _.get(product, "substitution_item") : null;
  const subItemStr = isSub && subItem ? `(substituted with ${subItem})` : "";

  // An item cannot be a combo and have a sub. If set to both in error, just indicate the combo.
  const comboSubStr = isCombo ? comboItemsStr : subItemStr;

  // -------------------------------------------------------------------------------
  // Get the Inventory
  // -------------------------------------------------------------------------------
  const isNew = _.get(product, "item_condition") === "New";
  const qtyQboValue = _.get(product, "inventory_for_product.qty_qbo");
  const qtyQboNew = isNew ? qtyQboValue : 0;
  const qtyQboUsed = isNew ? _.get(product, "qty_qbo_used") : qtyQboValue;
  const isInFba = _.get(product, "listing_status_amz_fba_us") === "Listed";
  const qtyFbaShippable = isInFba
    ? _.get(product, "inventory_for_product.qty_amz_fba_shippable")
    : 0;
  const qtyFbaTotal = isInFba
    ? _.get(product, "inventory_for_product.qty_amz_fba_total")
    : 0;

  function getInventoryNotes(product) {
    const notesForProduct = _.get(product, "notes_for_product", []);
    const prodCost = _.get(product, "product_cost", 0);

    return notesForProduct
      .filter(
        (note) =>
          note.note_type === "Inventory" ||
          note.note_type === "Inventory-Mismatch"
      )
      .map((note) => {
        const {
          note: noteContent,
          updated_by,
          last_update,
          note_type,
          value,
        } = note;
        const { first_name, last_name, email } = updated_by;

        let result = `Note: ${noteContent}\nUpdated by: ${first_name} ${last_name} (${email})\nLast updated: ${new Date(
          last_update
        ).toLocaleString()}`;

        if (
          note_type === "Inventory-Mismatch" &&
          value !== null &&
          prodCost !== null
        ) {
          const mismatchValue = value * prodCost;
          result += `\n${value} units mismatch; total loss exposure ${CONFIG.formatCurrency(
            mismatchValue
          )}`;
        }

        return result;
      })
      .join("\n\n");
  }

  const notesForProduct = getInventoryNotes(product);

  // Used Chandler is just the used items in QBO
  const usedChandler = qtyQboUsed && qtyQboUsed !== 0 ? qtyQboUsed : 0;
  const newChandler =
    qtyQboNew && qtyQboNew !== 0 ? qtyQboNew - qtyFbaTotal : 0;

  const newChandlerStr = isNew
    ? `Chandler New -> ${newChandler} (ignores staging)`
    : "";
  const usedChandlerStr = `Chandler Used -> ${usedChandler}`;
  const fbaShippableStr = isInFba ? `FBA Shippable -> ${qtyFbaShippable}` : "";
  const fbaTotalStr = isInFba ? `FBA Total -> ${qtyFbaTotal}` : "";

  /*******************************************************************************************************************************************************/
  /*                    SKU Search                                                                                                                 */
  /*******************************************************************************************************************************************************/
  const _renderSkuSearchBox = () => {
    return (
      // Put everything in a form so can use enter to submit
      <form onSubmit={_handleSearchSubmit}>
        <TextField
          label="Search SKU"
          variant="outlined"
          value={sku}
          onChange={(e) => setSku(e.target.value)}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ mt: 2 }}
        />

        <Button
          type="submit" // Button submits form
          startIcon={<ChangeCircleIcon />}
          variant="contained"
          // onClick={() => fetchProd(sku)}
          onClick={() => _handleUpdateSku(sku)}
          sx={{
            mt: 2,
            mb: 3,
            mr: 2,
          }}
        >
          {skuParam ? "Change SKU" : "Get SKU"}
        </Button>

        <Button
          startIcon={<RefreshIcon />}
          variant="contained"
          color="secondary"
          onClick={_handleRefreshProducts}
          sx={{
            mt: 2,
            mb: 3,
            mr: 2,
          }}
        >
          {"Refresh"}
        </Button>
      </form>
    );
  };

  const skuNotFetchedStr = "--SKU Not Fetched --";
  const pricingDetailBodyHeading = product.sku
    ? `${product.sku} Listings & Margins ${comboSubStr}`
    : skuNotFetchedStr;
  const compeDetailBodyHeading = product.sku
    ? `${product.sku} Competitor Analysis ${comboSubStr}`
    : skuNotFetchedStr;
  const inventoryDetailBodyHeading = product.sku
    ? `${product.sku} Stock Status ${comboSubStr}`
    : skuNotFetchedStr;
  const salesDetailBodyHeading = product.sku
    ? `${product.sku} Sales Status ${comboSubStr}`
    : skuNotFetchedStr;

  /*******************************************************************************************************************************************************/
  /*                    PRICING DETAIL                                                                                                                   */
  /*******************************************************************************************************************************************************/
  //-------------------------------------------------------------------------------
  // Build Pricing Channel Product Object
  //-------------------------------------------------------------------------------

  let channelProducts;
  if (product.length !== 0) {
    channelProducts = buildChannelProducts(product);
  }

  //-------------------------------------------------------------------------------
  // Margin Anal Redirect Button
  //-------------------------------------------------------------------------------
  const getMarginDataButton = (
    <React.Fragment>
      {CONFIG.RFW_HORIZDOT}
      <p>Get items with too Low or too High Margins ...</p>
      <Grid container spacing={1}>
        <Grid item xs={12} align="left">
          <Button
            color="primary"
            variant="contained"
            to="/margin-anal"
            component={Link}
          >
            {" "}
            Get Margin Data
          </Button>
        </Grid>
      </Grid>
      {CONFIG.RFW_HORIZDOT}
    </React.Fragment>
  );

  //-------------------------------------------------------------------------------
  // Margin Data Detail Footer
  //-------------------------------------------------------------------------------
  const getMarginDataFooter = (
    <React.Fragment>
      {CONFIG.RFW_HORIZDOT}
      <p>
        If any of the data here looks off, update prices and costs in Django
        Admin Backend
      </p>
      {CONFIG.RFW_HORIZDOT}
    </React.Fragment>
  );
  //-------------------------------------------------------------------------------
  // PRICING DETAILS RENDER
  //-------------------------------------------------------------------------------
  const pricingDetailBodyContent = (
    <React.Fragment>
      <Box display="flex" flexDirection="column" sx={{ marginBottom: 2 }}>
        <strong>{pricingDetailBodyHeading}</strong>
      </Box>
      <Box display="flex" flexDirection="column" sx={{ marginBottom: 2 }}>
        <BootstrapContainer
          colcontent={[
            costString,
            marginHighUsedString,
            marginLowUsedString,
            pricingLastUpdateString,
            qboLastUpdateString,
            "last supp update -> tbd",
          ]}
        />
      </Box>
      <Box display="flex" flexDirection="column" sx={{ marginBottom: 2 }}>
        {getMarginDataButton}
      </Box>
      <Box display="flex" flexDirection="column" sx={{ marginBottom: 2 }}>
        {typeof channelProducts != "undefined" ? (
          <PricingDetailTable product={channelProducts} />
        ) : (
          "--No channel products retrieved from DB--"
        )}
      </Box>
      <Box display="flex" flexDirection="column" sx={{ marginBottom: 2 }}>
        {getMarginDataFooter}
      </Box>
    </React.Fragment>
  );

  // Insert PriceMargin in return below

  //-------------------------------------------------------------------------------
  // Build Competitor Product Object
  //-------------------------------------------------------------------------------

  useEffect(() => {
    if (product.length !== 0) {
      setCompeProducts(buildCompeDetailProduct(product));
    }
  }, [product]);

  //-------------------------------------------------------------------------------
  // Build Inventory/Supplier Product Object
  //-------------------------------------------------------------------------------
  let inventoryProducts;
  if (product.length !== 0) {
    inventoryProducts = buildInventoryDetailProduct(product);
  }
  //-------------------------------------------------------------------------------
  // Competitor Anal Redirect Button
  //-------------------------------------------------------------------------------
  const getCompeDataButton = (
    <React.Fragment>
      {CONFIG.RFW_HORIZDOT}
      <p>Get items priced substantially above or below competition ...</p>
      <Grid container spacing={1}>
        <Grid item xs={12} align="left">
          <Button
            color="primary"
            variant="contained"
            to="/compe-anal"
            component={Link}
            sx={{ ml: 2, mb: 2 }}
          >
            {" "}
            Get Compe Data
          </Button>

          <Button
            sx={{ marginLeft: 2 }}
            color="primary"
            variant="contained"
            to="/stale-compe"
            component={Link}
          >
            {" "}
            Get Stale Compes
          </Button>
        </Grid>
      </Grid>
      {CONFIG.RFW_HORIZDOT}
    </React.Fragment>
  );

  //-------------------------------------------------------------------------------
  // Competitor Detail Footer
  //-------------------------------------------------------------------------------
  const getCompeDataFooter = (
    <React.Fragment>
      {CONFIG.RFW_HORIZDOT}
      <p>
        If any of the data here looks off, update prices, costs, and competitor
        pricing information in Django Admin Backend
      </p>
      {CONFIG.RFW_HORIZDOT}
    </React.Fragment>
  );

  //-------------------------------------------------------------------------------
  // COMPETITOR DETAIL RENDER
  //-------------------------------------------------------------------------------
  const competitorDetailBodyContent = (
    <React.Fragment>
      <Box display="flex" flexDirection="column" sx={{ marginBottom: 2 }}>
        <strong>{compeDetailBodyHeading}</strong>
      </Box>
      <Box display="flex" flexDirection="column" sx={{ marginBottom: 2 }}>
        <BootstrapContainer
          colcontent={[costString, pricingLastUpdateString]}
        />
      </Box>
      <Box display="flex" flexDirection="column" sx={{ marginBottom: 2 }}>
        {getCompeDataButton}
      </Box>
      <Box display="flex" flexDirection="column" sx={{ marginBottom: 2 }}>
        {compeProducts ? (
          <CompeDetailTable product={compeProducts} />
        ) : (
          "--No competitors retrieved from DB--"
        )}
      </Box>
      <Box display="flex" flexDirection="column" sx={{ marginBottom: 2 }}>
        {getCompeDataFooter}
      </Box>
    </React.Fragment>
  );

  //-------------------------------------------------------------------------------
  // Inventory DETAILS
  //-------------------------------------------------------------------------------
  const inventoryDetailBodyContent = (
    <React.Fragment>
      <Box display="flex" flexDirection="column" sx={{ marginBottom: 2 }}>
        <p>
          <strong>{inventoryDetailBodyHeading}</strong>
        </p>
      </Box>
      <Box display="flex" flexDirection="column" sx={{ marginBottom: 1 }}>
        <BootstrapContainer
          colcontent={[
            newChandlerStr,
            usedChandlerStr,
            "",
            fbaShippableStr,
            fbaTotalStr,
          ]}
        />
        {CONFIG.RFW_HORIZDOT}
      </Box>
      {notesForProduct && (
        <Box display="flex" flexDirection="column" sx={{ marginBottom: 1 }}>
          <p>
            <strong>Inventory Notes</strong>
          </p>
          <pre style={{ whiteSpace: "pre-wrap" }}>{notesForProduct}</pre>
          {CONFIG.RFW_HORIZDOT}
        </Box>
      )}
      <Box display="flex" flexDirection="column" sx={{ marginBottom: 2 }}>
        {typeof inventoryProducts != "undefined" ? (
          <InventoryDetailTable product={inventoryProducts} />
        ) : (
          "--No inventory data retrieved from DB--"
        )}
      </Box>
    </React.Fragment>
  );

  //-------------------------------------------------------------------------------
  // SALES DETAILS
  //-------------------------------------------------------------------------------
  const salesDetailBodyContent = (
    <React.Fragment>
      <Box display="flex" flexDirection="column" sx={{ marginBottom: 2 }}>
        <p>
          <strong>{salesDetailBodyHeading}</strong>
        </p>
        <p>
          Get the Top N SKUs per sales-period (e.g., Month-to-Date, Last Month,
          etc.) to see how this product compares ...{" "}
        </p>
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={12} align="left">
          <Button
            color="primary"
            variant="contained"
            to="/sales-anal"
            component={Link}
          >
            {" "}
            Get Top Sales
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  //-------------------------------------------------------------------------------
  // Boostrap Accordion --> https://getbootstrap.com/docs/5.0/components/accordion
  //-------------------------------------------------------------------------------
  return (
    <React.Fragment>
      {/* <div className="bg-primary text-white p-4 text-center"> */}
      {product.sku && (
        <div>
          <h5>
            {product.sku}: {product.title}{" "}
          </h5>
          <p>
            <em>
              (Update data in admin back-end DB to update the contents here)
            </em>
          </p>
        </div>
      )}

      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} align="left">
          {_renderSkuSearchBox()}
        </Grid>
      </Grid>

      <BootstrapAccordion
        bodyHeadings={[
          "Pricing Details",
          "Competitor Details",
          "Inventory Details",
          "Sales Details",
        ]}
        bodyContent={[
          pricingDetailBodyContent,
          competitorDetailBodyContent,
          inventoryDetailBodyContent,
          salesDetailBodyContent,
        ]}
      />
    </React.Fragment>
  );
};

export default LpimProductDetail;

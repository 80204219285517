import React from "react";

const BootstrapContainer = ({ colcontent = ["rown-col1-content"] }) => {
  return (
    <div className="container">
      <div className="row">
        {colcontent.map((col, index) => (
          <div className="col-sm-4" key={index}>
            {col}
          </div>
        ))}
      </div>
    </div>
  );
};
export default BootstrapContainer;

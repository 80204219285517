import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Pagination,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Box,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import http from "../../../services/httpService";
import _ from "lodash";

const baseURL = `${window._env_.REACT_APP_LPIM_API_URL}/lpim_api/inventory-mismatch`;

const InventoryMismatch = () => {
  const defaultSortCol = { path: "value_of_mismatch", order: "asc" };
  const defaultPageSize = 50;

  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalMismatchedItems, setTotalMismatchedItems] = useState(0);
  const [totalMismatchValue, setTotalMismatchValue] = useState(0);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [sortCol, setSortCol] = useState(defaultSortCol);

  const fetchProducts = async (page = 1, size = defaultPageSize) => {
    setIsLoading(true);
    const headers = {
      Authorization: `JWT ${http.getCookie("access_token")}`,
    };
    try {
      const response = await axios.get(
        `${baseURL}/?page=${page}&page_size=${size}`,
        {
          headers,
        }
      );
      setProducts(response.data.results);
      setTotalPages(Math.ceil(response.data.count / size));
      setTotalMismatchedItems(response.data.total_mismatched_items);
      setTotalMismatchValue(response.data.total_mismatch_value);
    } catch (error) {
      console.error("Error fetching products", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSort = (path) => {
    const order =
      sortCol.path === path && sortCol.order === "asc" ? "desc" : "asc";
    setSortCol({ path, order });
  };

  const handleRefresh = () => {
    setCurrentPage(1);
    setPageSize(defaultPageSize);
    setSortCol(defaultSortCol);
    fetchProducts(1, defaultPageSize); // Fetch the default page with the default size
  };

  const sortedProducts = _.orderBy(
    products,
    (product) => {
      if (sortCol.path === "notes_for_product.value") {
        // Sorting by Mismatch Quantity
        return _.get(product, "notes_for_product[0].value", 0);
      } else if (sortCol.path === "value_of_mismatch") {
        // Sorting by Value of Mismatch
        const value = _.get(product, "notes_for_product[0].value", 0);
        const productCost = _.get(product, "product_cost", 0);
        return value * productCost;
      } else {
        // General sorting
        return _.get(product, sortCol.path);
      }
    },
    [sortCol.order]
  );

  const pageSizeOptions =
    process.env.NODE_ENV === "production" ? [50, 100] : [5, 10, 50, 100];

  return (
    <div>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Box mb={3} display="flex" justifyContent="space-between">
            <Box>
              <Typography variant="h6">
                --- Total Mismatched Items: {totalMismatchedItems || 0} | Total
                Mismatch Value: $
                {totalMismatchValue ? totalMismatchValue.toFixed(2) : "0.00"}{" "}
                ---
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleRefresh}
              >
                Refresh
              </Button>
            </Box>
          </Box>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell onClick={() => handleSort("sku")}>
                    SKU{" "}
                    {sortCol.path === "sku" &&
                      (sortCol.order === "asc" ? "▲" : "▼")}
                  </TableCell>
                  <TableCell
                    onClick={() => handleSort("notes_for_product.value")}
                  >
                    Qty{" "}
                    {sortCol.path === "notes_for_product.value" &&
                      (sortCol.order === "asc" ? "▲" : "▼")}
                  </TableCell>
                  <TableCell onClick={() => handleSort("product_cost")}>
                    Each{" "}
                    {sortCol.path === "product_cost" &&
                      (sortCol.order === "asc" ? "▲" : "▼")}
                  </TableCell>
                  <TableCell onClick={() => handleSort("value_of_mismatch")}>
                    Value{" "}
                    {sortCol.path === "value_of_mismatch" &&
                      (sortCol.order === "asc" ? "▲" : "▼")}
                  </TableCell>
                  <TableCell>Mismatch Research Status & Notes</TableCell>
                  <TableCell>Updated</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedProducts.map((product) =>
                  product.notes_for_product.map((note) => (
                    <TableRow key={`${product.sku}-${note.last_update}`}>
                      <TableCell>
                        <Link to={`/product-detail/${product.sku}`}>
                          {product.sku}
                        </Link>
                      </TableCell>
                      <TableCell>{note.value}</TableCell>
                      <TableCell>${product.product_cost}</TableCell>
                      <TableCell>
                        ${note.value * product.product_cost}
                      </TableCell>
                      <TableCell>{note.note}</TableCell>
                      <TableCell>
                        {note.updated_by.first_name} {note.updated_by.last_name}{" "}
                        ({note.updated_by.email}) <br />
                        {new Date(note.last_update).toLocaleString()}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={3}
          >
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
            />
            <FormControl size="small" sx={{ minWidth: 80 }}>
              <Select
                value={pageSize}
                onChange={(e) => setPageSize(e.target.value)}
              >
                {pageSizeOptions.map((size) => (
                  <MenuItem key={size} value={size}>
                    {size}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </>
      )}
    </div>
  );
};

export default InventoryMismatch;

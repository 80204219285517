import React from "react";
import { Container } from "@mui/material";
import ContactUsForm from "../common/ContactUsForm";
import SetDocumentMeta from "../common/SetDocumentMeta";
import KthContactUsCommonContent from "./KthContactUsCommonContent";

const KthContactUs = ({ baseConfig }) => {
  SetDocumentMeta({
    title: "Contact Us | Kama Thuo, PLLC",
    description: "",
    keywords: "",
  });

  return (
    <Container maxWidth="lg" className="link-no-decoration" sx={{ mt: 2 }}>
      <KthContactUsCommonContent>
        {(agreementText, organizationErrorText) => (
          <ContactUsForm
            baseConfig={baseConfig}
            agreementText={agreementText}
            isOrganizationRequired={true}
            organizationErrorText={organizationErrorText}
          />
        )}
      </KthContactUsCommonContent>
    </Container>
  );
};

export default KthContactUs;

import React from "react";

export const RFW_WDI_BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://wdi.rfwel.com"
    : "http://djdev-wdi.rfwel.com:8000/";

export const RFW_AI_BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://ai.rfwel.com"
    : "http://djdev-ai.rfwel.com:8000/";

//--------------------------------------------------------------------------------------
// TEMPORARY DEVELOPMENT ISH -> MOVE OUT OF HERE
//--------------------------------------------------------------------------------------

// TEMP styling -> Move to CSS
export const RFW_HORIZDOT = (
  //<hr style={{ borderBottom: "dotted 1px #000" }}></hr>
  <hr
    style={{
      borderTop: "2px dotted #999",
      marginTop: "0.9091em",
      paddingTop: "0.9091em",
    }}
  ></hr>
);

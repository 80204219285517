import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Link,
  Grid,
  Paper,
  TextField,
  Button,
} from "@mui/material";

import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";

import axios from "axios";
import http from "../../services/httpService";
import { RoomPreferences } from "@mui/icons-material";

const baseURL = `${window._env_.REACT_APP_LPIM_API_URL}/lpim_api`;

const ProductDetailDashboard = () => {
  const { sku: urlSku } = useParams();
  const navigate = useNavigate();
  const [sku, setSku] = useState("");
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  //----------------------------------------------------------------------------
  // Set sku and Get Product
  //----------------------------------------------------------------------------
  useEffect(() => {
    if (urlSku) {
      setSku(urlSku);
      fetchProduct(urlSku);
    }
  }, [urlSku]);

  const _handleUpdateSku = (id) => {
    const newSku = id.trim();
    navigate(`/product-detail/${newSku}`);
  };

  const _handleSearchSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior (page refresh)
    _handleUpdateSku(sku);
  };

  const fetchProduct = async (id) => {
    setIsLoading(true);
    try {
      const headers = {
        Authorization: `JWT ${http.getCookie("access_token")}`,
      };
      // Example: /products?sku=WDI-TEST-DEVID-001
      const response = await axios.get(`${baseURL}/products?sku=${id}`, {
        headers,
      });
      // console.log(response.data.results[0]);
      setProduct(response.data.results[0]);
    } catch (error) {
      setError(error);
      console.error("Failed to fetch product details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  //-------------------------------------------------------------------------------
  // Render Product Search Button
  //-------------------------------------------------------------------------------

  const _renderSkuSearch = () => {
    return (
      // Put everything in a form so can use enter to submit
      <form onSubmit={_handleSearchSubmit}>
        {/* @TODO -> Prompts user for MPN but using Sku here. Although sku usually same as MPN this is not always the case! */}
        <TextField
          label="Product MPN"
          variant="outlined"
          value={sku}
          onChange={(e) => setSku(e.target.value)}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        <Button
          type="submit" // Button submits form
          startIcon={<ChangeCircleIcon />}
          variant="contained"
          // onClick={() => fetchProduct(sku)}
          onClick={() => _handleUpdateSku(sku)}
          disabled={isLoading || sku.trim() === ""}
          sx={{
            mt: 2,
            backgroundColor: "#88BBD6",
            "&:hover": { backgroundColor: "#71a5c7" },
          }}
        >
          {isLoading ? (
            <CircularProgress size={24} />
          ) : urlSku ? (
            "Change Product"
          ) : (
            "Get Product"
          )}
        </Button>

        <Button
          startIcon={<SearchIcon />}
          variant="outlined"
          sx={{
            mt: 2,
            ml: 2,
            backgroundColor: "white",
            "&:hover": { backgroundColor: "#f0f0f0" },
          }}
          href="/product-search"
        >
          Search Products
        </Button>
      </form>
    );
  };

  //-------------------------------------------------------------------------------
  // Render Images Card
  //-------------------------------------------------------------------------------
  const _renderImagesCard = () => {
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h6">Images</Typography>
          <Box>
            {product.images.map((image) => (
              <img
                key={image.image}
                src={image.image}
                alt={image.image_alt}
                title={image.image_type}
                style={{
                  width: "100px",
                  height: "100px",
                  marginRight: "10px",
                }}
              />
            ))}
          </Box>
        </CardContent>
      </Card>
    );
  };

  //-------------------------------------------------------------------------------
  // Render Full Dashboard
  //-------------------------------------------------------------------------------
  if (isLoading) return <CircularProgress size={24} />;
  if (error) return <div>Error: {error.message}</div>;
  return (
    <Grid container spacing={2}>
      {/* Product Search */}
      <Grid item xs={12} sm={6}>
        {_renderSkuSearch()}
      </Grid>

      {/* Images Box */}
      <Grid item xs={12} sm={6} md={3}>
        {product && _renderImagesCard()}
      </Grid>
    </Grid>
  );
};

export default ProductDetailDashboard;

import React from "react";
import { Link } from "react-router-dom";
import * as CONFIG from "../utils/Global-Labels-Links-Vars";
import Table from "../../common/Table";

const StaleCompeTable = ({ prods, onSort, sortColumn }) => {
  // Create static header
  // Remove-Item column doesn't have a label so we pass empty object so it is rendered
  // for watched column want label but we dont want to sort
  // To sort a column just add path variable below corresponding to the data attribute name
  const columnsStatic = [
    {
      path: "sku",
      label: "SKU",
      content: (prod) => (
        <Link to={`/product-detail/${prod.sku}`}> {prod.sku} </Link>
      ),
    },
    {
      path: "rfwel_price",
      label: "Our Price",
      content: (prod) => CONFIG.formatCurrency(prod.rfwel_price),
    },
    {
      path: "margin_percent",
      label: "Our Margin %",
      content: (prod) => CONFIG.formatPercentage(prod.margin_percent),
    },
    { path: "freshest_compe", label: "Freshest Competitor" },
    { path: "link", label: "Compe Link" },
    { path: "compe_last_update", label: "Competitor Last Update" },
  ];

  return (
    <Table
      columns={columnsStatic}
      sortColumn={sortColumn}
      onSort={onSort}
      data={prods}
      idProperty={"sku"}
    />
  );
};

export default StaleCompeTable;

import React, { useEffect, useState } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Grid,
  Typography,
  Paper,
  Button,
  Box,
  CircularProgress,
  Alert,
  Link as MuiLink,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faFilePdf,
  faExternalLinkAlt,
  faLink,
  faGavel,
  faToggleOn,
  faBracketsCurly,
  faCompassDrafting,
  faArrowLeft,
} from "@fortawesome/pro-solid-svg-icons";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import parse from "html-react-parser";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const apiUrlKth = `${window._env_.REACT_APP_PATANALAI_API_URL}`;

const AiDefinitionDetail = () => {
  const { slug } = useParams();
  const [definition, setDefinition] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchDefinitionDetail = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${apiUrlKth}/kth-ai/${slug}/definition-detail`
        );
        setDefinition(response.data);
      } catch (err) {
        setError("Failed to fetch AI definition detail.");
        console.error("Error fetching AI definition detail:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDefinitionDetail();
  }, [slug]);

  if (isLoading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="link-no-decoration">
        <Grid item xs={12}>
          <Alert severity="error" sx={{ mt: 4 }}>
            {error}
          </Alert>
          <Button
            startIcon={<FirstPageIcon />}
            color="primary"
            component={RouterLink}
            to="/ai-legal-definitions"
            sx={{ mt: 2, mb: 2 }}
          >
            Return to AI Definitions Search
          </Button>
        </Grid>
      </Container>
    );
  }

  if (!definition) {
    return (
      <Container className="link-no-decoration">
        <Grid item xs={12}>
          <Alert severity="warning" sx={{ mt: 4 }}>
            No AI definition data found.
          </Alert>
          <Button
            startIcon={<FirstPageIcon />}
            color="primary"
            component={RouterLink}
            to="/ai-legal-definitions"
            sx={{ mt: 2, mb: 2 }}
          >
            Return to AI Definitions Search
          </Button>
        </Grid>
      </Container>
    );
  }

  return (
    <Container className="link-no-decoration">
      <Grid container spacing={3} padding={3}>
        <Grid item xs={12}>
          <Button
            startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            color="secondary"
            variant="outlined"
            component={RouterLink}
            to={"/ai-legal-definitions"}
            sx={{ mb: 3, textTransform: "none" }}
          >
            Back to AI Definitions Search
          </Button>
          <Typography variant="h4" gutterBottom>
            {definition.term}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{ __html: definition.common_meaning }}
          />
        </Grid>

        {/* Legal Definitions Section */}
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ mt: 4 }}>
            Legal Definitions
          </Typography>

          <Paper sx={{ marginBottom: 2, padding: 2 }}>
            {definition.construed_definitions?.length > 0 ? (
              definition.construed_definitions.map((legalDef, index) => (
                <Box key={index} sx={{ marginBottom: 2 }}>
                  <Typography variant="h6">{legalDef.source}</Typography>
                  <Typography>{legalDef.source_details}</Typography>

                  {/* Source Type */}
                  {legalDef.source_type?.name && (
                    <>
                      <Typography sx={{ mt: 2 }}>
                        {legalDef.source_type.name}
                      </Typography>
                      <Typography
                        variant="caption" // Smaller text size
                        display="block" // Ensures the text is on its own line
                        sx={{
                          color: "text.secondary", // Muted color
                          mb: 2,
                          ml: 2,
                        }}
                      >
                        See more definitions from {legalDef.source_type.name}
                        {". "}
                        <MuiLink
                          href={`/ai-legal-definitions?source_type=${legalDef.source_type.slug}`}
                          color="primary"
                          sx={{
                            textDecoration: "none",
                            // ml: 1,
                            display: "inline-flex",
                            alignItems: "center",
                            fontSize: "small", // Match the size of the ChevronRightIcon
                          }}
                        >
                          <Typography variant="caption" component="span">
                            Search
                          </Typography>
                          <ChevronRightIcon fontSize="small" />
                        </MuiLink>
                      </Typography>
                    </>
                  )}

                  {/* Source Jurisdiction */}
                  {legalDef.source_jurisdiction?.name && (
                    <>
                      <Typography>
                        {legalDef.source_jurisdiction.name}
                      </Typography>

                      <Typography
                        variant="caption" // Smaller text size
                        display="block" // Ensures the text is on its own line
                        sx={{
                          color: "text.secondary", // Muted color
                          mb: 2,
                          ml: 2,
                        }}
                      >
                        See more definitions from{" "}
                        {legalDef.source_jurisdiction.name}
                        {". "}
                        <MuiLink
                          href={`/ai-legal-definitions?source_jurisdiction=${legalDef.source_jurisdiction.slug}`}
                          color="primary"
                          sx={{
                            textDecoration: "none",
                            // ml: 1,
                            display: "inline-flex",
                            alignItems: "center",
                            fontSize: "small", // Match the size of the ChevronRightIcon
                          }}
                        >
                          <Typography variant="caption" component="span">
                            Search
                          </Typography>
                          <ChevronRightIcon fontSize="small" />
                        </MuiLink>
                      </Typography>
                    </>
                  )}

                  <Typography
                    dangerouslySetInnerHTML={{ __html: legalDef.legal_meaning }}
                  />
                  {legalDef.source_link && (
                    <a
                      href={legalDef.source_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {legalDef.source_link_label || "Source Link"}
                    </a>
                  )}
                </Box>
              ))
            ) : (
              <Alert severity="info">No legal constructions found.</Alert>
            )}
          </Paper>
        </Grid>

        {/* References Section */}
        {definition.reflinks_for_legal_defn?.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ mt: 4 }}>
              References
            </Typography>
            {definition.reflinks_for_legal_defn.map((ref, index) => (
              <Paper key={index} sx={{ marginBottom: 2, padding: 2 }}>
                <Typography variant="h6">
                  {ref.reflink_description_title}
                </Typography>
                <Typography
                  dangerouslySetInnerHTML={{ __html: ref.reflink_description }}
                />
                <Typography variant="body1" sx={{ ml: 4, mb: 2 }}>
                  <FontAwesomeIcon icon={faLink} style={{ marginRight: 8 }} />
                  <a
                    href={ref.reflink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {ref.reflink_label}
                  </a>
                </Typography>
              </Paper>
            ))}
          </Grid>
        )}

        {/* Related Terms Section */}
        {definition.related_terms?.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ mt: 4 }}>
              Related Terms
            </Typography>
            <Box>
              {definition.related_terms.map((related, index) => (
                <Button
                  key={index}
                  component={RouterLink}
                  to={`/ai-legal-definitions/${related.related_term_slug}`}
                  sx={{ mr: 2, mt: 1 }}
                >
                  {related.related_term_name}
                </Button>
              ))}
            </Box>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default AiDefinitionDetail;

import React from "react";
import { Link } from "react-router-dom";
import * as CONFIG from "../utils/Global-Labels-Links-Vars";
import Table from "../../common/Table";

const MarketingAnalTable = ({ prods, onSort, sortColumn }) => {
  const columnsStatic = [
    {
      path: "sku",
      label: "SKU",
      content: (prod) => (
        <Link to={`/product-detail/${prod.sku}`}> {prod.sku} </Link>
      ),
    },
    { path: "link", label: "BigC Link" },
    {
      path: "price",
      label: "Price",
      content: (prod) => CONFIG.formatCurrency(prod.price),
    },
    {
      path: "margin_percent",
      label: "Margin %",
      content: (prod) => CONFIG.formatPercentage(prod.margin_percent),
    },
    { path: "last_update", label: "Last Updated" },
  ];

  return (
    <Table
      columns={columnsStatic}
      sortColumn={sortColumn}
      onSort={onSort}
      data={prods}
      idProperty={"sku"}
      id2Property={""}
    />
  );
};

export default MarketingAnalTable;

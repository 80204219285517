import React, { Component, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  Box,
  Typography,
  TextField,
  FormHelperText,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Collapse,
  Alert,
} from "@mui/material";
import prods from "../utils/prods";
import Pagination from "../../common/Pagination";
import { paginate } from "../utils/paginate";
import ListGroup from "../../common/ListGroup";
import SearchBox from "../../common/SearchBox";
import _ from "lodash";
import * as CONFIG from "../utils/Global-Labels-Links-Vars";
import LoadingSpinner from "../../common/LoadingSpinner";
import { getMarketingListings } from "../utils/buildChannelProducts";
import MarketingAnalTable from "./MarketingAnalTable";

const MarketingAnal = ({ mktgChannel }) => {
  //-------------------------------------------------------------------------------
  // SET DEFAULTS
  //-------------------------------------------------------------------------------
  const DEFAULT_PAGE_SIZE = 10;
  const DEFAULT_SKUS_TO_GET = 200;
  const MAX_SKUS_TO_GET = 500;

  // Marketing Organic
  const ORGANIC_NOTP1 = "ORGANIC_NOTP1";
  const ORGANIC_P1TOP5 = "ORGANIC_P1TOP5";
  const ORGANIC_P1TOP10 = "ORGANIC_P1TOP10";
  const ORGANIC_NOTTOP10 = "ORGANIC_NOTTOP10";
  const ORGANIC_STALE = "ORGANIC_STALE";

  // Marketing Shopping
  const SHOP_NO = "SHOP_NO";
  const SHOP_P1 = "SHOP_P1";
  const SHOP_MOREPAGE = "SHOP_MOREPAGE";
  const SHOP_STALE = "SHOP_STALE";

  // Show Stock Status of Items to List
  const SHOW_STOCK_ALL = "STOCK_ALL";
  const SHOW_INSTOCK = "STOCK_INSTOCK";

  //-------------------------------------------------------------------------------
  // HOOKS
  //-------------------------------------------------------------------------------

  const navigate = useNavigate();
  const [freshnessDays, setFreshnessDays] = useState(
    CONFIG.DEFAULT_MARKETING_FRESH_DAYS
  );
  const [showStock, setShowStock] = useState(SHOW_INSTOCK);
  const [skusToGet, setSkusToGet] = useState(DEFAULT_SKUS_TO_GET);
  const [marketingAnal, setMarketingAnal] = useState(SHOP_NO);
  const [products, setProducts] = useState([]);
  const [filteredChannelProducts, setFilteredChannelProducts] = useState([]);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(1);
  const [categories, setCategories] = useState([{ title: "All Categories" }]);
  const [selectedCategory, setSelectedCategory] = useState({
    title: "All Categories",
  });
  const [sortCol, setSortCol] = useState({
    path: "margin_percent",
    order: "desc",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  //-------------------------------------------------------------
  const numProds = products.length;

  //-------------------------------------------------------------
  const _handleShowStockChange = (e) => {
    setShowStock(e.target.value);
  };

  //-------------------------------------------------------------
  const _handleFreshnessDaysChange = (e) => {
    let selectedFreshnessDays = e.target.value;
    if (selectedFreshnessDays > CONFIG.MAX_MARKETING_FRESH_DAYS) {
      selectedFreshnessDays = CONFIG.MAX_MARKETING_FRESH_DAYS;
    }

    setFreshnessDays(selectedFreshnessDays);
  };

  //-------------------------------------------------------------
  const _handleSkusToGetChange = (e) => {
    let selectedSkusToGet = e.target.value;
    if (selectedSkusToGet > MAX_SKUS_TO_GET) {
      selectedSkusToGet = MAX_SKUS_TO_GET;
    }

    setSkusToGet(selectedSkusToGet);
  };

  //-------------------------------------------------------------
  const _handleMarketingAnalChange = (e) => {
    setMarketingAnal(e.target.value);
  };

  const showOnlyInStockQuery = showStock === SHOW_INSTOCK ? "true" : "false";

  //-------------------------------------------------------------
  const _handleGetMarketingItemsButtonClicked = () => {
    // Set IsLoading to true and disable Refresh button to prevent multiple API calls
    // Will only fetch if there are no products loaded so setIsLoading(true) only then
    if (numProds === 0) {
      setIsLoading(true);
    }
    const fetchProds = async () => {
      const productsFetched = await prods.get_all(
        `/patanal_api/products-marketing?channel=${mktgChannel}&instock=${showOnlyInStockQuery}`
      );
      setProducts(productsFetched);

      // Hide Loading screen when fetch complete
      setIsLoading(false);
    };
    // Fetch Prods only if Prods is not included in state
    if (numProds === 0) {
      fetchProds();
    }
  };

  //-------------------------------------------------------------
  const _handleRefreshProducts = async () => {
    setIsLoading(true);
    const productsFetched = await prods.get_all(
      `/patanal_api/products-marketing?channel=${mktgChannel}&instock=${showOnlyInStockQuery}`
    );
    setProducts(productsFetched);
    setIsLoading(false);
  };

  //-------------------------------------------------------------------------------
  // EFFECTS
  //-------------------------------------------------------------------------------

  //----------------------------------------------
  // Get filtered inactive items when filter changes
  //------------------------------------------------
  useEffect(
    // Update filtered channel products whenever filters change
    () => {
      // filter channProds to include only the items that meet threshold set
      const filteredChannProds = getMarketingListings({
        products,
        marketingAnal,
        freshnessDays,
        skusToGet,
        mktgChannel,
      });

      setFilteredChannelProducts(filteredChannProds);

      //Set categories based on filtered products
      const filterCategories = filteredChannProds.map((cat) => {
        let catProperties = {
          title: cat.category,
        };
        return catProperties;
      });

      // Now set categories using setCategories hook using title.
      setCategories([{ title: "All Categories" }, ...filterCategories]);

      // Also select to select all categories when filter changes
      setSelectedCategory({
        title: "All Categories",
      });
    },
    [products, marketingAnal, freshnessDays, skusToGet]
  );

  //----------------------------------------------
  // Force refetch from DB when filter changes
  useEffect(() => {
    // Only refresh if not currently loading some other fetch
    if (!isLoading && numProds !== 0) {
      _handleRefreshProducts();
    }
  }, [showStock]); // This will run when one of the dependencies changes

  //----------------------------------------------
  useEffect(() => {
    // This will run when `mktgChannel` changes
    setProducts([]);
  }, [mktgChannel]);

  //-------------------------------------------------------------
  const _handlePageChange = (page) => {
    //console.log(`Page Clicked = ${page}`);
    setCurrentPage(page);
  };
  //-------------------------------------------------------------
  const _handleCategorySelect = (category) => {
    //console.log(category);
    setSelectedCategory(category);
    // Note cannot set search query to null because this is a controlled component. If set to null/undefined react will think this is an uncontrolled component
    // and will give warning when user types in typed that trying to covert an uncontrolled component to a controlled component.
    setSearchQuery("");
    setCurrentPage(1);

    // Reset current page to 1 otherwise if select a category with multiple pages then go back to a category with fewer pages, will display blank
    setCurrentPage(1);
  };
  //-------------------------------------------------------------
  const _handleSort = (receivedSortCol) => {
    setSortCol((prevState) => ({
      ...prevState,
      path: receivedSortCol.path,
      order: receivedSortCol.order,
    }));
  };
  //-------------------------------------------------------------
  const _handleSearchChange = (query) => {
    setSearchQuery(query);
    setSelectedCategory("");
    setCurrentPage(1);
  };
  //-------------------------------------------------------------------------------
  // COMPONENT OBJECTS
  //-------------------------------------------------------------------------------
  // use destructuring to get length of products (number of items and rename to prodCount)
  // the below equivalent to const prodCount = products.length.
  const { length: prodCount } = filteredChannelProducts;

  // filter products based on selected category (or search query) before pagination
  // && used to cover all categories
  // start with filtered products as all products
  let productsFiltered = filteredChannelProducts;

  if (searchQuery) {
    // if there is a search query then fitler based on search query
    productsFiltered = filteredChannelProducts.filter((p) =>
      p.sku.toLowerCase().startsWith(searchQuery.toLowerCase())
    );
  } else {
    // if no search query but there's category selection then filter based on category
    productsFiltered =
      selectedCategory && selectedCategory.title !== "All Categories"
        ? filteredChannelProducts.filter(
            (p) => p.category === selectedCategory.title
          )
        : filteredChannelProducts;
  }

  // sort products
  // sortCol.path and sortCol.order are in array because we can sort by multiple columns using lodash's orderBy util
  const productsSorted = _.orderBy(
    productsFiltered,
    [sortCol.path],
    [sortCol.order]
  );

  // paginate products
  const productsPaginated = paginate(productsSorted, currentPage, pageSize);

  //-------------------------------------------------------------------------------
  // Conditional Render of Margin Anal Table
  //-------------------------------------------------------------------------------
  const _renderMarketingAnalTable = () => {
    if (isLoading) return <LoadingSpinner />;
    if (!prodCount || prodCount === 0)
      return (
        <div style={{ marginTop: "10px" }}>
          {CONFIG.RFW_HORIZDOT}
          <p> ..... No listings in the database match the search criteria.</p>
        </div>
      );
    return (
      <Grid item xs={12} align="center">
        <div>{CONFIG.RFW_HORIZDOT}</div>
        <div className="row">
          <div className="col-2">
            <ListGroup
              items={categories}
              selectedItem={selectedCategory}
              onItemSelect={_handleCategorySelect}
              textProperty="title"
              idProperty="title"
            />
          </div>
          <div className="col">
            <p>
              Showing{" "}
              <strong>
                {" "}
                {productsFiltered.length} {selectedCategory.title}{" "}
              </strong>{" "}
              products in the database matching selected criteria.
            </p>
            <SearchBox
              searchValue={searchQuery}
              searchOnChange={(e) => _handleSearchChange(e.currentTarget.value)}
            />
            <MarketingAnalTable
              prods={productsPaginated}
              sortColumn={sortCol}
              onSort={_handleSort}
            />

            <Pagination
              itemsCount={productsFiltered.length}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={_handlePageChange}
            />
          </div>
        </div>
      </Grid>
    );
  };
  //-------------------------------------------------------------------------------
  //Render of Margin Anal Page
  //-------------------------------------------------------------------------------
  const channelTitle = CONFIG.capitalizeFirst(mktgChannel);
  const labelNotOnShop = `Not on ${channelTitle} Shop`;
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} align="center">
        <Typography component="h4" variant="h4">
          {channelTitle} Analysis
        </Typography>
      </Grid>

      <Grid item xs={12} align="center">
        <FormControl component="fieldset">
          <RadioGroup
            row
            defaultValue={SHOP_NO}
            onChange={_handleMarketingAnalChange}
          >
            <Box
              sx={{
                display: "inline-flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  background: "#DAF7A6",
                  borderRadius: "16px",
                  paddingX: 2,
                }}
              >
                <Typography component="h6" variant="h6">
                  {channelTitle} Organic Analysis
                </Typography>
                <FormControlLabel
                  value={ORGANIC_NOTP1}
                  control={<Radio color="primary" />}
                  label="Not Pg 1"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value={ORGANIC_P1TOP5}
                  control={<Radio color="secondary" />}
                  label="Pg 1, Top 5"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value={ORGANIC_P1TOP10}
                  control={<Radio color="secondary" />}
                  label="Pg 1, Top 10"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value={ORGANIC_NOTTOP10}
                  control={<Radio color="secondary" />}
                  label="pg 1, Top 11+"
                  labelPlacement="bottom"
                />

                <FormControlLabel
                  value={ORGANIC_STALE}
                  control={<Radio color="secondary" />}
                  label="Stale Data"
                  labelPlacement="bottom"
                />
              </Box>

              <Box
                sx={{
                  background: "#F1DAEE",
                  borderRadius: "16px",
                  paddingX: 2,
                  marginLeft: 2,
                }}
              >
                <Typography component="h6" variant="h6">
                  {channelTitle} Shopping Analysis
                </Typography>
                <FormControlLabel
                  value={SHOP_NO}
                  control={<Radio color="primary" />}
                  label={labelNotOnShop}
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value={SHOP_P1}
                  control={<Radio color="secondary" />}
                  label="On Pg 1"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value={SHOP_MOREPAGE}
                  control={<Radio color="secondary" />}
                  label="On More Page"
                  labelPlacement="bottom"
                />

                <FormControlLabel
                  value={SHOP_STALE}
                  control={<Radio color="secondary" />}
                  label="Stale Data"
                  labelPlacement="bottom"
                />
              </Box>
            </Box>
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12} align="center">
        <Box
          sx={{
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Box sx={{ background: "#D5F7F1", borderRadius: "16px" }}>
            <Typography component="h6" variant="h6">
              {"Select Items to Show"}
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                row
                defaultValue={SHOW_INSTOCK}
                onChange={_handleShowStockChange}
              >
                <FormControlLabel
                  value={SHOW_STOCK_ALL}
                  control={<Radio color="primary" />}
                  label="Show All"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value={SHOW_INSTOCK}
                  control={<Radio color="primary" />}
                  label="Show Only In Stock"
                  labelPlacement="bottom"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box
            sx={{
              background: "#C2FDA4",
              borderRadius: "16px",
              paddingX: 2,
              marginLeft: 2,
            }}
          >
            <Typography component="h6" variant="h6">
              {"Select # Days of Freshness"}
            </Typography>
            <FormControl>
              <TextField
                required={true}
                type="number"
                onChange={_handleFreshnessDaysChange}
                defaultValue={CONFIG.DEFAULT_MARKETING_FRESH_DAYS}
                inputProps={{
                  min: 1,
                  max: CONFIG.MAX_MARKETING_FRESH_DAYS,
                  style: { textAlign: "center" },
                }}
              />
              <FormHelperText>
                <span align="center">Stale if not refreshed in X days</span>
              </FormHelperText>
            </FormControl>
          </Box>

          <Box
            sx={{
              background: "#EFF2CB",
              borderRadius: "16px",
              paddingX: 2,
              marginLeft: 2,
            }}
          >
            <Typography component="h6" variant="h6">
              {"Select Max Number of Listings to Get"}
            </Typography>
            <FormControl>
              <TextField
                required={true}
                type="number"
                onChange={_handleSkusToGetChange}
                defaultValue={DEFAULT_SKUS_TO_GET}
                inputProps={{
                  min: 1,
                  max: MAX_SKUS_TO_GET,
                  style: { textAlign: "center" },
                }}
              />
              <FormHelperText>
                <span align="center">Number of Listings to Get</span>
              </FormHelperText>
            </FormControl>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} align="center">
        <Button
          color="primary"
          variant="contained"
          onClick={_handleGetMarketingItemsButtonClicked}
          disabled={isLoading || numProds !== 0}
        >
          Get {channelTitle} Listings
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={_handleRefreshProducts}
          disabled={isLoading}
          sx={{ marginLeft: 2 }}
        >
          {"Refresh from DB"}
        </Button>
      </Grid>
      <Grid item xs={12} align="center">
        <Button color="secondary" variant="contained" to="/" component={Link}>
          {" "}
          Back
        </Button>
      </Grid>

      {_renderMarketingAnalTable()}
    </Grid>
  );
};

export default MarketingAnal;

import React, { useState, useEffect } from "react";

import {
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Typography,
  Grid,
  Box,
  Paper,
  IconButton,
  Tooltip,
  Autocomplete,
  Link as MuiLink,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import http from "../../services/httpService";
import Pagination from "../common/Pagination";
import _ from "lodash";
import * as CONFIG from "./utils/patanalaiGlobalLabelsVars";
import DeviceSearchResultsTable from "./DeviceSearchResultsTable";
import SearchBox from "../common/SearchBox";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// The above broken -- gives error -> Module not found: Error: Package path ./_lib/format/longFormatters is not exported from package /app/node_modules/date-fns (see exports field in /app/node_modules/date-fns/package.json)
// Was used for  <LocalizationProvider dateAdapter={AdapterDateFns}>
// Switched to moment
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import InfoIcon from "@mui/icons-material/Info";
import InputAdornment from "@mui/material/InputAdornment";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCircleInfo, faCalendarAlt } from "@fortawesome/pro-solid-svg-icons";
import SetDocumentMeta from "../common/SetDocumentMeta";

// const baseURL = `${window._env_.REACT_APP_LPIM_API_URL}/patanal_api/devices`;
const apiUrlPatanal = `${window._env_.REACT_APP_PATANALAI_API_URL}`; // Patanal API Endpoint

const DeviceSearch = () => {
  // Set DEFAULTS
  const DEFAULT_PAGE_SIZE = 10;

  // Set Title, Meta Description, Meta Keywords
  SetDocumentMeta({
    title: "Device Search | Patent Analysis",
    description:
      "Search and drill-down on wireless devices. Search by brand, part-number, FCC IDs, associated wireless standards and standard revisions, keyword search on title and description, technical categories, device classification, and more.",
    keywords: "wireless device research, patent monetization, device search",
  });

  // State for Device Filters
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [standards, setStandards] = useState([]);
  const [standardRevisions, setStandardRevisions] = useState([]);
  const [devices, setDevices] = useState([]);

  // State for user selections
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedStandard, setSelectedStandard] = useState("");
  const [selectedStandardRevision, setSelectedStandardRevision] = useState("");
  const [mpnSearch, setMpnSearch] = useState("");
  const [fccSearch, setFccSearch] = useState("");

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [initialSearchPerformed, setInitialSearchPerformed] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [releaseDate, setReleaseDate] = useState(null);

  // const [releaseDate, setReleaseDate] = useState(moment());
  const [sortCol, setSortCol] = useState({
    path: "mpn",
    order: "asc",
  });
  const [isLoading, setIsLoading] = useState(false);

  //----------------------------------------------------------------------------
  // Filter Options Fetch
  //----------------------------------------------------------------------------
  useEffect(() => {
    const fetchOptions = async () => {
      const headers = {
        Authorization: `JWT ${http.getCookie("access_token")}`,
      };
      try {
        const [brandsRes, categoriesRes, standardsRes, revisionsRes] =
          await Promise.all([
            axios.get(`${apiUrlPatanal}/devices/brands`, { headers }),
            axios.get(`${apiUrlPatanal}/devices/categories`, { headers }),
            axios.get(`${apiUrlPatanal}/devices/wireless_standards`, {
              headers,
            }),
            axios.get(`${apiUrlPatanal}/devices/wireless_standard_revisions`, {
              headers,
            }),
          ]);
        setBrands(brandsRes.data);
        setCategories(categoriesRes.data);
        setStandards(standardsRes.data);
        setStandardRevisions(revisionsRes.data);
      } catch (error) {
        console.error("Failed to fetch options", error);
      }
    };

    fetchOptions();
  }, []);

  //----------------------------------------------------------------------------
  // Device Fetch
  //----------------------------------------------------------------------------
  const fetchDevices = async (pageNumber = 1) => {
    setIsLoading(true);
    setInitialSearchPerformed(true);

    const headers = {
      Authorization: `JWT ${http.getCookie("access_token")}`,
    };
    const query = new URLSearchParams({
      page_size: pageSize,
      page: pageNumber,
    });

    if (mpnSearch) query.set("mpn", mpnSearch);
    else if (fccSearch) query.set("fccid", fccSearch);
    else {
      if (selectedBrand) query.set("brand", selectedBrand);
      if (selectedCategory) query.set("category", selectedCategory);
      if (selectedStandard) query.set("standard", selectedStandard);
      if (selectedStandardRevision)
        query.set("std_version", selectedStandardRevision);
      if (releaseDate)
        query.set("release_date", releaseDate.toISOString().split("T")[0]);
    }

    try {
      const response = await axios.get(
        `${apiUrlPatanal}/devices?${query.toString()}`,
        {
          headers,
        }
      );
      setDevices(response.data.results);
      setTotalResults(response.data.count);
      setTotalPages(Math.ceil(response.data.count / pageSize));
      setCurrentPage(pageNumber);
    } catch (error) {
      console.error("Failed to fetch devices", error);
      setDevices([]);
    } finally {
      setIsLoading(false);
    }
  };

  const clearSearch = () => {
    // Reset all search-related states
    setSelectedBrand("");
    setSelectedCategory("");
    setSelectedStandard("");
    setSelectedStandardRevision("");
    setMpnSearch("");
    setFccSearch("");
    setDevices([]);
    setCurrentPage(1);
    setInitialSearchPerformed(false);
    setReleaseDate(null);
    // Note: You might not want to reset pageSize to allow users to keep their preferred setting
  };

  //----------------------------------------------------------------------------
  // Pagination Control
  //----------------------------------------------------------------------------
  const _handlePageChange = (page) => {
    //console.log(`Page Clicked = ${page}`);
    setCurrentPage(page);
    // No need to call fetchDevices here as it will be called by the effect hook when currentPage changes
  };

  // When page size is changed, we should reset back to the first page and fetch devices.
  const _handlePageSizeChange = (event) => {
    const newPageSize = event.target.value;
    setPageSize(newPageSize);
    setCurrentPage(1); // Reset to the first page
    // No need to call fetchDevices here as it will be called by the effect hook when currentPage changes
  };

  // This effect is used to fetch devices on page change or when page size changes,
  // but only after an initial search has been performed.
  useEffect(() => {
    if (initialSearchPerformed) {
      fetchDevices(currentPage);
    }
  }, [currentPage, pageSize]);

  //----------------------------------------------------------------------------
  // Search Results/ Sort
  //----------------------------------------------------------------------------
  const _handleSearchChange = (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const _handleSort = (receivedSortCol) => {
    setSortCol((prevState) => ({
      ...prevState,
      path: receivedSortCol.path,
      order: receivedSortCol.order,
    }));
  };

  const filteredStandardRevisions = selectedStandard
    ? standardRevisions.filter((sr) => sr.standard_name === selectedStandard)
    : [];

  let devicesFiltered;

  if (searchQuery) {
    // if there is a search query then filter based on search query
    devicesFiltered = devices.filter((dev) =>
      dev.mpn.toLowerCase().startsWith(searchQuery.toLowerCase())
    );
  } else {
    devicesFiltered = devices;
  }

  // sort products
  // sortCol.path and sortCol.order are in array because we can sort by multiple columns using lodash's orderBy util
  const devicesSorted = _.orderBy(
    devicesFiltered,
    [sortCol.path],
    [sortCol.order]
  );

  // get number of devices and desctructure to rename to deviceCount
  const { length: deviceCount } = devices;

  //-------------------------------------------------------------------------------
  // Render Release Date Selection
  //-------------------------------------------------------------------------------
  const _renderReleaseDatePicker = () => {
    return (
      // See for further customizations -> https://mui.com/x/react-date-pickers/date-picker/
      <Grid container alignItems="center">
        <Grid item>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale="en-us"
          >
            <DatePicker
              label="Released after"
              value={releaseDate}
              onChange={setReleaseDate}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item>
          <Tooltip title="Returns devices publicly released after date. Note that devices without an explicit release date will be excluded!">
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  //-------------------------------------------------------------------------------
  // Conditional Render of Device Results Table
  //-------------------------------------------------------------------------------
  const _renderDeviceTable = () => {
    if (isLoading) return <CircularProgress size={24} />;
    if (!initialSearchPerformed)
      return (
        <div style={{ marginTop: "10px" }}>
          {CONFIG.RFW_HORIZDOT}
          <Alert severity="info">
            Search by MPN/FCC ID or Brand, Category, Standard, Standard
            Revision.
          </Alert>
        </div>
      );

    if (!deviceCount || deviceCount === 0)
      return (
        <div style={{ marginTop: "10px" }}>
          {CONFIG.RFW_HORIZDOT}
          <Alert severity="warning">
            No devices in the database match the search criteria.
          </Alert>
        </div>
      );
    return (
      <>
        <div>{CONFIG.RFW_HORIZDOT}</div>
        <Grid container alignItems="center" sx={{ mb: 2 }}>
          {/* Pagination Info and Page Size Selection */}
          {_renderPaginationInfo()}

          <Grid item xs={12} align="center">
            <SearchBox
              searchValue={searchQuery}
              searchOnChange={(e) => _handleSearchChange(e.currentTarget.value)}
            />
          </Grid>

          <Grid item xs={12} align="center">
            <DeviceSearchResultsTable
              devs={devicesSorted}
              sortColumn={sortCol}
              onSort={_handleSort}
            />
          </Grid>

          <Grid item xs={12} align="center">
            {totalPages > 1 && (
              <Pagination
                itemsCount={totalResults} // This is total devices and not devices returned for current page
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={_handlePageChange}
              />
            )}
          </Grid>
        </Grid>
      </>
    );
  }; // end const _renderDeviceTable = () =>

  //-------------------------------------------------------------------------------
  // Render Pagination Controls
  //-------------------------------------------------------------------------------
  const _renderPaginationInfo = () => (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      sx={{ mb: 2 }}
    >
      <Grid item>
        <Typography variant="subtitle1">
          Total results: {totalResults}
        </Typography>
      </Grid>
      <Grid item>
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle1" sx={{ marginRight: 2 }}>
            Items per page:
          </Typography>
          <FormControl size="small" sx={{ minWidth: 80 }}>
            <Select
              id="page-size-select"
              value={pageSize}
              onChange={_handlePageSizeChange}
              displayEmpty
            >
              {[3, 5, 10, 20].map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );

  //-------------------------------------------------------------------------------
  // Render Full Page w/ Results Table
  //-------------------------------------------------------------------------------
  return (
    <Grid container spacing={1}>
      {/* Search Filters Container */}
      <Container maxWidth="lg" className="link-no-decoration">
        <Paper
          elevation={3}
          sx={{ p: 3, margin: "auto", maxWidth: "90%", mt: 2 }}
        >
          <Typography variant="h6" align="center" gutterBottom>
            Device Search
          </Typography>
          <Typography
            variant="caption" // Smaller text size
            align="center"
            display="block" // Ensures the text is on its own line
            sx={{
              color: "text.secondary", // Muted color
              mb: 3,
            }}
          >
            (Device data provided by{" "}
            <MuiLink
              href="https://wdi.rfwel.com"
              target="_blank"
              // rel="noopener noreferrer"
            >
              Rfwel WDI
            </MuiLink>
            )
          </Typography>
          <Grid container spacing={2} justifyContent="left" sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                label="MPN"
                value={mpnSearch}
                disabled={
                  Boolean(fccSearch) ||
                  Boolean(selectedBrand) ||
                  Boolean(selectedCategory) ||
                  Boolean(selectedStandard) ||
                  Boolean(selectedStandardRevision)
                }
                onChange={(e) => setMpnSearch(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                label="FCC ID"
                value={fccSearch}
                disabled={
                  Boolean(mpnSearch) ||
                  Boolean(selectedBrand) ||
                  Boolean(selectedCategory) ||
                  Boolean(selectedStandard) ||
                  Boolean(selectedStandardRevision)
                }
                onChange={(e) => setFccSearch(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} justifyContent="left" sx={{ mb: 2 }}>
            {/* Brand Filter with Search */}
            <Grid item xs={12} sm={6} md={3}>
              <Autocomplete
                value={selectedBrand}
                onChange={(_, newValue) => {
                  setSelectedBrand(newValue);
                  setMpnSearch("");
                  setFccSearch("");
                }}
                isOptionEqualToValue={(option, value) => option === value}
                disabled={Boolean(mpnSearch) || Boolean(fccSearch)}
                options={brands.map((option) => option.name)}
                renderInput={(params) => (
                  <TextField {...params} label="Brand" />
                )}
                fullWidth
              />
            </Grid>

            {/* Category Filter with search */}
            <Grid item xs={12} sm={6} md={3}>
              <Autocomplete
                value={selectedCategory}
                onChange={(_, newValue) => {
                  setSelectedCategory(newValue);
                  setMpnSearch("");
                  setFccSearch("");
                }}
                isOptionEqualToValue={(option, value) => option === value}
                disabled={Boolean(mpnSearch) || Boolean(fccSearch)}
                options={categories.map((option) => option.title)}
                renderInput={(params) => (
                  <TextField {...params} label="Category" />
                )}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              {/* Standard Filter */}
              <FormControl fullWidth sx={{ minWidth: 120, maxWidth: 300 }}>
                <InputLabel>Standard</InputLabel>
                <Select
                  value={selectedStandard}
                  label="Standard"
                  onChange={(e) => {
                    setSelectedStandard(e.target.value);
                    setMpnSearch("");
                    setFccSearch("");
                  }}
                  disabled={Boolean(mpnSearch) || Boolean(fccSearch)}
                >
                  {standards.map((standard) => (
                    <MenuItem key={standard.standard} value={standard.standard}>
                      {standard.standard}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              {/* Standard Revision -- filtered based on selected Standard */}
              <FormControl fullWidth sx={{ minWidth: 120, maxWidth: 300 }}>
                <InputLabel>Standard Revision</InputLabel>
                <Select
                  value={selectedStandardRevision}
                  label="Standard Revision"
                  onChange={(e) => setSelectedStandardRevision(e.target.value)}
                  disabled={!selectedStandard}
                >
                  {filteredStandardRevisions.map((revision) => (
                    <MenuItem key={revision.rev} value={revision.rev}>
                      {revision.rev}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Filter by Device Release Date */}
            <Grid item xs="auto">
              {_renderReleaseDatePicker()}
            </Grid>

            <Grid item xs={12} align="center">
              {/* Create marginBottom for xtra small devices (mobile) when buttons stack */}
              <Button
                startIcon={<SearchIcon />}
                variant="contained"
                onClick={() => fetchDevices()}
                sx={{ mr: 2, mb: { xs: 1, sm: 0 } }}
              >
                Search Devices
              </Button>
              <Button
                startIcon={<SearchOffIcon />}
                variant="outlined"
                onClick={clearSearch}
                sx={{ mr: 2, mb: { xs: 1, sm: 0 } }}
              >
                Clear Search
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>

      {/* Device Results Table & Pagination Rendering */}
      <Grid item xs={12} align="center">
        {_renderDeviceTable()}
      </Grid>
    </Grid>
  );
};

export default DeviceSearch;

import * as categoriesAPI from "./categories";
import http from "../../../services/httpService";

export const testProds = [];

export function getProds() {
  return testProds;
}

export function getProd(sku) {
  return testProds.find((sk) => sk.sku === sku);
}

export function saveProd(prod) {
  let prodInDb = testProds.find((p) => p.sku === prod.sku) || {};
  prodInDb.bigc_pid = prod.bigc_pid;
  prodInDb.sku = prod.sku;
  prodInDb.title = prod.title;
  /*
  prodInDb.category = categoriesAPI.categories.find(
    (cat) => cat.catStatic === prod.categoryId
  );
  */
  prodInDb.category.catStatic = prod.categoryId;
  prodInDb.category.catName = categoriesAPI.testCategories.find(
    (cat) => cat.catStatic === prod.categoryId
  );
  prodInDb.qtyInStock = prod.qtyInStock;
  prodInDb.bigc_price = prod.bigc_price;

  if (!prodInDb.bigc_pid) {
    prodInDb.bigc_pid = Date.now().toString();
  }

  console.log(
    `Saved catgory --> ${prodInDb.category} || saved catstatic --> ${prodInDb.category.catStatic}`
  );
  console.log(`Saved catgory name --> ${prodInDb.category.catName}`);

  testProds.push(prodInDb);

  return prodInDb;
}

//---------------------------------------------------------------------------------------------------------------
// Fetch Product
//---------------------------------------------------------------------------------------------------------------

export async function fetchAllProdData(apiEndpoint) {
  const productsFetched = await http.get(apiEndpoint);
  //console.log("productsFetched at util/prods -->");
  //console.log(productsFetched);
  return productsFetched;
}

export async function fetchProdDetail(apiEndpoint) {
  const productFetched = await http.get(apiEndpoint);
  //console.log("productFetched at util/prods -->");
  //console.log(productFetched);
  return productFetched;
}

//---------------------------------------------------------------------------------------------------------------
// Post Product
//---------------------------------------------------------------------------------------------------------------
export async function postAllProdData(apiEndpoint, postData) {
  const productsFetched = await http.post(apiEndpoint, postData);
  //console.log("productsFetched at util/prods -->");
  //console.log(productsFetched);
  return productsFetched;
}

export async function postProdDetail(apiEndpoint, postData) {
  const productFetched = await http.post(apiEndpoint, postData);
  //console.log("productFetched at util/prods -->");
  //console.log(productFetched);
  return productFetched;
}

export default {
  get_all: fetchAllProdData,
  get_prod: fetchProdDetail,
  post_all: postAllProdData,
  post_prod: postProdDetail,
};

import React from "react";
import { Link } from "react-router-dom";
import * as CONFIG from "../utils/Global-Labels-Links-Vars";
import Table from "../../common/Table";

const StaleInventoryTable = ({ prods, onSort, sortColumn }) => {
  // Create static header
  const columnsStatic = [
    {
      path: "sku",
      label: "SKU",
      content: (prod) => (
        <Link to={`/product-detail/${prod.sku}`}> {prod.sku} </Link>
      ),
    },
    {
      path: "rfwel_price",
      label: "Our Price",
      content: (prod) => CONFIG.formatCurrency(prod.rfwel_price),
    },
    {
      path: "cost",
      label: "Our Cost",
      content: (prod) => CONFIG.formatCurrency(prod.cost),
    },
    {
      path: "margin_percent",
      label: "Our Margin %",
      content: (prod) => CONFIG.formatPercentage(prod.margin_percent),
    },
    { path: "freshest_supplier", label: "Freshest Supplier" },
    { path: "supplier_last_update", label: "Supplier Last Update" },
  ];

  return (
    <Table
      columns={columnsStatic}
      sortColumn={sortColumn}
      onSort={onSort}
      data={prods}
      idProperty={"sku"}
    />
  );
};

export default StaleInventoryTable;

import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Grid, Alert, Button } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import { useAuth } from "../../context/AuthContext";

const RestrictedAccessPrompt = ({ component = "" }) => {
  const { user } = useAuth();
  return (
    <Alert
      severity="info"
      sx={{ display: "flex", alignItems: "center", justifyContent: "left" }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm>
          {!user
            ? `Please login for full access to the ${component} component.`
            : `Upgrade for full access to the ${component} component.`}
        </Grid>
        <Grid item xs={12} sm="auto">
          <Button
            startIcon={user ? <UpgradeIcon /> : <LoginIcon />}
            variant="outlined"
            size="small"
            component={RouterLink}
            to={user ? "/account" : "/login"}
          >
            {user ? "Upgrade" : "Login"}
          </Button>
        </Grid>
      </Grid>
    </Alert>
  );
};

export default RestrictedAccessPrompt;

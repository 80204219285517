import React, { useState } from "react";
import Table from "../../common/Table";
import _ from "lodash";
import * as CONFIG from "../utils/Global-Labels-Links-Vars";

const CompeDetailTable = ({ product }) => {
  const columnsStatic = [
    { path: "compe_link", label: "Seller" },
    {
      path: "price",
      label: "Price",
      content: (prod) => CONFIG.formatCurrency(prod.price),
    },
    {
      path: "sale_price",
      label: "Sale Price",
      content: (prod) => CONFIG.formatCurrency(prod.sale_price),
    },
    { path: "in_stock", label: "In Stock" },
    { path: "available_date", label: "Available Date" },
    {
      path: "matched_margin",
      label: "Matched Margin",
      content: (prod) => CONFIG.formatCurrency(prod.matched_margin),
    },
    {
      path: "matched_margin_percent",
      label: "Matched Margin %",
      content: (prod) => CONFIG.formatPercentage(prod.matched_margin_percent),
    },
    { path: "last_update", label: "Last Updated" },
  ];

  //----------------------------------------------------------------------------------------------
  // SORTING
  //----------------------------------------------------------------------------------------------
  // Sort State Hook
  const [sortCol, setSortCol] = useState({
    path: "price",
    order: "desc",
  });

  // Sort Handler
  const _handleSort = (receivedSortCol) => {
    setSortCol((prevState) => ({
      ...prevState,
      path: receivedSortCol.path,
      order: receivedSortCol.order,
    }));
  };

  const pricingSorted = _.orderBy(product, [sortCol.path], [sortCol.order]);

  //----------------------------------------------------------------------------------------------

  return (
    <Table
      columns={columnsStatic}
      sortColumn={sortCol}
      onSort={_handleSort}
      data={pricingSorted}
      idProperty={"competitor"}
    />
  );
};

export default CompeDetailTable;

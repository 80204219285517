import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Table from "../common/Table";
import { Button } from "@mui/material";
import { useWorksheet } from "../../context/WorksheetContext";

const DeviceSearchResultsTable = ({ devs, onSort, sortColumn }) => {
  const { worksheet, addToWorksheet } = useWorksheet();
  const devicesInWorksheet = worksheet.devices; // Access the 'devices' array from the worksheet object
  const deviceSource = `Dev Search`;

  // Create static header
  // To sort a column just add path variable below corresponding to the data attribute name
  const columnsStatic = [
    {
      path: "mpn",
      label: "MPN",
      content: (dev) => (
        <RouterLink to={`/devices/device-detail/${dev.devid}`}>
          {" "}
          {dev.mpn}{" "}
        </RouterLink>
      ),
    },
    { path: "title", label: "Title" },
    { path: "brand_name", label: "Brand" },
    { path: "category_name", label: "Category" },
    {
      path: "standards",
      label: "Standards",
      content: (dev) => dev.standards.join(", "),
    },
    {
      key: "action",
      content: (dev) => {
        // Check if the device is already in the worksheet
        const deviceInWorksheet = devicesInWorksheet.some(
          (d) => d.devid === dev.devid
        );

        return deviceInWorksheet ? (
          <Button
            size="small"
            style={{ backgroundColor: "green", color: "white" }}
            disabled
          >
            Added to Worksheet
          </Button>
        ) : (
          <Button
            variant="outlined"
            size="small"
            onClick={() => addToWorksheet(dev, "devices", deviceSource)}
          >
            Add to Worksheet
          </Button>
        );
      },
    },
  ];

  return (
    <Table
      columns={columnsStatic}
      sortColumn={sortColumn}
      onSort={onSort}
      data={devs}
      idProperty={"devid"}
    />
  );
};

export default DeviceSearchResultsTable;

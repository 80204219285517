import React from "react";
import { Link } from "react-router-dom";
import Table from "../../common/Table";
import { buildChannelLinks } from "../utils/buildChannelLink";
import * as CONFIG from "../utils/Global-Labels-Links-Vars";

const ActiveNotInStockTable = ({ prods, onSort, sortColumn }) => {
  // Create static header
  // To sort a column just add path variable below corresponding to the data attribute name
  const columnsStatic = [
    {
      path: "sku",
      label: "SKU",
      content: (prod) => (
        <Link to={`/product-detail/${prod.sku}`}> {prod.sku} </Link>
      ),
    },
    {
      path: "channel",
      label: "Channel",
      content: (prod) => buildChannelLinks(prod.channel, prod.channel_id),
    },
    {
      path: "margin_percent",
      label: "Margin %",
      content: (prod) => CONFIG.formatPercentage(prod.margin_percent),
    },
    { path: "chanstat", label: "Status" },
    { path: "qty_channel", label: "Quantity" },
    { path: "handling", label: "Handling Time/Availability" },
    { path: "qty_qbo", label: "QBO Qty" },
    { path: "supp_stock", label: "Supplier w/ Stock" },
  ];

  return (
    <Table
      columns={columnsStatic}
      sortColumn={sortColumn}
      onSort={onSort}
      data={prods}
      idProperty={"sku"}
      id2Property={"channel"}
    />
  );
};

export default ActiveNotInStockTable;

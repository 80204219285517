import React from "react";
import { Typography, Container, Paper, Link } from "@mui/material";

const AboutPatanalAi = () => {
  return (
    <Container maxWidth="md" className="link-no-decoration">
      <Paper
        style={{
          padding: "20px",
          marginTop: "20px",
          backgroundColor: "#f9f9f9",
        }}
      >
        <Typography variant="h5" gutterBottom>
          About Us
        </Typography>
        <Typography paragraph>
          Patent Analytics, Inc is training AI models on U.S. wireless
          technology patents, NPL, wireless standards, wireless devices, and
          components for SEP & non-SEP patent analysis (non-SEP currently only
          antennas).
        </Typography>

        <Typography paragraph>
          The Patents AI tool provides a chat interface and UI tools to query
          these models for wireless patent analysis tasks.
        </Typography>
        <Typography paragraph>
          <Link href="https://www.patentanalytics.com/aboutus" target="_blank">
            Learn More
          </Link>
        </Typography>
      </Paper>
    </Container>
  );
};

export default AboutPatanalAi;

import React, { useEffect, useState } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Alert,
  Button,
  Grid,
  Divider,
  Link as MuiLink,
} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faLink,
  faCompassDrafting,
  faSignalStream,
  faGlobeAmericas,
  faFlag,
  faGavel,
} from "@fortawesome/pro-solid-svg-icons";
import SetDocumentMeta from "../common/SetDocumentMeta";
import parse from "html-react-parser";
import * as CONFIG from "./utils/kthGlobalLabelsVars";

const apiUrlKth = `${window._env_.REACT_APP_PATANALAI_API_URL}`;

const ParsedContent = ({ html }) => (
  <span
    style={{ color: "inherit", fontFamily: "inherit", fontSize: "inherit" }}
  >
    {parse(html)}
  </span>
);

const SpectrumDetail = () => {
  const { slug } = useParams();
  const [band, setBand] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // @TODO - make dynamic based on the standard
  const pageTitle = "Spectrum Detail | Telecom Law Help";
  const pageMetaDescription = "";
  const pageMetaKeywords = "";

  SetDocumentMeta({
    title: pageTitle,
    description: pageMetaDescription,
    keywords: pageMetaKeywords,
  });

  useEffect(() => {
    const fetchBandDetail = async () => {
      try {
        const response = await axios.get(
          `${apiUrlKth}/kth-telecom/${slug}/spectrum-detail`
        );
        setBand(response.data);
      } catch (err) {
        setError("Failed to fetch spectrum details.");
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBandDetail();
  }, [slug]);

  if (isLoading)
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  if (error)
    return (
      <Container>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Alert severity="error">{error}</Alert>
        </Grid>
        <Button
          startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
          color="secondary"
          variant="outlined"
          component={RouterLink}
          to={"/spectrum-licensing"}
          sx={{ mb: 2, mt: 2, textTransform: "none" }}
        >
          Back to Spectrum Search
        </Button>
      </Container>
    );

  return (
    <Container className="link-no-decoration">
      <Grid container spacing={3} padding={3}>
        <Grid item xs={12}>
          <Button
            startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            color="secondary"
            variant="outlined"
            component={RouterLink}
            to={"/spectrum-licensing"}
            sx={{ mt: 2, mb: 2, textTransform: "none" }}
          >
            Back to Spectrum Search
          </Button>
          {/* Band Details */}
          <Typography variant="h4" gutterBottom>
            {band.band}
          </Typography>
          {band.band_name || band.frequency ? (
            <Typography variant="h5" gutterBottom>
              {band.band_name} {band.frequency && `(${band.frequency})`}
            </Typography>
          ) : null}

          <Box mt={2}>
            <Grid
              container
              spacing={2}
              alignItems="flex-start"
              direction={{ xs: "column", sm: "column", md: "row" }} // Stack on small screens, row layout on large screens
            >
              {/* Allocated Use */}
              {band.allocated_use && band.allocated_use.length > 0 && (
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="h6"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <FontAwesomeIcon
                      icon={faSignalStream}
                      style={{ marginRight: 8 }}
                    />
                    Allocated Use
                  </Typography>
                  <Typography variant="body2">
                    {band.allocated_use.join(", ")}
                  </Typography>
                </Grid>
              )}

              {/* Radio Service */}
              {band.radio_service_name && band.radio_service_slug && (
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="h6"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <FontAwesomeIcon
                      icon={faGavel}
                      style={{ marginRight: 8 }}
                    />
                    Radio Service
                  </Typography>
                  <MuiLink
                    href={`/wireless-legal-compliance/${band.radio_service_slug}`}
                    variant="body2"
                  >
                    {band.radio_service_name}
                  </MuiLink>
                </Grid>
              )}

              {/* Used in Regions */}
              {band.regions && band.regions.length > 0 && (
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="h6"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <FontAwesomeIcon
                      icon={faGlobeAmericas}
                      style={{ marginRight: 8 }}
                    />
                    Used in Regions
                  </Typography>
                  <Typography variant="body2">
                    {band.regions.join(", ")}
                  </Typography>
                </Grid>
              )}

              {/* Used in Countries */}
              {band.countries && band.countries.length > 0 && (
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="h6"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <FontAwesomeIcon icon={faFlag} style={{ marginRight: 8 }} />
                    Used in Countries
                  </Typography>
                  <Typography variant="body2">
                    {band.countries.join(", ")}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>

        {/* Licensing Details */}
        {band.licensing_details.length > 0 && (
          <Grid item xs={12}>
            <Divider sx={{ my: 3 }} />
            <Typography variant="h5" gutterBottom>
              Spectrum Licensing Details
            </Typography>
            <TableContainer sx={{ mb: 4 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    {band.licensing_details.map((license, index) => (
                      <TableCell key={index}>
                        {license.band_portion || "Subpart"}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    [
                      "license_type",
                      "license_eligibility",
                      "license_eligibility_restrictions",
                      "license_authority",
                      "sharing_model",
                      "frequency_coordination_method",
                      "frequency_coordination_details",
                      "application_details",
                      "permitted_uses_restrictions_stds",
                      "geographic_applicability",
                    ]
                      .map((field) => {
                        // Check if any column in the current row has data
                        const hasData = band.licensing_details.some((license) =>
                          license[field]?.trim()
                        );

                        // Skip rendering the row entirely if all columns are empty
                        if (!hasData) {
                          return null;
                        }

                        return (
                          <TableRow key={field}>
                            <TableCell>{field.replace(/_/g, " ")}</TableCell>
                            {band.licensing_details.map((license, colIndex) => (
                              <TableCell key={colIndex}>
                                {license[field]?.trim() ? (
                                  // ? parse(license[field].trim())
                                  <ParsedContent html={license[field].trim()} />
                                ) : (
                                  "No Data"
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        );
                      })
                      .filter(Boolean) /* Remove null rows */
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}

        <Grid item xs={12}>
          <Divider sx={{ my: 3 }} />
          <Typography variant="h5">Spectrum Technical Details</Typography>
          <Typography variant="body1">
            Rfwel Engineering's Wireless Telecom Research group has provided
            technical details on the {band.band} band.
          </Typography>

          <Typography variant="body1" sx={{ ml: 3, mt: 2 }}>
            <FontAwesomeIcon
              icon={faCompassDrafting}
              style={{ marginRight: 8 }}
            />
            <a
              href={`${CONFIG.RFW_WDI_BASE_URL}/wireless-spectrum/${band.slug}`}
              target="_blank"
            >
              Explore Technical Details
            </a>{" "}
            (external link)
          </Typography>
        </Grid>

        {/* Refs Section */}
        {band.references.length > 0 && (
          <Grid item xs={12}>
            <Divider sx={{ my: 3 }} />
            <Typography variant="h5" sx={{ mt: 3, mb: 1 }}>
              References
            </Typography>
            {band.references.map((ref, index) => (
              <Box
                key={index}
                sx={{
                  backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#e8e8e8",
                  p: 2,
                  borderRadius: 1,
                  mb: 2,
                  boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
                }}
              >
                {ref.ref_discussion_title && (
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", color: "#333", mb: 1 }}
                  >
                    {ref.ref_discussion_title}
                  </Typography>
                )}
                {ref.ref_discussion && (
                  <Box color="textSecondary" sx={{ ml: 2, mb: 1 }}>
                    {parse(ref.ref_discussion)}
                  </Box>
                )}
                {ref.ref_link_label && ref.ref_link && (
                  <Typography variant="body1" sx={{ ml: 2 }}>
                    <FontAwesomeIcon
                      icon={faLink}
                      style={{ marginRight: 8, color: "#007bff" }}
                    />
                    <a
                      href={ref.ref_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: "none",
                        color: "#007bff",

                        "&:hover": { color: "#0056b3" },
                      }}
                    >
                      {ref.ref_link_label}
                    </a>
                  </Typography>
                )}
              </Box>
            ))}
          </Grid>
        )}

        {/* Related spectrum */}
        {band.related_bands?.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ mt: 4 }}>
              Related Spectrum
            </Typography>
            <Box>
              {band.related_bands.map((related, index) => (
                <Button
                  key={index}
                  component={RouterLink}
                  to={`/wireless-spectrum/${related.related_band_slug}`}
                  sx={{ mr: 2, mt: 1 }}
                >
                  {related.related_band_name}
                </Button>
              ))}
            </Box>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default SpectrumDetail;

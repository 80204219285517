import { getCookie } from "./httpService";
import jwtDecode from "jwt-decode";
import axios from "../services/axiosInstance";
import { csrftoken } from "../components/common/csrftoken";

const tokenKey = "access_token";

//----------------------------------------------------------------------------------------------------
// Login
//----------------------------------------------------------------------------------------------------
const login = async (username, password) => {
  // Include CSRF token in the request headers
  const headers = {
    "X-CSRFToken": csrftoken,
  };

  try {
    const response = await axios.post(
      `/auth/jwt/create/`,
      { username, password },
      { headers }
    );
    // Get the access token from the JSON object
    const accessJwt = response.data.access;

    // Store the access token in a secure HttpOnly cookie
    document.cookie = `${tokenKey}=${accessJwt}; Secure; SameSite=Strict`;

    // Decode the token to get user details
    const decodedJwt = jwtDecode(accessJwt);

    // Return successful login indication
    return { success: true, user: decodedJwt, message: "Login successful" };
  } catch (error) {
    console.error("Login failed:", error);
    // Return error to be handled in component
    return { success: false, message: error.message };
  }
};

//----------------------------------------------------------------------------------------------------
// Logout
//----------------------------------------------------------------------------------------------------
export function logout() {
  // Remove the access token from the cookie
  document.cookie = `${tokenKey}=; Max-Age=0; SameSite=Strict`;
}

//----------------------------------------------------------------------------------------------------
// Get user
//----------------------------------------------------------------------------------------------------
export function getCurrentUser() {
  try {
    // Read the access token from the cookie
    const accessJwt = getCookie(tokenKey);
    if (!accessJwt) {
      return null;
    }
    const decodedJwt = jwtDecode(accessJwt);
    if (Date.now() >= decodedJwt.exp * 1000) {
      return null;
    }
    return decodedJwt;
  } catch (ex) {
    console.error("Error decoding token:", ex);
    return null;
  }
}

// export function getJwt() {
//   // Read the access token from the cookie
//   return getCookie(tokenKey);
// }

//----------------------------------------------------------------------------------------------------
// Default Export
//----------------------------------------------------------------------------------------------------
// export a default object with login and logout
// this way it can be imported as <import anyobjectname from "authService"> and you can use funcions as anyobjectname.login(...)
// An alternative way would be to do: import * as anyobjectname from "authService" which would allow us to do the same anyobjectname.login(...)
export default {
  login,
  logout,
  getCurrentUser,
  // getJwt,
};

import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import axios from "axios";
import {
  Container,
  TextField,
  Select,
  MenuItem,
  Typography,
  Card,
  CardContent,
  Box,
  Button,
  Pagination,
} from "@mui/material";

const apiUrlKth = `${window._env_.REACT_APP_PATANALAI_API_URL}`;

// Set ITEMS_PER_PAGE based on environment
const environment = window._env_.REACT_APP_ENVIRONMENT || "production";
const ITEMS_PER_PAGE = environment === "production" ? 10 : 2;

const AiDefinitionList = () => {
  const [definitions, setDefinitions] = useState([]);
  const [filteredDefinitions, setFilteredDefinitions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sourceType, setSourceType] = useState("");
  const [sourceJurisdiction, setSourceJurisdiction] = useState("");
  const [sourceTypeOptions, setSourceTypeOptions] = useState([]);
  const [sourceJurisdictionOptions, setSourceJurisdictionOptions] = useState(
    []
  );
  const [selectedAlphabet, setSelectedAlphabet] = useState("");
  const [page, setPage] = useState(1);

  useEffect(() => {
    fetchFilters();
    fetchDefinitions(); // Initial fetch
  }, []);

  const fetchFilters = async () => {
    try {
      const response = await axios.get(
        `${apiUrlKth}/kth-ai/definition-filters`
      );
      setSourceTypeOptions(response.data.source_types || []);
      setSourceJurisdictionOptions(response.data.source_jurisdictions || []);
    } catch (error) {
      console.error("Error fetching filter options:", error);
    }
  };

  const fetchDefinitions = async () => {
    try {
      const response = await axios.get(`${apiUrlKth}/kth-ai/definitions`);
      setDefinitions(response.data || []);
      setFilteredDefinitions(response.data || []); // Set filtered definitions initially to all
    } catch (error) {
      console.error("Error fetching definitions:", error);
    }
  };

  // Helper function to filter and paginate definitions
  const applyFilters = () => {
    let filtered = definitions;

    // Apply search filter
    if (searchTerm) {
      filtered = filtered.filter((definition) =>
        definition.term.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Apply source type filter
    if (sourceType) {
      filtered = filtered.filter(
        (definition) => definition.source_type === sourceType
      );
    }

    // Apply source jurisdiction filter
    if (sourceJurisdiction) {
      filtered = filtered.filter(
        (definition) => definition.source_jurisdiction === sourceJurisdiction
      );
    }

    // Apply alphabet filter
    if (selectedAlphabet) {
      filtered = filtered.filter((definition) =>
        definition.term.startsWith(selectedAlphabet)
      );
    }

    setFilteredDefinitions(filtered);
    setPage(1); // Reset to first page after applying filters
  };

  const handlePageChange = (event, value) => setPage(value);
  const handleApplyFilters = () => applyFilters();
  const handleClearFilters = () => {
    setSearchTerm("");
    setSourceType("");
    setSourceJurisdiction("");
    setSelectedAlphabet("");
    setFilteredDefinitions(definitions);
    setPage(1); // Reset to first page
  };

  // Calculate items for the current page
  const startIndex = (page - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const paginatedDefinitions = filteredDefinitions.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filteredDefinitions.length / ITEMS_PER_PAGE);

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        AI Legal Definitions
      </Typography>
      <Box mb={2}>
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Box>
      <Box mb={2} display="flex" justifyContent="space-between">
        <Select
          value={sourceType}
          onChange={(e) => setSourceType(e.target.value)}
          displayEmpty
        >
          <MenuItem value="">All Legal Meaning Sources</MenuItem>
          {sourceTypeOptions.map((type, index) => (
            <MenuItem key={index} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
        <Select
          value={sourceJurisdiction}
          onChange={(e) => setSourceJurisdiction(e.target.value)}
          displayEmpty
        >
          <MenuItem value="">All Legal Meaning Jurisdictions</MenuItem>
          {sourceJurisdictionOptions.map((jurisdiction, index) => (
            <MenuItem key={index} value={jurisdiction}>
              {jurisdiction}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box mb={2} display="flex" justifyContent="space-between">
        <Button
          variant="contained"
          color="primary"
          onClick={handleApplyFilters}
        >
          Apply Filter
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleClearFilters}
        >
          Clear Filters
        </Button>
      </Box>
      <Box mb={2} display="flex" justifyContent="center" flexWrap="wrap">
        {"ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("").map((letter) => (
          <Button
            key={letter}
            onClick={() => setSelectedAlphabet(letter)}
            variant={selectedAlphabet === letter ? "contained" : "text"}
            sx={{ marginX: 0.5, marginY: 0.5 }}
          >
            {letter}
          </Button>
        ))}
      </Box>
      {paginatedDefinitions.length > 0 ? (
        paginatedDefinitions.map((definition) => (
          <Card key={definition.slug} sx={{ marginBottom: 2 }}>
            <CardContent>
              <Typography variant="h5">{definition.term}</Typography>
              <Typography variant="body2">{definition.term_blurb}</Typography>
              <Button
                component={RouterLink}
                to={`/ai-legal-definitions/${definition.slug}`}
                sx={{ mt: 1 }}
              >
                View Detail
              </Button>
            </CardContent>
          </Card>
        ))
      ) : (
        <Typography variant="body1" sx={{ mt: 2 }}>
          No definitions found.
        </Typography>
      )}
      <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        sx={{ mt: 3 }}
      />
    </Container>
  );
};

export default AiDefinitionList;

import _ from "lodash";
import * as CONFIG from "../utils/Global-Labels-Links-Vars";

//**********************************************************************************************************************************/
// Calculate Margin Per Channel
//**********************************************************************************************************************************/
export function getChannelMargin(channel, price, cost) {
  let feePercent = 0;
  let freeShippingEstimate = 0;
  let otherCosts = 0;
  switch (channel) {
    case CONFIG.BIGC:
      //For BigC PayPal fee ~ 3% & Add about $9.05 for free shipping
      feePercent = 0.03;
      // For BigC free shipping only above threshold (currently $60)
      if (price >= CONFIG.FREE_SHIPPING_THRESHOLD) {
        freeShippingEstimate = 9.05;
      } else {
        freeShippingEstimate = 0;
      }
      otherCosts = 0;
      break;
    case CONFIG.AMZ_SELLER_US:
    case CONFIG.AMZ_SELLER_US_B2B:
    case CONFIG.AMZ_FBA_US:
    case CONFIG.AMZ_FBA_US_B2B:
      // See --> https://sell.amazon.com/pricing#referral-fees
      // Amazon referral fees: cellphone devices/Consumer electronics = 8%; electronic accessories 15% for first $100 then 8%; industrial & scientific=12%; all else=15%
      // Conservatively use 15% worst case fee plus about ~$10 for free shipping
      feePercent = 0; // Put fee in other costs below
      freeShippingEstimate = 9.05;
      if (price > 100) {
        // For price > 100, Fee = 15% up to $100 and 8% for portion above $100
        otherCosts = 15 + (price - 100) * 0.08;
      } else {
        // For price < 100, Fee = 15%
        otherCosts = price * 0.15;
      }
      // ------- FOR FBA ---------
      // (1) Make freeShippingEstimate as $1 to account for cost of pro-rated per-unit cost of shipping to FBA
      // (2) Add an FBA fee estimate. Use size tier "large standard" at 18" x 14" x 8" < 1.5lb (see https://sell.amazon.com/pricing?ref_=asus_soa_rd&#fulfillment-fees)
      if (channel === CONFIG.AMZ_FBA_US || channel === CONFIG.AMZ_FBA_US_B2B) {
        freeShippingEstimate = 1; // cost to ship to FBA
        otherCosts += 5.4; // FBA fee estimate for size tier "large standard"
      }
      break;
    case CONFIG.AMZ_SELLER_CA:
      // This conversion is now handled in serializer - remove from here
      /*
      if (price) {
        price = price * CONFIG.FOREX_CAD_TO_USD;
      }
      */
      // Conservatively use 15% for fee to Canada (see above for US fees)
      feePercent = 0.15;
      freeShippingEstimate = 0; // No free shipping to Canada
      otherCosts = 9.5; // Prepaid customs fees
      break;
    case CONFIG.EBAY:
      // See -> https://www.ebay.com/help/selling/fees-credits-invoices/selling-fees?id=4822
      // eBay fees for most categories -> Final value fee % + $0.30 per order
      // Final value fees -> 13.25% on total amount of the sale up to $7,500 calculated per item; 2.35% on the portion of the sale over $7,500
      // For now just use 13.25% plus about ~$10 for free shipping
      feePercent = 0.1325;
      freeShippingEstimate = 9.05;
      otherCosts = 0.3; // should be per order but this is applied per item (margin calculation conservatively assumes single item orders)
      break;
    case CONFIG.WMT:
      // See --> https://marketplace.walmart.com/referral-fees/
      // For Electronics Accessories -> 15% up to $100 and 8% for portion above $100
      // For now conservatively use 15% for full range
      feePercent = 0; // Put fee in other costs below
      freeShippingEstimate = 9.05;
      if (price > 100) {
        // For price > 100, Fee = 15% up to $100 and 8% for portion above $100
        otherCosts = 15 + (price - 100) * 0.08;
      } else {
        // For price < 100, Fee = 15%
        otherCosts = price * 0.15;
      }
      break;
    case CONFIG.NE:
      // See --> https://sellerportal.newegg.com/reports/commissionrate
      // Consumer Electronics/Home Improvement/Computer Hardware = 10%, Industrial Supplies = 12%
      // Conservatively use 12% (HVAC Controls under Industrial Supplies)
      feePercent = 0.12;
      freeShippingEstimate = 9.05;
      otherCosts = 0;
      break;
    case CONFIG.NE_BIZ:
      // TBD -> Match with Newegg for now
      feePercent = 0.12;
      freeShippingEstimate = 9.05;
      otherCosts = 0;
      break;
  }

  let marginObj;

  if (price && cost) {
    // For Canada channels, price already convereted from CAD to USD above
    const fee = price * feePercent;
    const totalCost = cost + fee + freeShippingEstimate + otherCosts;
    const grossMargin = (price - totalCost).toFixed(2);
    const grossMarginPercent = ((grossMargin / price) * 100).toFixed(2);
    marginObj = {
      margin: Number(grossMargin),
      margin_percent: parseFloat(grossMarginPercent),
    };
  } else {
    //return "-unavailable-";
    return null;
  }
  return marginObj;
}

//**********************************************************************************************************************************/
// Calculate Margin Per Channel
//**********************************************************************************************************************************/
export function getBigcPrice(product) {
  let bigcPrice;
  // Get BigC default and sale price
  const bigcSalePrice = _.get(product, "prices_for_product.price_sale_bigc");
  const bigcDefaultPrice = _.get(product, "prices_for_product.price_bigc");
  bigcPrice = bigcSalePrice > 0.9 ? bigcSalePrice : bigcDefaultPrice;

  // Get BigC MOQ
  const bigcMoq = _.get(product, "inventory_for_product.moq_bigc");

  if (bigcPrice) {
    bigcPrice = parseFloat(bigcPrice);
  }

  if (bigcPrice && bigcMoq > 1) {
    bigcPrice = bigcPrice * bigcMoq;
  }

  if (bigcPrice) return Number(bigcPrice.toFixed(2));
  return null;
}

//**********************************************************************************************************************************/
export default {
  get: getChannelMargin,
};

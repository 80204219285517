import React from "react";
import { Link } from "react-router-dom";
import Table from "../../common/Table";

const LpimProductSearchResultsTable = ({ prods, onSort, sortColumn }) => {
  // Create static header
  // To sort a column just add path variable below corresponding to the data attribute name
  const columnsStatic = [
    {
      path: "sku",
      label: "SKU",
      content: (prod) => (
        <Link to={`/product-detail/${prod.sku}`}> {prod.sku} </Link>
      ),
    },
    { path: "title", label: "Title" },
    { path: "brand_name", label: "Brand" },
    { path: "category_name", label: "Category" },
  ];

  return (
    <Table
      columns={columnsStatic}
      sortColumn={sortColumn}
      onSort={onSort}
      data={prods}
      idProperty={"sku"}
    />
  );
};

export default LpimProductSearchResultsTable;

import React from "react";
import { List, ListItemButton, ListItemText, Typography } from "@mui/material";
import { stripClaimNumber } from "./utils/patentUtils";

const PatentClaimsList = ({
  claimsList,
  selectedClaim,
  handleClaimSelect,
  removeClaimNumber = false,
}) => {
  // This regex only targets spaces at the start and end of the string but not tabs
  const trimSpacesOnly = (str) => {
    return str.replace(/^ +| +$/g, "");
  };

  // Tis regex removes newlines from windows or mac
  const trimNewLines = (str) => {
    return str.replace(/\r\n|\n|\r/g, " ");
  };

  const formatClaimText = (cl) => {
    let claim = removeClaimNumber ? stripClaimNumber(cl) : cl;

    claim = trimNewLines(claim);

    // Split the claim into parts based on colons and semicolons, capturing the punctuation
    // const parts = claim.split(/(:|; and|; or|;)(?=\s+)/);
    let parts = claim.split(/(:|; and|; or|;)/);

    // let DEBUGParts = parts.join("-->\n");
    // console.log(`parts=${DEBUGParts}`);

    let formattedParts = [];
    let indentLevel = 0;
    let addIndent = false;

    parts.forEach((part, index) => {
      if (part === ":") {
        formattedParts.push(part);
        addIndent = true;
        indentLevel++;
      } else if (part === "; and" || part === "; or" || part === ";") {
        // This part is punctuation, append it and prepare to indent next part
        {
          formattedParts.push(part);
          addIndent = true;
        }
      } else {
        // This is a text part
        if (addIndent) {
          // Add indent to this part
          formattedParts.push(
            "\n\n" + "   ".repeat(indentLevel + 1) + part.trim()
          );

          // addIndent = false; // Reset indent flag
        } else {
          // Normal text without new indent
          formattedParts.push(part);
        }
      }

      // Reset indent level if a semicolon followed by and or or
      if (part === "; and" && parts[index + 2] === ";") {
        indentLevel = Math.max(indentLevel - 1, 0); // Decrease indent after list ends
      }
    });

    return formattedParts.join("");
  };

  return (
    <List>
      {claimsList.map((claim, index) => {
        // if (index !== 28) return "skip for now";
        const regex = / claim \d+/i; // Regex to check for dependent claims
        const isDependentClaim = regex.test(claim);
        const formattedClaim = formatClaimText(claim); // Format claim text

        return (
          <ListItemButton
            key={index}
            onClick={() => handleClaimSelect(stripClaimNumber(claim), index)}
            sx={{
              bgcolor:
                selectedClaim === index
                  ? "action.selected"
                  : "background.paper",
              "&:hover": { bgcolor: "action.hover" },
              pl: isDependentClaim ? 4 : 2, // Indent dependent claims more
            }}
          >
            {/* If remove claim number, remove from list (above) and add as heading */}
            <ListItemText
              primary={removeClaimNumber ? `Claim ${index + 1}` : ""}
              primaryTypographyProps={{
                fontWeight: isDependentClaim ? "normal" : "bold",
              }}
              secondary={
                <span style={{ whiteSpace: "pre-wrap" }}>{formattedClaim}</span>
              }
              secondaryTypographyProps={{
                fontSize: isDependentClaim ? "0.875rem" : "1rem",
                color: isDependentClaim ? "text.secondary" : "text.primary",
                // whiteSpace: "pre-line", // Respect line breaks
              }}
            />

            {selectedClaim === index && (
              <Typography
                sx={{
                  color: "white",
                  backgroundColor: "green",
                  borderRadius: "4px",
                  p: 0.5,
                  display: "inline-block",
                  ml: 2,
                  fontSize: "0.8rem",
                }}
              >
                Applied
              </Typography>
            )}
          </ListItemButton>
        );
      })}
    </List>
  );
};

export default PatentClaimsList;

import React from "react";
import * as CONFIG from "../lpim/utils/Global-Labels-Links-Vars";

const StatusIcon = ({ status, scope, listed = true }) => {
  let faClass = "";
  let btnClass = "";
  let btnLabel = "";
  switch (status) {
    case CONFIG.MARGIN_LOW_STR:
      faClass = "fa-solid fa-arrow-down";
      btnClass = "btn btn-danger btn-sm";
      btnLabel = "Margin LOW";
      break;
    case CONFIG.ON_CLEARANCE_STR:
      faClass = "fa-solid fa-check";
      btnClass = "btn btn-success btn-sm";
      btnLabel = "Clearance";
      break;
    case CONFIG.MARGIN_HIGH_STR:
      faClass = "fa-solid fa-arrow-up";
      btnClass = "btn btn-info btn-sm";
      btnLabel = "Margin HIGH";
      break;
    case CONFIG.MARGIN_OK_STR:
      faClass = "fa-solid fa-check";
      btnClass = "btn btn-success btn-sm";
      btnLabel = "Margin OK";
      break;
    case CONFIG.UNK_STR:
      faClass = "fa-solid fa-triangle-exclamation";
      btnClass = "btn btn-danger btn-sm";
      btnLabel = "Margin UNKOWN";
      break;
  }

  const faIcon = listed ? <i className={faClass}></i> : "";
  const bootstrapIcon = listed ? (
    <button className={btnClass}>{btnLabel}</button>
  ) : (
    "--not listed--"
  );

  return scope === "detail" ? bootstrapIcon : faIcon;
};

export default StatusIcon;

import Raven from "raven-js";

function init() {
  //@TODO --> sign up for sentry.io loggin account and add configuration here: https://sentry.io/pricing/
  Raven.config("url-provided-when-sign-up", {
    release: "1-0-0",
    environment: "development-test",
  }).install();
}

function log(error) {
  Raven.captureException(error);
}

export default {
  init,
  log,
};

import React from "react";
import { Container, Typography, Paper, Link } from "@mui/material";

const TermsOfService = () => {
  return (
    <Container maxWidth="md" className="link-no-decoration">
      <Paper
        style={{
          padding: "20px",
          marginTop: "20px",
          backgroundColor: "#f9f9f9",
        }}
      >
        <Typography variant="h4" gutterBottom align="center">
          Terms of Service
        </Typography>
        <Typography variant="h6">Introduction</Typography>
        <Typography paragraph>
          By accessing or using Patent Analytics, Inc websites and webtools
          (collectively "websites"), including, without limitation patanal.ai
          and patentanalytics.com, you agree to be bound by these Terms of
          Service and all terms incorporated by reference. If you do not agree
          to all of these terms, do not use these websites.
        </Typography>
        <Typography variant="h6">Use of Services</Typography>
        <Typography paragraph>
          Our websites provide patent analytics information. The services are
          available for your use subject to the terms outlined herein.
        </Typography>
        <Typography variant="h6">Disclaimer of Warranties</Typography>
        <Typography paragraph>
          THE INFORMATION CONTAINED IN THESE WEBSITES IS PROVIDED TO YOU "AS
          IS," FOR YOUR INTERNAL INFORMATIONAL PURPOSES ONLY, WITHOUT ANY
          REPRESENTATION OR WARRANTY OF ACCURACY OR COMPLETENESS OF INFORMATION
          OR OTHER WARRANTY OF ANY KIND, INCLUDING ANY IMPLIED WARRANTY OF
          QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
          NON-INFRINGEMENT. IN NO EVENT WILL PATENT ANALYTICS, INC BE LIABLE TO
          ANY PARTY FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR
          CONSEQUENTIAL DAMAGES FOR USE OF THESE WEBSITES OR RELIANCE UPON ANY
          INFORMATION OR MATERIAL ACCESSED VIA IT OR ANY OTHER HYPERLINKED
          WEBSITE INCLUDING, BUT NOT LIMITED TO, DAMAGES ARISING FROM LOSS OF
          PROFITS, BUSINESS INTERRUPTION, OR LOSS OF DATA, EVEN IF PATENT
          ANALYTICS, INC IS EXPRESSLY ADVISED ABOUT THE POSSIBILITY OF SUCH
          DAMAGES, TO THE FULLEST EXTENT ALLOWABLE BY LAW.
        </Typography>
        <Typography variant="h6">Disclaimer of Legal Opinion</Typography>
        <Typography paragraph>
          Any information and search results shown on the websites are NOT A
          LEGAL OPINION. We recommend you to talk to your attorneys before
          taking any decision. Several U.S.-based law firms have lawyers
          experienced in analyzing wireless technology patents including, for
          example,{" "}
          <Link href="https://www.kthlaw.com/patents" target="_blank">
            Kama Thuo PLLC
          </Link>
          , and{" "}
          <Link
            href="https://www.perkinscoie.com/en/practices/intellectual-property-law/index.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Perkins Coie LLP
          </Link>
          , or you can consult your local bar or U.S.P.T.O lawyer referral
          service.
        </Typography>
        <Typography variant="h6">External Links Disclaimer</Typography>
        <Typography paragraph>
          Our websites may contain links to external websites that are not
          provided or maintained by or in any way affiliated with Patent
          Analytics, Inc. These links are provided solely for your convenience
          to provide further information. They do not signify that we endorse
          the website(s) or the content within them. Patent Analytics, Inc. does
          not guarantee the accuracy, relevance, timeliness, or completeness of
          any information on these external websites. Please be cautious and
          check the terms and privacy policies of any external sites you
          interact with.
        </Typography>
        <Typography variant="h6">
          Submitting Information to Patent Analytics, Inc.
        </Typography>
        <Typography paragraph>
          For details on what types of information are collected and how they
          are handled, please refer to our{" "}
          <Link href="/privacy-policy">Privacy Policy</Link>.
        </Typography>
        <Typography paragraph>
          WE DO NOT STORE OR LOG YOUR PATENT OR DEVICE SEARCH QUERIES OR SEARCH
          HISTORY ON OUR SERVERS.
        </Typography>
        <Typography paragraph>
          Your search queries are sent to the search server over a secure link
          and are used solely for locating and retrieving search results. No
          records or logs of the search queries are kept once the search results
          are computed and returned.
        </Typography>
        <Typography paragraph>
          When you submit other information to Patent Analytics, Inc. through
          our websites, unless covered by a separate written agreement of
          confidentiality signed by both parties, any such information provided
          will be deemed NOT TO BE CONFIDENTIAL. Furthermore, by submitting any
          unsolicited ideas or materials, including but not limited to ideas on
          improving or enhancing our analytics tools and AI/ML models, you agree
          that your submissions will automatically become the property of Patent
          Analytics, Inc., without any compensation to you. Patent Analytics,
          Inc. may use or redistribute the submissions and their contents for
          any purpose and in any way.
        </Typography>

        <Typography variant="h6">Limitations of Data</Typography>
        <Typography paragraph>
          The patent search and device search features are limited to the
          patents, non-patent literature (NPL), and device information available
          in our database and data providers' databases at the time of
          searching. We cannot guarantee the comprehensiveness of these
          databases and you may not always find the most relevant results due to
          the limitation of the databases, articulation of the queries, and the
          search algorithm.
        </Typography>
        <Typography variant="h6">Accuracy of Data</Typography>
        <Typography paragraph>
          Users are advised to cross-check the text and metadata of the search
          results we provide with other data sources. The accuracy of the text
          and metadata cannot be guaranteed because we rely on 3rd party data
          sources to get these details. Some metadata, such as patent assignee
          information, can update at any time and the latest information may not
          reflect in the search results. Preferably, the details of patent
          results should be verified from the official patent office websites
          and, for NPL, the details should be verified from the original
          publishers' websites.
        </Typography>
        <Typography variant="h6">Intellectual Property</Typography>
        <Typography paragraph>
          All content on our websites, including but not limited to text,
          graphics, logos, button icons, images, audio clips, digital downloads,
          and software, is the property of Patent Analytics, Inc. or its content
          suppliers and protected by United States and international copyright
          laws.
        </Typography>
        <Typography variant="h6">Governing Law</Typography>
        <Typography paragraph>
          These terms and any disputes arising out of or related to the services
          shall be governed by, construed, and enforced in accordance with, the
          laws of the State of Arizona.
        </Typography>
        <Typography
          sx={{ fontStyle: "italic", color: "gray", fontSize: "0.875rem" }}
        >
          This Terms of Service was last revised on April 13, 2024.
        </Typography>
      </Paper>
    </Container>
  );
};

export default TermsOfService;

import axios from "axios";

// axios.defaults.baseURL = window._env_.REACT_APP_API_URL;
// const instance = axios.create();
// // instance.defaults.timeout = 5000;

//Modify to conditionally set the base URL only when needed.
const instance = axios.create({
  baseURL: window._env_.REACT_APP_API_URL, // Only set this if it's needed for specific cases
});

export default instance;

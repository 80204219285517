import React from "react";
import {
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const GenaiResults = ({ result }) => {
  if (!result || !result.results) {
    return null;
  }

  return (
    <Box mb={4}>
      <Paper elevation={3} sx={{ p: 2, mb: 2, bgcolor: "#f0f0f0" }}>
        <Typography variant="h6">Submitted Prompt</Typography>
        <Typography variant="body1">{result.prompt}</Typography>
      </Paper>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Typography variant="h6">Search Results</Typography>
        {result.results.length > 0 ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Device ID</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Brand</TableCell>
                  <TableCell>Title</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {result.results.map((device) => (
                  <TableRow key={device.devid}>
                    <TableCell>{device.devid}</TableCell>
                    <TableCell>{device.category}</TableCell>
                    <TableCell>{device.brand}</TableCell>
                    <TableCell>{device.title}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body1">No results found.</Typography>
        )}
      </Paper>
    </Box>
  );
};

export default GenaiResults;

import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import LoginForm from "../LoginForm";
import Logout from "../Logout";
import NotFound from "../NotFound";
import PartsFinderHome from "./PartsFinderHome";
import PartsFinder from "./PartsFinder";
import ProductSearch from "./ProductSearch";
import ProductDetailDashboard from "./ProductDetailDashboard";
import ContactUsForm from "../common/ContactUsForm";
import RequireAuth from "../common/RequireAuth";

const PartsFinderRoutes = ({ user = "", baseConfig }) => {
  let routes = useRoutes([
    {
      path: "/",
      element: user ? <PartsFinderHome /> : <Navigate to="/login" />,
    },
    {
      path: "/login",
      element: <LoginForm termsLink="#" linkTarget="_self" />,
    },
    { path: "/logout", element: <Logout /> },
    { path: "/not-found", element: <NotFound /> },
    {
      path: "/contact",
      element: <ContactUsForm baseConfig={baseConfig} />,
    },
    //--------- Authenticated Paths --------------------------------------------------------
    //---- DEVICES -----
    {
      path: "/product-search",
      element: (
        <RequireAuth>
          <ProductSearch />
        </RequireAuth>
      ),
    },
    {
      path: "/product-detail",
      element: (
        <RequireAuth>
          <ProductDetailDashboard />
        </RequireAuth>
      ),
    },
    {
      path: "/product-detail/:sku",
      element: (
        <RequireAuth>
          <ProductDetailDashboard />
        </RequireAuth>
      ),
    },
    {
      path: "/find-parts",
      element: (
        <RequireAuth>
          <PartsFinder />
        </RequireAuth>
      ),
    },
    //--------- Catch-all for undefined routes --------------------------------------------------------
    {
      path: "*",
      element: <NotFound />, // This will display a 404 message for undefined paths
    },
  ]);
  return routes;
};

export default PartsFinderRoutes;

// validationSchemas.js
import * as Yup from "yup";

// ASIN validation schema
export const asinSchema = Yup.string()
  .matches(
    /^[A-Za-z0-9]{10}$/,
    "ASIN must be exactly 10 alphanumeric characters"
  )
  .required("ASIN is required");

// Patent validation schema
export const patentSchema = Yup.string()
  .matches(
    /^(US\d{7,8}[B][12]|D\d{7}|RE\d{5})$/,
    "Invalid Patent Number format"
  )
  .required("Patent Number is required");

import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  Collapse,
  Divider,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faChevronUp,
  faChevronDown,
  faArrowUpRightFromSquare,
} from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@mui/material/styles";

// Now NavbarSaas is a functional component
const NavbarSaas = ({
  menuItems,
  user,
  handleMenuClick,
  openSubmenus,
  isChild = false,
}) => {
  const theme = useTheme();

  // Styles for main menu items
  const mainItemStyle = {
    color: "white",
    "&:hover": {
      backgroundColor: isChild ? "transparent" : "#88BBD6",
      color: "white",
    },
    "& .MuiListItemIcon-root": {
      minWidth: "40px",
    },
  };

  // Styles for child menu items
  const childItemStyle = {
    color: "white",
    "&:hover": {
      // Apply the hover style to the secondary text
      "& .MuiListItemText-secondary": {
        color: "#88BBD6", // Change the text color on hover
      },
    },
    paddingLeft: theme.spacing(7),
  };

  const childItemText = {
    "& .MuiListItemText-secondary": {
      // Target the secondary text directly
      color: "white", // Ensure the text is white
      // fontSize: "0.75rem", // Adjust the font size if needed
    },
  };

  // Function to recursively create menu items from the config
  const renderMenuItems = (items, isNested = false) => {
    return items
      .map((item, index) => {
        // Use the index for generating keys
        if (item.authRequired && !user) {
          return null;
        }

        // Determine the icon for the item if it exists
        const listItemIcon =
          item.iconFa && !isNested ? (
            <ListItemIcon>
              <FontAwesomeIcon icon={item.iconFa} style={{ color: "white" }} />
            </ListItemIcon>
          ) : !isNested ? (
            <ListItemIcon>
              <FontAwesomeIcon
                icon={faArrowUpRightFromSquare}
                style={{ color: "white" }}
              />
            </ListItemIcon>
          ) : null;

        if (item.children && item.children.length > 0) {
          // Handle nested items - return array
          const keyBase = `item-${index}`; // Construct a base for your keys
          return [
            <ListItem
              // button
              onClick={() =>
                handleMenuClick(
                  item.title,
                  item.children && item.children.length > 0 ? true : false
                )
              }
              sx={mainItemStyle}
              key={`${keyBase}-button`}
            >
              {/* Selectively render list item icon specified in Navbar config */}
              {listItemIcon}
              <ListItemText primary={item.title} />
              {openSubmenus[item.title] ? (
                <FontAwesomeIcon
                  icon={faChevronUp}
                  style={{ color: "white" }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faChevronDown}
                  style={{ color: "white" }}
                />
              )}
            </ListItem>,
            <Divider key={`${keyBase}-divider`} />,
            <Collapse
              in={openSubmenus[item.title]}
              timeout="auto"
              unmountOnExit
              key={`${keyBase}-collapse`}
            >
              <List component="div" disablePadding>
                {renderMenuItems(item.children, true)}
              </List>
            </Collapse>,
          ];
        } else {
          // Handle top-level items without children
          const handleItemClick = () => {
            // If the drawer should open when a menu item is clicked,
            // this function needs to call handleMenuClick even for top-level items
            handleMenuClick(item.title);
          };
          // Check if the path is 'external' for navigating to an external URL
          if (item.path === "external") {
            return (
              <ListItem
                key={item.title}
                sx={mainItemStyle}
                component="a" // Use anchor tag for external links
                href={item.externalUrl}
                target="_blank" // Open in a new tab
                rel="noopener noreferrer"
              >
                {listItemIcon}
                <ListItemText primary={item.title} />
              </ListItem>
            );
          } else {
            // Render a simple list item for internal navigation
            return (
              <ListItem
                key={item.title}
                component={RouterLink}
                to={item.path}
                sx={isNested ? childItemStyle : mainItemStyle}
                onClick={handleItemClick}
              >
                {listItemIcon}
                {/* <ListItemText primary={item.title} /> */}
                {isNested ? (
                  <ListItemText secondary={item.title} sx={childItemText} />
                ) : (
                  <ListItemText primary={item.title} />
                )}
              </ListItem>
            );
          }
        }
      })
      .flat(); // Flatten the array to ensure there are no nested arrays;
  };

  return <>{renderMenuItems(menuItems, isChild)}</>;
};

export default NavbarSaas;

import React from "react";
import { Paper, Typography, Grid } from "@mui/material";

const QuickSightDashboards = () => {
  const dashboards = [
    {
      id: "8b5898b8-bf85-42b9-9c3e-bd47b124420d",
      src: "https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/796113841058/dashboards/8b5898b8-bf85-42b9-9c3e-bd47b124420d?directory_alias=null",
    },
    {
      id: "2437345f-a0e3-46cd-ad65-e8d114d150ee",
      src: "https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/796113841058/dashboards/2437345f-a0e3-46cd-ad65-e8d114d150ee?directory_alias=null",
    },
  ];

  return (
    <Grid container spacing={4}>
      {dashboards.map((dashboard) => (
        <Grid item xs={12} key={dashboard.id}>
          <Paper elevation={2}>
            <Typography variant="h6" style={{ padding: "20px" }}>
              QuickSight Dashboard {dashboard.id}
            </Typography>
            <iframe
              src={dashboard.src}
              width="960"
              height="720"
              frameBorder="0"
              allowFullScreen
              title={`Dashboard ${dashboard.id}`}
              style={{ width: "100%", height: "100%", minHeight: "720px" }}
            ></iframe>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default QuickSightDashboards;

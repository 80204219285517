import React, { useState, useEffect } from "react";
import Table from "../../common/Table";
import _ from "lodash";
import * as CONFIG from "../utils/Global-Labels-Links-Vars";

const InventoryDetailTable = ({ product }) => {
  const columnsStatic = [
    { path: "supplier_link", label: "Supplier" },
    { path: "supplier_sku", label: "Supplier SKU" },
    {
      path: "cost",
      label: "Cost",
      content: (prod) => CONFIG.formatCurrency(prod.cost),
    },
    { path: "stock_status", label: "Stock Status" },
    { path: "qty", label: "Qty" },
    { path: "leadtime", label: "Leadtime Days" },
    { path: "available_date", label: "Available Date" },
    { path: "last_update", label: "Last Updated" },
  ];

  //----------------------------------------------------------------------------------------------
  // SORTING
  //----------------------------------------------------------------------------------------------
  // Sort State Hook
  const [sortCol, setSortCol] = useState({
    path: "cost",
    order: "asc",
  });

  // Sort Handler
  const _handleSort = (receivedSortCol) => {
    setSortCol((prevState) => ({
      ...prevState,
      path: receivedSortCol.path,
      order: receivedSortCol.order,
    }));
  };

  const pricingSorted = _.orderBy(product, [sortCol.path], [sortCol.order]);

  //----------------------------------------------------------------------------------------------

  return (
    <Table
      columns={columnsStatic}
      sortColumn={sortCol}
      onSort={_handleSort}
      data={pricingSorted}
      idProperty={"supplier"}
    />
  );
};

export default InventoryDetailTable;

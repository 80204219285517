import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Typography,
  Grid,
  Box,
  Paper,
  IconButton,
  Tooltip,
} from "@mui/material";
import Alert from "@mui/material/Alert";

import CircularProgress from "@mui/material/CircularProgress";
import http from "../../services/httpService";
import Pagination from "../common/Pagination";
import _ from "lodash";
import * as CONFIG from "./utils/partsFinderGlobalLabelsVars";
import ProductSearchResultsTable from "./ProductSearchResultsTable";
import SearchBox from "../common/SearchBox";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";

const baseURL = `${window._env_.REACT_APP_LPIM_API_URL}/lpim_api/products`;

const ProductSearch = () => {
  // Set DEFAULTS
  const DEFAULT_PAGE_SIZE = 3;

  // State for Product Filters
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);

  // State for user selections
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [mpnSearch, setMpnSearch] = useState("");
  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0); //
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [initialSearchPerformed, setInitialSearchPerformed] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [sortCol, setSortCol] = useState({
    path: "mpn",
    order: "asc",
  });
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchOptions = async () => {
      const headers = {
        Authorization: `JWT ${http.getCookie("access_token")}`,
      };
      try {
        const [brandsRes, categoriesRes] = await Promise.all([
          axios.get(`${baseURL}/brands`, { headers }),
          axios.get(`${baseURL}/categories`, { headers }),
        ]);
        setBrands(brandsRes.data);
        setCategories(categoriesRes.data);
      } catch (error) {
        console.error("Failed to fetch options", error);
      }
    };

    fetchOptions();
  }, []);

  const fetchProducts = async (pageNumber = 1) => {
    setIsLoading(true);
    setInitialSearchPerformed(true);

    const headers = {
      Authorization: `JWT ${http.getCookie("access_token")}`,
    };
    const query = new URLSearchParams({
      page_size: pageSize,
      page: pageNumber,
    });

    if (mpnSearch) query.set("mpn", mpnSearch);
    else {
      if (selectedBrand) query.set("brand", selectedBrand);
      if (selectedCategory) query.set("category", selectedCategory);
    }

    try {
      const response = await axios.get(`${baseURL}?${query.toString()}`, {
        headers,
      });
      setProducts(response.data.results);
      setTotalResults(response.data.count);
      setTotalPages(Math.ceil(response.data.count / pageSize));
      setCurrentPage(pageNumber);
    } catch (error) {
      console.error("Failed to fetch products", error);
      setProducts([]);
    } finally {
      setIsLoading(false);
    }
  };

  const clearSearch = () => {
    // Reset all search-related states
    setSelectedBrand("");
    setSelectedCategory("");
    setMpnSearch("");
    setProducts([]);
    setCurrentPage(1);
    setInitialSearchPerformed(false);
    // Note: You might not want to reset pageSize to allow users to keep their preferred setting
  };

  const _handlePageChange = (page) => {
    //console.log(`Page Clicked = ${page}`);
    setCurrentPage(page);
    // No need to call fetchProducts here as it will be called by the effect hook when currentPage changes
  };

  // When page size is changed, we should reset back to the first page and fetch products.
  const _handlePageSizeChange = (event) => {
    const newPageSize = event.target.value;
    setPageSize(newPageSize);
    setCurrentPage(1); // Reset to the first page
    // No need to call fetchProducts here as it will be called by the effect hook when currentPage changes
  };

  // This effect is used to fetch products on page change or when page size changes,
  // but only after an initial search has been performed.
  useEffect(() => {
    if (initialSearchPerformed) {
      fetchProducts(currentPage);
    }
  }, [currentPage, pageSize]);

  const _handleSearchChange = (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const _handleSort = (receivedSortCol) => {
    setSortCol((prevState) => ({
      ...prevState,
      path: receivedSortCol.path,
      order: receivedSortCol.order,
    }));
  };
  //----------- search/ sort ------

  let productsFiltered;

  if (searchQuery) {
    // if there is a search query then filter based on search query
    productsFiltered = products.filter((prod) =>
      prod.mpn.toLowerCase().startsWith(searchQuery.toLowerCase())
    );
  } else {
    productsFiltered = products;
  }

  // sort products
  // sortCol.path and sortCol.order are in array because we can sort by multiple columns using lodash's orderBy util
  const productsSorted = _.orderBy(
    productsFiltered,
    [sortCol.path],
    [sortCol.order]
  );

  // get number of products and desctructure to rename to productCount
  const { length: productCount } = products;

  //-------------------------------------------------------------------------------
  // Conditional Render of Product Results Table
  //-------------------------------------------------------------------------------
  const _renderProductTable = () => {
    if (isLoading) return <CircularProgress size={24} />;
    if (!initialSearchPerformed)
      return (
        <div style={{ marginTop: "10px" }}>
          {CONFIG.RFW_HORIZDOT}
          <Alert severity="info">Search by MPN, Brand, Category</Alert>
        </div>
      );

    if (!productCount || productCount === 0)
      return (
        <div style={{ marginTop: "10px" }}>
          {CONFIG.RFW_HORIZDOT}
          <Alert severity="warning">
            No products in the database match the search criteria.
          </Alert>
        </div>
      );
    return (
      <>
        <div>{CONFIG.RFW_HORIZDOT}</div>
        <Grid container alignItems="center" sx={{ mb: 2 }}>
          {/* Pagination Info and Page Size Selection */}
          {_renderPaginationInfo()}

          <Grid item xs={12} align="center">
            <SearchBox
              searchValue={searchQuery}
              searchOnChange={(e) => _handleSearchChange(e.currentTarget.value)}
            />
          </Grid>

          <Grid item xs={12} align="center">
            <ProductSearchResultsTable
              prods={productsSorted}
              sortColumn={sortCol}
              onSort={_handleSort}
            />
          </Grid>

          <Grid item xs={12} align="center">
            {totalPages > 1 && (
              <Pagination
                itemsCount={totalResults} // This is total products and not products returned for current page
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={_handlePageChange}
              />
            )}
          </Grid>
        </Grid>
      </>
    );
  }; // end const _renderProductTable = () =>

  //-------------------------------------------------------------------------------
  // Render Pagination Controls
  //-------------------------------------------------------------------------------
  const _renderPaginationInfo = () => (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      sx={{ mb: 2 }}
    >
      <Grid item>
        <Typography variant="subtitle1">
          Total results: {totalResults}
        </Typography>
      </Grid>
      <Grid item>
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle1" sx={{ marginRight: 2 }}>
            Items per page:
          </Typography>
          <FormControl size="small" sx={{ minWidth: 80 }}>
            <Select
              id="page-size-select"
              value={pageSize}
              onChange={_handlePageSizeChange}
              displayEmpty
            >
              {[3, 5, 10, 20].map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );

  //-------------------------------------------------------------------------------
  // Render Full Page w/ Results Table
  //-------------------------------------------------------------------------------
  return (
    <Grid container spacing={1}>
      {/* Search Filters Container */}
      <Container maxWidth="lg">
        <Paper
          elevation={3}
          sx={{ p: 3, margin: "auto", maxWidth: "90%", mt: 2 }}
        >
          <Typography variant="h6" align="center" sx={{ mb: 2 }}>
            Product Search
          </Typography>
          <Grid container spacing={2} justifyContent="left" sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                label="MPN"
                value={mpnSearch}
                disabled={Boolean(selectedBrand) || Boolean(selectedCategory)}
                onChange={(e) => setMpnSearch(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} justifyContent="left" sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6} md={3}>
              {/* Brand Filter */}
              <FormControl fullWidth sx={{ minWidth: 120, maxWidth: 300 }}>
                <InputLabel>Brand</InputLabel>
                <Select
                  value={selectedBrand}
                  label="Brand"
                  onChange={(e) => {
                    setSelectedBrand(e.target.value);
                    setMpnSearch("");
                  }}
                  disabled={Boolean(mpnSearch)}
                >
                  {brands.map((brand) => (
                    <MenuItem key={brand.name} value={brand.name}>
                      {brand.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              {/* Category Filter */}
              <FormControl fullWidth sx={{ minWidth: 120, maxWidth: 300 }}>
                <InputLabel>Category</InputLabel>
                <Select
                  value={selectedCategory}
                  label="Category"
                  onChange={(e) => {
                    setSelectedCategory(e.target.value);
                    setMpnSearch("");
                  }}
                  disabled={Boolean(mpnSearch)}
                >
                  {categories.map((category) => (
                    <MenuItem key={category.title} value={category.title}>
                      {category.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} align="center">
              {/* Create marginBottom for xtra small products (mobile) when buttons stack */}
              <Button
                startIcon={<SearchIcon />}
                variant="contained"
                onClick={() => fetchProducts()}
                sx={{ mr: 2, mb: { xs: 1, sm: 0 } }}
              >
                Search Products
              </Button>
              <Button
                startIcon={<SearchOffIcon />}
                variant="outlined"
                onClick={clearSearch}
                sx={{ mr: 2, mb: { xs: 1, sm: 0 } }}
              >
                Clear Search
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>

      {/* Product Results Table & Pagination Rendering */}
      <Grid item xs={12} align="center">
        {_renderProductTable()}
      </Grid>
    </Grid>
  );
};

export default ProductSearch;

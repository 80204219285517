import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Table from "../common/Table";
import { Button } from "@mui/material";
import { useWorksheet } from "../../context/WorksheetContext";

const PatentReviewTable = ({ patents, onSort, sortColumn }) => {
  // Create static header
  // To sort a column just add path variable below corresponding to the data attribute name
  const columnsStatic = [
    {
      path: "patent_id",
      label: "Patent ID",
      content: (pat) => (
        <RouterLink to={`/patents/patent-search/${pat.patent_id}`}>
          {" "}
          {pat.patent_id}{" "}
        </RouterLink>
      ),
    },
    { path: "title", label: "Title" },
    { path: "publication_date", label: "Publication Date" },
    { path: "reviewer", label: "Reviewer" },
    { path: "score", label: "Score" },
    { path: "notes", label: "Notes" },
    {
      key: "action",
      content: (pat) => {
        return (
          <Button
            size="small"
            style={{ backgroundColor: "green", color: "white" }}
            disabled
          >
            Add Notes
          </Button>
        );
      },
    },
  ];

  return (
    <Table
      columns={columnsStatic}
      sortColumn={sortColumn}
      onSort={onSort}
      data={patents}
      idProperty={"patent_id"}
    />
  );
};

export default PatentReviewTable;

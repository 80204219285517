import React, { Component, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  Box,
  Typography,
  TextField,
  FormHelperText,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Collapse,
  Alert,
} from "@mui/material";
import prods from "../utils/prods";

import Pagination from "../../common/Pagination";
import { paginate } from "../utils/paginate";
import ListGroup from "../../common/ListGroup";
import SearchBox from "../../common/SearchBox";
import _ from "lodash";
import * as CONFIG from "../utils/Global-Labels-Links-Vars";
import LoadingSpinner from "../../common/LoadingSpinner";
import {
  /*buildCompeProducts,*/
  getFilteredCompe,
} from "../utils/buildChannelProducts";
import CompeAnalTable from "./CompeAnalTable";

const CompeAnal = () => {
  //-------------------------------------------------------------------------------
  // SET DEFAULTS
  //-------------------------------------------------------------------------------
  const DEFAULT_PAGE_SIZE = 10;
  const DEFAULT_SKUS_TO_GET = 200;
  const MAX_SKUS_TO_GET = 500;

  // Show SKU values
  const SHOW_SKUS_ALL = "SHOW_ALL";
  const SHOW_SKUS_ADJUSTABLE = "SHOW_ADJUSTABLE";
  const SHOW_SKUS_UNADJUSTABLE = "SHOW_UNADJUSTABLE";

  // Show competitor values
  const SHOW_COMPE_ALL = "SHOW_ALL";
  const SHOW_COMPE_STOCKED = "SHOW_STOCKED";
  const SHOW_COMPE_PRIMARY = "SHOW_PRIMARY";

  // Price Locks
  const HIDE_NONE = "HIDE_NONE";
  const HIDE_PRICELOCKED = "HIDE_PRICELOCKED";

  // On-Sale Match
  const DO_NOT_MATCH_ON_SALE = "DO_NOT_MATCH_ON_SALE";
  const MATCH_ON_SALE = "MATCH_ON_SALE";

  //-------------------------------------------------------------------------------
  // HOOKS
  //-------------------------------------------------------------------------------

  const navigate = useNavigate();
  const [showSKU, setShowSku] = useState(SHOW_SKUS_ADJUSTABLE);
  const [skusToGet, setSkusToGet] = useState(DEFAULT_SKUS_TO_GET);
  const [priceLockedHide, setPriceLockedHide] = useState(HIDE_PRICELOCKED);
  const [matchOnSale, setMatchOnSale] = useState(DO_NOT_MATCH_ON_SALE);
  const [marginThreshold, setMarginThreshold] = useState(CONFIG.MARGIN_LOW);

  const [showCompetitors, setShowCompetitors] = useState(SHOW_COMPE_STOCKED);
  const [products, setProducts] = useState([]);
  /*const [compeProducts, setCompeProducts] = useState([]);*/
  const [filteredCompeProducts, setFilteredCompeProducts] = useState([]);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(1);
  const [categories, setCategories] = useState([{ title: "All Categories" }]);
  const [selectedCategory, setSelectedCategory] = useState({
    title: "All Categories",
  });
  const [sortCol, setSortCol] = useState({
    path: "price",
    order: "desc",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  //-------------------------------------------------------------
  const numProds = products.length;

  //-------------------------------------------------------------
  const _handleShowSKUChange = (e) => {
    setShowSku(e.target.value);
  };

  //-------------------------------------------------------------
  const _handleSkusToGetChange = (e) => {
    let selectedSkusToGet = e.target.value;
    if (selectedSkusToGet > MAX_SKUS_TO_GET) {
      selectedSkusToGet = MAX_SKUS_TO_GET;
    }

    setSkusToGet(selectedSkusToGet);
  };

  //-------------------------------------------------------------
  const _handlePriceLockedHideChange = (e) => {
    setPriceLockedHide(e.target.value);
  };

  //-------------------------------------------------------------
  const _handleOnSaleMatchChange = (e) => {
    setMatchOnSale(e.target.value);
  };

  //-------------------------------------------------------------
  const _handleMarginThresholdChange = (e) => {
    setMarginThreshold(e.target.value);
  };

  //-------------------------------------------------------------
  const _handleShowCompetitorsChange = (e) => {
    setShowCompetitors(e.target.value);
  };

  // Determine compe stock status we are getting
  const compeStockQuery =
    showCompetitors === SHOW_COMPE_STOCKED
      ? "in-stock"
      : showCompetitors === SHOW_COMPE_PRIMARY
      ? "pri-compe"
      : "all-compe";

  //-------------------------------------------------------------
  const _handleGetCompeDataButtonClicked = () => {
    // Set IsLoading to true and disable Get Margin Data button to prevent multiple API calls
    // Will only fetch if there are no products loaded so setIsLoading(true) only then
    if (numProds === 0) {
      setIsLoading(true);
    }
    const fetchProds = async () => {
      const productsFetched = await prods.get_all(
        `/patanal_api/products-competitor?compe_stock=${compeStockQuery}`
      );
      setProducts(productsFetched);

      // Hide Loading screen when fetch complete
      setIsLoading(false);
    };
    // Fetch Prods only if Prods is not included in state
    if (numProds === 0) {
      fetchProds();
    }
  };

  //-------------------------------------------------------------
  const _handleRefreshProducts = async () => {
    setIsLoading(true);
    const productsFetched = await prods.get_all(
      `/patanal_api/products-competitor?compe_stock=${compeStockQuery}`
    );
    setProducts(productsFetched);
    setIsLoading(false);
  };

  //-------------------------------------------------------------------------------
  // EFFECTS
  //-------------------------------------------------------------------------------

  useEffect(
    // Update filtered channel products whenever filters change
    () => {
      // filter compeProds to include only the items that meet search criteria
      const filteredCompeProds = getFilteredCompe({
        products,
        marginThreshold,
        showSKU,
        showCompetitors,
        skusToGet,
        priceLockedHide,
        matchOnSale,
      });

      setFilteredCompeProducts(filteredCompeProds);

      //Set categories based on filtered products
      const filterCategories = filteredCompeProds.map((cat) => {
        let catProperties = {
          title: cat.category,
        };
        return catProperties;
      });

      // Now set categories using setCategories hook using title.
      setCategories([{ title: "All Categories" }, ...filterCategories]);

      // Also select to select all categories when filter changes
      setSelectedCategory({
        title: "All Categories",
      });
    },
    [
      products,
      marginThreshold,
      showSKU,
      showCompetitors,
      skusToGet,
      priceLockedHide,
      matchOnSale,
    ]
  );

  useEffect(() => {
    // Only refresh if not currently loading some other fetch
    if (!isLoading && numProds !== 0) {
      _handleRefreshProducts();
    }
  }, [showCompetitors]); // This will run when one of the dependencies changes

  //-------------------------------------------------------------
  const _handlePageChange = (page) => {
    //console.log(`Page Clicked = ${page}`);
    setCurrentPage(page);
  };
  //-------------------------------------------------------------
  const _handleCategorySelect = (category) => {
    //console.log(category);
    setSelectedCategory(category);
    // Note cannot set search query to null because this is a controlled component. If set to null/undefined react will think this is an uncontrolled component
    // and will give warning when user types in typed that trying to covert an uncontrolled component to a controlled component.
    setSearchQuery("");
    setCurrentPage(1);

    // Reset current page to 1 otherwise if select a category with multiple pages then go back to a category with fewer pages, will display blank
    setCurrentPage(1);
  };
  //-------------------------------------------------------------
  const _handleSort = (receivedSortCol) => {
    setSortCol((prevState) => ({
      ...prevState,
      path: receivedSortCol.path,
      order: receivedSortCol.order,
    }));
  };
  //-------------------------------------------------------------
  const _handleSearchChange = (query) => {
    setSearchQuery(query);
    setSelectedCategory("");
    setCurrentPage(1);
  };
  //-------------------------------------------------------------------------------
  // COMPONENT OBJECTS
  //-------------------------------------------------------------------------------
  // use destructuring to get length of products (number of items and rename to prodCount)
  // the below equivalent to const prodCount = products.length.
  const { length: prodCount } = filteredCompeProducts;

  // filter products based on selected category (or search query) before pagination
  // && used to cover all categories
  // start with filtered products as all products
  let productsFiltered = filteredCompeProducts;

  if (searchQuery) {
    // if there is a search query then fitler based on search query
    productsFiltered = filteredCompeProducts.filter((p) =>
      p.sku.toLowerCase().startsWith(searchQuery.toLowerCase())
    );
  } else {
    // if no search query but there's category selection then filter based on category
    productsFiltered =
      selectedCategory && selectedCategory.title !== "All Categories"
        ? filteredCompeProducts.filter(
            (p) => p.category === selectedCategory.title
          )
        : filteredCompeProducts;
  }

  // sort products
  // sortCol.path and sortCol.order are in array because we can sort by multiple columns using lodash's orderBy util
  const productsSorted = _.orderBy(
    productsFiltered,
    [sortCol.path],
    [sortCol.order]
  );

  // paginate products
  const productsPaginated = paginate(productsSorted, currentPage, pageSize);

  //-------------------------------------------------------------------------------
  // Conditional Render of Margin Anal Table
  //-------------------------------------------------------------------------------
  const _renderMarginTable = () => {
    if (isLoading) return <LoadingSpinner />;
    if (!prodCount || prodCount === 0)
      return (
        <div style={{ marginTop: "10px" }}>
          {CONFIG.RFW_HORIZDOT}
          <p> ..... No listings in the database match the search criteria.</p>
        </div>
      );
    return (
      <Grid item xs={12} align="center">
        <div>{CONFIG.RFW_HORIZDOT}</div>
        <div className="row">
          <div className="col-2">
            <ListGroup
              items={categories}
              selectedItem={selectedCategory}
              onItemSelect={_handleCategorySelect}
              textProperty="title"
              idProperty="title"
            />
          </div>
          <div className="col">
            <p>
              Showing{" "}
              <strong>
                {" "}
                {productsFiltered.length} {selectedCategory.title}{" "}
              </strong>{" "}
              products in the database matching selected criteria.
            </p>
            <SearchBox
              searchValue={searchQuery}
              searchOnChange={(e) => _handleSearchChange(e.currentTarget.value)}
            />
            <CompeAnalTable
              prods={productsPaginated}
              sortColumn={sortCol}
              onSort={_handleSort}
            />

            <Pagination
              itemsCount={productsFiltered.length}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={_handlePageChange}
            />
          </div>
        </div>
      </Grid>
    );
  };
  //-------------------------------------------------------------------------------
  //Render of Margin Anal Page
  //-------------------------------------------------------------------------------
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} align="center">
        <Typography component="h4" variant="h4">
          {"Competitor Analysis"}
        </Typography>
      </Grid>

      <Grid item xs={12} align="center">
        <Box
          sx={{
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Box sx={{ background: "#AEDFDF", borderRadius: "16px" }}>
            <Typography component="h6" variant="h6">
              {"Select Items to Show"}
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                row
                defaultValue={SHOW_SKUS_ADJUSTABLE}
                onChange={_handleShowSKUChange}
              >
                <FormControlLabel
                  value={SHOW_SKUS_ALL}
                  control={<Radio color="primary" />}
                  label="Show All"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value={SHOW_SKUS_ADJUSTABLE}
                  control={<Radio color="primary" />}
                  label="Show Price-Adjustable SKUs"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value={SHOW_SKUS_UNADJUSTABLE}
                  control={<Radio color="primary" />}
                  label="Show Cost-Contrained SKUs"
                  labelPlacement="bottom"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              background: "#B1D3E8",
              borderRadius: "16px",
              marginLeft: 2,
            }}
          >
            <Typography component="h6" variant="h6">
              {"Select Competitors to Show"}
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                row
                defaultValue={SHOW_COMPE_STOCKED}
                onChange={_handleShowCompetitorsChange}
              >
                <FormControlLabel
                  value={SHOW_COMPE_ALL}
                  control={<Radio color="primary" />}
                  label="Show All"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value={SHOW_COMPE_STOCKED}
                  control={<Radio color="primary" />}
                  label="Show Only with Stock"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value={SHOW_COMPE_PRIMARY}
                  control={<Radio color="primary" />}
                  label="Show Only Primary Competitors"
                  labelPlacement="bottom"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} align="center">
        <Box
          sx={{
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              background: "#D5F7F1",
              borderRadius: "16px",
              paddingX: 2,
              marginLeft: 2,
            }}
          >
            <Typography component="h6" variant="h6">
              {"Show/Hide Price-Locked"}
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                row
                defaultValue={HIDE_PRICELOCKED}
                onChange={_handlePriceLockedHideChange}
              >
                <FormControlLabel
                  value={HIDE_NONE}
                  control={<Radio color="primary" />}
                  label="Show All"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value={HIDE_PRICELOCKED}
                  control={<Radio color="primary" />}
                  label="Hide BigC PriceLocked"
                  labelPlacement="bottom"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box
            sx={{
              background: "#ECC4E4",
              borderRadius: "16px",
              paddingX: 2,
              marginLeft: 2,
            }}
          >
            <Typography component="h6" variant="h6">
              {"Select Min Targeted Rfwel Margin"}
            </Typography>
            <FormControl>
              <TextField
                required={true}
                type="number"
                onChange={_handleMarginThresholdChange}
                defaultValue={CONFIG.MARGIN_LOW}
                inputProps={{
                  min: 1,
                  max: 100,
                  style: { textAlign: "center" },
                }}
              />
              <FormHelperText>
                <span align="center">Min Rfwel % Margin</span>
              </FormHelperText>
            </FormControl>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} align="center">
        <Box
          sx={{
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              background: "#C2FDA4",
              borderRadius: "16px",
              paddingX: 2,
              marginLeft: 2,
            }}
          >
            <Typography component="h6" variant="h6">
              {"Match On-Sale Price"}
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                row
                defaultValue={DO_NOT_MATCH_ON_SALE}
                onChange={_handleOnSaleMatchChange}
              >
                <FormControlLabel
                  value={MATCH_ON_SALE}
                  control={<Radio color="primary" />}
                  label="Yes"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value={DO_NOT_MATCH_ON_SALE}
                  control={<Radio color="primary" />}
                  label="No"
                  labelPlacement="bottom"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box
            sx={{
              background: "#E2E8B5",
              borderRadius: "16px",
              paddingX: 2,
              marginLeft: 2,
            }}
          >
            <Typography component="h6" variant="h6">
              {"Select Max Number of SKUs to Get"}
            </Typography>
            <FormControl>
              <TextField
                required={true}
                type="number"
                onChange={_handleSkusToGetChange}
                defaultValue={DEFAULT_SKUS_TO_GET}
                inputProps={{
                  min: 1,
                  max: MAX_SKUS_TO_GET,
                  style: { textAlign: "center" },
                }}
              />
              <FormHelperText>
                <span align="center">Number of SKUs to Get</span>
              </FormHelperText>
            </FormControl>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} align="center">
        <Button
          color="primary"
          variant="contained"
          onClick={_handleGetCompeDataButtonClicked}
          disabled={isLoading || numProds !== 0}
          sx={{ ml: 2, mb: 2 }}
        >
          {" "}
          Get Competitor Data
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={_handleRefreshProducts}
          disabled={isLoading}
          sx={{ marginLeft: 2 }}
        >
          {"Refresh from DB"}
        </Button>
      </Grid>
      <Grid item xs={12} align="center">
        <Button color="secondary" variant="contained" to="/" component={Link}>
          {" "}
          Back
        </Button>
      </Grid>

      {_renderMarginTable()}
    </Grid>
  );
};

export default CompeAnal;

// @TODO
/*
--> cross-reference with competitor pricing to determine price reduction opportunity
--> Create assignments for data refresh (competitor pricing list, cost/price investigation, etc.)
*/

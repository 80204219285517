import React from "react";
import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Initialize toast
toast.configure();

const WorksheetActionButton = ({
  inWorksheet,
  worksheetData,
  worksheetType,
  addToWorksheet,
  replaceInWorksheet,
}) => {
  const handleReplace = () => {
    replaceInWorksheet(worksheetData, worksheetType);
    toast.success("Item replaced in the worksheet!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  if (inWorksheet) {
    return (
      <Button
        size="small"
        variant="outlined"
        onClick={handleReplace}
        sx={{
          backgroundColor: "green",
          color: "white",
          "&:hover": {
            backgroundColor: "white",
            color: "green",
          },
        }}
      >
        Added to Worksheet (Replace)
      </Button>
    );
  }

  return (
    <Button
      variant="outlined"
      startIcon={<FontAwesomeIcon icon={faPlus} />}
      onClick={() => addToWorksheet(worksheetData, worksheetType)}
    >
      Add to Worksheet
    </Button>
  );
};

export default WorksheetActionButton;

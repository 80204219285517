import { add } from "date-fns";
// Install react-datetime
//https://www.npmjs.com/package/react-datetime

// JS Date Objects --> https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getDate

// @TODO --> if today return time in 24hrs, else return yesterday, -> 2 days ago -> 3 days ago ... -> last week -> 2 weeks ago -> 3 weeks ago -> 1 month ago -> 2 months ago -> 3 months ago -> MAY 28
//******************************************************************************************/
// Format Time String
//******************************************************************************************/
export function formatDate(dateStr) {
  // If have no input then return null
  if (!dateStr) {
    return null;
  }

  //var options = { year: "numeric", month: "short", day: "numeric" };
  var options = { month: "short", day: "numeric" };
  return new Date(dateStr).toLocaleDateString([], options);
}

//******************************************************************************************/
// Determine if Date is in Future
//******************************************************************************************/
export function isDateFuture(dateStr) {
  // If have no input then return null
  if (!dateStr) return false;

  // Get Current date
  const currentDate = new Date();
  const dateInput = new Date(dateStr);

  if (dateInput > currentDate) return true;
  return false;
}

//******************************************************************************************/
// Add T Days to a Date
//******************************************************************************************/
// @DEPRECATED -> use add from date-fns npm package instead --> https://date-fns.org/v2.29.3/docs/add
export function addDays(dateStr, days) {
  if (!dateStr) return null;
  const laterDate = new Date(dateStr);
  laterDate.setDate(dateStr.getDate() + days);
  return laterDate;
}

//******************************************************************************************/
// Determine if Date is More than T Days Ago
//******************************************************************************************/
export function isDateTAgo(dateStr, days) {
  // If have no date input then return false
  if (!dateStr) return false;

  // Get Current date
  const currentDate = new Date();
  const dateInput = new Date(dateStr);
  // Use pure form from date-fns --> https://date-fns.org/v2.29.3/docs/add
  // const compeExpireDate = addDays(dateInput, days);
  const compeExpireDate = add(dateInput, {
    days: days,
  });

  // console.log(`currentDate = ${currentDate.toString()}`);
  // console.log(`dateInput = ${dateInput.toString()}`);
  // console.log(`days = ${days}`);
  // console.log(`compeExpireDate = ${compeExpireDate.toString()}`);

  // Write true if expires in the future
  if (compeExpireDate > currentDate) return true;
  return false;
}

//******************************************************************************************/
export default formatDate;

// var dateString = "2018-05-18T04:00:00.000Z"
// document.getElementById("results").innerHTML = formatDate(dateString);

// You could also provide specific locale, if needed. For example:
//function formatDate(string){
//    var options = { year: 'numeric', month: 'long', day: 'numeric' };
//    return new Date(string).toLocaleDateString('en-US',options);
//}

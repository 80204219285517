import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "./context/AuthContext";
import baseConfigs from "./components/baseConfigs";
import useIdleTimer from "./services/useIdleTimer";
// import ReactGA from "react-ga4"; //@TODO -> uninstall ReactGA
import { Dialog, DialogActions, DialogTitle, Button } from "@mui/material";
import { getImageUrl } from "./utils/helpers";

const isProduction = window._env_.REACT_APP_ENVIRONMENT === "production";

const App = ({ domain }) => {
  const { user } = useAuth();
  const IDLE_TIMER_MINUTES = 30; // Logout if inactive for 30 minutes
  const IDLE_TIME_WARNING_SECONDS = 30; // Logout 30 seconds after warning of inactivity pops up
  const navigate = useNavigate();
  const baseConfig = baseConfigs[domain] || {};
  const [isInactivityModalOpen, setIsInactivityModalOpen] = useState(false);
  const isInactivityModalOpenRef = useRef(isInactivityModalOpen); // Use a ref to track modal state
  const [enableIdleTimer, setEnableIdleTimer] = useState(!!user);
  const BaseComponentToRender = baseConfig.BaseComponent;

  //-------------------------------------------------------------------------
  // Track Inactivity
  //-------------------------------------------------------------------------
  useEffect(() => {
    setEnableIdleTimer(!!user); //!!user makes explicity user boolean true if user is not null (if user is truthy)
  }, [user]);

  useEffect(() => {
    isInactivityModalOpenRef.current = isInactivityModalOpen; // Update the ref whenever the state changes
  }, [isInactivityModalOpen]);

  const handleTimeout = useCallback(() => {
    if (user) {
      setIsInactivityModalOpen(true);
      setTimeout(() => {
        if (isInactivityModalOpenRef.current) {
          // Check the current value from the ref
          logoutInactiveUser();
        }
      }, IDLE_TIME_WARNING_SECONDS * 1000);
    }
  }, [user]);

  // Use idle timer only when enabled
  useIdleTimer(IDLE_TIMER_MINUTES, handleTimeout, domain, enableIdleTimer);

  const logoutInactiveUser = () => {
    setIsInactivityModalOpen(false);
    alert("You have been logged out due to inactivity.");
    navigate("/logout");
  };

  const handleStayLoggedIn = () => {
    setIsInactivityModalOpen(false);
    // The act of clicking on the modal to select keep me logged in resets inactivity timer
  };

  const logoutModalClick = () => {
    setIsInactivityModalOpen(false);
    navigate("/logout");
  };

  //-------------------------------------------------------------------------
  // Google Analytics
  //-------------------------------------------------------------------------

  // useEffect(() => {
  //   if (baseConfig.googleAnalyticsId && isProduction) {
  //     ReactGA.initialize(baseConfig.googleAnalyticsId);
  //     // ReactGA.send("pageview");
  //     ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  //   }
  // }, [baseConfig.googleAnalyticsId, isProduction]);
  // Use custom initialization instead of ReactGA so that we can use async/defer to fix LCP issue.
  // Google Analytics
  useEffect(() => {
    if (baseConfig.googleAnalyticsId && isProduction) {
      // Check if the script already exists to avoid duplicates
      if (!document.getElementById("google-analytics-script")) {
        // Create the Google Analytics script
        const script = document.createElement("script");
        script.id = "google-analytics-script";
        script.src = `https://www.googletagmanager.com/gtag/js?id=${baseConfig.googleAnalyticsId}`;
        script.async = true; // Load asynchronously
        script.defer = true; // Defer execution
        document.head.appendChild(script);

        // Initialize Google Analytics after script is loaded
        script.onload = () => {
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            window.dataLayer.push(arguments);
          }
          gtag("js", new Date());
          gtag("config", baseConfig.googleAnalyticsId, {
            page_path: window.location.pathname,
          });
        };
      }
    }
  }, [baseConfig.googleAnalyticsId, isProduction]);

  //-------------------------------------------------------------------------
  // Cookie Consent Script
  //-------------------------------------------------------------------------
  useEffect(() => {
    if (baseConfig.cookieConsentScript) {
      const script = document.createElement("script");
      script.id = "cookieyes";
      script.type = "text/javascript";
      script.src = baseConfig.cookieConsentScript;
      script.async = true; // add async/defer attribute to resolve LCP issues
      script.defer = true;
      document.body.appendChild(script);
    }
  }, [baseConfig.cookieConsentScript]);

  //-------------------------------------------------------------------------
  // Set Favicons
  //-------------------------------------------------------------------------
  useEffect(() => {
    const updateFavicon = (fileName, relAttribute) => {
      const folderPath = baseConfig.faviconFolder || "react-favicon"; // Fallback to default folder
      const fullPath = getImageUrl(`${folderPath}/${fileName}`);

      // Create a new link element
      const link = document.createElement("link");
      link.rel = relAttribute;
      link.href = fullPath;

      // Remove existing link with the same rel attribute, if any
      const existingLink = document.querySelector(
        `link[rel='${relAttribute}']`
      );
      if (existingLink) {
        document.head.removeChild(existingLink);
      }

      // Append the new link element to the head
      document.head.appendChild(link);
    };

    // Update each favicon link
    updateFavicon("favicon.ico", "shortcut icon");
    updateFavicon("favicon-32x32.png", "icon");
    updateFavicon("favicon-16x16.png", "icon");
    updateFavicon("apple-touch-icon.png", "apple-touch-icon");
  }, [baseConfig.faviconFolder]);

  //-------------------------------------------------------------------------
  // Build Page
  //-------------------------------------------------------------------------
  return (
    <>
      <ToastContainer />
      <BaseComponentToRender user={user} baseConfig={baseConfig} />
      {/* Dialog for inactivity timeout */}
      <Dialog
        open={isInactivityModalOpen}
        onClose={() => setIsInactivityModalOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Are you still there?"}</DialogTitle>
        <DialogActions>
          <Button onClick={handleStayLoggedIn} color="primary">
            Yes, Keep me logged in
          </Button>
          <Button onClick={logoutModalClick} color="secondary" autoFocus>
            No, Log me out
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default App;

import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Container, Typography, Link as MuiLink, Paper } from "@mui/material";
import SetDocumentMeta from "../common/SetDocumentMeta";

const KthDisclaimer = ({ baseConfig }) => {
  SetDocumentMeta({
    title: "Disclaimer | Kama Thuo, PLLC",
    description: "",
    keywords: "",
  });
  const { headerTextColor } = baseConfig.siteBranding;

  return (
    <Container maxWidth="lg" className="link-no-decoration">
      <Paper
        style={{
          padding: "20px",
          marginTop: "20px",
          backgroundColor: "#f9f9f9",
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          align="center"
          sx={{ color: headerTextColor }}
        >
          Legal Disclaimer
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ color: headerTextColor }}>
          Legal Notice
        </Typography>
        <Typography paragraph>
          Kama Thuo, PLLC is a law firm organized as a professional limited
          liability corporation incorporated in Arizona.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ color: headerTextColor }}>
          Copyright Notice
        </Typography>
        <Typography paragraph>
          All content on this website, including text, graphics, and design, is
          ©Kama Thuo, PLLC. You may download and print materials for personal
          reference, but any other use without prior written permission is
          strictly prohibited.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ color: headerTextColor }}>
          Disclaimer of Legal Opinion
        </Typography>
        <Typography paragraph>
          The materials available at this web site are for informational
          purposes only and not for the purpose of providing legal advice. It is
          not intended to create or promote an attorney-client relationship and
          does not constitute and should not be relied upon as legal advice. It
          is not intended to seek professional employment in any state where
          lawyers in the firm are not admitted to practice, or in any state
          where this website would not comply with applicable requirements
          concerning advertisements and solicitations.{" "}
          <MuiLink href="https://www.kthlaw.com/contact">Contact Us</MuiLink> if
          you require legal assistance.
        </Typography>
        <Typography paragraph>
          Use of and access to this web site or any of the e-mail links
          contained within the site do not create an attorney-client
          relationship between any Kama Thuo, PLLC attorney and you. The
          opinions expressed at or through this site are the opinions of the
          individual author and may not reflect the opinions of the firm or any
          individual attorney, engineer, or business professional.
        </Typography>
        <Typography paragraph>
          If you elect to communicate with the firm, or any of its
          professionals, through this website, do not transmit any information
          about any matter that may involve you until the firm has agreed to
          represent you. This agreement to represent you will be in the form of
          a written engagement letter.
        </Typography>
        <Typography paragraph>
          For more details, refer to our{" "}
          <MuiLink component={RouterLink} to="/terms-of-service">
            Terms of Service
          </MuiLink>
          .
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ color: headerTextColor }}>
          Confidentiality and Data Privacy
        </Typography>
        <Typography paragraph>
          When using this website, please avoid transmitting confidential or
          sensitive information unless we have expressly agreed to represent
          you. Information submitted through our website is subject to our{" "}
          <MuiLink component={RouterLink} to="/privacy-policy">
            Privacy Policy
          </MuiLink>
          , which explains how we collect, use, and protect your personal data.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ color: headerTextColor }}>
          External Links Disclaimer
        </Typography>
        <Typography paragraph>
          This website may contain links to third-party websites for your
          convenience. We do not control or endorse these external websites and
          are not responsible for their content, privacy policies, or practices.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ color: headerTextColor }}>
          Contact Us
        </Typography>
        <Typography paragraph>
          To request more information about Kama Thuo, PLLC, please{" "}
          <MuiLink component={RouterLink} to="/contact">
            Contact Us
          </MuiLink>
          .
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ color: headerTextColor }}>
          Advertising Statement
        </Typography>
        <Typography paragraph>
          Portions of this website may contain Attorney Advertising under the
          rules of some states. Prior results do not guarantee a similar
          outcome.
        </Typography>

        <Typography
          sx={{ fontStyle: "italic", color: "gray", fontSize: "0.875rem" }}
        >
          This Disclaimer was last revised on November 15, 2024.
        </Typography>
      </Paper>
    </Container>
  );
};

export default KthDisclaimer;

import { useEffect } from "react";
import { useAuth } from "../context/AuthContext";

const Logout = () => {
  const { logout } = useAuth();
  useEffect(() => {
    logout();
    // Redirect to homepage --> need to refresh so that navbar at home page updates so use JS redirect vs React
    window.location = "/";
  });

  return null;
};

export default Logout;

import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Container,
  Typography,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import SetDocumentMeta from "../common/SetDocumentMeta";

const KthPrivacyPolicy = () => {
  SetDocumentMeta({
    title: "Privacy Policy | Kama Thuo, PLLC",
    description: "",
    keywords: "",
  });
  const disclosureData = [
    {
      category: "Identifiers",
      recipients:
        "Cloud service providers, marketing services providers, event management software and event service providers, visitor management software providers, data analytics providers, software development providers, search firms and headhunters, recruiting software, engineering and trade schools, internet service providers, data storage providers, other users of our Services if you post content to interactive areas of those Services, background check providers, legal authorities to comply with legal obligations, and state bar associations.",
    },
    {
      category:
        "Characteristics of protected classifications under California or federal law",
      recipients:
        "Cloud service providers, event management software and event service providers, data analytics providers, software development providers, search firms and headhunters, recruiting software, engineering and trade schools, background check providers, and legal authorities to comply with legal obligations.",
    },
    {
      category: "Commercial information",
      recipients:
        "Cloud service providers, marketing services providers, event management software and event service providers, data analytics providers, internet service providers, data storage providers, and operating systems and platforms.",
    },
    {
      category: "Internet and electronic network activity information",
      recipients:
        "Cloud service providers, marketing services providers, software development providers, data analytics providers, internet service providers, data storage providers, and operating systems and platforms.",
    },
    {
      category: "Geolocation data",
      recipients:
        "Cloud service providers, marketing services providers, software development providers, data analytics providers, data storage providers, and operating systems and platforms.",
    },
    {
      category: "Audio, electronic, visual, and similar information",
      recipients:
        "Cloud service providers, data analytics providers, data storage providers, and operating systems and platforms.",
    },
    {
      category: "Professional or employment-related information",
      recipients:
        "Cloud service providers, marketing services providers, event management software and event service providers, data analytics providers, software development providers, search firms and headhunters, recruiting software, internet service providers, data storage providers, and background check providers.",
    },
  ];

  return (
    <Container maxWidth="lg" className="link-no-decoration">
      <Paper
        style={{
          padding: "20px",
          marginTop: "20px",
          backgroundColor: "#f9f9f9",
        }}
      >
        <Typography variant="h4" gutterBottom align="center">
          Privacy Policy
        </Typography>
        <Typography paragraph>
          This Privacy Policy (“Policy”) explains how Kama Thuo, PLLC. (“Kama
          Thuo”, “Kth”, “Kth Law”, “we”, or “us”) collects, stores, uses, and
          discloses personal information about you. This Policy applies to
          information we collect when you access or use our websites, mobile
          applications, and other online services (collectively, the “Services”)
          and when you visit our offices, attend our events, apply for a job
          with us, or otherwise interact with us as described below. We may
          provide separate or additional notices about our privacy practices, in
          which case this Policy will not apply.
        </Typography>
        <Typography paragraph>
          We may change this Policy from time to time. If we make changes, we
          will notify you by revising the date at the bottom of this Policy. We
          encourage you to review this Policy frequently to stay informed about
          our information practices and the choices available to you.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Contents
        </Typography>
        <ul>
          <li>
            <Link href="/privacy-policy#Collection">
              Collection of Information
            </Link>
          </li>
          <li>
            <Link href="/privacy-policy#Use">Use of Personal Information</Link>
          </li>
          <li>
            <Link href="/privacy-policy#Disclosure">
              Disclosure of Personal Information
            </Link>
          </li>
          <li>
            <Link href="/privacy-policy#choices">
              Your Privacy Rights and Choices
            </Link>
          </li>
          <li>
            <Link href="/privacy-policy#links">
              Links to Other Websites and Third-Party Content
            </Link>
          </li>
          <li>
            <Link href="/privacy-policy#data-transfers">Data Transfers</Link>
          </li>
          <li>
            <Link href="/privacy-policy#California">
              California Privacy Information
            </Link>
          </li>
          <li>
            <Link href="/privacy-policy#EEA">
              Individuals in the EEA, UK, or Switzerland
            </Link>
          </li>
          <li>
            <Link href="/privacy-policy#contact-info">Contact Information</Link>
          </li>
        </ul>

        <Typography variant="h5" gutterBottom id="Collection">
          Collection of Information
        </Typography>

        <Typography paragraph>
          Any personal information we may collect about you depends on how you
          interact with us or use our Services. We describe below the categories
          of personal information we collect and the sources of this
          information.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Information You Provide to Us
        </Typography>
        <Typography paragraph>
          We collect personal information you provide directly to us including
          when you create an account to access or use the Services, subscribe to
          receive our emails, access or use any collaboration tools, participate
          in any interactive features of the Services, send us an email, fill
          out a form, respond to a survey, comment on a blog or forum thread,
          register for or participate in a webinar or event, apply for a job
          with us, interact with us on social media, enter a promotional
          drawing, or otherwise communicate with us.
        </Typography>
        <Typography paragraph>
          The types of personal information we may collect include:
        </Typography>
        <Typography paragraph>
          <ul>
            <li>
              <Typography>
                Contact information, such as name, email address, postal
                address, and phone number;
              </Typography>
            </li>
            <li>
              <Typography>
                Education information, such as educational degrees, languages,
                professional memberships, qualifications, and certifications;
              </Typography>
            </li>
            <li>
              <Typography>
                Employment information, such as business unit/division, company
                name, job status, job title, employment history, office
                location, and LinkedIn profile information;
              </Typography>
            </li>
            <li>
              <Typography>
                Health and health-related information, such as accessibility
                requirements, dietary restrictions, and, where necessary and
                appropriate to protect the health and safety of our personnel
                and others, information related to symptoms of, or risk of
                exposure to, certain illnesses;
              </Typography>
            </li>
            <li>
              <Typography>
                Government-issued identification numbers such as a social
                security number, driver’s license number, or state-issued
                identification number in connection with your application for
                employment with us;
              </Typography>
            </li>
            <li>
              <Typography>
                Photos, videos, and audio files that contain images or voice
                recordings;
              </Typography>
            </li>
            <li>
              <Typography>
                Demographic information, which may include information about
                protected classifications, such as age, gender, race/ethnic
                origin, and nationality; and
              </Typography>
            </li>
            <li>
              <Typography>
                Any other information you choose to provide.
              </Typography>
            </li>
          </ul>
        </Typography>

        <Typography variant="h6" gutterBottom>
          Personal Information We Collect Automatically When You Interact with
          Us
        </Typography>
        <Typography paragraph>
          We automatically collect personal information when you access or use
          the Services. The types of personal information we collect may
          include:
        </Typography>
        <ul>
          <li>
            <Typography>
              <strong>Log Information:</strong> We collect log information about
              your use of the Services, including your browser type and
              language, app version, access times, pages viewed, Internet
              Protocol (“IP”) address, links clicked, approximate geographic
              location, and the webpage you visited before navigating to the
              Services.
            </Typography>
          </li>
          <li>
            <Typography>
              <strong>Device Information:</strong> We collect information about
              the mobile device you use to access our Services, which may
              include the hardware model, operating system and version, unique
              device identifiers, and mobile network information.
            </Typography>
          </li>
          <li>
            <Typography>
              <strong>Location Information:</strong> In accordance with your
              device permissions, we may collect information about the precise
              location of your device. You may stop the collection of precise
              location information at any time (see the{" "}
              <Link href="/privacy-policy#choices">
                Your Privacy Rights and Choices
              </Link>{" "}
              section below for details).
            </Typography>
          </li>
          <li>
            <Typography>
              <strong>
                Information Collected by Cookies and Other Tracking
                Technologies:
              </strong>{" "}
              We and our service providers use various technologies to collect
              information about your use of the Services and other websites and
              applications, including cookies and web beacons (or pixel tags).
              Cookies are small data files stored on your hard drive or in
              device memory that help us to, among other things, improve the
              Services and your experience, see which areas and features of the
              Services are popular, and count visits. Web beacons (also known as
              “pixel tags” or “clear GIFs”) are electronic images that may be
              used on the Services or in our emails and help deliver cookies,
              count visits, understand usage and campaign effectiveness, and
              determine if an email has been opened and acted upon. For more
              information about our use of these technologies, please refer to
              our{" "}
              <Link component={RouterLink} to="/cookie-policy">
                Cookie Policy
              </Link>{" "}
              and the{" "}
              <Link href="/privacy-policy#choices">
                Your Privacy Rights and Choices
              </Link>{" "}
              section below.
            </Typography>
          </li>
        </ul>
        <Typography variant="h6" gutterBottom>
          Information We Collect From Other Sources
        </Typography>
        <Typography paragraph>
          We may also collect personal information about you from other sources,
          including publicly available databases, and combine that with
          information we collect through the Services. For example, we may use
          information from LinkedIn to update information about you in our
          contact database.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Information We Derive
        </Typography>
        <Typography paragraph>
          We may derive information or draw inferences about you based on other
          types of personal information we collect. For example, we may infer
          your location based on your IP address, or that you are interested in
          employment or participating in an event based on your browsing
          behavior on our Services.
        </Typography>

        <Typography variant="h5" gutterBottom id="Use">
          Use of Personal Information
        </Typography>
        <Typography paragraph>
          We use the personal information we collect from and about you for
          various business purposes including to:
        </Typography>
        <Typography paragraph>
          <ul>
            <li>
              <Typography>Maintain and improve our Services;</Typography>
            </li>
            <li>
              <Typography>
                Personalize and improve your experience on our Services;
              </Typography>
            </li>
            <li>
              <Typography>
                Respond to your questions, comments, and requests;
              </Typography>
            </li>
            <li>
              <Typography>
                Provide the information or services you request and send you
                related information;
              </Typography>
            </li>
            <li>
              <Typography>
                Send you newsletters, updates, and other communications we think
                will be of interest to you, including about our services,
                programming, and events unless you have opted out of such
                communications (see{" "}
                <Link href="/privacy-policy#opt-out">
                  Opting Out of Marketing Communications
                </Link>{" "}
                for more information);
              </Typography>
            </li>
            <li>
              <Typography>
                Administer surveys and promotional drawings;
              </Typography>
            </li>
            <li>
              <Typography>
                Enable you to access our premises to attend events;
              </Typography>
            </li>
            <li>
              <Typography>
                Assess job applicants and make hiring decisions;
              </Typography>
            </li>
            <li>
              <Typography>
                Monitor and analyze usage, trends, and activities related to the
                Services;
              </Typography>
            </li>
            <li>
              <Typography>
                Manage your online account(s) and send you technical notices,
                updates, security alerts, and support and administrative
                messages;
              </Typography>
            </li>
            <li>
              <Typography>
                Detect, investigate, and prevent security incidents and other
                malicious, deceptive, fraudulent, or illegal activity and
                protect the rights and property of Kama Thuo, PLLC and others;
              </Typography>
            </li>
            <li>
              <Typography>
                Identify, report, and repair errors that impair the
                functionality of the Services;
              </Typography>
            </li>
            <li>
              <Typography>
                Comply with our legal and financial obligations;
              </Typography>
            </li>
            <li>
              <Typography>
                Protect the health, safety, and vital interests of our personnel
                and others;
              </Typography>
            </li>
          </ul>
        </Typography>
        <Typography variant="h5" gutterBottom id="Disclosure">
          Disclosure of Personal Information
        </Typography>
        <Typography paragraph>
          We may disclose your personal information as follows or as otherwise
          described in this Policy:
        </Typography>
        <Typography paragraph>
          <ul>
            <li>
              <Typography>
                To our parents, affiliates, subsidiaries, and other companies
                under common control and ownership;
              </Typography>
            </li>
            <li>
              <Typography>
                With vendors, consultants, professional advisors, and other
                service providers that need access to your personal information
                to provide services to us;
              </Typography>
            </li>
            <li>
              <Typography>
                In connection with, or during negotiations of, any merger,
                financing, divestiture, restructuring, reorganization,
                dissolution, or other sale of Kama Thuo PLLC’s assets or
                acquisition of all or a portion of our business to another
                company;
              </Typography>
            </li>
            <li>
              <Typography>
                In response to a request for information if we believe
                disclosure is in accordance with, or required by, any applicable
                law or legal process, including lawful requests by public
                authorities to meet national security or law enforcement
                requirements;
              </Typography>
            </li>
            <li>
              <Typography>
                If we believe your actions are inconsistent with our user
                agreements or policies, or to protect the rights, property, and
                safety of us or any third party;
              </Typography>
            </li>
            <li>
              <Typography>
                With other users of our Services if you post content to
                interactive areas of those Services. For example, any personal
                information you post in connection with an online profile, blog,
                or other interactive areas of the Services will be available to
                other users of those areas and, in some cases, may be publicly
                available;
              </Typography>
            </li>
            <li>
              <Typography>
                With your consent or at your direction, including if we notify
                you that your personal information will be disclosed in a
                particular manner and you provide such personal information. We
                may also disclose your personal information with third parties
                when you intentionally direct us to do so or when you use our
                Services to intentionally interact with third parties.
              </Typography>
            </li>
          </ul>
        </Typography>
        <Typography paragraph>
          We may also share aggregated or de-identified information, which
          cannot reasonably be used to identify you. Kama Thuo, PLLC processes,
          maintains, and uses this information only in a de-identified fashion
          and will not attempt to re-identify such information, except as
          permitted by law.
        </Typography>

        <Typography variant="h5" gutterBottom id="choices">
          Your Privacy Rights and Choices
        </Typography>
        <Typography paragraph>
          You have certain choices with respect to your personal information, as
          described below.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Data Subject Requests
        </Typography>
        <Typography paragraph>
          Depending on where you reside, you may have the right to: (1) know
          more about and access the personal information we collect, use, and
          disclose about you and to receive your data in a portable format, (2)
          delete your personal information, (3) correct inaccurate personal
          information, and (4) object to, or request that we restrict, certain
          processing. You may make these requests by{" "}
          <Link href="/contact">contacting us.</Link>
        </Typography>
        <Typography paragraph>
          We will verify your request by asking you to provide information
          sufficient to confirm your identity, such as your name, email address,
          and information about your interactions with us. If you would like to
          use an authorized agent to exercise your rights, we may request
          evidence that you have provided such agent with power of attorney, or
          that the agent otherwise has valid signed authority to submit requests
          on your behalf, and ask that you verify your identity directly with
          us.
        </Typography>
        <Typography variant="h6" gutterBottom id="opt-out">
          Opting Out of Marketing Communications
        </Typography>
        <Typography paragraph>
          You may opt out of receiving promotional communications from us or
          request changes to your communication preferences by following the
          instructions in those communications. If you opt out, we may still
          send you non-promotional communications, such as those about your
          account or our ongoing business relations.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Location Data
        </Typography>
        <Typography paragraph>
          When you first launch any of our mobile applications that collect
          precise location information, you will be asked to consent to the
          application’s collection of location information. If you initially
          consent to our collection of this information, you can subsequently
          stop the collection of this information at any time by changing the
          preferences on your mobile device. You may also stop our collection of
          this location information by following the standard uninstall process
          to remove our mobile apps from your device. If you do so, our mobile
          applications, or certain features thereof, may no longer function
          properly.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Mobile Push Notifications/Alerts
        </Typography>
        <Typography paragraph>
          With your consent, we may send push notifications or alerts to your
          mobile device. You can deactivate these messages at any time by
          changing the notification settings on your mobile device.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Cookies
        </Typography>
        <Typography paragraph>
          Most web browsers are set to accept cookies by default. If you prefer,
          you can usually set your browser to remove or reject cookies. Please
          note that removing or rejecting cookies could affect the availability
          and functionality of our Services. For more information, please see
          our{" "}
          <Link component={RouterLink} to="/cookie-policy">
            Cookie Policy
          </Link>
          .
        </Typography>

        <Typography variant="h5" gutterBottom id="links">
          Links to Other Websites and Third-Party Content
        </Typography>
        <Typography paragraph>
          We may provide links to or embed videos hosted by third-party
          websites, services, and applications, such as YouTube, that Patent
          Analytics, Inc does not operate or control. This Policy does not apply
          to third-party services, and we cannot take responsibility for the
          content, privacy policies, or practices of third-party services. We
          encourage you to review the privacy policies of any third-party
          service before providing any information to or through them. The
          Services may include an activity feed, social media buttons, and
          widgets, such as the Facebook "Like" button or the "Share This"
          button. Your interactions with these features are governed by the
          privacy policy of the third-party service that provides the feature.
        </Typography>

        <Typography variant="h5" gutterBottom id="data-transfers">
          Data Transfers
        </Typography>
        <Typography paragraph>
          Kama Thuo, PLLC is headquartered in the United States and we have
          operations and vendors in the United States and other countries.
          Therefore, we and those who perform work for us may transfer your
          personal information to, or store or access it in, jurisdictions that
          may not provide levels of data protection that are equivalent to those
          of your home jurisdiction. While in another jurisdiction for
          processing, the courts, law enforcement, and national security
          authorities of that jurisdiction may access your personal information.
        </Typography>
        <Typography paragraph>
          If you are in the European Economic Area (“EEA”), Switzerland, or the
          United Kingdom, we provide adequate protection for the transfer of
          personal data to countries outside of those jurisdictions by executing
          Standard Contractual Clauses, as authorized under EU or UK law. You
          are entitled to obtain a copy of the Standard Contractual Clauses we
          use to facilitate the transfer of personal data by contacting us.
        </Typography>

        <Typography variant="h5" gutterBottom id="California">
          California Privacy Information
        </Typography>
        <Typography paragraph>
          The California Consumer Privacy Act (“CCPA”) requires us to explain
          some information using definitions and categories set out under the
          CCPA. If you are a California resident, this section applies to you.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Additional Disclosures
        </Typography>
        <Typography paragraph>
          We collect, and in the preceding 12 months, we have collected, the
          following categories of personal information: identifiers (such as
          your name, email address, or IP address), characteristics of protected
          classifications under California or federal law (such as your age),
          commercial information (such as events attended), internet and
          electronic network activity information (such as browsing history,
          search history, information about your activities on our Services),
          geolocation data (such as your city of residence), audio, electronic,
          visual, and similar information (such as recordings of selected
          events, conferences, or meetings), and professional or
          employment-related information (such as job title and employment
          history). For more information on the precise data points we collect,
          please see the{" "}
          <Link href="/privacy-policy#Collection">
            Collection of Personal Information
          </Link>{" "}
          section above.
        </Typography>
        <Typography paragraph>
          We collect personal information for the business and commercial
          purposes described in the{" "}
          <Link href="/privacy-policy#Use">Use of Personal Information</Link>{" "}
          section above.
        </Typography>

        <Typography paragraph>
          In the preceding 12 months, we have disclosed the following categories
          of personal information for business purposes to the following
          categories of recipients:
        </Typography>

        <Typography paragraph>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ verticalAlign: "top", fontWeight: "bold" }}>
                  Categories of Personal Information
                </TableCell>
                <TableCell style={{ verticalAlign: "top", fontWeight: "bold" }}>
                  Categories of Recipients
                </TableCell>
              </TableRow>
              {disclosureData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell style={{ verticalAlign: "top" }}>
                    <Typography>{row.category}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{row.recipients}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Typography>

        <Typography paragraph>
          We do not use or disclose sensitive personal information for the
          purpose of inferring characteristics about you.
        </Typography>
        <Typography paragraph>
          Under the CCPA, if a business sells personal information, it must
          allow California residents to opt out of the sale or sharing of their
          personal information. However, we do not “sell” or “share” and have
          not “sold” or “shared” personal information in the preceding 12 months
          for purposes of the CCPA.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Privacy Rights
        </Typography>
        <Typography paragraph>
          Please see the{" "}
          <Link href="/privacy-policy#choices">
            Your Privacy Rights and Choices
          </Link>{" "}
          section above for more information about your privacy rights and how
          to exercise them (including as an authorized agent of someone else),
          and how we will verify requesters. We will not discriminate against
          you if you choose to exercise your rights under the CCPA.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Data Retention
        </Typography>

        <Typography paragraph>
          We storepersonal information for as long as necessary to carry out the
          purposes for which we originally collected it and for other legitimate
          business purposes, including to meet our legal, regulatory, or other
          compliance obligations.
        </Typography>

        <Typography variant="h5" gutterBottom id="EEA">
          Individuals in the EEA, UK, or Switzerland
        </Typography>
        <Typography paragraph>
          If you are located in the EEA, the United Kingdom, or Switzerland, you
          have certain rights and protections under applicable laws regarding
          the processing of your personal data, and this section applies to you.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Legal Basis for Processing
        </Typography>
        <Typography paragraph>
          When we process your personal data, we will do so in reliance on the
          following lawful bases:
        </Typography>
        <Typography paragraph>
          <ul>
            <li>
              To perform our responsibilities under our contract with you (e.g.,
              processing payments for and providing the products and services
              you requested).
            </li>
            <li>
              When we have a legitimate interest in processing your personal
              data to operate our business or protect our interests (e.g., to
              provide, maintain, and improve our products and services, conduct
              data analytics, and communicate with you).
            </li>
            <li>
              To comply with our legal obligations (e.g., to maintain a record
              of your consents and track those who have opted out of marketing
              communications).
            </li>
            <li>
              When we have your consent to do so (e.g., when you opt in to
              receive marketing communications from us). When consent is the
              legal basis for our processing your personal data, you may
              withdraw such consent at any time.
            </li>
          </ul>
        </Typography>

        <Typography variant="h6" gutterBottom>
          Additional Rights
        </Typography>
        <Typography paragraph>
          If you have a concern about our processing of personal data that we
          are not able to resolve, you have the right to lodge a complaint with
          the Data Protection Authority where you reside. Contact details for
          your Data Protection Authority can be found using the links below:
        </Typography>
        <Typography paragraph>
          <ul>
            <li>
              For individuals in the EEA:{" "}
              <Link
                href="https://edpb.europa.eu/about-edpb/board/members_en"
                target="_blank"
                rel="noopener noreferrer"
              >
                European Data Protection Board Members
              </Link>
            </li>

            <li>
              For individuals in the UK:{" "}
              <Link
                href="https://ico.org.uk/global/contact-us/"
                target="_blank"
                rel="noopener noreferrer"
              >
                UK Information Commissioner's Office
              </Link>
            </li>

            <li>
              For individuals in Switzerland:{" "}
              <Link
                href="https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/contact.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Swiss Federal Data Protection and Information Commissioner
              </Link>
            </li>
          </ul>
        </Typography>

        <Typography variant="h6" gutterBottom id="contact-info">
          Contact Us
        </Typography>

        <Typography paragraph>
          If you have any questions about our use of cookies, please{" "}
          <Link href="/contact">contact us</Link>. Additionally, see our{" "}
          <Link href="/terms-of-service">Terms of Service</Link>
          {". "}
        </Typography>

        <Typography
          sx={{ fontStyle: "italic", color: "gray", fontSize: "0.875rem" }}
        >
          This Privacy Policy was last revised on April 13, 2024.
        </Typography>
      </Paper>
    </Container>
  );
};

export default KthPrivacyPolicy;

import React from "react";
import { Button, MenuItem, Menu } from "@mui/material";
import { map } from "lodash";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

const ListGroup = ({
  items,
  textProperty = "name",
  idProperty = "id",
  groupingProperty = "",
  onItemSelect,
  selectedItem,
}) => {
  const TECH_GROUPS = ["COMM", "IDC", "INFRA", "POWER", "HVAC"];
  const ALL_IT_CAT = { title: "All Categories", id: 0 };

  //-----------------------------------------------------------------------------------------
  // Use Unique ID Property
  //-----------------------------------------------------------------------------------------
  // If using ID then already unique so can skip - if using title could be duplicate
  // like when we create category from fetched products
  let uniqueItems;
  if (idProperty !== "id") {
    uniqueItems = [
      ...new Map(items.map((item) => [item[idProperty], item])).values(),
    ];
  } else {
    // shallow copy ok here
    uniqueItems = [...items];
  }

  //-----------------------------------------------------------------------------------------
  // Conditional Render - Render Grouped Menu
  //-----------------------------------------------------------------------------------------
  const _renderGroupedMenu = () => {
    return (
      <React.Fragment>
        <button
          onClick={() => onItemSelect(ALL_IT_CAT)}
          className={"btn btn-primary btn-lg mb-3"}
        >
          {"All Categories"}
        </button>
        {TECH_GROUPS.map((grp) => (
          <DropdownButton
            key={grp}
            id="dropdown-basic-button"
            title={grp}
            style={{ marginBottom: 10 }}
          >
            {uniqueItems
              .filter((grpItm) => grpItm[groupingProperty] == grp)
              .map((itm) => (
                <Dropdown.Item
                  key={itm[idProperty]}
                  onClick={() => onItemSelect(itm)}
                >
                  {itm[textProperty]}
                </Dropdown.Item>
              ))}
          </DropdownButton>
        ))}
      </React.Fragment>
    );
  };

  //-----------------------------------------------------------------------------------------
  // Conditional Render - Render Grouped Menu
  //-----------------------------------------------------------------------------------------
  // KLUDGE -- shouldnt need to do this - categories should be unique
  const _renderUnGroupedMenu = () => {
    // Shouldnt have to do this - category should be unique
    /*
    const _randKey = () => {
      return Math.floor(Math.random() * 100000 + 1);
    };
    */
    return (
      <ul className="list-group">
        {uniqueItems.map((itm) => (
          <li
            onClick={() => onItemSelect(itm)}
            key={itm[idProperty]}
            className={
              itm == selectedItem ? "list-group-item active" : "list-group-item"
            }
          >
            {itm[textProperty]}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <React.Fragment>
      {groupingProperty ? _renderGroupedMenu() : _renderUnGroupedMenu()}
    </React.Fragment>
  );
};

// Can do default props thus or can assign when destructuring (see CreateRoomPage)
/*
ListGroup.defaultProps = {
  textProperty: "name",
  idProperty: "id",
};
*/

export default ListGroup;

//-----------------------------------------------------------------------------------------------------------
// Simple List group from Bootstrap --> list group from Bootstrap --> https://getbootstrap.com/docs/4.0/components/list-group/
//-----------------------------------------------------------------------------------------------------------
// Menu will look like this:
// <ul class="list-group">
//   <li class="list-group-item active">Cras justo odio</li>
//   <li class="list-group-item">Dapibus ac facilisis in</li>
//   <li class="list-group-item">Morbi leo risus</li>
//   <li class="list-group-item">Porta ac consectetur ac</li>
//   <li class="list-group-item">Vestibulum at eros</li>
// </ul>
// ListGroup li raises an event when clicked "onItemSelect" handled in products with method _handleCategorySelect
// React Render will look like this:
// return (
//   <ul className="list-group">
//     {items.map((itm) => (
//       <li
//         onClick={() => onItemSelect(itm)}
//         key={itm[idProperty]}
//         className={
//           itm == selectedItem ? "list-group-item active" : "list-group-item"
//         }
//       >
//         {itm[textProperty]}
//       </li>
//     ))}
//   </ul>
// );
// React-Bootstrap --> Working Code but not optimal
// return (
//   <React.Fragment>
//     <DropdownButton
//       id="dropdown-basic-button"
//       title="POWER"
//       style={{ marginBottom: 10 }}
//     >
//       {KLUDGE_items.filter(
//         (grpItm) => grpItm[groupingProperty] == "POWER"
//       ).map((itm) => (
//         <Dropdown.Item
//           key={itm[idProperty]}
//           onClick={() => onItemSelect(itm)}
//         >
//           {itm[textProperty]}
//         </Dropdown.Item>
//       ))}
//     </DropdownButton>
//     <DropdownButton id="dropdown-basic-button" title="HVAC">
//       {KLUDGE_items.filter(
//         (grpItm) => grpItm[groupingProperty] == "HVAC"
//       ).map((itm) => (
//         <Dropdown.Item
//           key={itm[idProperty]}
//           onClick={() => onItemSelect(itm)}
//         >
//           {itm[textProperty]}
//         </Dropdown.Item>
//       ))}
//     </DropdownButton>
//   </React.Fragment>
// );

import React from "react";
import {
  Toolbar,
  Typography,
  Container,
  Link as MuiLink,
  Box,
  Divider,
  AppBar,
} from "@mui/material";

const PatentAnalyticsFooter = () => {
  return (
    <AppBar
      position="static"
      style={{
        background: "#EEE",
        boxShadow: "none",
        minHeight: "150px",
        marginTop: "20px",
      }}
    >
      <Container>
        <Toolbar
          disableGutters
          style={{ flexDirection: "column", alignItems: "center" }}
        >
          {/* First Row - Navigation Links */}
          <Box
            sx={{
              alignSelf: "stretch",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginTop: "10px",
              marginBottom: "8px",
            }}
          >
            <MuiLink
              href="/"
              style={{
                textDecoration: "none",
                color: "#94618E",
                margin: "0 10px",
              }}
            >
              <Typography variant="body2">Home</Typography>
            </MuiLink>
            <MuiLink
              href="https://www.patanal.ai"
              target="_blank"
              style={{
                textDecoration: "none",
                color: "#94618E",
                margin: "0 10px",
              }}
            >
              <Typography variant="body2">Patanal AI Tool</Typography>
            </MuiLink>

            <MuiLink
              href="/aboutus"
              style={{
                textDecoration: "none",
                color: "#94618E",
                margin: "0 10px",
              }}
            >
              <Typography variant="body2">About</Typography>
            </MuiLink>
          </Box>

          <Divider style={{ width: "100%", margin: "8px 0" }} />

          {/* Second Row - Copyright Notice */}
          <Typography
            variant="body2"
            style={{ color: "#94618E", marginBottom: "8px" }}
          >
            ©{new Date().getFullYear()} Patent Analytics, Inc. All rights
            reserved.
          </Typography>

          {/* Third Row - Legal Links */}
          <Typography
            variant="body2"
            style={{ color: "#94618E", marginBottom: "8px" }}
          >
            <MuiLink
              href="/terms-of-service"
              style={{
                textDecoration: "none",
                color: "#88BBD6",
                marginRight: "5px",
              }}
            >
              Terms of Service
            </MuiLink>
            |{" "}
            <MuiLink
              href="/privacy-policy"
              style={{
                textDecoration: "none",
                color: "#88BBD6",
                marginRight: "5px",
              }}
            >
              Privacy Policy
            </MuiLink>
            |{" "}
            <MuiLink
              href="/dmca-notice"
              style={{
                textDecoration: "none",
                color: "#88BBD6",
                marginRight: "5px",
              }}
            >
              DMCA Notice
            </MuiLink>
            |{" "}
            <MuiLink
              href="/contact"
              style={{
                textDecoration: "none",
                color: "#88BBD6",
                marginRight: "5px",
              }}
            >
              Contact Us
            </MuiLink>
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default PatentAnalyticsFooter;

import { useEffect, useCallback, useRef } from "react";
import { debounce } from "lodash";

// This hook takes timeout duration in minutes and determines if timer has elapsed
// Default timeout when none passed is 15 minutes
// Action to Logout when time has elapsed is in LoginForm
const useIdleTimer = (timeoutMinutes = 15, onTimeout, domain, isActive) => {
  const lastActivityKey = `lastActivity-${domain}`;
  const timerRef = useRef(null);

  const updateLastActivity = useCallback(() => {
    localStorage.setItem(lastActivityKey, Date.now().toString());
  }, [lastActivityKey]);

  //last activity time is only written to localStorage if there hasn’t been another activity for a certain period
  const debouncedUpdateLastActivity = debounce(updateLastActivity, 10000); // Debounce for 10 seconds

  const resetTimer = useCallback(() => {
    debouncedUpdateLastActivity(); // Update last activity time debounced
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      if (typeof onTimeout === "function") {
        onTimeout();
      } else {
        console.error("onTimeout is not a function:", onTimeout);
      }
    }, timeoutMinutes * 60 * 1000);
  }, [debouncedUpdateLastActivity, timeoutMinutes, onTimeout]);

  useEffect(() => {
    if (!isActive) return;
    const handleActivity = () => {
      resetTimer();
    };

    document.addEventListener("mousemove", handleActivity);
    document.addEventListener("keypress", handleActivity);

    resetTimer(); // Set the initial timer

    return () => {
      document.removeEventListener("mousemove", handleActivity);
      document.removeEventListener("keypress", handleActivity);
      clearTimeout(timerRef.current);
      debouncedUpdateLastActivity.cancel(); // Cancel any pending debounced calls
    };
  }, [resetTimer, lastActivityKey, isActive, debouncedUpdateLastActivity]);

  // Optionally, return whether the user is active or not if needed
  // For now, the function doesn't need to return anything unless needed for UI updates
};
export default useIdleTimer;

import React, { Component } from "react";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";

const Table = (props) => {
  const { columns, sortColumn, onSort, data, idProperty, id2Property } = props;

  return (
    <table className="table">
      <TableHeader columns={columns} sortColumn={sortColumn} onSort={onSort} />
      <TableBody
        data={data}
        columns={columns}
        idProperty={idProperty}
        id2Property={id2Property}
      />
    </table>
  );
};

export default Table;

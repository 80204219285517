import React, { Component } from "react";
import _ from "lodash";

const TableBodyTiered = (props) => {
  const { data, columns, idProperty } = props;

  const _renderCell = (item, col) => {
    // if we have content (e.g., delete button, watch field, etc., then return the content)
    if (col.content) return col.content(item);
    // if we dont have content return the lodash extracted path
    return _.get(item, col.path);
  };

  const _createKey = (item, col) => {
    return item[idProperty] + " " + (col.path || col.key);
  };

  // because we have a nested property cannot use bracket notation item[col.path] so need to use lodash .get method
  return (
    <tbody>
      {data.map((item) => (
        <tr key={item[idProperty]}>
          {columns.map((col) => (
            <td key={_createKey(item, col)}>{_renderCell(item, col)}</td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};
export default TableBodyTiered;

TableBodyTiered.defaultProps = { idProperty: "id" };

import React, { useEffect, useState } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import axios from "axios";
import {
  Typography,
  Box,
  Chip,
  CircularProgress,
  Alert,
  Divider,
  Paper,
  Container,
  Grid,
  Button,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faFilePdf,
  faExternalLinkAlt,
  faLink,
  faGavel,
  faToggleOn,
  faBracketsCurly,
  faCompassDrafting,
  faArrowLeft,
  faDotCircle,
} from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import SetDocumentMeta from "../common/SetDocumentMeta";
import parse from "html-react-parser";

const apiUrlKth = `${window._env_.REACT_APP_PATANALAI_API_URL}`;
const IMAGE_BASE_URL =
  process.env.NODE_ENV === "production"
    ? ""
    : "http://djdev-lpim-backend.rfwel.com:8000/";

const WirelessStdOrgDetail = ({ baseConfig }) => {
  const { tertColor } = baseConfig.siteBranding;

  const { slug } = useParams();
  const [org, setOrg] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // @TODO - make dynamic based on the standard
  const pageTitle = "Wireless Standards Organization | Telecom Law Help";
  const pageMetaDescription = "";
  const pageMetaKeywords = "";

  SetDocumentMeta({
    title: pageTitle,
    description: pageMetaDescription,
    keywords: pageMetaKeywords,
  });

  useEffect(() => {
    const fetchOrgDetail = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${apiUrlKth}/kth-telecom/${slug}/std-org-detail`
        );
        setOrg(response.data);
      } catch (err) {
        setError("Failed to fetch standard organization detail.");
        console.error("Error fetching organization detail:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrgDetail();
  }, [slug]);

  if (isLoading)
    return (
      <Container>
        <CircularProgress />
      </Container>
    );

  if (error)
    return (
      <Container>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Alert severity="error">{error}</Alert>{" "}
        </Grid>
        <Button
          startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
          color="secondary"
          variant="outlined"
          component={RouterLink}
          to={"/wireless-standards"}
          sx={{ mb: 2, mt: 2, textTransform: "none" }}
        >
          Back to Standards Search
        </Button>
      </Container>
    );
  if (!org)
    return (
      <Container>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Alert severity="warning">
            No standard organization data found..
          </Alert>{" "}
        </Grid>
        <Button
          startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
          color="secondary"
          variant="outlined"
          component={RouterLink}
          to={"/wireless-standards"}
          sx={{ mb: 2, mt: 2, textTransform: "none" }}
        >
          Back to Standards Search
        </Button>
      </Container>
    );

  return (
    <Container className="link-no-decoration">
      <Grid container spacing={3} padding={3}>
        <Grid item xs={12}>
          <Button
            startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            color="secondary"
            variant="outlined"
            component={RouterLink}
            to={"/wireless-standards"}
            sx={{ mb: 2, textTransform: "none" }}
          >
            Back to Standards Search
          </Button>
          <Box mb={3}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ display: "flex", alignItems: "center" }}
            >
              {org.stdorg_logo && (
                <img
                  src={`${IMAGE_BASE_URL}${org.stdorg_logo}`}
                  alt={`${org.name} logo`}
                  style={{ height: 64, width: "auto", marginRight: 8 }}
                />
              )}
              {/* {org.name} */}
            </Typography>

            {org.link && (
              <Typography variant="body1" sx={{ mb: 2 }}>
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  style={{ marginRight: 8 }}
                />
                <a href={org.link} target="_blank" rel="noopener noreferrer">
                  {`${org.name} Standard Organization`}
                </a>
              </Typography>
            )}

            {/* Summary Section */}
            {org.refs_for_stdorg?.filter((ref) => ref.is_summary_table).length >
              0 && (
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
                  Legal Summary
                </Typography>
                {org.refs_for_stdorg
                  .filter((ref) => ref.is_summary_table)
                  .map((ref, index) => (
                    <React.Fragment key={index}>
                      {ref.ref_discussion_title && (
                        <Typography
                          key={`${index}-title`}
                          variant="body1"
                          sx={{ mt: 1 }}
                        >
                          <strong>{ref.ref_discussion_title}</strong>{" "}
                        </Typography>
                      )}
                      {ref.ref_discussion && (
                        <Box
                          key={`${index}-discussion`}
                          color="textSecondary"
                          // sx={{ ml: 4 }}
                        >
                          {parse(ref.ref_discussion)}
                        </Box>
                      )}

                      {ref.ref_link_label && ref.ref_link && (
                        <Typography
                          key={`${index}-link`}
                          variant="body1"
                          sx={{ ml: 4, mb: 2 }}
                        >
                          <FontAwesomeIcon
                            key={`${index}-icon`}
                            icon={faLink}
                            style={{ marginRight: 8 }}
                          />
                          <a
                            href={ref.ref_link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {ref.ref_link_label}
                          </a>
                        </Typography>
                      )}
                    </React.Fragment>
                  ))}
              </Grid>
            )}

            <Typography
              sx={{ mt: 3 }}
              dangerouslySetInnerHTML={{ __html: org.description }}
            />

            {org.wireless_standards && org.wireless_standards.length > 0 && (
              <>
                <Divider sx={{ my: 3 }} />
                <Typography variant="h4" gutterBottom>
                  {org.name} Standards
                </Typography>
                <Box sx={{ pl: 2, mt: 1 }}>
                  {org.wireless_standards.map((standard, index) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      mb={2}
                      sx={{
                        color: "inherit", // inherit the text color
                        transition: "color 0.3s",
                        "&:hover": { color: tertColor }, // apply tertColor on hover for both icon and text
                      }}
                    >
                      {/* Icon as bullet */}
                      <FontAwesomeIcon
                        icon={faDotCircle}
                        style={{ marginRight: 8 }}
                      />
                      <RouterLink
                        to={`/wireless-standards/${standard.slug}`}
                        style={{ textDecoration: "none", color: "inherit" }} // inherit color from parent Box
                      >
                        <Typography
                          sx={{
                            transition: "color 0.3s",
                            "&:hover": { color: tertColor },
                            fontSize: "1.1rem",
                          }}
                        >
                          {standard.standard}
                        </Typography>
                      </RouterLink>
                    </Box>
                  ))}
                </Box>
              </>
            )}

            {/* Standard Refs Section */}
            {org.refs_for_stdorg?.filter((ref) => !ref.is_summary_table)
              .length > 0 && (
              <Grid item xs={12}>
                <Divider sx={{ my: 3 }} />
                <Typography variant="h4" sx={{ mt: 3, mb: 1 }}>
                  References
                </Typography>
                {org.refs_for_stdorg
                  .filter((ref) => !ref.is_summary_table)
                  .map((ref, index) => (
                    <Box
                      key={index}
                      sx={{
                        backgroundColor:
                          index % 2 === 0 ? "#f9f9f9" : "#e8e8e8", // Alternating background color
                        p: 2,
                        borderRadius: 1,
                        mb: 2,
                        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)", // Subtle shadow to give depth
                      }}
                    >
                      {ref.ref_discussion_title && (
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: "bold",
                            color: "#333",
                            mb: 1,
                          }}
                        >
                          {ref.ref_discussion_title}
                        </Typography>
                      )}
                      {ref.ref_discussion && (
                        <Box color="textSecondary" sx={{ ml: 2, mb: 1 }}>
                          {parse(ref.ref_discussion)}
                        </Box>
                      )}
                      {ref.ref_link_label && ref.ref_link && (
                        <Typography variant="body1" sx={{ ml: 2 }}>
                          <FontAwesomeIcon
                            icon={faLink}
                            style={{ marginRight: 8, color: "#007bff" }}
                          />
                          <a
                            href={ref.ref_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: "none",
                              color: "#007bff",
                              fontWeight: "bold",
                              "&:hover": { color: "#0056b3" },
                            }}
                          >
                            {ref.ref_link_label}
                          </a>
                        </Typography>
                      )}
                    </Box>
                  ))}
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default WirelessStdOrgDetail;

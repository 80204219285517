import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from "@mui/material";

const WizInfoDialog = ({ onClose, content }) => (
  <Dialog open={true} onClose={onClose}>
    <DialogTitle>More Information</DialogTitle>
    <DialogContent>
      <Typography variant="body1">{content}</Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

export default WizInfoDialog;

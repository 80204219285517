//----------------------------------------------------------------------------------
// Normalize Patent ID
//----------------------------------------------------------------------------------
export const normalizePatId = (id) => {
  // Normalize the ID by removing spaces, commas, and dashes
  let normalizedId = id.replace(/[\s,-]/g, "");

  // Check if the normalized ID is numeric only, If so, append "US" and "B2"
  if (/^\d+$/.test(normalizedId)) {
    normalizedId = `US${normalizedId}B2`;
  }

  // Append 'B2' if it doesn't end with 'B1' or 'B2'
  if (!/B1$/.test(normalizedId) && !/B2$/.test(normalizedId)) {
    normalizedId = `${normalizedId}B2`;
  }

  return normalizedId;
};

//----------------------------------------------------------------------------------
// Remove list # from claim
//----------------------------------------------------------------------------------
export const stripClaimNumber = (claim) => {
  return claim.replace(/^\d+\.\s*/, "");
};

import React, { Component } from "react";
import _ from "lodash";

const TableBody = (props) => {
  const { data, columns, idProperty = "id", id2Property = "" } = props;

  const _renderCell = (item, col) => {
    // if we have content (e.g., delete button, watch field, etc., then return the content)
    if (col.content) return col.content(item);
    // if we dont have content return the lodash extracted path
    return _.get(item, col.path);
  };

  const _createKeyRow = (item) => {
    let key = item[idProperty];
    if (id2Property) {
      key += item[id2Property];
    }
    return key;
  };

  const _createKeyCol = (item, col) => {
    let key = item[idProperty] + "-" + (col.path || col.key);
    if (id2Property) {
      key += item[id2Property];
    }
    return key;
  };

  // because we have a nested property cannot use bracket notation item[col.path] so need to use lodash .get method
  return (
    <tbody>
      {data.map((item) => (
        <tr key={_createKeyRow(item)}>
          {columns.map((col) => (
            <td key={_createKeyCol(item, col)}>{_renderCell(item, col)}</td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};
export default TableBody;

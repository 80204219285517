import http from "../../../services/httpService";

export const testCategories = [];

export function getCategories() {
  //return categories.filter((cat) => cat);
  return testCategories;
}

export async function fetchCategoryData() {
  const categoriesFetched = await http.get("/patanal_api/categories");
  //console.log("categoriesFetched at util/prods -->");
  //console.log(categoriesFetched);
  return categoriesFetched;
}

export default {
  //get_all: getCategories,
  get_all: fetchCategoryData,
};

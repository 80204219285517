import React, { useState, useEffect } from "react";
import { Fab, useMediaQuery } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTheme } from "@mui/material/styles";

const BackToTopButton = () => {
  const [visible, setVisible] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  // Hide button on small screens
  if (isSmallScreen) {
    return null;
  }

  return (
    visible && (
      <Fab
        onClick={scrollToTop}
        size="small"
        style={{
          backgroundColor: "#4ABDAC",
          color: "white",
          position: "fixed",
          bottom: "20px",
          right: "20px",
          zIndex: 1000,
        }}
        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#2D93AD")}
        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#4ABDAC")}
      >
        <KeyboardArrowUpIcon />
      </Fab>
    )
  );
};

export default BackToTopButton;

import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Paper,
  InputBase,
  Divider,
  IconButton,
  Toolbar,
  Grid,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import MailIcon from "@mui/icons-material/Mail";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import { useAuth } from "../../context/AuthContext";
import { getImageUrl } from "../../utils/helpers";

const PatentAnalyticsHeader = ({ baseConfig }) => {
  const { user } = useAuth();
  const { siteBranding } = baseConfig;

  return (
    <Toolbar>
      <Grid container justifyContent="space-between" alignItems="center">
        {/* Logo and search box here */}
        <Grid item>
          {siteBranding?.logo && (
            <RouterLink to="/" style={{ textDecoration: "none" }}>
              <img
                src={getImageUrl(siteBranding.logo)}
                alt="Logo"
                style={{
                  maxHeight: "64px",
                  width: "auto",
                  marginTop: "5px",
                }}
              />
            </RouterLink>
          )}
        </Grid>
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 400,
          }}
        >
          <IconButton
            sx={{ p: "10px", color: siteBranding.priColor }}
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search ..."
            inputProps={{ "aria-label": "search site" }}
          />
          <IconButton
            // component={RouterLink}
            // to="/search"
            sx={{ p: "10px", color: siteBranding.priColor }}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton
            component={RouterLink}
            to="/contact"
            sx={{ p: "10px", color: siteBranding.priColor }}
            aria-label="contact"
            title="Contact Us" // tooltip for contact us
          >
            <MailIcon />
          </IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

          {user ? (
            <IconButton
              component={RouterLink}
              to="/logout"
              sx={{ p: "10px", color: siteBranding.priColor }}
              aria-label="logout"
              title="Logout" // tooltip for logout
            >
              <LogoutIcon />
            </IconButton>
          ) : (
            <IconButton
              component={RouterLink}
              to="/login"
              sx={{ p: "10px", color: siteBranding.priColor }}
              aria-label="login"
              title="Login" // tooltip for login
            >
              <LoginIcon />
            </IconButton>
          )}
        </Paper>
      </Grid>
    </Toolbar>
  );
};

export default PatentAnalyticsHeader;

import React from "react";
import { Link } from "react-router-dom";
import Table from "../../common/Table";
import { buildChannelLinks } from "../utils/buildChannelLink";
import * as CONFIG from "../utils/Global-Labels-Links-Vars";

const MarginAnalTable = ({ prods, onSort, sortColumn }) => {
  // Create static header
  // To sort a column just add path variable below corresponding to the data attribute name
  const columnsStatic = [
    {
      path: "sku",
      label: "SKU",
      content: (prod) => (
        <Link to={`/product-detail/${prod.sku}`}> {prod.sku} </Link>
      ),
    },
    {
      path: "channel",
      label: "Channel",
      content: (prod) => buildChannelLinks(prod.channel, prod.channid),
    },
    {
      path: "price",
      label: "Channel Price (USD)",
      content: (prod) => CONFIG.formatCurrency(prod.price),
    },
    {
      path: "cost",
      label: "Item Cost",
      content: (prod) => CONFIG.formatCurrency(prod.cost),
    },
    {
      path: "margin",
      label: "Gross Margin",
      content: (prod) => CONFIG.formatCurrency(prod.margin),
    },
    {
      path: "margin_percent",
      label: "Gross Margin %",
      content: (prod) => CONFIG.formatPercentage(prod.margin_percent),
    },
  ];

  return (
    <Table
      columns={columnsStatic}
      sortColumn={sortColumn}
      onSort={onSort}
      data={prods}
      idProperty={"sku"}
      id2Property={"channel"}
    />
  );
};

export default MarginAnalTable;

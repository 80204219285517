import React from "react";
//import "../../../static/css/spinner.css";

const LoadingSpinner = () => {
  // return (
  //   <div className="spinner-container">
  //     <div className="loading-spinner"></div>
  //   </div>
  // );
  // switch to boostrap spinner here -> https://getbootstrap.com/docs/5.0/components/spinners/
  return (
    <div className="spinner-border" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  );
};

export default LoadingSpinner;

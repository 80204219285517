import React from "react";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import styled from "styled-components";

// Styled components for custom styles
const StyledNavbar = styled(({ tertColor, ...props }) => <Navbar {...props} />)`
  .custom-nav-item {
    position: relative;
    font-size: 1.1rem; /* Increase the font size slightly */

    &.active::after {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0;
      right: 0;
      height: 3px;
      background-color: ${(props) => props.tertColor};
    }
  }

  .custom-dropdown-item {
    &.active,
    &:hover {
      background-color: ${(props) => props.tertColor} !important;
      color: #fff !important;
    }
  }
`;

const NavbarWeb = ({ navConfig, baseConfig }) => {
  const { user } = useAuth();
  const location = useLocation();
  const currentPath = location.pathname;

  // Extracting colors from baseConfig.siteBranding
  const { tertColor } = baseConfig.siteBranding;

  // Helper function to check if the current path matches the item's path or any of its children
  const isActive = (item) => {
    if (item.path === currentPath) {
      return true;
    }
    return item.children?.some((child) => child.path === currentPath);
  };

  return (
    <StyledNavbar expand="lg" tertColor={tertColor}>
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {navConfig.map((item) => {
              if (item.authRequired && !user) {
                return null;
              }
              return item.children.length > 0 ? (
                <NavDropdown
                  title={item.title}
                  id={`dropdown-${item.title}`}
                  key={item.title}
                  className={`custom-nav-item ${
                    isActive(item) ? "active" : ""
                  }`}
                >
                  {item.children.map((child) => (
                    <LinkContainer to={child.path} key={child.title}>
                      <NavDropdown.Item
                        className={`custom-dropdown-item ${
                          currentPath === child.path ? "active" : ""
                        }`}
                      >
                        {child.title}
                      </NavDropdown.Item>
                    </LinkContainer>
                  ))}
                </NavDropdown>
              ) : (
                <LinkContainer to={item.path} key={item.title}>
                  <Nav.Link
                    className={`custom-nav-item ${
                      currentPath === item.path ? "active" : ""
                    }`}
                  >
                    {item.title}
                  </Nav.Link>
                </LinkContainer>
              );
            })}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </StyledNavbar>
  );
};

export default NavbarWeb;

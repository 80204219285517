import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import ReusableTitle from "../common/ReusableTitle";
import { faFileContract } from "@fortawesome/pro-solid-svg-icons";

const KthPatentLicensing = ({ baseConfig }) => {
  const {
    priColor,
    priHoverColor,
    tertColor,
    tertHoverColor,
    quadColor,
    quadHoverColor,
  } = baseConfig.siteBranding;

  return (
    <Container>
      <Box sx={{ mt: 2 }}>
        <ReusableTitle
          title="Patent Licensing & Enforcement"
          isMainHeading={true}
          siteBranding={baseConfig.siteBranding}
          icon={{ faIcon: faFileContract }}
        />

        {/* Amazon Patent Enforcement Section */}
        <Box sx={{ mt: 2, mb: 2 }}>
          <ReusableTitle
            title="Amazon Patent Enforcement (Assertion and Defense)"
            isMainHeading={false}
            siteBranding={baseConfig.siteBranding}
          />
          <Typography variant="body1" gutterBottom sx={{ mt: 2, ml: 3 }}>
            At Kama Thuo, PLLC, we provide expert legal guidance for patent
            owners seeking to protect their intellectual property rights against
            Amazon sellers, and Amazon sellers defending against infringement
            claims. Our goal is to deliver efficient resolutions, including
            reinstating suspended listings for resellers when their ASINs do not
            infringe the asserted patent’s claims.
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ mt: 2, ml: 3 }}>
            Amazon's APEX program has streamlined the patent enforcement process
            for disputes involving Amazon resellers. This program offers a
            faster and more cost-effective alternative to traditional
            litigation, but it comes with unique challenges. Learn more about
            the pros and cons of the APEX program in our{" "}
            <a
              href="https://news-insights.kthlaw.com/en-us/patents/patent-enforcement/amazon-apex"
              target="_blank"
              rel="noopener noreferrer"
              // style={{ textDecoration: "none" }} // Optional styling
            >
              News & Insights
            </a>{" "}
            site.
          </Typography>

          <Typography variant="body1" gutterBottom sx={{ mt: 2, ml: 3 }}>
            Explore how our <strong>Patent Enforcement Wizard</strong> can
            provide valuable insights. Whether you're an Amazon seller or a
            patent owner, the wizard simplifies the enforcement or defense flow
            and offers actionable guidance.
            <RouterLink to="/patent-enforcement">Try it now</RouterLink> and
            take the next step toward resolving your dispute effectively.
          </Typography>

          {/* Call-to-Actions */}
          <Button
            startIcon={<LaunchIcon />}
            variant="contained"
            sx={{
              mt: 2,
              ml: 3,
              backgroundColor: tertColor,
              color: "#fff",
              "&:hover": { backgroundColor: tertHoverColor, color: "#fff" },
            }}
            component={RouterLink}
            to="/patent-enforcement"
          >
            Try Patent Enforcement Wizard
          </Button>
          <Button
            startIcon={<LaunchIcon />}
            variant="contained"
            sx={{
              mt: 2,
              ml: 3,
              backgroundColor: priColor,
              color: "#fff",
              "&:hover": { backgroundColor: priHoverColor, color: "#fff" },
            }}
            component={RouterLink}
            to="/news-insights?practice=patents"
          >
            News & Insights
          </Button>
          <Button
            startIcon={<ConnectWithoutContactIcon />}
            variant="contained"
            size="medium"
            sx={{
              mt: 2,
              ml: 3,

              backgroundColor: quadColor,
              color: "#fff",
              "&:hover": {
                backgroundColor: quadHoverColor,
                color: "#fff",
              },
            }}
            href="/contact"
          >
            Get in Touch
          </Button>
        </Box>

        {/* General Patent Licensing Section */}
        <Box sx={{ mt: 4, mb: 2 }}>
          <ReusableTitle
            title="Licensing & Monetization Expertise"
            isMainHeading={false}
            siteBranding={baseConfig.siteBranding}
          />
          <Typography variant="body1" gutterBottom sx={{ mt: 2, ml: 3 }}>
            Beyond Amazon, we provide comprehensive patent licensing and
            monetization services to help you maximize your intellectual
            property investment. Whether you're looking to license your patents,
            negotiate royalties, or assess the value of your portfolio, our team
            is here to guide you every step of the way.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default KthPatentLicensing;

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Container,
  TextField,
  Button,
  Typography,
  Paper,
  Box,
  FormControlLabel,
  Checkbox,
  Link as MuiLink,
  Alert,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Joi from "joi-browser";
// import auth from "../services/authService";
import { useAuth } from "../context/AuthContext";

const LoginForm = ({ termsLink, linkTarget }) => {
  const [user, setUser] = useState({ username: "", password: "" });
  const [errors, setErrors] = useState({});
  const [rememberMe, setRememberMe] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);

  // const { from } = location.state || { from: { pathname: "/" } }; // Default redirection is home

  // Define JOI schema
  const joiSchema = {
    username: Joi.string().required().email().label("Username"),
    password: Joi.string().required().min(5).label("Password"),
  };

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(user, joiSchema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const errors = validate();
    setErrors(errors || {});
    if (errors) return;

    const result = await login(user.username, user.password);
    if (result.success) {
      // On successful login, either navigate to path that was going to or to home page
      const from = location.state?.from?.pathname || "/";
      navigate(from);
    } else {
      // Update error state based on response from login function
      setErrors({
        ...errors,
        auth: result.message || "Invalid username or password",
      });
    }
  };

  const handleChange = ({ target: input }) => {
    const newUser = { ...user };
    newUser[input.name] = input.value;
    setUser(newUser);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container component="main" maxWidth="xs" className="link-no-decoration">
      <Paper elevation={3} sx={{ mt: 8, p: 4 }}>
        <Typography
          component="h1"
          variant="h5"
          align="center"
          style={{ color: "#94618E" }}
        >
          Sign In
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          {errors.auth && <Alert severity="error">{errors.auth}</Alert>}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={user.username}
            onChange={handleChange}
            error={!!errors.username}
            helperText={errors.username}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            // type="password"
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            value={user.password}
            onChange={handleChange}
            error={!!errors.password}
            helperText={errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={agreeToTerms}
                onChange={(e) => setAgreeToTerms(e.target.checked)}
                color="primary"
              />
            }
            label={
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ fontSize: "0.875rem" }}
              >
                I agree to the{" "}
                <MuiLink href={termsLink} target={linkTarget} variant="body2">
                  terms
                </MuiLink>{" "}
              </Typography>
            }
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                color="primary"
              />
            }
            label={
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ fontSize: "0.875rem" }}
              >
                Remember me
              </Typography>
            }
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 2,
              mb: 2,
              backgroundColor: "#88BBD6",
              "&:hover": { backgroundColor: "#71a5c7" },
            }}
            disabled={!agreeToTerms} // Disable button if terms are not agreed
          >
            Login
          </Button>
          <Typography variant="body2" align="center" sx={{ mt: 2 }}>
            Don't have an account?
            <MuiLink href="/account" variant="body2" sx={{ ml: 1 }}>
              Contact us
            </MuiLink>{" "}
            for access.
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default LoginForm;

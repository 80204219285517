import React, { useState } from "react";
import axios from "axios";
import http from "../../services/httpService";
import { TextField, Button, Box, Stack, Container } from "@mui/material";

const apiUrlPatanal = `${window._env_.REACT_APP_PATANALAI_API_URL}`; // Patanal API Endpoint

const GenaiPrompt = ({ onResults, onClear }) => {
  const [prompt, setPrompt] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const apiUrl = `${apiUrlPatanal}/genai/query/`;
    const accessToken = http.getCookie("access_token");
    const headers = {
      Authorization: `JWT ${accessToken}`,
    };
    const body = {
      prompt,
    };

    try {
      const response = await axios.post(apiUrl, body, { headers });
      onResults({ prompt, results: response.data.results });
      setPrompt("");
    } catch (error) {
      console.error("There was an error querying the OpenAI API:", error);
    }
  };

  const handleClear = () => {
    onClear();
  };

  return (
    <Container maxWidth="lg" className="link-no-decoration">
      <Box component="form" onSubmit={handleSubmit}>
        <Stack direction="row" spacing={2} alignItems="center">
          <TextField
            fullWidth
            label="Enter your prompt"
            variant="outlined"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            required
          />
          <Button variant="contained" color="primary" type="submit">
            Submit
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleClear}>
            Clear
          </Button>
        </Stack>
      </Box>
    </Container>
  );
};

export default GenaiPrompt;

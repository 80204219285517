import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Grid,
  Box,
  Typography,
  TextField,
  FormHelperText,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import _ from "lodash";
import ListGroup from "../../common/ListGroup";
import SearchBox from "../../common/SearchBox";
import Pagination from "../../common/Pagination";
import LoadingSpinner from "../../common/LoadingSpinner";
import prods from "../utils/prods";
import cats from "../utils/categories";
import { paginate } from "../utils/paginate";
import {
  buildChannelProducts,
  mapChannelProducts,
} from "../utils/buildChannelProducts";
import PriceWatchTable from "./PriceWatchTable";

// Dont need this - have instead react-bootstrap but this app using cdn link in index.html
//import { table } from "react-bootstrap";

const PriceWatchHome = () => {
  //-------------------------------------------------------------------------------
  // SET DEFAULTS
  //-------------------------------------------------------------------------------
  const DEFAULT_PAGE_SIZE = 35;

  //-------------------------------------------------------------------------------
  // STATES
  //-------------------------------------------------------------------------------

  const [products, setProducts] = useState([]);
  const [watched, setWatched] = useState(false);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(1);
  const [categories, setCategories] = useState([
    { title: "All Categories", id: 0 },
    //...cats.get_all(),
  ]); //add an "All Categories" category
  const [selectedCategory, setSelectedCategory] = useState({
    title: "All Categories",
    id: 0,
  });
  const [sortCol, setSortCol] = useState({ path: "sku", order: "asc" });
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  //-------------------------------------------------------------
  const numProds = products.length;
  //-------------------------------------------------------------------------------
  // COMPONENT METHODS
  //-------------------------------------------------------------------------------
  const _handleRemoveItem = (prod) => {
    const filteredProds = products.filter((p) => p.sku != prod.sku);
    setProducts(filteredProds);
  };
  //--------------------------------------------------------------
  const _handleWatched = (prod) => {
    // clone products using spread operator
    const clonedProds = [...products];
    // find the index of the passed product from the cloned products
    const index = clonedProds.indexOf(prod);
    // clone all the properties of the passed product
    clonedProds[index] = { ...clonedProds[index] };
    // one we have all the properties just edit the watched field (toggle boolean field)
    clonedProds[index].watched = !clonedProds[index].watched;
    // set state to pass products
    setProducts(clonedProds);

    // @TODO --> call backend to make sure the watched status is updated in the DB
  };
  //-------------------------------------------------------------
  const _handlePageChange = (page) => {
    //console.log(`Page Clicked = ${page}`);
    setCurrentPage(page);
  };
  //-------------------------------------------------------------
  const _handleCategorySelect = (category) => {
    //console.log(category);
    setSelectedCategory(category);
    // Note cannot set search query to null because this is a controlled component. If set to null/undefined react will think this is an uncontrolled component
    // and will give warning when user types in typed that trying to covert an uncontrolled component to a controlled component.
    setSearchQuery("");
    setCurrentPage(1);

    // Reset current page to 1 otherwise if select a category with multiple pages then go back to a category with fewer pages, will display blank
    setCurrentPage(1);
  };
  //-------------------------------------------------------------
  const _handleSort = (receivedSortCol) => {
    setSortCol((prevState) => ({
      ...prevState,
      path: receivedSortCol.path,
      order: receivedSortCol.order,
    }));
  };
  //-------------------------------------------------------------
  const _handleSearchChange = (query) => {
    setSearchQuery(query);
    setSelectedCategory("");
    setCurrentPage(1);
  };
  //-------------------------------------------------------------
  const _handleSearchSubmit = () => {
    console.log("Product Search Form Submitted");
  };
  //-------------------------------------------------------------
  const _handleRefreshProducts = async () => {
    setIsLoading(true);
    const productsFetched = await prods.get_all(
      "/patanal_api/products-price-watch/"
    );
    //console.log("productsFetched inside _handleRefreshProducts -->");
    //console.log(productsFetched);
    setProducts(productsFetched);
    setIsLoading(false);
  };

  //-------------------------------------------------------------------------------
  // EFFECTS
  //-------------------------------------------------------------------------------
  useEffect(() => {
    // Set IsLoading to true and disable Refresh button to prevent multiple API calls
    // Will only fetch if there are no products loaded so setIsLoading(true) only then
    if (numProds === 0) {
      setIsLoading(true);
    }
    const fetchProds = async () => {
      const productsFetched = await prods.get_all(
        "/patanal_api/products-price-watch/"
      );
      setProducts(productsFetched);

      // Hide Loading screen when fetch complete
      setIsLoading(false);
    };
    // Fetch Prods only if Prods is not included in state
    if (numProds === 0) {
      fetchProds();
    }
  }, []);

  //-------------------------------------------------------------
  // Fetch categories only when products change
  useEffect(() => {
    const fetchCats = async () => {
      const categoriesFetched = await cats.get_all("/patanal_api/categories/");
      setCategories([{ id: 0, title: "All Categories" }, ...categoriesFetched]);
      //console.log("categories fetched at useEffect --> ");
      //console.log(categoriesFetched);
    };
    fetchCats();
  }, [products]);

  //-------------------------------------------------------------------------------
  // COMPONENT OBJECTS
  //-------------------------------------------------------------------------------
  // use destructuring to get length of products (number of items and rename to prodCount)
  // the below equivalent to const prodCount = products.length.
  const { length: prodCount } = products;

  // filter products based on selected category (or search query) before pagination
  // && used to cover all categories
  // start with filtered products as all products
  let productsFiltered = products;

  if (searchQuery) {
    // if there is a search query then fitler based on search query
    productsFiltered = products.filter((p) =>
      p.sku.toLowerCase().startsWith(searchQuery.toLowerCase())
    );
  } else {
    // if no search query but there's category selection then filter based on category
    productsFiltered =
      selectedCategory && selectedCategory.id !== 0
        ? products.filter((p) => p.category.id === selectedCategory.id)
        : products;
  }

  // sort products
  // sortCol.path and sortCol.order are in array because we can sort by multiple columns using lodash's orderBy util
  const productsSorted = _.orderBy(
    productsFiltered,
    [sortCol.path],
    [sortCol.order]
  );

  // paginate products
  const productsPaginated = paginate(productsSorted, currentPage, pageSize);

  //------------------------------------------------------------
  // Extract Channel Products from Sorted, Filtered, Paginated
  //------------------------------------------------------------
  let channelProducts;
  if (productsPaginated.length !== 0) {
    const channProds = buildChannelProducts(productsPaginated);
    channelProducts = mapChannelProducts(channProds);
  }

  //-------------------------------------------------------------------------------
  // COMPONENT RENDER
  //-------------------------------------------------------------------------------
  // If products is undefined - still fetching, render waiting
  if (isLoading) return <LoadingSpinner />;
  if (!products || !categories) return <p>Getting products, please wait ...</p>;
  if (!prodCount || prodCount == 0)
    return <p> No products listed in database.</p>;
  return (
    <div className="row">
      <div className="col-2">
        <ListGroup
          items={categories}
          selectedItem={selectedCategory}
          onItemSelect={_handleCategorySelect}
          textProperty="title"
          idProperty="id"
          groupingProperty="tech_group"
        />
      </div>
      <div className="col">
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ marginBottom: 3 }}
        >
          <Button
            variant="contained"
            color="primary"
            to="/products"
            component={Link}
          >
            {"All Products"}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={_handleRefreshProducts}
            disabled={isLoading}
          >
            {"Refresh"}
          </Button>
        </Box>

        <SearchBox
          searchValue={searchQuery}
          searchOnChange={(e) => _handleSearchChange(e.currentTarget.value)}
        />
        <p>
          Showing{" "}
          <strong>
            {" "}
            {productsFiltered.length} {selectedCategory.title}{" "}
          </strong>{" "}
          products in the database enabled for price-watch (if product missing,
          enable price watch in admin area and refresh).
        </p>

        {channelProducts && (
          <PriceWatchTable
            prods={channelProducts}
            sortColumn={sortCol}
            onRemove={_handleRemoveItem}
            onWatch={_handleWatched}
            onSort={_handleSort}
          />
        )}

        <Pagination
          itemsCount={productsFiltered.length}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={_handlePageChange}
        />
      </div>
    </div>
  );
};
export default PriceWatchHome;

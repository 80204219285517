import React from "react";
import PropTypes from "prop-types";
import * as CONFIG from "../utils/Global-Labels-Links-Vars";

const BuyButton = ({ channel, pid, size = "lg" }) => {
  let url = "";
  let label = "";
  let classValue = "";

  // if channel or pid is missing do not render the button
  if (!channel || !pid) {
    return "";
  }

  switch (channel) {
    case CONFIG.BIGC:
      url = `https://shop.rfwel.com/products.php?productId=${pid}&source=${CONFIG.SOURCE_TAG}`;
      label = "Buy RfWeL";
      classValue =
        size === "lg"
          ? "btn btn-primary btn-lg mb-3"
          : "btn btn-primary btn-sm mb-2";
      break;
    case CONFIG.AMZ:
      url = `https://www.amazon.com/gp/product/${pid}?m=A3HRH3RGP717Q`;
      label = "Buy Amazon";
      classValue =
        size === "lg"
          ? "btn btn-warning btn-lg mb-3"
          : "btn btn-warning btn-sm mb-2";
      break;
    case CONFIG.AMZ_SELLER_US:
      url = `https://www.amazon.com/gp/product/${pid}?m=A3HRH3RGP717Q`;
      label = "Buy Amazon B2C";
      classValue =
        size === "lg"
          ? "btn btn-warning btn-lg mb-3"
          : "btn btn-warning btn-sm mb-2";
      break;
    case CONFIG.AMZ_SELLER_US_B2B:
      url = `https://www.amazon.com/gp/product/${pid}?m=A3HRH3RGP717Q`;
      label = "Buy Amazon B2B";
      classValue =
        size === "lg"
          ? "btn btn-warning btn-lg mb-3"
          : "btn btn-warning btn-sm mb-2";
      break;
    case CONFIG.AMZ_FBA_US:
      url = `https://www.amazon.com/gp/product/${pid}?m=A3HRH3RGP717Q`;
      label = "Buy Amazon Prime B2C";
      classValue =
        size === "lg"
          ? "btn btn-warning btn-lg mb-3"
          : "btn btn-warning btn-sm mb-2";
      break;
    case CONFIG.AMZ_FBA_US_B2B:
      url = `https://www.amazon.com/gp/product/${pid}?m=A3HRH3RGP717Q`;
      label = "Buy Amazon Prime B2B";
      classValue =
        size === "lg"
          ? "btn btn-warning btn-lg mb-3"
          : "btn btn-warning btn-sm mb-2";
      break;
    case CONFIG.AMZ_SELLER_CA:
      url = `https://www.amazon.com/gp/product/${pid}?m=A3HRH3RGP717Q`;
      label = "Buy AMZ Canada";
      classValue =
        size === "lg"
          ? "btn btn-warning btn-lg mb-3"
          : "btn btn-warning btn-sm mb-2";
      break;
    case CONFIG.EBAY:
      url = `https://www.ebay.com/itm/${pid}`;
      label = "Buy eBay";
      classValue =
        size === "lg"
          ? "btn btn-success btn-lg mb-3"
          : "btn btn-success btn-sm mb-2";
      break;
    case CONFIG.WMT:
      url = `https://www.walmart.com/ip/${pid}?selectedSellerId=101005160`;
      label = "Buy Walmart";
      classValue =
        size === "lg"
          ? "btn btn-secondary btn-lg mb-3"
          : "btn btn-secondary btn-sm mb-2";
      break;
    case CONFIG.NE:
      url = `https://www.newegg.com/Product/Product.aspx?Item=${pid}`;
      label = "Buy Newegg";
      classValue =
        size === "lg" ? "btn btn-info btn-lg mb-3" : "btn btn-info btn-sm mb-2";
      break;
    case CONFIG.NE_BIZ:
      url = `https://www.neweggbusiness.com/Product/Product.aspx?Item=${pid}`;
      label = "Buy Newegg Biz";
      classValue =
        size === "lg"
          ? "btn btn-secondary btn-lg mb-3"
          : "btn btn-secondary btn-sm mb-2";
      break;
  }

  return (
    <button onClick={() => window.open(url, "_blank")} className={classValue}>
      {label}
    </button>
  );
};

BuyButton.propTypes = {
  channel: PropTypes.string.isRequired,
  pid: PropTypes.string.isRequired,
  size: PropTypes.string,
};

export default BuyButton;

import _ from "lodash";

// Paginate on the frontend using lodash utilities -- return array of items in certain page
export function paginate(items, pageNumber, pageSize) {
  // determine starting index of items at pageNumber (e.g., if pageSize=2, page 1 is at index 0, page 2 is at index 2, page 3 at index 4 )
  const startIndex = (pageNumber - 1) * pageSize;

  // convert items array into lodash wrapper object _() and then convert back using .value()
  return _(items).slice(startIndex).take(pageSize).value();
}

import React, { createContext, useContext, useState, useEffect } from "react";
import auth from "../services/authService";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(auth.getCurrentUser());

  const handleLogin = async (username, password) => {
    const result = await auth.login(username, password);
    if (result.success) {
      // auth.login returns { success: true, user: decodedJwt, message: "Login successful" } on success
      setUser(result.user);
      return { ...result, user: result.user };
    }
    return result;
  };

  const handleLogout = () => {
    auth.logout();
    setUser(null); // Clear user from context
  };

  return (
    <AuthContext.Provider
      value={{ user, login: handleLogin, logout: handleLogout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}

import _ from "lodash";

export function getPriceLocks(product) {
  const priceLockedArr = product.price_locked;

  // Get objects for each potential channel that could be locked. If channel not locked object will be null
  const lockedObjBigC = _.find(priceLockedArr, { channel: "bigc" });
  const lockedObjAmzSellerUs = _.find(priceLockedArr, {
    channel: "amz_seller_us",
  });
  const lockedObjAmzFbaUs = _.find(priceLockedArr, {
    channel: "amz_fba_us",
  });
  const lockedObjAmzSellerCa = _.find(priceLockedArr, {
    channel: "amz_seller_ca",
  });
  const lockedObjEbay = _.find(priceLockedArr, { channel: "ebay" });
  const lockedObjWmt = _.find(priceLockedArr, { channel: "wmt" });
  const lockedObjNe = _.find(priceLockedArr, { channel: "newegg" });
  const lockedObjNeBiz = _.find(priceLockedArr, { channel: "newegg_biz" });

  // Create booleans if object for channel exists
  const bigcPriceLocked = lockedObjBigC !== undefined;
  const amzSellerUsPriceLocked = lockedObjAmzSellerUs !== undefined;
  const amzFbaUsPriceLocked = lockedObjAmzFbaUs !== undefined;
  const amzSellerCaPriceLocked = lockedObjAmzSellerCa !== undefined;
  const ebayPriceLocked = lockedObjEbay !== undefined;
  const wmtPriceLocked = lockedObjWmt !== undefined;
  const nePriceLocked = lockedObjNe !== undefined;
  const neBizPriceLocked = lockedObjNeBiz !== undefined;

  return {
    price_lock_bigc: bigcPriceLocked,
    price_lock_release_bigc: bigcPriceLocked
      ? lockedObjBigC.pricelock_release_date
      : null,
    price_lock_amz_seller_us: amzSellerUsPriceLocked,
    price_lock_release_amz_seller_us: amzSellerUsPriceLocked
      ? lockedObjAmzSellerUs.pricelock_release_date
      : null,
    price_lock_amz_fba_us: amzFbaUsPriceLocked,
    price_lock_release_amz_fba_us: amzFbaUsPriceLocked
      ? lockedObjAmzFbaUs.pricelock_release_date
      : null,
    price_lock_amz_seller_ca: amzSellerCaPriceLocked,
    price_lock_release_amz_seller_ca: amzSellerCaPriceLocked
      ? lockedObjAmzSellerCa.pricelock_release_date
      : null,
    price_lock_ebay: ebayPriceLocked,
    price_lock_release_ebay: ebayPriceLocked
      ? lockedObjEbay.pricelock_release_date
      : null,
    price_lock_wmt: wmtPriceLocked,
    price_lock_release_wmt: wmtPriceLocked
      ? lockedObjWmt.pricelock_release_date
      : null,
    price_lock_newegg: nePriceLocked,
    price_lock_release_newegg: nePriceLocked
      ? lockedObjNe.pricelock_release_date
      : null,
    price_lock_newegg_biz: neBizPriceLocked,
    price_lock_release_newegg_biz: neBizPriceLocked
      ? lockedObjNeBiz.pricelock_release_date
      : null,
  };

  // TODO - get notes from objects
}

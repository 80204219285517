import React from "react";
import { Grid } from "@mui/material";
import RestrictedAccessPrompt from "../common/RestrictedAccessPrompt";
import { useAuth } from "../../context/AuthContext";

const SegmentSearch = () => {
  const { user } = useAuth();
  return (
    <Grid container spacing={2} className="link-no-decoration">
      <Grid item xs={12} sx={{ mt: 4 }}>
        <RestrictedAccessPrompt component={"Segment Search"} />
      </Grid>
    </Grid>
  );
};

export default SegmentSearch;

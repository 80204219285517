import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faForward } from "@fortawesome/pro-solid-svg-icons";
import WizInfoDialog from "./WizInfoDialog";
import parse from "html-react-parser";
import { US_STATES } from "../../../utils/constants"; // Import a list of U.S. states

const WizActionSelectOptionField = ({
  question,
  questionInfo,
  initialValue = "",
  onComplete,
  fieldType,
}) => {
  // Define configurations for each field type
  const config = {
    location: {
      label: "Select Location",
      options: [
        {
          value: "United States",
          info: "",
        },
        { value: "Throughout U.S.", info: "" },
        { value: "Canada", info: "" },
        { value: "Europe", info: "" },
        { value: "Other", info: "" },
      ],
      allowMultiple: false,
      allowSkip: false,
    },
    market: {
      label: "Select Market",
      options: [
        { value: "Amazon United States", info: "" },
        { value: "Amazon Canada", info: "" },
        {
          value: "Amazon Global",
          info: "",
        },
      ],
      allowMultiple: false,
      allowSkip: false,
    },
    productType: {
      label: "Select Product Type",
      options: [
        { value: "Wireless Device", info: "" },
        { value: "Other Electrical or Electronic Component", info: "" },
        { value: "Other Mechanical Component", info: "" },
        {
          value: "Other",
          info: "<p>Our firm primary handles electrical, electronic, and mechanical products.</p>",
        },
      ],
      allowMultiple: true,
      allowSkip: true,
    },
  }[fieldType];

  const validInitialValue = initialValue || "";
  const [selection, setSelection] = useState(
    validInitialValue === "Skipped"
      ? "Skipped"
      : validInitialValue.includes(" - ")
      ? validInitialValue.split(" - ")[0]
      : validInitialValue
  );
  const [stateSelection, setStateSelection] = useState(
    validInitialValue.includes(" - ") ? validInitialValue.split(" - ")[1] : ""
  );
  const [showInfo, setShowInfo] = useState(null);
  const [isEditing, setIsEditing] = useState(!validInitialValue);
  const [skip, setSkip] = useState(validInitialValue === "Skipped");

  const handleNext = () => {
    if (isEditing) {
      const finalSelection = skip
        ? "Skipped"
        : fieldType === "location" &&
          selection === "United States" &&
          stateSelection
        ? `United States - ${stateSelection}`
        : selection;

      let selectedOptionInfo =
        config.options.find((opt) => opt.value === selection)?.info || "";

      // Append info based on state selection
      if (fieldType === "location") {
        if (
          selection === "United States" &&
          selection !== "Throughout U.S." &&
          stateSelection
        ) {
          selectedOptionInfo +=
            stateSelection === "Arizona" || stateSelection === "California"
              ? `<p>Licensed in ${stateSelection}; can assist with all related legal matters.</p>`
              : `<p>Licensed only in Arizona and California. For other states, services are limited to federal patent-related matters; ${stateSelection}-specific legal advice is unavailable.</p>`;
        } else if (selection === "Throughout U.S.") {
          selectedOptionInfo += `<p>Licensed only in Arizona and California. For other states, services are limited to federal patent-related matters; state-specific legal advice is unavailable.</p>`;
        } else if (selection === "Other") {
          selectedOptionInfo += `<p>Note: licensed only in Arizona and California, so legal services outside of federal patent matters are limited to these states.</p>`;
        } else if (selection !== "United States") {
          selectedOptionInfo += `<p>Services outside the U.S. are limited to U.S. federal patent matters; cannot provide ${selection}-specific legal advice.</p>`;
        }
      }
      onComplete(finalSelection, selectedOptionInfo);
    }
    setIsEditing(!isEditing);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setSelection(value);
    if (fieldType === "location" && value !== "United States") {
      setStateSelection(""); // Clear state selection if "United States" is not chosen
    }
  };

  const handleStateChange = (event) => {
    setStateSelection(event.target.value);
  };

  const handleSkipChange = (event) => {
    setSkip(event.target.checked);
    if (event.target.checked) {
      setSelection("Skipped");
      setStateSelection("");
    } else {
      setSelection("");
    }
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
        <Typography variant="h6" sx={{ mr: 1 }}>
          {question}
        </Typography>
        {questionInfo && (
          <Button
            startIcon={<InfoIcon />}
            onClick={() => setShowInfo(questionInfo)}
          >
            Info
          </Button>
        )}
      </Box>

      {config.allowSkip && (
        <FormControlLabel
          control={<Checkbox checked={skip} onChange={handleSkipChange} />}
          label="Skip for Now"
          sx={{ mb: 2 }}
          disabled={!isEditing}
        />
      )}

      <TextField
        select
        label={config.label}
        value={selection}
        onChange={handleChange}
        fullWidth
        variant="outlined"
        disabled={!isEditing || skip}
      >
        {/* Conditionally include 'Skipped' in the dropdown only if it's selected */}
        {selection === "Skipped" && (
          <MenuItem value="Skipped" style={{ display: "none" }}>
            Skipped
          </MenuItem>
        )}
        {config.options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.value}
          </MenuItem>
        ))}
      </TextField>

      {fieldType === "location" && selection === "United States" && (
        <TextField
          select
          label="State"
          value={stateSelection}
          onChange={handleStateChange}
          fullWidth
          variant="outlined"
          sx={{ mt: 2 }}
          disabled={!isEditing || skip}
        >
          {US_STATES.map((state) => (
            <MenuItem key={state} value={state}>
              {state}
            </MenuItem>
          ))}
        </TextField>
      )}

      <Button
        onClick={handleNext}
        startIcon={
          isEditing ? (
            <FontAwesomeIcon icon={faForward} />
          ) : (
            <FontAwesomeIcon icon={faPenToSquare} />
          )
        }
        variant="contained"
        color={isEditing ? "primary" : "secondary"}
        disabled={
          (!selection || (selection === "United States" && !stateSelection)) &&
          !skip
        }
        sx={{ mt: 2 }}
      >
        {isEditing ? "Next" : "Edit"}
      </Button>
      {showInfo && (
        <WizInfoDialog
          onClose={() => setShowInfo(null)}
          content={parse(showInfo)}
        />
      )}
    </Box>
  );
};

export default WizActionSelectOptionField;

import React from "react";
import { Link } from "react-router-dom";
import formatDate from "../utils/formatDateTime";
import * as CONFIG from "../utils/Global-Labels-Links-Vars";
import Table from "../../common/Table";

const SalesAnalTable = ({ prods, onSort, sortColumn }) => {
  // Create static header
  // Remove-Item column doesn't have a label so we pass empty object so it is rendered
  // for watched column want label but we dont want to sort
  // To sort a column just add path variable below corresponding to the data attribute name
  const columnsStatic = [
    {
      path: "sku",
      label: "SKU",
      content: (prod) => (
        <Link to={`/product-detail/${prod.sku}`}> {prod.sku} </Link>
      ),
    },
    { path: "qty_sold", label: "Units Sold" },
    {
      path: "gross_margin",
      label: "Gross Margin",
      content: (prod) => CONFIG.formatCurrency(prod.gross_margin),
    },
    {
      path: "gross_margin_percent",
      label: "Gross Margin % (No Fees)",
      content: (prod) => CONFIG.formatCurrency(prod.gross_margin_percent),
    },
    {
      path: "percent_of_sales",
      label: "% of Total Sales",
      content: (prod) => CONFIG.formatCurrency(prod.percent_of_sales),
    },
    {
      key: "last-sales-data-check",
      label: "Sales Data Updated",
      content: (prod) => formatDate(prod.last_update),
    },
  ];

  return (
    <Table
      columns={columnsStatic}
      sortColumn={sortColumn}
      onSort={onSort}
      data={prods}
      idProperty={"sku"}
    />
  );
};

export default SalesAnalTable;

import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import axios from "axios";

import {
  Typography,
  Container,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Grid,
  Button,
  Alert,
  InputAdornment,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogActions,
  Divider,
  Stack,
  useMediaQuery,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import SearchIcon from "@mui/icons-material/Search";
import ContactUsForm from "../common/ContactUsForm";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faFilePdf,
  faExternalLinkAlt,
  faLink,
  faToggleOn,
  faGlobe,
  faSection,
  faConveyorBeltArm,
  faShieldHalved,
  faFlagUsa,
  faScaleBalanced,
  faGavel,
  faBadgeSheriff,
  faIndustry,
  faMessageBot,
  faFileCertificate,
  faPrintMagnifyingGlass,
  faFileCheck,
  faBracketsCurly,
  faLayerPlus,
  faMobileSignal,
  faRouter,
  faMapLocationDot,
  faSensorCloud,
  faGlobeWifi,
  faNfcSignal,
  faTowerBroadcast,
  faRoadCircleExclamation,
  faHouseSignal,
  faWalkieTalkie,
} from "@fortawesome/pro-solid-svg-icons";

import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import ConstructionIcon from "@mui/icons-material/Construction";
import KthContactUsCommonContent from "./KthContactUsCommonContent";
import parse from "html-react-parser";
import Pagination from "../common/Pagination";
import * as CONFIG from "./utils/kthGlobalLabelsVars";
import SetDocumentMeta from "../common/SetDocumentMeta";

const apiUrlKth = `${window._env_.REACT_APP_PATANALAI_API_URL}`;
const IMAGE_BASE_URL =
  process.env.NODE_ENV === "production"
    ? ""
    : "http://djdev-lpim-backend.rfwel.com:8000/";

// Set ITEMS_PER_PAGE based on environment
const environment = window._env_.REACT_APP_ENVIRONMENT || "production";
const ITEMS_PER_PAGE = environment === "production" ? 10 : 3;

// Set # of standard_orgs to show in drop-down list
const SHOW_STD_ORG = environment === "production" ? 6 : 4;

const statusColors = {
  Active: "#28a745", // Green
  "Partially Active": "#ffc107", // Yellow/Orange
  Pending: "red", // Blue
  Unknown: "#6c757d", // Gray
};

const scrollToElement = (id) => {
  const element = document.getElementById(id);
  if (element) {
    const offset = 20; // Adjust this value as needed
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
};

const infoBlockTit = "Wireless Telecom Law";
const infoBlockBLurb =
  "Legal counseling on wireless telecommunications — devices, networks, standards, sites, spectrum, regulations, and more.";
const infoBlockLink = "/wireless";
const infoBlockLinkLabel = "Learn More";
const contactHelpBlurb =
  "Need Help Now? Contact Us to Discuss Your Wireless Standards Legal Questions.";
const contactFormTit = "Wireless Standards Help";
const pageTitle = "Wireless Standards | Telecom Law Help";
const pageMetaDescription =
  "Wireless standards data compiled by Kama Thuo, PLLC's wireless telecom attorneys.";
const pageMetaKeywords =
  "wireless telecom attorneys, standard essential patents, standards compliance, \
              legal advice for wireless standards";

const WirelessStandards = ({ baseConfig }) => {
  const {
    priColor,
    priHoverColor,
    tertColor,
    tertHoverColor,
    quadColor,
    quadHoverColor,
    sextColor,
    sextHoverColor,
  } = baseConfig.siteBranding;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const [standards, setStandards] = useState([]);
  const [filteredApplications, setFilteredApplications] = useState([]);
  const [applications, setApplications] = useState([]);
  const [standardStatuses, setStandardStatuses] = useState([]);
  const [stdOrgs, setStdOrgs] = useState([]);
  const [currentPage, setCurrentPage] = useState({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({
    application: "",
    standard_status: "",
    standard_org: "",
    search: "",
    importance: "all",
  });

  SetDocumentMeta({
    title: pageTitle,
    description: pageMetaDescription,
    keywords: pageMetaKeywords,
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const application = queryParams.get("application") || "";
    const standardStatus = queryParams.get("status") || "";
    const stdOrg = queryParams.get("standard_org") || "";

    setFilters((prevFilters) => ({
      ...prevFilters,
      application: application,
      standard_status: standardStatus,
      standard_org: stdOrg,
    }));

    fetchStandardData();
  }, [location.search]);

  useEffect(() => {
    applyFilters();
  }, [filters, standards]);

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    if (hash) {
      scrollToElement(hash);
    }
  }, []);

  const fetchStandardData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${apiUrlKth}/kth-telecom/standards`);

      const data = response.data;

      // Sort standards by ordering field
      data.sort((a, b) => a.ordering - b.ordering);

      setStandards(data);
      setFilteredApplications(data);

      const uniqueApplications = Array.from(
        new Set(
          data
            .filter((standard) => standard.application?.slug) // Ensure application exists
            .map((standard) => standard.application.slug)
        )
      ).map(
        (slug) =>
          data.find((standard) => standard.application?.slug === slug)
            .application
      );

      const uniqueStandardStatuses = Array.from(
        new Set(
          data
            .filter((standard) => standard.standard_status) // Ensure standard_status exists
            .map((standard) => standard.standard_status)
        )
      );

      const uniqueStdOrgs = Array.from(
        new Set(
          data
            .filter((standard) => standard.standard_org?.slug) // Ensure standard_org exists
            .map((standard) => standard.standard_org.slug)
        )
      ).map(
        (slug) =>
          data.find((standard) => standard.standard_org?.slug === slug)
            .standard_org
      );

      setApplications(uniqueApplications);
      setStandardStatuses(uniqueStandardStatuses);
      setStdOrgs(uniqueStdOrgs);
    } catch (err) {
      setError("Failed to fetch standard data.");
      console.error("Error fetching data:", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStandardData();
  }, []);

  const applyFilters = () => {
    const { application, standard_status, standard_org, search, importance } =
      filters;

    let filtered = standards.filter((standard) => {
      const matchesApplication =
        !application ||
        (standard.application && standard.application.slug === application);
      const matchesStandardStatus =
        !standard_status || standard.standard_status === standard_status;
      const matchesStdOrg =
        !standard_org ||
        (standard.standard_org && standard.standard_org.slug === standard_org);
      const matchesImportance =
        importance === "all" ||
        ["Featured", "Important"].includes(standard.importance);
      const matchesSearch =
        !search ||
        standard.standard.toLowerCase().includes(search.toLowerCase()) ||
        (standard.standard_blurb &&
          standard.standard_blurb.toLowerCase().includes(search.toLowerCase()));

      return (
        matchesApplication &&
        matchesStandardStatus &&
        matchesStdOrg &&
        (importance === "all" || matchesImportance) &&
        matchesSearch
      );
    });

    setFilteredApplications(filtered);
  };

  const handleSearchChange = (event) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      search: event.target.value,
    }));
    setCurrentPage({}); // Reset the currentPage state to reset to page 1
  };

  const handleFilterChange = (event) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [event.target.name]: event.target.value,
    }));
    setCurrentPage({}); // Reset the currentPage state to reset to page 1
  };

  const handleImportanceChange = (event) => {
    setFilters({ ...filters, importance: event.target.value });
    setCurrentPage({}); // Reset the currentPage state to reset to page 1
  };

  const clearFilters = () => {
    setFilters({
      application: "",
      standard_status: "",
      standard_org: "",
      search: "",
      importance: "all",
    });
  };

  const handlePageChange = (contentType, page) => {
    setCurrentPage({
      ...currentPage,
      [contentType]: page,
    });
  };

  const groupedApplications = filteredApplications.reduce((acc, standard) => {
    const app = (standard.application && standard.application.slug) || "other";
    if (!acc[app]) {
      acc[app] = {
        name: standard.application ? standard.application.name : "Other",
        items: [],
      };
    }
    acc[app].items.push(standard);
    return acc;
  }, {});

  const applicationIcons = {
    cellular: faMobileSignal,
    // iot: faSensorCloud,
    positioning: faMapLocationDot,
    wlan: faRouter,
    wpan: faNfcSignal,
    bwa: faTowerBroadcast,
    v2x: faRoadCircleExclamation,
    iot: faHouseSignal,
    "voice-communication": faWalkieTalkie,
    other: faLayerPlus,
  };

  const openContactForm = () => {
    setContactDialogOpen(true);
  };

  const closeContactForm = () => {
    setContactDialogOpen(false);
  };

  const _renderInformationBlocks = () => {
    return (
      <>
        {/* Information Block 1 */}
        <Grid item xs={12} md={6}>
          <Card
            variant="outlined"
            sx={{
              backgroundColor: "#f8f9fa",
              // borderLeft: "4px solid #28a745",
              borderLeft: "4px solid #4ABDAC",
              display: "flex",
              alignItems: "center",
              height: "100%", // Ensure this card stretches to full height
            }}
          >
            <CardContent sx={{ display: "flex", alignItems: "center" }}>
              <Box>
                <Typography variant="h6" gutterBottom>
                  {infoBlockTit}
                </Typography>

                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  gutterBottom
                >
                  {infoBlockBLurb}
                </Typography>
                <Button
                  variant="contained"
                  color="success"
                  sx={{
                    mt: 2,
                    backgroundColor: tertColor,
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: tertHoverColor,
                      color: "#fff",
                    },
                  }}
                  component={RouterLink}
                  to={infoBlockLink}
                  endIcon={<ArrowForwardIosIcon />}
                >
                  {infoBlockLinkLabel}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Information Block 2 */}
        <Grid item xs={12} md={6}>
          <Card
            variant="outlined"
            sx={{
              backgroundColor: "#f8f9fa",
              borderLeft: "4px solid #007bff",
              height: "100%", // Ensure this card stretches to full height
            }}
          >
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {contactHelpBlurb}
              </Typography>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                // spacing={2}
                alignItems={isSmallScreen ? "left" : "center"}
              >
                <Box>
                  <Typography
                    variant="subtitle1"
                    color="textPrimary"
                    gutterBottom
                  >
                    Call us for a free consultation
                  </Typography>
                  <Typography
                    variant="h5"
                    color="textPrimary"
                    sx={{ fontWeight: "bold" }}
                  >
                    +1-602-837-0631
                  </Typography>
                </Box>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ ml: 3, mr: 3 }}
                />
                <Button
                  startIcon={<ConnectWithoutContactIcon />}
                  variant="contained"
                  color="primary"
                  onClick={openContactForm}
                  endIcon={<ArrowForwardIosIcon />}
                  sx={{ maxWidth: "200px", maxHeight: "40px" }}
                >
                  Contact Us
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        {/* Contact Us Form Dialog */}
        <Dialog
          open={contactDialogOpen}
          onClose={closeContactForm}
          maxWidth="md"
          fullWidth
        >
          <DialogContent>
            <KthContactUsCommonContent>
              {(agreementText, organizationErrorText) => (
                <ContactUsForm
                  baseConfig={baseConfig}
                  formTitle={contactFormTit}
                  agreementText={agreementText}
                  isOrganizationRequired={true}
                  organizationErrorText={organizationErrorText}
                  onSuccessCallback={closeContactForm} // Close dialog on success
                />
              )}
            </KthContactUsCommonContent>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeContactForm}>Close</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  return (
    <Container className="link-no-decoration">
      <Typography variant="h4" sx={{ mt: 4 }}>
        Wireless Standards
      </Typography>

      <Box sx={{ mt: 2 }}>
        {/* Information Blocks Container */}
        <Grid container spacing={2} alignItems="stretch">
          {_renderInformationBlocks()}
        </Grid>
      </Box>

      <Typography variant="h4" sx={{ mt: 4 }}>
        Search Standards
      </Typography>

      <Grid container spacing={2} sx={{ mt: 2, mb: 4 }}>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            label={`Search by name or description`}
            value={filters.search}
            onChange={handleSearchChange}
            variant="outlined"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Application</InputLabel>
            <Select
              name="application"
              value={filters.application}
              onChange={handleFilterChange}
              label="Standard Type"
            >
              <MenuItem value="">
                <em>All Applications</em>
              </MenuItem>
              {applications.map((app) => (
                <MenuItem key={app.slug} value={app.slug}>
                  {app.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Standard Status</InputLabel>
            <Select
              name="standard_status"
              value={filters.standard_status}
              onChange={handleFilterChange}
              label="Standard Status"
            >
              <MenuItem value="">
                <em>All Statuses</em>
              </MenuItem>
              {standardStatuses.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Std Org</InputLabel>
            <Select
              name="standard_org"
              value={filters.standard_org}
              onChange={handleFilterChange}
              label="Std Org"
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: SHOW_STD_ORG * 48, // Limit the height for the visible options
                    overflowY: "auto", // Enable vertical scroll
                  },
                },
              }}
            >
              <MenuItem value="">
                <em>All Std Orgs</em>
              </MenuItem>
              {stdOrgs.map((standard_org) => (
                <MenuItem key={standard_org.slug} value={standard_org.slug}>
                  {standard_org.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Box sx={{ mb: 3 }}>
        <FormControl component="fieldset" sx={{ mr: 2, mb: 2 }}>
          <RadioGroup
            row
            name="importance"
            value={filters.importance}
            onChange={handleImportanceChange}
          >
            <FormControlLabel
              value="all"
              control={<Radio />}
              label="Show All"
            />
            <FormControlLabel
              value="key"
              control={<Radio />}
              label="Show Key Standards"
            />
          </RadioGroup>
        </FormControl>
        <Button
          startIcon={<SearchOffIcon />}
          onClick={clearFilters}
          variant="outlined"
          sx={{ mb: 2 }}
        >
          Clear Filters
        </Button>
      </Box>

      {filters.search ||
      filters.application ||
      filters.standard_status ||
      filters.standard_org ||
      filters.importance !== "all" ? (
        <Alert
          severity="info"
          action={
            <Button color="inherit" size="small" onClick={clearFilters}>
              Show All
            </Button>
          }
        >
          {filters.search ||
          filters.application ||
          filters.standard_status ||
          filters.standard_org ? (
            <>
              Showing results for
              {filters.search && ` search keywords = "${filters.search}"`}
              {filters.application && (filters.search ? " & " : "")}
              {filters.application && ` application = ${filters.application}`}
              {(filters.application || filters.search) &&
                filters.standard_status &&
                " & "}
              {filters.standard_status &&
                ` status = ${filters.standard_status}`}
              {(filters.application ||
                filters.standard_status ||
                filters.search) &&
                filters.standard_org &&
                " & "}
              {filters.standard_org &&
                ` standard_org = ${filters.standard_org}`}
              {filters.importance !== "all" && " & showing key standards only"}
            </>
          ) : (
            "Showing key standards only"
          )}
        </Alert>
      ) : null}

      {CONFIG.RFW_HORIZDOT}

      {filteredApplications.length > 0 && (
        <>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ marginTop: "10px", marginBottom: "5px" }}
          >
            Click on link to jump to application:
          </Typography>
          <div style={{ display: "flex", flexWrap: "wrap", marginBottom: 40 }}>
            {Object.keys(groupedApplications).map((slug, index) => (
              <Typography variant="body1" style={{ marginRight: 8 }} key={slug}>
                <a
                  href={`#${slug}`}
                  onClick={(e) => {
                    e.preventDefault();
                    const element = document.getElementById(slug);
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                  }}
                >
                  <FontAwesomeIcon
                    icon={applicationIcons[slug]}
                    style={{ marginRight: "7px" }}
                  />
                  {groupedApplications[slug].name}
                </a>
                {index < Object.keys(groupedApplications).length - 1 && " | "}
              </Typography>
            ))}
          </div>
        </>
      )}
      {isLoading ? (
        <CircularProgress />
      ) : filteredApplications.length === 0 ? (
        <Alert severity="warning" sx={{ mt: 4 }}>
          No results found for the selected filters.
        </Alert>
      ) : (
        Object.keys(groupedApplications).map((slug) => {
          const applicationItems = groupedApplications[slug].items;
          const page = currentPage[slug] || 1;
          const paginatedItems = applicationItems.slice(
            (page - 1) * ITEMS_PER_PAGE,
            page * ITEMS_PER_PAGE
          );

          return (
            <div key={slug}>
              <Typography variant="h5" id={slug} sx={{ mt: 6 }}>
                {groupedApplications[slug].name} ({applicationItems.length})
              </Typography>
              <hr />

              {paginatedItems.map((standard) => (
                <div key={standard.slug}>
                  {/* First row: Logo */}
                  {standard.standard_logo && (
                    <Box sx={{ mb: 1, textAlign: "center" }}>
                      <RouterLink to={`/wireless-standards/${standard.slug}`}>
                        <img
                          src={`${IMAGE_BASE_URL}${standard.standard_logo}`}
                          alt={`${standard.standard} logo`}
                          style={{
                            height: 50,
                            width: "auto",
                            cursor: "pointer",
                          }} // Cursor change on hover to indicate clickability
                        />
                      </RouterLink>
                    </Box>
                  )}

                  {/* Second row: Standard name and details */}
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Typography
                      component={RouterLink}
                      to={`/wireless-standards/${standard.slug}`}
                      sx={{
                        fontSize: "1.1rem",
                        fontWeight: "bold",
                        color: "black",
                        textDecoration: "none",
                        "&:hover": { color: tertColor },
                        display: "inline-flex",
                        alignItems: "center",
                      }}
                    >
                      {standard.standard}
                    </Typography>

                    {/* Standard Status - only show if not active */}
                    {standard.standard_status &&
                      standard.standard_status !== "Active" && (
                        <Typography
                          sx={{
                            color: "white",
                            backgroundColor:
                              statusColors[standard.standard_status] ||
                              "#6c757d",
                            borderRadius: "4px",
                            p: 0.5,
                            ml: 2,
                            display: "inline-flex",
                            fontSize: "0.8rem",
                          }}
                        >
                          {standard.standard_status}
                        </Typography>
                      )}

                    {/* Standard Organization */}
                    {standard.standard_org && (
                      <Typography
                        component={RouterLink}
                        to={`/wireless-standards-org/${standard.standard_org.slug}`}
                        sx={{
                          color: "white",
                          backgroundColor: quadColor,
                          borderRadius: "4px",
                          p: 0.5,
                          ml: 2,
                          display: "inline-flex",
                          fontSize: "0.8rem",
                          textDecoration: "none",
                          "&:hover": {
                            backgroundColor: quadHoverColor,
                            color: "white",
                            cursor: "pointer",
                          },
                        }}
                      >
                        {standard.standard_org.name}
                      </Typography>
                    )}
                  </Box>

                  {/* Standard Blurb */}
                  {standard.standard_blurb && (
                    <Box color="textSecondary" sx={{ mt: 1, mb: 2 }}>
                      {parse(standard.standard_blurb)}
                    </Box>
                  )}
                </div>
              ))}

              {applicationItems.length > ITEMS_PER_PAGE && (
                <Box sx={{ mt: 5 }}>
                  <Pagination
                    itemsCount={applicationItems.length}
                    pageSize={ITEMS_PER_PAGE}
                    currentPage={page}
                    onPageChange={(page) => handlePageChange(slug, page)}
                  />
                </Box>
              )}
            </div>
          );
        })
      )}
    </Container>
  );
};

export default WirelessStandards;

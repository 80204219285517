import React from "react";
import { Box, Paper, Typography, Divider, Button } from "@mui/material";
import parse from "html-react-parser";

const WorksheetWizSummary = ({ wiz, removeFromWorksheet, wizType }) => (
  <Box>
    {wiz.map((wz) => (
      <Paper
        key={wz.wizid}
        variant="outlined"
        sx={{ p: 3, mb: 3, borderRadius: 2 }}
      >
        <Button
          size="small"
          variant="contained"
          sx={{
            mb: 3,
            bgcolor: "error.main",
            "&:hover": { bgcolor: "error.dark" },
          }}
          onClick={() => removeFromWorksheet(wz.wizid, wizType)}
        >
          Remove from Worksheet
        </Button>
        {wz.wizall.map((section, idx) => (
          <Box key={idx} sx={{ mb: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              {section.header}
            </Typography>
            <Divider sx={{ mb: 2 }} />
            {section.questions.map((q, qIdx) => (
              <Box key={qIdx} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <strong>Q{qIdx + 1}: </strong>
                  {q.question}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ ml: 2, fontWeight: "bold", color: "green" }}
                >
                  Answer: {q.answer}
                </Typography>
                {q.notes && (
                  <Typography
                    variant="body2"
                    sx={{
                      mt: 1,
                      ml: 2,
                      bgcolor: "grey.100",
                      p: 1,
                      borderRadius: 1,
                      fontStyle: "italic",
                    }}
                  >
                    {parse(q.notes)}
                  </Typography>
                )}
              </Box>
            ))}
          </Box>
        ))}
      </Paper>
    ))}
  </Box>
);

export default WorksheetWizSummary;

import React from "react";
import { Grid, Typography } from "@mui/material";

const PartsFinder = () => {
  return (
    <Grid container spacing={3} align="left">
      <Grid item xs={12}>
        <Typography variant="body1" component="div">
          Find parts by Tech group - Category - Device Type
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PartsFinder;

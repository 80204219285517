import { toast } from "react-toastify";
import logger from "./logService";
import { csrftoken } from "../components/common/csrftoken";
import axios from "../services/axiosInstance";

// export function setJwt(jwt) {
//   // Add x-auth-token as a token header. If user not logged in token underfined and header will not be set
//   // axios.defaults.headers.common["x-auth-token"] = jwt;
// }

/***********************************************************************************************************************************/
// Axios Intercepter
/***********************************************************************************************************************************/
axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    logger.log(error);

    toast.error("An unexpected error occurred.");
  }
  // return rejected promise  -- will handle the 400-series errors (client errors) in the catch block
  return Promise.reject(error);
});

/***********************************************************************************************************************************/
// Get Cookie Function (to extract access token)
/***********************************************************************************************************************************/
export function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";").map((cookie) => cookie.trim());
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

/***********************************************************************************************************************************/
// Axios Fetch Get Service
/***********************************************************************************************************************************/

export function fetch_get(apiEndpoint) {
  // Read the access token from the cookie
  const jwt = getCookie("access_token");

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + jwt,
    },
  };

  return axios.get(apiEndpoint, requestOptions).then((response) => {
    const data = response.data;
    let fetchedData;
    if ("results" in data) {
      fetchedData = data.results;
    } else {
      fetchedData = data;
    }
    return fetchedData;
  });
}

/***********************************************************************************************************************************/
// Axios Fetch Post Service
/***********************************************************************************************************************************/
export function fetch_post(apiEndpoint, body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": csrftoken,
    },
    body: JSON.stringify(body),
  };

  let fetchedData = axios.get(apiEndpoint, requestOptions).then((response) => {
    //console.log("data at fetch_get httpservice");
    const res = response.data;
    //console.log(res);
    // if data is paginated (e.g., products), the actual data will be in results array. Return that.
    if ("results" in res) {
      fetchedData = res.results;
    } else {
      fetchedData = res;
    }
    return fetchedData;
  });
  return fetchedData;
}

/***********************************************************************************************************************************/
// Exports
/***********************************************************************************************************************************/

export default {
  get: fetch_get,
  post: fetch_post,
  getCookie: getCookie,
  // setJwt,
};

import React, { useState, useEffect, useRef } from "react";
import Carousel from "react-material-ui-carousel";
import ImageCarouselItem from "./ImageCarouselItem";
import { Dialog, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ImageCarousel = ({ images }) => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const imgRef = useRef();

  const handleOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Measure the image dimensions after it has loaded
  useEffect(() => {
    if (imgRef.current && imgRef.current.complete) {
      setImageSize({
        width: imgRef.current.naturalWidth,
        height: imgRef.current.naturalHeight,
      });
    }
  }, [selectedImage]);

  return (
    <div>
      {images.length > 0 && (
        <Carousel
          autoPlay={false}
          animation="slide"
          navButtonsAlwaysVisible={images.length > 1} //Only show nav buttons if more than one image
          swipe={images.length > 1} // Allow swipe only if more than one image
          indicators={images.length > 1} // Remove the indicator dots
        >
          {images.map((img, i) => (
            <ImageCarouselItem key={i} item={img} onClick={handleOpen} />
          ))}
        </Carousel>
      )}
      {images.length > 1 && ( // Only show thumbnails if more than one image
        <div
          style={{
            display: "flex",
            justifyContent: "center", // This centers the thumbnails
            flexWrap: "wrap", // Allows thumbnails to wrap in container
            marginTop: "1rem", // Add some space between carousel and thumbnails
            maxWidth: "300px", // Maximum width for the thumbnail container
            margin: "auto", // Center the thumbnail container
          }}
        >
          {images.map((img, i) => (
            <img
              key={i}
              src={img.image}
              alt={img.image_alt}
              className="thumbnail"
              onClick={() => handleOpen(img.image)}
              style={{
                width: "50px",
                height: "50px",
                cursor: "pointer",
                margin: "5px",
                objectFit: "cover", // This will cover the area without breaking aspect ratio
              }}
            />
          ))}
        </div>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="image-dialog-title"
        PaperProps={{
          style: {
            width: "auto", // Auto width based on content
            maxWidth: "none", // Override maxWidth to none
          },
        }}
      >
        <IconButton
          onClick={handleClose}
          style={{ position: "absolute", right: "10px", top: "10px" }}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            margin: "auto", // Center the box
            maxWidth: "calc(100% - 64px)", // Full width minus padding
            maxHeight: "90vh",
            display: "flex", // For centering the image
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden", // In case the image is still too large
          }}
        >
          <img
            ref={imgRef}
            src={selectedImage}
            alt="Enlarged view"
            style={{
              maxWidth: "100%",
              maxHeight: "90vh",
            }}
            onLoad={() => {
              // Update size state when image is loaded
              setImageSize({
                width: imgRef.current.naturalWidth,
                height: imgRef.current.naturalHeight,
              });
            }}
          />
        </Box>
      </Dialog>
    </div>
  );
};

export default ImageCarousel;

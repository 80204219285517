import React from "react";
import { useRoutes, Navigate } from "react-router-dom";
import LoginForm from "../LoginForm";
import Logout from "../Logout";
import NotFound from "../NotFound";
import KthHome from "./KthHome";
import AboutKth from "./AboutKth";
import KthServicesPatents from "./KthServicesPatents";
import KthServicesAi from "./KthServicesAi";
import KthServicesWireless from "./KthServicesWireless";
import KthTermsOfService from "./KthTermsOfService";
import KthPrivacyPolicy from "./KthPrivacyPolicy";
import KthCookiePolicy from "./KthCookiePolicy";
import KthDisclaimer from "./KthDisclaimer";
import KthContactUs from "./KthContactUs";
import KthProfessionals from "./KthProfessionals";
import KthProfile from "./KthProfile";
import KthNewsInsights from "./KthNewsInsights";
import AiResources from "./AiResources";
import KthCaseMgmt from "./KthCaseMgmt";
import AiServiceDetail from "./AiServiceDetail";
import Compliance from "./Compliance";
import ComplianceDetail from "./ComplianceDetail";
import AiDefinitionList from "./AiDefinitionList";
import AiDefinitionDetail from "./AiDefinitionDetail";
import AiEULATerms from "./AiEULATerms";
import WizMain from "./wizards-home/WizMain";
import RequireAuth from "../common/RequireAuth";
import WirelessStandards from "./WirelessStandards";
import WirelessStdDetail from "./WirelessStdDetail";
import WirelessStdOrgDetail from "./WirelessStdOrgDetail";
import Spectrum from "./Spectrum";
import SpectrumDetail from "./SpectrumDetail";

const KthRoutes = ({ user = "", baseConfig }) => {
  let routes = useRoutes([
    //--------- Public paths --------------------------------------------------------
    {
      path: "/",
      element: <KthHome baseConfig={baseConfig} />,
    },
    {
      path: "/us/*",
      element: <Navigate to="/" replace />,
    },
    { path: "/aboutus", element: <AboutKth baseConfig={baseConfig} /> },
    {
      path: "/login",
      element: <LoginForm termsLink="/terms-of-service" linkTarget="_blank" />,
    },
    //@TODO -- for now account form is just contactus form.
    {
      path: "/account",
      element: <KthContactUs baseConfig={baseConfig} />,
    },
    {
      path: "/logout",
      element: <Logout baseConfig={baseConfig} />,
    },
    {
      path: "/contact",
      element: <KthContactUs baseConfig={baseConfig} />,
    },
    {
      path: "/disclaimer",
      element: <KthDisclaimer baseConfig={baseConfig} />,
    },
    {
      path: "/professionals",
      element: <KthProfessionals baseConfig={baseConfig} />,
    },
    {
      path: "/professionals/:id",
      element: <KthProfile baseConfig={baseConfig} />,
    },
    {
      path: "/terms-of-service",
      element: <KthTermsOfService baseConfig={baseConfig} />,
    },
    {
      path: "/privacy-policy",
      element: <KthPrivacyPolicy baseConfig={baseConfig} />,
    },
    {
      path: "/cookie-policy",
      element: <KthCookiePolicy baseConfig={baseConfig} />,
    },
    //--------- Service Public Paths --------------------------------------------------------
    {
      path: "/patents",
      element: <KthServicesPatents baseConfig={baseConfig} />,
    },
    {
      path: "/ai",
      element: <KthServicesAi baseConfig={baseConfig} />,
    },
    {
      path: "/wireless",
      element: <KthServicesWireless baseConfig={baseConfig} />,
    },
    {
      path: "/telecom",
      element: <Navigate to="/wireless" replace />,
    },
    //--------- News & Insights -------------------------------------------------
    {
      path: "/news-insights",
      element: <KthNewsInsights baseConfig={baseConfig} />,
    },
    //--------- Patent Resources -------------------------------------------------
    {
      path: "/patent-ai-resources",
      element: <AiResources baseConfig={baseConfig} />,
    },
    //--------- AI Resources -------------------------------------------------
    {
      path: "/ai-legal-compliance",
      element: <Compliance baseConfig={baseConfig} scope="ai" />,
    },
    {
      path: "/ai-legal-compliance/:slug",
      element: <ComplianceDetail baseConfig={baseConfig} scope="ai" />,
    },
    //--------- Telecom Resources -------------------------------------------------
    {
      path: "/wireless-legal-compliance",
      element: <Compliance baseConfig={baseConfig} scope="wireless" />,
    },
    {
      path: "/wireless-legal-compliance/:slug",
      element: <ComplianceDetail baseConfig={baseConfig} scope="wireless" />,
    },
    {
      path: "/wireless-standards",
      element: <WirelessStandards baseConfig={baseConfig} />,
    },
    {
      path: "/wireless-standards/:slug",
      element: <WirelessStdDetail baseConfig={baseConfig} />,
    },
    {
      path: "/spectrum-licensing",
      element: <Spectrum baseConfig={baseConfig} />,
    },
    {
      path: "/wireless-spectrum/:slug",
      element: <SpectrumDetail baseConfig={baseConfig} />,
    },
    {
      path: "/wireless-standards-org/:slug",
      element: <WirelessStdOrgDetail baseConfig={baseConfig} />,
    },
    //--------- Wizards --------------------------------------------------------
    {
      path: "/patent-enforcement",
      element: (
        <WizMain baseConfig={baseConfig} wizardType="patent-enforcement" />
      ),
    },
    {
      path: "/ai-compliance-checker",
      element: (
        <RequireAuth>
          <WizMain baseConfig={baseConfig} wizardType="ai-compliance" />
        </RequireAuth>
      ),
    },
    {
      path: "/ai-eula-checker",
      element: (
        <RequireAuth>
          <WizMain baseConfig={baseConfig} wizardType="ai-eula" />
        </RequireAuth>
      ),
    },
    //--------- Authenticated Paths --------------------------------------------------------

    {
      path: "/ai-service/:slug",
      element: (
        <RequireAuth>
          <AiServiceDetail baseConfig={baseConfig} />
        </RequireAuth>
      ),
    },

    {
      path: "/ai-eula-terms",

      element: (
        <RequireAuth>
          <AiEULATerms baseConfig={baseConfig} />
        </RequireAuth>
      ),
    },
    {
      path: "/ai-legal-definitions",
      element: (
        <RequireAuth>
          <AiDefinitionList baseConfig={baseConfig} />
        </RequireAuth>
      ),
    },
    {
      path: "/ai-legal-definitions/:slug",
      element: (
        <RequireAuth>
          <AiDefinitionDetail baseConfig={baseConfig} />
        </RequireAuth>
      ),
    },
    {
      path: "/case-management",
      element: (
        <RequireAuth>
          <KthCaseMgmt baseConfig={baseConfig} />
        </RequireAuth>
      ),
    },

    //--------- Catch-all for undefined routes --------------------------------------------------------
    {
      path: "*",
      element: <NotFound />, // This will display a 404 message for undefined paths
    },
  ]); // end useRoutes
  return routes;
};

export default KthRoutes;

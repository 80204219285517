import {
  faHome,
  faBroadcastTower,
  faFileContract,
  faNfcSignal,
  faSquareInfo,
  faFileCertificate,
} from "@fortawesome/pro-solid-svg-icons";
import HomeIcon from "@mui/icons-material/Home";
import CellTowerIcon from "@mui/icons-material/CellTower";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import ArticleIcon from "@mui/icons-material/Article";

export const patanalAiNavbarConfig = [
  {
    title: "Home | Chat",
    path: "/",
    iconFa: faHome,
    iconMui: HomeIcon,
    authRequired: false,
    children: [],
  },
  {
    title: "Wireless Patents",
    path: "/patents",
    iconFa: faFileCertificate,
    iconMui: LibraryBooksIcon,
    authRequired: false,
    children: [
      {
        title: "Patent Search",
        path: "/patents/patent-search",
        pageFooterText:
          "Patent analytics powered by <a href='https://github.com/pqaidevteam/pqai/blob/master/docs/README-API.md' target='_blank' rel='noopener noreferrer'>Project PQAI</a>",
      },
      {
        title: "Prior Art Search",
        path: "/patents/patent-prior-art",
        pageFooterText:
          "Patent analytics powered by <a href='https://github.com/pqaidevteam/pqai/blob/master/docs/README-API.md' target='_blank' rel='noopener noreferrer'>Project PQAI</a>",
      },
      {
        title: "Patent Classification",
        path: "/patents/patent-classify",
      },
      {
        title: "Patent Review",
        path: "/patents/patent-review",
      },
    ],
  },
  {
    title: "Wireless Devices",
    path: "/devices",
    iconFa: faNfcSignal,
    iconMui: CellTowerIcon,
    authRequired: false,
    children: [
      {
        title: "Device Search",
        path: "/devices/device-search",
        pageFooterText:
          "Device data provided by <a href='https://wdi.rfwel.com/' target='_blank'>Rfwel Engr WDI Research</a>",
      },
      {
        title: "Device Detail",
        path: "/devices/device-detail",
        pageFooterText:
          "Device data provided by <a href='https://wdi.rfwel.com/' target='_blank'>Rfwel Engr WDI Research</a>",
      },
      {
        title: "Device Classification",
        path: "/devices/device-classify",
      },
    ],
  },
  {
    title: "Wireless Standards",
    path: "/standards",
    iconFa: faBroadcastTower,
    iconMui: CellTowerIcon,
    authRequired: false,
    children: [
      {
        title: "Cellular (LTE/NR)",
        path: "/standards/cellular",
      },
      {
        title: "LPWAN (IoT)",
        path: "/standards/lpwan",
      },
      {
        title: "WLAN (WiFi)",
        path: "/standards/wlan",
      },
    ],
  },

  {
    title: "Licensing",
    path: "/licensing",
    iconFa: faFileContract,
    iconMui: ArticleIcon,
    authRequired: true,
    children: [
      {
        title: "Segment Search",
        path: "/licensing/segment-search",
      },
      {
        title: "Segment Detail",
        path: "/licensing/segment-detail",
      },
    ],
  },
];

import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Typography,
  Chip,
  Avatar,
  Link as MuiLink,
  Button,
} from "@mui/material";
import CodeIcon from "@mui/icons-material/Code";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import ClassIcon from "@mui/icons-material/Class";
import TuneIcon from "@mui/icons-material/Tune";
import BalanceIcon from "@mui/icons-material/Balance";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import PersonIcon from "@mui/icons-material/Person";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import VideocamIcon from "@mui/icons-material/Videocam";
import SummarizeIcon from "@mui/icons-material/Summarize";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import QuizIcon from "@mui/icons-material/Quiz";
import ReusableTitle from "../common/ReusableTitle";
import { faTag } from "@fortawesome/pro-solid-svg-icons";
import LaunchIcon from "@mui/icons-material/Launch";
import { getImageUrl } from "../../utils/helpers";

const labelToLogo = {
  "AWS SageMaker": getImageUrl("aws_logo2.jpg"),
  // Dataloop: getImageUrl("dataloop_logo.jpg"),
  Doccano: getImageUrl("doccano_logo.png"),
  Labelbox: getImageUrl("labelbox_logo.jpg"),
  LightTag: getImageUrl("lighttag_logo.png"),
  // Prodigy: getImageUrl("prodigy_logo.png"),
  SuperAnnotate: getImageUrl("superannotate_logo.png"),
  // Encord: getImageUrl("encord_logo.png"), // Computer vision & video annotation
  "Label Studio": getImageUrl("label_studio_logo.jpg"),
  // "Scale AI": getImageUrl("scaleai_logo.png"),
  // "V7 Labs": getImageUrl("v7labs_logo.jpg"), // Computer vision & video annotation
  // Supervisely: getImageUrl("supervisely.png"), // Computer vision & video annotation
};

const KthAiLabeling = ({ baseConfig }) => {
  const {
    priColor,
    priHoverColor,
    tertColor,
    tertHoverColor,
    quadColor,
    quadHoverColor,
  } = baseConfig.siteBranding;
  return (
    <Container>
      <Box sx={{ mt: 2 }}>
        <ReusableTitle
          title="Labeling Data for AI Training & Fine-Tuning"
          isMainHeading={true}
          siteBranding={baseConfig.siteBranding}
          icon={{ faIcon: faTag }}
        />

        <Box sx={{ mt: 2, mb: 4 }}>
          <ReusableTitle
            title="Overview of Kama Thuo, PLLC Labeling Services"
            isMainHeading={false}
            siteBranding={baseConfig.siteBranding}
          />
          <Typography variant="body1" gutterBottom sx={{ mt: 2, ml: 3 }}>
            Kama Thuo, PLLC is a U.S. licensed law firm with an engineering &
            technology law practice focus. As part of our AI counseling and AI
            patent analytics tool support, our staff has become adept at
            annotating data to train AI models for legal applications. In
            addition to U.S.-based staff, we have highly-trained and tech-savvy
            staff in Nairobi for budget-friendly labeling tasks, including staff
            with engineering and science degrees from accredited national
            universities. We closely collaborate with our AI Automation vendor{" "}
            <MuiLink href="https://ai.rfwel.com/" target="_blank">
              Rfwel Engineering, LLC
            </MuiLink>{" "}
            for more nuanced AI support. Please{" "}
            <MuiLink component={RouterLink} to="/contact">
              contact us
            </MuiLink>{" "}
            to discuss your legal annotation needs.
          </Typography>

          <Button
            startIcon={<LaunchIcon />}
            variant="contained"
            sx={{
              mt: 2,
              ml: 3,
              backgroundColor: priColor,
              color: "#fff",
              "&:hover": { backgroundColor: priHoverColor, color: "#fff" },
            }}
            component={RouterLink}
            to="/news-insights?category=ai-labeling"
          >
            Learn More
          </Button>

          <Grid container spacing={1} sx={{ mt: 2, ml: 3 }}>
            <Grid item>
              <Chip label="Legal Annotation" />
            </Grid>
            <Grid item>
              <Chip label="Patent Law" />
            </Grid>
            <Grid item>
              <Chip label="Patent Analytics" />
            </Grid>
            <Grid item>
              <Chip label="Technology Transactions" />
            </Grid>
          </Grid>
        </Box>

        <ReusableTitle
          title="Summary of Skillset"
          isMainHeading={false}
          siteBranding={baseConfig.siteBranding}
        />

        <Typography
          variant="h6"
          gutterBottom
          sx={{ mt: 2, ml: 3, color: "#96858F" }}
        >
          Example Labeling Software
        </Typography>

        <Grid container spacing={1} sx={{ mt: 1, mb: 4, ml: 3 }}>
          {Object.keys(labelToLogo).map((label) => (
            <Grid item key={label}>
              <Chip
                label={label}
                avatar={<Avatar src={labelToLogo[label]} />}
              />
            </Grid>
          ))}
        </Grid>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ mt: 2, ml: 3, color: "#96858F" }}
        >
          Example Data Types
        </Typography>

        <Grid container spacing={1} sx={{ mt: 1, mb: 4, ml: 3 }}>
          {/* <Grid item>
            <Chip label="Computer Code/Programming" icon={<CodeIcon />} />
          </Grid> */}
          <Grid item>
            <Chip label="Document" icon={<AssignmentIcon />} />
          </Grid>
          <Grid item>
            <Chip label="Text" icon={<TextFieldsIcon />} />
          </Grid>
          <Grid item>
            <Chip label="Audio" icon={<GraphicEqIcon />} />
          </Grid>
          <Grid item>
            <Chip label="Video" icon={<VideocamIcon />} />
          </Grid>
        </Grid>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ mt: 2, ml: 3, color: "#96858F" }}
        >
          Example Labeling Types
        </Typography>

        <Grid container spacing={1} sx={{ mt: 1, ml: 3 }}>
          <Grid item>
            <Chip label="Classification" icon={<ClassIcon />} />
          </Grid>
          <Grid item>
            <Chip label="Data Collection" icon={<DataUsageIcon />} />
          </Grid>
          {/* <Grid item>
            <Chip
              label="Entity (NER) Classification"
              icon={<AssignmentTurnedInIcon />}
            />
          </Grid> */}
          <Grid item>
            <Chip label="Fine-tuning" icon={<TuneIcon />} />
          </Grid>
          <Grid item>
            <Chip
              label="Prompt + Response Writing"
              icon={<AssignmentTurnedInIcon />}
            />
          </Grid>
          <Grid item>
            <Chip label="Legal Text Summarizing" icon={<SummarizeIcon />} />
          </Grid>
          <Grid item>
            <Chip label="RLHF" icon={<SensorOccupiedIcon />} />
          </Grid>
          <Grid item>
            <Chip label="Evaluation" icon={<QuizIcon />} />
          </Grid>
        </Grid>

        <Box sx={{ mt: 4 }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ mt: 2, ml: 3, color: "#96858F" }}
          >
            Industry Labeling Experience
          </Typography>

          <Chip
            label="Legal Annotation"
            icon={<BalanceIcon />}
            sx={{ mt: 1, ml: 3 }}
          />
        </Box>

        <Box sx={{ mt: 4 }}>
          <ReusableTitle
            title="Data Security Compliance"
            isMainHeading={false}
            siteBranding={baseConfig.siteBranding}
          />

          <Typography variant="body1" gutterBottom sx={{ mt: 2, ml: 3 }}>
            As a full-fledged U.S. law firm we enforce strict client
            confidentiality to protect attorney-client privilege, attorney work
            product, and our ethical duty of confidentiality.
          </Typography>
          <Grid container spacing={1} sx={{ mt: 2, ml: 3 }}>
            <Grid item>
              <Chip label="Attorney-Client Priviledge" />
            </Grid>
            <Grid item>
              <Chip label="Attorney Work-Product" />
            </Grid>
            <Grid item>
              <Chip label="Ethical Duty of Confidentiality" />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 4 }}>
          <ReusableTitle
            title="Staffing Locations"
            isMainHeading={false}
            siteBranding={baseConfig.siteBranding}
          />

          <Chip
            label="Phoenix, USA"
            avatar={<Avatar src={getImageUrl("usa_flag_round_100px.png")} />}
            sx={{ mr: 2, mt: 2, ml: 3 }}
          />
          <Chip
            label="Nairobi, Kenya"
            avatar={<Avatar src={getImageUrl("kenya_flag.png")} />}
            sx={{ mr: 2, mt: 2 }}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default KthAiLabeling;

import React, { Component, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  Typography,
  TextField,
  FormHelperText,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Collapse,
  Alert,
} from "@mui/material";
import prods from "../utils/prods";
import SalesAnalTable from "./SalesAnalTable";
import Pagination from "../../common/Pagination";
import { paginate } from "../utils/paginate";
import ListGroup from "../../common/ListGroup";
import SearchBox from "../../common/SearchBox";
import _ from "lodash";
import * as CONFIG from "../utils/Global-Labels-Links-Vars";
import LoadingSpinner from "../../common/LoadingSpinner";

const SalesAnal = () => {
  //-------------------------------------------------------------------------------
  // SET DEFAULTS
  //-------------------------------------------------------------------------------
  const DEFAULT_PAGE_SIZE = 10;
  const DEFAULT_SALES_PERIOD = "YTD";
  const DEFAULT_SKUS_TO_GET = 10;
  const MAX_SKUS_TO_GET = 100;
  //-------------------------------------------------------------------------------
  // HOOKS
  //-------------------------------------------------------------------------------

  const navigate = useNavigate();
  const [skusToGet, setSkusToGet] = useState(DEFAULT_SKUS_TO_GET);
  const [salesPeriod, setSalesPeriod] = useState(DEFAULT_SALES_PERIOD);
  const [products, setProducts] = useState([]);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(1);
  const [categories, setCategories] = useState([{ title: "All Categories" }]);
  const [selectedCategory, setSelectedCategory] = useState({
    title: "All Categories",
  });
  const [sortCol, setSortCol] = useState({
    path: "gross_margin",
    order: "desc",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  //-------------------------------------------------------------
  const _handleSkusToGetChange = (e) => {
    let selectedSkusToGet = e.target.value;
    if (selectedSkusToGet > MAX_SKUS_TO_GET) {
      selectedSkusToGet = MAX_SKUS_TO_GET;
    }

    setSkusToGet(selectedSkusToGet);
  };
  //-------------------------------------------------------------
  const _handleSalesPeriodChange = (e) => {
    setSalesPeriod(e.target.value);
  };
  //-------------------------------------------------------------
  const _handleGetSalesDataButtonClicked = () => {
    // Set IsLoading to true and disable Get Margin Data button to prevent multiple API calls
    setIsLoading(true);
    const fetchProds = async () => {
      const productsFetched = await prods.get_all(
        `/patanal_api/products-top-sales/?sales_period=${salesPeriod}&numprods=${skusToGet}`
      );
      setProducts(productsFetched);
      // Hide Loading Screen on fetch
      setIsLoading(false);
      //console.log("Sales products fetched at useEffect --> ");
      //console.log(productsFetched);
      // crate a categories array of objects using map
      // See example if want to modify objects based on conditions -> https://www.freecodecamp.org/news/javascript-array-of-objects-tutorial-how-to-create-update-and-loop-through-objects-using-js-array-methods/
      const categoriesFetched = productsFetched.map((cat) => {
        let catProperties = {
          title: cat.category,
        };
        return catProperties;
      });
      //console.log("categoriesFetched --> ");
      //console.log(categoriesFetched);
      // Now set categories using setCategories hook using title.
      setCategories([{ title: "All Categories" }, ...categoriesFetched]);
    };
    fetchProds();
  };

  //-------------------------------------------------------------
  const _handlePageChange = (page) => {
    //console.log(`Page Clicked = ${page}`);
    setCurrentPage(page);
  };
  //-------------------------------------------------------------
  const _handleCategorySelect = (category) => {
    //console.log(category);
    setSelectedCategory(category);
    // Note cannot set search query to null because this is a controlled component. If set to null/undefined react will think this is an uncontrolled component
    // and will give warning when user types in typed that trying to covert an uncontrolled component to a controlled component.
    setSearchQuery("");
    setCurrentPage(1);

    // Reset current page to 1 otherwise if select a category with multiple pages then go back to a category with fewer pages, will display blank
    setCurrentPage(1);
  };
  //-------------------------------------------------------------
  const _handleSort = (receivedSortCol) => {
    setSortCol((prevState) => ({
      ...prevState,
      path: receivedSortCol.path,
      order: receivedSortCol.order,
    }));
  };
  //-------------------------------------------------------------
  const _handleSearchChange = (query) => {
    setSearchQuery(query);
    setSelectedCategory("");
    setCurrentPage(1);
  };
  //-------------------------------------------------------------------------------
  // COMPONENT OBJECTS
  //-------------------------------------------------------------------------------
  // use destructuring to get length of products (number of items and rename to prodCount)
  // the below equivalent to const prodCount = products.length.
  const { length: prodCount } = products;

  // filter products based on selected category (or search query) before pagination
  // && used to cover all categories
  // start with filtered products as all products
  let productsFiltered = products;

  if (searchQuery) {
    // if there is a search query then fitler based on search query
    productsFiltered = products.filter((p) =>
      p.sku.toLowerCase().startsWith(searchQuery.toLowerCase())
    );
  } else {
    // if no search query but there's category selection then filter based on category
    productsFiltered =
      selectedCategory && selectedCategory.title !== "All Categories"
        ? products.filter((p) => p.category === selectedCategory.title)
        : products;
  }

  // sort products
  // sortCol.path and sortCol.order are in array because we can sort by multiple columns using lodash's orderBy util
  const productsSorted = _.orderBy(
    productsFiltered,
    [sortCol.path],
    [sortCol.order]
  );

  // paginate products
  const productsPaginated = paginate(productsSorted, currentPage, pageSize);

  //-------------------------------------------------------------------------------
  // Conditional Render of Sales Anal Table
  //-------------------------------------------------------------------------------
  const _renderSalesTable = () => {
    if (isLoading) return <LoadingSpinner />;
    if (!prodCount || prodCount === 0)
      return (
        <div style={{ marginTop: "10px" }}>
          {CONFIG.RFW_HORIZDOT}
          <p> No products listed in database matching search criteria.</p>
        </div>
      );
    return (
      <Grid item xs={12} align="center">
        <div>{CONFIG.RFW_HORIZDOT}</div>
        <div className="row">
          <div className="col-2">
            <ListGroup
              items={categories}
              selectedItem={selectedCategory}
              onItemSelect={_handleCategorySelect}
              textProperty="title"
              idProperty="title"
            />
          </div>
          <div className="col">
            <p>
              Showing{" "}
              <strong>
                {" "}
                {productsFiltered.length} {selectedCategory.title}{" "}
              </strong>{" "}
              products in the database matching selected criteria.
            </p>
            <SearchBox
              searchValue={searchQuery}
              searchOnChange={(e) => _handleSearchChange(e.currentTarget.value)}
            />
            <SalesAnalTable
              prods={productsPaginated}
              sortColumn={sortCol}
              onSort={_handleSort}
            />

            <Pagination
              itemsCount={productsFiltered.length}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={_handlePageChange}
            />
          </div>
        </div>
      </Grid>
    );
  };

  //-------------------------------------------------------------------------------
  //Render of Sales Anal Page
  //-------------------------------------------------------------------------------
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} align="center">
        <Typography component="h4" variant="h4">
          {"Sales Analysis"}
        </Typography>
        <Typography component="h6" variant="h6">
          {"Select Sales Period (All Rfwel Listing Channels)"}
        </Typography>
      </Grid>
      <Grid item xs={12} align="center">
        <FormControl component="fieldset">
          <RadioGroup
            row
            defaultValue={"YTD"}
            onChange={_handleSalesPeriodChange}
          >
            <FormControlLabel
              value="MTD"
              control={<Radio color="primary" />}
              label="Month-to-Date"
              labelPlacement="bottom"
            />
            <FormControlLabel
              value="YTD"
              control={<Radio color="primary" />}
              label="Year-to-Date"
              labelPlacement="bottom"
            />
            <FormControlLabel
              value="LM"
              control={<Radio color="primary" />}
              label="Last Month"
              labelPlacement="bottom"
            />
            <FormControlLabel
              value="LY"
              control={<Radio color="primary" />}
              label="Last Year"
              labelPlacement="bottom"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} align="center">
        <FormControl>
          <TextField
            required={true}
            type="number"
            onChange={_handleSkusToGetChange}
            defaultValue={DEFAULT_SKUS_TO_GET}
            inputProps={{
              min: 1,
              max: MAX_SKUS_TO_GET,
              style: { textAlign: "center" },
            }}
          />
          <FormHelperText>
            <span align="center">Number of SKUs to Get</span>
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid item xs={12} align="center">
        <Button
          color="primary"
          variant="contained"
          onClick={_handleGetSalesDataButtonClicked}
          disabled={isLoading}
        >
          {" "}
          Get Sales Data
        </Button>
      </Grid>
      <Grid item xs={12} align="center">
        <Button color="secondary" variant="contained" to="/" component={Link}>
          {" "}
          Back
        </Button>
      </Grid>

      {_renderSalesTable()}
    </Grid>
  );
};

export default SalesAnal;

import React from "react";
import { Link } from "react-router-dom";
import Table from "../../common/Table";
import { buildChannelLinks } from "../utils/buildChannelLink";
import * as CONFIG from "../utils/Global-Labels-Links-Vars";

const PriceVsBigcTable = ({ prods, onSort, sortColumn }) => {
  const columnsStatic = [
    {
      path: "sku",
      label: "SKU",
      content: (prod) => (
        <Link to={`/product-detail/${prod.sku}`}> {prod.sku} </Link>
      ),
    },
    {
      path: "price_bigc_unit",
      label: "BigC Price",
      content: (prod) => CONFIG.formatCurrency(prod.price_bigc_unit),
    },
    {
      path: "margin_percent_bigc",
      label: "BigC Margin %",
      content: (prod) => CONFIG.formatPercentage(prod.margin_percent_bigc),
    },
    {
      path: "channel",
      label: "Channel",
      content: (prod) => buildChannelLinks(prod.channel, prod.channid),
    },
    {
      path: "price",
      label: "Channel Price",
      content: (prod) => CONFIG.formatCurrency(prod.price),
    },
    {
      path: "margin_percent",
      label: "Channel Margin %",
      content: (prod) => CONFIG.formatPercentage(prod.margin_percent),
    },
    {
      path: "percent_above_bigc",
      label: "Percent Above BigC %",
      content: (prod) => CONFIG.formatPercentage(prod.percent_above_bigc),
    },
  ];

  return (
    <Table
      columns={columnsStatic}
      sortColumn={sortColumn}
      onSort={onSort}
      data={prods}
      idProperty={"sku"}
      id2Property={"channel"}
    />
  );
};

export default PriceVsBigcTable;

import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  Alert,
  Box,
  Typography,
} from "@mui/material";
import StorageIcon from "@mui/icons-material/Storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileXls, faFilePdf } from "@fortawesome/pro-solid-svg-icons";
import WorksheetPatentTable from "./WorksheetPatentTable";
import WorksheetDeviceTable from "./WorksheetDeviceTable";
import WorksheetLicensingTable from "./WorksheetLicensingTable";
import WorksheetWizSummary from "./WorksheetWizSummary";
import { useWorksheet } from "../../context/WorksheetContext";
import { exportToExcel, exportAllToExcel } from "../../utils/excelExportUtils";
import { exportToPDF, exportAllToPDF } from "../../utils/pdfExportUtils";
import { useAuth } from "../../context/AuthContext";
import RestrictedAccessPrompt from "./RestrictedAccessPrompt";

const Worksheet = ({ baseConfig }) => {
  const {
    worksheetTabs,
    worksheetUserAuth,
    siteBranding: { worksheetButtonColor },
  } = baseConfig;

  const { user } = useAuth();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(worksheetTabs[0]);
  const { worksheet, removeFromWorksheet } = useWorksheet();

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  //-----------------------------------------------------------------------------------------
  // Render Worksheet Tab Content
  //-----------------------------------------------------------------------------------------
  const renderContent = () => {
    const data = worksheet[activeTab];
    const hideExportExcel = [
      "patent-enforcement",
      "ai-compliance",
      "ai-eula",
    ].includes(activeTab);

    if (!user && worksheetUserAuth) {
      //  Show restricted access prompt if user is not authenticated, and auth is required
      return <RestrictedAccessPrompt component={"Worksheet"} />;
    }

    if (activeTab === "summary") {
      return (
        <Box sx={{ display: "flex", justifyContent: "flex-start", padding: 2 }}>
          <Button
            variant="outlined"
            startIcon={<FontAwesomeIcon icon={faFileXls} />}
            onClick={() => exportAllToExcel(worksheet)}
            sx={{ ml: 2, width: "auto" }}
          >
            Export all to Excel
          </Button>

          <Button
            variant="outlined"
            startIcon={<FontAwesomeIcon icon={faFilePdf} />}
            onClick={() => exportAllToPDF(worksheet)}
            sx={{ ml: 2, width: "auto" }}
          >
            Export all to PDF
          </Button>
        </Box>
      );
    }

    // put after summary because summary does not contain data
    if (!worksheet[activeTab] || worksheet[activeTab].length === 0) {
      return (
        <Alert severity="info" style={{ margin: 20 }}>
          There are no items saved to the {activeTab} worksheet.
        </Alert>
      );
    }

    return (
      <>
        <Box sx={{ display: "flex", justifyContent: "flex-start", padding: 2 }}>
          {!hideExportExcel && (
            <Button
              variant="outlined"
              startIcon={<FontAwesomeIcon icon={faFileXls} />}
              onClick={() => exportToExcel(data, activeTab)}
              sx={{ width: "auto" }}
            >
              Export Excel
            </Button>
          )}
          <Button
            variant="outlined"
            startIcon={<FontAwesomeIcon icon={faFilePdf} />}
            onClick={() => exportToPDF(data, activeTab)}
            sx={{ ml: 2, width: "auto" }}
          >
            Export PDF
          </Button>
        </Box>
        {activeTab === "patents" ? (
          <WorksheetPatentTable
            patents={data}
            removeFromWorksheet={removeFromWorksheet}
          />
        ) : activeTab === "devices" ? (
          <WorksheetDeviceTable
            devices={data}
            removeFromWorksheet={removeFromWorksheet}
          />
        ) : activeTab === "patent-enforcement" ||
          activeTab === "ai-compliance" ||
          activeTab === "ai-eula" ? (
          <WorksheetWizSummary
            wiz={data}
            wizType={activeTab}
            removeFromWorksheet={removeFromWorksheet}
          />
        ) : (
          <WorksheetLicensingTable
            licensing={data}
            removeFromWorksheet={removeFromWorksheet}
          />
        )}
        <Box sx={{ ml: 2, mt: 2, mb: 2 }}>
          <Typography variant="body2" sx={{ fontStyle: "italic" }}>
            {hideExportExcel
              ? "Click on 'Export PDF' above to export the worksheet."
              : `Click on 'Export Excel' or 'Export PDF' above to export the ${activeTab} worksheet.`}{" "}
            {worksheetTabs.includes("summary")
              ? 'Export all worksheets to an Excel workbook or PDF in the "Summary" tab.'
              : ""}
          </Typography>
        </Box>
      </>
    );
  };

  const tabLabels = {
    summary: "Summary",
    patents: "Patents",
    devices: "Devices",
    licensing: "Licensing",
    "patent-enforcement": "Patent Assertion/Defense",
    "ai-compliance": "AI Compliance",
    "ai-eula": "AI EULA",
  };

  //-----------------------------------------------------------------------------------------
  // Render View Worksheet Button and Dialog
  //-----------------------------------------------------------------------------------------
  return (
    <>
      <Button
        variant="text"
        size="large"
        onClick={() => setDialogOpen(true)}
        startIcon={<StorageIcon />}
        sx={{ mr: 2, color: worksheetButtonColor }}
      >
        Worksheet
      </Button>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth
        maxWidth="xl"
      >
        <DialogContent>
          <Tabs
            value={activeTab}
            onChange={handleChangeTab}
            variant="scrollable"
            scrollButtons="auto"
            centered={false}
            sx={{ overflowX: "auto" }}
          >
            {worksheetTabs.map((tab) => (
              <Tab
                key={tab}
                label={tabLabels[tab] || tab.replace("-", " ").toUpperCase()}
                value={tab}
              />
            ))}
          </Tabs>
          {renderContent()}
        </DialogContent>

        <DialogActions>
          <Button color="primary" onClick={() => setDialogOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Worksheet;
